import {
  Component,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ModalProps, ModalService } from './modal.service';

@Component({
  selector: 'rr-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalContainerComponent {
  @ViewChild('confirmDiscard') confirmDiscard: TemplateRef<any>;

  constructor(public modalService: ModalService) {}

  closeAfterConfirm(modal: ModalProps) {
    if (modal.dismissable ?? true) {
      if (modal.confirmDiscard) {
        this.modalService.show({
          title: 'Discard Changes?',
          template: this.confirmDiscard,
          showClose: false,
          dismissable: false,
        });
        return;
      }

      this.modalService.close();
    }
  }
}
