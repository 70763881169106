import { Provider } from '@angular/core';
import { GarmentSelectionService } from './garment-selection/services/garment-selection.service';
import { IGarmentSelectionService } from './garment-selection/services/i-garment-selection.service';
import { IAccessorySelectionService } from './accessory-selection/service/iaccessory-selection.service';
import { AccessorySelectionService } from './accessory-selection/service/accessory-selection.service';

export const GeneralComponents = [];

export const GeneralProviders: Provider[] = [
  {
    provide: IGarmentSelectionService,
    useClass: GarmentSelectionService,
  },
  {
    provide: IAccessorySelectionService,
    useClass: AccessorySelectionService,
  },
];
