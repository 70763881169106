import { NgModule } from '@angular/core';
import { ModalComponent } from './components/modal/modal.component';
import { RRSharedModule } from '../shared-module/shared.module';
import { ModalConfirmDirective } from '../modal/directives/modal-confirm.directive';
import { ConfirmDeleteModalComponent } from './components/confirm-delete-modal/confirm-delete-modal.component';
import { RRBootstrapModule } from '../bootstrap/bootstrap.module';
import { BaseModalComponent } from './components/base-modal/base-modal.component';

@NgModule({
  declarations: [
    ModalComponent,
    ModalConfirmDirective,
    ConfirmDeleteModalComponent,
    BaseModalComponent,
  ],
  imports: [RRSharedModule, RRBootstrapModule],
  exports: [
    ModalComponent,
    ModalConfirmDirective,
    ConfirmDeleteModalComponent,
    BaseModalComponent,
  ],
})
export class RRModalModule {}
