<div class="header {{ visible ? 'mb-3' : '' }}">
  <h2>
    {{ title }}
    <span style="font-size: small"> {{ ' (' + references?.length + ')' }}</span>
  </h2>
  <span
    title="loading..."
    class="loader"
    [hidden]="(loading$ | async) === false"
  ></span>
  <button
    class="btn btn-outline-primary"
    (click)="openClicked()"
    [hidden]="loading$ | async"
  >
    {{ visible ? 'Close' : 'Edit' }}
  </button>
</div>
<div
  cdkDropList
  [hidden]="!visible"
  (cdkDropListDropped)="drop($event)"
  [cdkDropListAutoScrollStep]="20"
  [cdkDropListAutoScrollDisabled]="false"
>
  <span *ngIf="references?.length === 0"><b>No items selected...</b></span>
  <div *ngFor="let reference of references; trackBy: identify" cdkDrag>
    <app-asset-list-row
      [reference]="reference"
      (updateReference)="this.updateReference($event)"
      (removeReference)="this.removeReference($event)"
      (moveReferenceUp)="this.moveRefUp.emit($event)"
      (moveReferenceDown)="this.moveRefDown.emit($event)"
    >
    </app-asset-list-row>
  </div>
</div>
