import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RRTableComponent } from './components/table/table.component';
import { RRTableRowDirective } from './directives/table-row';
import { RRPipesModule } from '../pipes/public-api';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [RRTableComponent, RRTableRowDirective],
  imports: [CommonModule, RRPipesModule, NgxSkeletonLoaderModule],
  exports: [RRTableComponent, RRTableRowDirective],
})
export class RRTableModule {}
