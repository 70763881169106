<div class="collection-overview d-flex flex-column">
  <div class="overview-scroll d-flex flex-column">
    <rr-card id="collection-list">
      <rr-overlay-spinner [spinnerStatus]="spinnerStatus"></rr-overlay-spinner>
      <div class="inner">
        <h2>Collections</h2>
        <button
          id="collection-overview-new-collection"
          class="btn btn-primary mt-3 mb-5 ml-3"
          (click)="onCreateNewClicked()"
          routerLink="/collections/create"
        >
          <span class="oi oi-plus"></span>
          <span> New Collection </span>
        </button>
        <rr-table
          [configuration]="collectionTableConfiguration"
          [datasource]="collectionDatasource"
          (sortColumnClicked)="onSortColumn($event)"
        >
          <div
            class="empty-table-state d-flex flex-column justify-content-center align-items-center"
            emptyState
          >
            <span class="rr-empty-hanger"></span>
            <span class="empty-first-line"
              >We couldn’t find any collections.</span
            >
            <span class="empty-second-line">Try adjusting your filters.</span>
            <!-- <span class="btn-underline" (click)="clearFilter()"
            >Clear all filters</span
          > -->
          </div>
        </rr-table>
      </div>
    </rr-card>
  </div>
</div>
