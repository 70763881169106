import { Component, OnInit } from '@angular/core';
import { OutfitReferenceJson } from '../../collection-screen.state';
import { InputTextComponent } from '../../../components/inputs/input-text/input-text.component';
import { CommonModule } from '@angular/common';
import { BaseListRowComponent } from '../base-list-row/base-list-row.component';
import { RowButtonsComponent } from '../row-buttons/row-buttons.component';
import { SimpleFileService } from '../../services/file.service';
import { RRCopyToClipboardModule } from 'projects/web-ui-component-library/src';
import { CopyToClipboardComponent } from '../copy-to-clipboard/copy-to-clipboard.component';
import { CdkDragHandle } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-outfit-list-row',
  standalone: true,
  imports: [
    InputTextComponent,
    CommonModule,
    RowButtonsComponent,
    RRCopyToClipboardModule,
    CopyToClipboardComponent,
    CdkDragHandle,
  ],
  templateUrl: './outfit-list-row.component.html',
  styleUrls: ['../base-list-row/base-list-row.scss'],
})
export class OutfitListRowComponent
  extends BaseListRowComponent<OutfitReferenceJson>
  implements OnInit
{
  public fileContent: string;
  constructor(private _simpleFileService: SimpleFileService) {
    super();
  }
  ngOnInit(): void {
    if (this.reference?.resultUrl) {
      this._simpleFileService
        .readJsonFile(this.reference.resultUrl)
        .subscribe((res) => {
          this.fileContent = btoa(
            JSON.stringify({
              type: 'pictofit::virtualstyling::style',
              collection: res,
            }),
          );
        });
    }
  }
}
