import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ILogger } from 'projects/content-service-cms/src/app/logging';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarService {
  public killHttp: Subject<void>;
  public isLoading: Subject<any>;
  public showProgressBar: boolean;

  private maxEntities: number;
  private progressBarValue: number;

  constructor(private _logger: ILogger) {
    this.killHttp = new Subject<void>();
    this.isLoading = new Subject<any>();
    this.showProgressBar = false;

    this.maxEntities = 0;
    this.progressBarValue = 0;
  }

  public show(maxEntities: number, progressBarValue: number = 0): void {
    this.maxEntities = maxEntities;
    this.progressBarValue = progressBarValue;
    this.isLoading.next({
      show: true,
      maxEntities: this.maxEntities,
      progressBarValue: this.progressBarValue,
    });
  }

  public increaseProgressBarValue(): void {
    this.progressBarValue++;
    this.isLoading.next({
      show: true,
      maxEntities: this.maxEntities,
      progressBarValue: this.progressBarValue,
    });
  }

  public error(text: string): void {
    this._logger.error(text);
    this.isLoading.next({ show: false });
  }

  public hide(): void {
    setTimeout(() => {
      this.isLoading.next({ show: false });
    }, 750);
  }

  public destroyHttpRequest(): void {
    this.killHttp.next();
    this.killHttp.complete();
    this.killHttp = new Subject<void>();
  }
}
