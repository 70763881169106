import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AxiosResponse } from 'axios';
import { IRequestDetail } from 'projects/content-service-cms/src/app/data';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { DataService } from '../../core/services/data.service';
import {
  IApiSdkService,
  ICsApiSdkConfiguration,
  IPublicRequestService,
  IRequest,
} from 'projects/content-service-cms/src/app/data/api';
import {
  ConfigurationPublicOnlyDTOPage,
  ConfigurationsApi,
} from '@reactivereality/cs-api-sdk';
import { NgDiscoveryService } from '../../core/services/ng-discovery.service';

@Injectable({
  providedIn: 'root',
})
export class PublicRequestService
  extends DataService<IRequest, any>
  implements IPublicRequestService
{
  private static endpointName = 'configurations/public';

  private _requestApi: ConfigurationsApi;
  private _apiVersion: string;

  constructor(
    protected _http: HttpClient,
    protected _logger: ILogger,
    protected _apiSdkService: IApiSdkService,
    protected _discoveryService: NgDiscoveryService,
  ) {
    super(
      PublicRequestService.endpointName,
      _http,
      _logger,
      _apiSdkService,
      _discoveryService,
    );
    this._apiSdkService.sdkConfig$.subscribe({
      next: this.initService,
      error: this.errorOnInitService,
    });
  }

  getPublicRequestByID(requestId: string): Promise<IRequestDetail> {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .vversionConfigurationsPublicGet(
          this._apiVersion,
          undefined,
          undefined,
          undefined,
          `{"id":"${requestId}"}`,
          true,
        )
        .then((response: AxiosResponse<ConfigurationPublicOnlyDTOPage>) => {
          if (response && response.data) {
            return response.data.results[0];
          }
          return undefined;
        });
    }

    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }

  public getFirstApprovedAvatar2D() {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .vversionConfigurationsPublicGet(
          this._apiVersion,
          undefined,
          undefined,
          undefined,
          '{"productTypeId":6,"stateId":4}',
          true,
        )
        .then((response: AxiosResponse<ConfigurationPublicOnlyDTOPage>) => {
          if (
            response &&
            response.data &&
            response.data.results &&
            response.data.results.length > 0
          ) {
            return response.data.results[0];
          }
          return undefined;
        });
    }

    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }
  protected initService = (sdkConfig: ICsApiSdkConfiguration) => {
    if (
      sdkConfig !== undefined &&
      sdkConfig.configurationsApiSdk &&
      sdkConfig.apiVersion
    ) {
      this._apiVersion = sdkConfig.apiVersion;
      this._requestApi = sdkConfig.configurationsApiSdk;
    } else {
      this.errorOnInitService(
        new Error('There is no CS API Sdk Configuration or API version.'),
      );
    }
  };
}
