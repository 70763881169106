import { createAction, props } from '@ngrx/store';

export const navigateToAction = createAction(
  '[Navigation] Navigate To',
  props<{
    route: string[];
  }>(),
);

export const navigateToCompleteAction = createAction(
  '[Navigation] Navigate To Complete',
);
