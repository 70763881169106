import { NgModule } from '@angular/core';
import { ProgressbarComponent } from './components/progressbar/progressbar.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RRSharedModule } from '../shared-module/shared.module';

@NgModule({
  declarations: [ProgressbarComponent],
  imports: [RRSharedModule, ProgressbarModule.forRoot()],
  exports: [ProgressbarComponent],
})
export class RRProgressbarModule {}
