import { Injectable } from '@angular/core';
import { CustomerDTO, CustomersApi } from '@reactivereality/cs-api-sdk';
import { AxiosResponse } from 'axios';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import {
  IApiSdkService,
  ICsApiSdkConfiguration,
  ICustomer,
  ICustomerService,
} from '../api';

@Injectable({
  providedIn: 'root',
})
export class CustomerService implements ICustomerService {
  private _customerApi: CustomersApi;
  private apiVersion: string;

  constructor(
    private _logger: ILogger,
    private _apiSdkService: IApiSdkService,
  ) {
    this._logger.info('Creating customer service ...');

    this._apiSdkService.sdkConfig$.subscribe({
      next: this.initCustomersService,
      error: this.errorOnInitCustomersService,
    });
  }

  public async fetchCustomerById(
    customerId: string,
  ): Promise<ICustomer | undefined> {
    if (this._customerApi) {
      return this._customerApi
        .vversionCustomersIdGet(customerId, this.apiVersion)
        .then((response: AxiosResponse<CustomerDTO>) => {
          if (response && response.data) {
            return {
              id: response.data.id,
              name: response.data.name,
            };
          }

          return undefined;
        });
    }

    return Promise.reject('There is no Customers API.');
  }

  private initCustomersService = (sdkConfig: ICsApiSdkConfiguration) => {
    if (sdkConfig && sdkConfig.apiVersion && sdkConfig.customersApiSdk) {
      this.apiVersion = sdkConfig.apiVersion;
      this._customerApi = sdkConfig.customersApiSdk;
    } else {
      this.errorOnInitCustomersService(
        new Error(
          'There is no Customers API Sdk Configuration or API version.',
        ),
      );
    }
  };

  private errorOnInitCustomersService = (error: Error) => {
    this._logger.error('Cannot initate Customers API.', error);
  };

  private errorOnRefreshMyCustomers = (error: Error) => {
    this._logger.error('Error when refreshing my customers.', error);
  };
}
