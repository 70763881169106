import { InjectionToken, inject } from '@angular/core';
import { ActionReducer } from '@ngrx/store';
import { CustomerReducerService } from '../service/customer-reducer.service';
import { ICustomerState } from './customer.state';

export const REDUCER_TOKEN = new InjectionToken<ActionReducer<ICustomerState>>(
  'Customer Registered Reducers',
  {
    factory: () => {
      const serv = inject(CustomerReducerService);
      // return reducers synchronously
      return serv.getReducers();
    },
  },
);
