/* eslint-disable max-lines-per-function */
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FileUploadFullComponent } from 'projects/content-service-cms/src/app/components/file-upload-full/file-upload-full.component';
import { AddGarmentModalComponent } from 'projects/content-service-cms/src/app/components/garment-selection/add-garment-modal/add-garment-modal.component';
import { GarmentSelectionComponent } from 'projects/content-service-cms/src/app/components/garment-selection/garment-selection.component';
import { VirtualDressingRoomModule } from 'projects/content-service-cms/src/app/virtual-dressing-room/virtual-dressing-room.module';
import { RRSpinnerModule } from 'projects/web-ui-component-library/src';
import { ActionButtonsComponent } from '../action-buttons/action-buttons.component';
import { CommonInputsComponent } from '../common-inputs/common-inputs.component';
import { CreateRequestModalStore } from '../create-request-modal.state';
import { CreateOutfit2DParallaxService } from '../services/create-outfit-2d-parallax.service';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from 'projects/content-service-cms/src/app/app.state';
import { createNewRequestAction } from '../../../store/requests-overview.actions';
import { AvatarSelectionService } from 'projects/content-service-cms/src/app/components/avatar-selection-entry/avatar-selection.service';
import { AvatarService } from 'projects/content-service-cms/src/app/virtual-dressing-room/data/services/avatar.service';
import { SceneService } from 'projects/content-service-cms/src/app/virtual-dressing-room/data/services/scene.service';
import { AvatarSelectionEntryComponent } from 'projects/content-service-cms/src/app/components/avatar-selection-entry/avatar-selection-entry.component';
import { SceneSelectionEntryComponent } from 'projects/content-service-cms/src/app/components/scene-selection-entry/scene-selection-entry.component';
import { RRIDressingRoomSettings } from 'projects/content-service-cms/src/app/virtual-dressing-room/interfaces/dressing-room-settings';
import {
  IVDRDisplay,
  IVDRStatus,
} from 'projects/content-service-cms/src/app/virtual-dressing-room/interfaces/vdressing-room';
import { IAuthService } from 'projects/content-service-cms/src/app/auth';
import { NgDiscoveryService } from 'projects/content-service-cms/src/app/core/services/ng-discovery.service';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { AlertService } from 'projects/content-service-cms/src/app/core/services/alert.service';
import { ViewerMode } from 'projects/content-service-cms/src/app/virtual-dressing-room/data/api';
import { Avatar2DAsset } from 'projects/content-service-cms/src/app/virtual-dressing-room/data/model/Avatar2DAsset';
import { HttpClient } from '@angular/common/http';
import { Garment2DAsset } from 'projects/content-service-cms/src/app/virtual-dressing-room/data/model/GarmentAssets';
import { SceneAsset } from 'projects/content-service-cms/src/app/virtual-dressing-room/data/model/SceneAsset';
import {
  ACCESSORY_SLOT,
  AccessorySelectionComponent,
} from '../../../../components/accessory-selection/accessory-selection.component';
import { IScreenShotService } from 'projects/content-service-cms/src/app/core/interfaces/i-screenshot';
import { VirtualDressingRoomComponent } from 'projects/content-service-cms/src/app/virtual-dressing-room/virtual-dressing-room/virtual-dressing-room.component';
import { CreateRequestInputData } from '../create-request-input.interface';

@Component({
  selector: 'app-create-outfit2d-parallax',
  standalone: true,
  imports: [
    CommonModule,
    AddGarmentModalComponent,
    RRSpinnerModule,
    VirtualDressingRoomModule,
    FileUploadFullComponent,
    CommonInputsComponent,
    GarmentSelectionComponent,
    ActionButtonsComponent,
    AvatarSelectionEntryComponent,
    SceneSelectionEntryComponent,
    AccessorySelectionComponent,
  ],
  templateUrl: './create-outfit2d-parallax.component.html',
  styleUrl: './create-outfit2d-parallax.component.scss',
})
export class CreateOutfit2dParallaxComponent
  implements OnInit, OnDestroy, IScreenShotService
{
  private _onDestroy$: Subject<void>;
  public dressingRoomSettings: RRIDressingRoomSettings;
  public vdrUpdate$: BehaviorSubject<IVDRDisplay>;
  public vdrStatus$: BehaviorSubject<IVDRStatus>;
  public vdrReset$: Subject<void>;
  public token = this.authService.getToken();

  @ViewChild('MainVDR') vdr: VirtualDressingRoomComponent;

  constructor(
    public createRequestModalStore: CreateRequestModalStore,
    public authService: IAuthService,
    public avatarSelectionService: AvatarSelectionService,
    public avatarService: AvatarService,
    public sceneService: SceneService,
    private _outfitService: CreateOutfit2DParallaxService,
    private _store: Store<IAppState>,
    private _discoveryService: NgDiscoveryService,
    private _logger: ILogger,
    private _alertService: AlertService,
    private _http: HttpClient,
  ) {
    this.vdrStatus$ = new BehaviorSubject<IVDRStatus>(undefined);
    this.vdrUpdate$ = new BehaviorSubject<IVDRDisplay>(undefined);
    this.vdrReset$ = new Subject<void>();
    this.dressingRoomSettings = {
      type: '2D_PARALLAX',
      computeServerUrl:
        this._discoveryService.config.apiConfiguration.computeServerUrl,
      height: '100%',
      width: '100%',
      disableAvatarSelection: true,
      disableFullscreenMode: true,
    };
  }
  async takeScreenshot(height?: number, width?: number): Promise<string> {
    return await this.vdr.takeScreenshot();
  }

  ngOnInit(): void {
    this._onDestroy$ = new Subject<void>();
    this.vdrStatus$.subscribe((status) => {
      if (status?.error) {
        this._logger.error(`Received error status from VDR!!`);
        this._alertService.showErrorAlert(`Failed to perform 2D tryon.`);
        this._logger.debug(`Received status from VDR!!`, status);
      }
    });
    this.createRequestModalStore.inputData$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((inputData: CreateRequestInputData) => {
        const isCollectionOutfit =
          inputData.additionalInfo?.isCollectionOutfit ?? false;
        this.createRequestModalStore.changeIsCollectionOutfit(
          isCollectionOutfit,
        );
      });
    this.createRequestModalStore.requestCommonDataName$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
        this.isInputValid();
      });
    this.createRequestModalStore.garments$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((_) => {
        this.updateViewer();
        this._logger.debug(
          'Garments changed!!',
          this.createRequestModalStore.current(),
        );
      });
    this.createRequestModalStore.refreshRoom$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((refresh) => {
        if (refresh) {
          this.updateViewer();
          this.createRequestModalStore.refreshRoomNow(false);
        }
      });

    this.createRequestModalStore.avatar$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((avatar) => {
        this._logger.debug(`Updated avatar!`, avatar);
        this.updateViewer();
      });

    this.createRequestModalStore.scene$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((scene) => {
        this._logger.debug(`Updated scene!`, scene);
        this.updateViewer();
      });

    this.createRequestModalStore.accessories$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((accessories) => {
        this._logger.debug(`Updated accessories!`, accessories);
        this.updateViewer();
      });

    this.createRequestModalStore.createRequestAction$
      .pipe(
        takeUntil(this._onDestroy$),
        filter((c) => c),
      )
      .subscribe(async () => {
        const image = await this.takeScreenshot();
        await this._outfitService.createRequest(
          this.createRequestModalStore,
          image,
          false,
        );
        this.createRequestModalStore.cancelAction();
      });
    this.createRequestModalStore.createRequestActionAndAnotherOne$
      .pipe(
        takeUntil(this._onDestroy$),
        filter((c) => c),
      )
      .subscribe(async () => {
        const image = await this.takeScreenshot();
        await this._outfitService.createRequest(
          this.createRequestModalStore,
          image,
          false,
        );
        const state = this.createRequestModalStore.current();
        this._store.dispatch(
          createNewRequestAction({
            productType: state.productType,
          }),
        );
      });
    this.createRequestModalStore.saveDraftAction$
      .pipe(
        takeUntil(this._onDestroy$),
        filter((c) => c),
      )
      .subscribe(async () => {
        const image = await this.takeScreenshot();
        await this._outfitService.createRequest(
          this.createRequestModalStore,
          image,
          true,
        );
        this.createRequestModalStore.cancelAction();
      });
  }

  private updateViewer = async () => {
    const currentAvatar = this.createRequestModalStore.currentAvatar();
    const currentScene = this.createRequestModalStore.currentScene();
    if (!currentAvatar || !currentScene) {
      this._logger.debug('not ready to show');
      return;
    }

    const viewerMode: ViewerMode = ViewerMode.MODE_2D_PARALLAX;

    const avatarWebResult = currentAvatar.results.find(
      (r) => r.format === 'web',
    );

    if (!avatarWebResult) {
      this._logger.error(`Failed to load web result of avatar!`);
      throw new Error(`Failed to load web result of avatar!`);
    }

    const avatar: any = new Avatar2DAsset(
      avatarWebResult.files.map((f) => ({
        filename: f.name,
        mimetype: f.mimeType,
        url: f.url,
      })),
      this._http,
      this._logger,
    );

    const sceneWebResult = currentScene.results.find((r) => r.format === 'web');

    if (!sceneWebResult) {
      this._logger.error(`Failed to load web result of scene!`);
      throw new Error(`Failed to load web result of scene!`);
    }

    const scene: any = new SceneAsset(
      sceneWebResult.files.map((f) => ({
        filename: f.name,
        mimetype: f.mimeType,
        url: f.url,
      })),
      this._http,
      this._logger,
    );

    const currentGarments = this.createRequestModalStore
      .current()
      .dressingRoom.garments.filter(
        (c) => c.results.find((r) => r.format === 'web') !== undefined,
      )
      .map((r) => {
        const result = r.results.find((r) => r.format === 'web');
        return {
          asset: new Garment2DAsset(
            result.files.map((f) => ({
              filename: f.name,
              url: f.url,
            })),
            this._http,
            this._logger,
          ),
          id: r.id,
        };
      });

    const currentAccessoires = [];
    for (const pos of Object.values(ACCESSORY_SLOT)) {
      const acc =
        this.createRequestModalStore.current().dressingRoom.accessories[pos];
      if (!acc) continue;
      const result = acc.results.find((r) => r.format === 'web');
      if (!result) continue;
      const asset = new Garment2DAsset(
        result.files.map((f) => ({
          filename: f.name,
          url: f.url,
        })),
        this._http,
        this._logger,
      );

      currentAccessoires[pos] = { id: acc.id, asset: asset };
    }

    this.vdrUpdate$.next({
      garments: currentGarments,
      scene: {
        id: scene.id,
        asset: scene,
      },
      avatar: {
        id: currentAvatar.id,
        asset: avatar,
      },
      focusedGarment: null,
      sizeRecomendation: null,
      mode: viewerMode,
      accessories: currentAccessoires,
    });

    this.isInputValid();
  };

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._onDestroy$.unsubscribe();
    this._onDestroy$ = undefined;
  }

  public addAvatarClicked() {
    this.avatarSelectionService.selectAvatar();
  }

  private isInputValid() {
    const currentState = this.createRequestModalStore.current();

    if (
      currentState.requestCommonData.name &&
      currentState.dressingRoom?.avatar &&
      currentState.dressingRoom?.scene &&
      currentState.dressingRoom?.garments?.length > 0
    ) {
      this.createRequestModalStore.changeCreateRequestCommonData({
        dataValid: true,
      });
    } else {
      this.createRequestModalStore.changeCreateRequestCommonData({
        dataValid: false,
      });
    }
  }
}
