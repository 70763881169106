import { Injectable, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface PageContext {
  title: string;
  parent?: {
    title: string;
    url: string;
  };
  actionBar?: {
    type: Type<any>;
    onCreate: (ref: any) => void;
  };
}

@Injectable({
  providedIn: 'root',
})
export class PageContextService {
  private readonly pageContextSubject: BehaviorSubject<PageContext>;

  constructor() {
    this.pageContextSubject = new BehaviorSubject<PageContext>({
      title: '',
    });
  }

  public subscribe(cb: (value: PageContext) => void): PageContextService {
    this.pageContextSubject.subscribe(cb);
    return this;
  }

  public setPageTitle(title: string): PageContextService {
    this.pageContextSubject.next({
      ...this.pageContextSubject.value,
      title,
    });

    return this;
  }

  public setPageParent(parent?: {
    title: string;
    url: string;
  }): PageContextService {
    this.pageContextSubject.next({
      ...this.pageContextSubject.value,
      parent,
    });

    return this;
  }

  public clearPageActionBar(): PageContextService {
    this.pageContextSubject.next({
      ...this.pageContextSubject.value,
      actionBar: undefined,
    });

    return this;
  }

  public setPageActionBar<T>(
    type: Type<T>,
    onCreate: (ref: T) => void,
  ): PageContextService {
    this.pageContextSubject.next({
      ...this.pageContextSubject.value,
      actionBar: {
        type,
        onCreate,
      },
    });

    return this;
  }
}
