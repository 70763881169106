import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DragAndDropDirective } from '../../directives/drag-and-drop.directive';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  standalone: true,
  imports: [CommonModule, DragAndDropDirective],
})
export class FileUploadComponent implements OnInit {
  @Input() multipleFiles: boolean = true;
  @Output() filesSelected: EventEmitter<File[]> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onFilesSelected(event: Event) {
    const target = <HTMLInputElement>event.target;
    this.filesSelected.emit(Array.from(target.files));
  }

  onFilesDropped(event: any) {
    const files = <File[]>event;
    this.filesSelected.emit(Array.from(files));
  }
}
