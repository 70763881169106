import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'rr-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, AfterViewInit {
  isSelecting: boolean = false;
  locale: string;

  hovered: Date | null;
  displayed: {
    date: Date;
    year: number;
    month: string;
    days: Array<{ day: number; month: number; year: number; date: Date }>;
  };

  @Input() selected: { from: Date; to: Date } | null;
  @Input() firstDay: 'Sunday' | 'Monday' = 'Monday';
  @Input() singleSelect: boolean = false;

  @Output() selectedChanged: EventEmitter<{ from: Date; to: Date }> =
    new EventEmitter();

  constructor() {
    this.locale =
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language ?? 'en';
  }

  ngAfterViewInit(): void {
    const now = new Date();
    this.updateDisplayed(
      new Date(now.getFullYear(), now.getUTCMonth(), 1, 12, 0, 0),
    );
  }

  days() {
    const firstDay = this.firstDay === 'Sunday' ? 'Sun' : '';
    const lastDay = this.firstDay === 'Sunday' ? '' : 'Sun';
    return [firstDay, 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', lastDay].filter(
      (v) => v.length > 0,
    );
  }

  updateDisplayed(date: Date) {
    const year = date.getFullYear();
    // console.log("updateDisplayed")

    const update = {
      date,
      year,
      month: Intl.DateTimeFormat(this.locale, { month: 'long' }).format(date),
      days: this.getDays(date).map((day) => ({
        ...day,
        date: new Date(day.year, day.month, day.day),
      })),
    };

    this.displayed = update;
  }

  displayMonth(month: number) {
    const { date } = this.displayed;
    date.setMonth(month);
    this.updateDisplayed(date);
  }

  skipMonth(offset: number) {
    const { date } = this.displayed;
    date.setMonth(date.getMonth() + offset);
    this.updateDisplayed(date);
  }

  onSelectDate(year: number, month: number, day: number, event?: MouseEvent) {
    if (event) event.stopPropagation();
    const selected = new Date(year, month, day);

    this.selected = {
      from: null,
      to: null,
      ...this.selected,
    };

    if (!this.isSelecting) {
      this.selected.from = selected;
      this.selected.to = null;
      if (this.singleSelect) {
        this.selected.to = this.selected.from;
        this.hovered = null;
        this.selectedChanged.emit(this.selected);
      }
    } else if (selected.getTime() < this.selected.from.getTime()) {
      console.log('Is before:', this.selected.from, selected);
      this.selected.from = selected;
      this.isSelecting = false;
    } else {
      this.hovered = null;
      this.selected.to = selected;
      this.selectedChanged.emit(this.selected);
    }

    if (month !== this.displayed.date.getMonth()) this.displayMonth(month);
    this.isSelecting = !this.isSelecting;
  }

  onHoverDate(year: number, month: number, day: number, event?: MouseEvent) {
    // if (event) event.stopPropagation();

    if (this.isSelecting) {
      this.hovered = new Date(year, month, day);
    }
  }

  *getDaysOfMonth(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    /*
    A year is leap year if following conditions are satisfied:
      Year is multiple of 400.
      Year is multiple of 4 and not multiple of 100.
    */
    const isLeapYear = () => {
      if (year % 400 == 0 && year % 4 == 0 && year % 100 != 0) return true;
      return false;
    };
    const daysInMonth = [
      31,
      isLeapYear() ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ][month];

    for (let day = 1; day <= daysInMonth; day++) {
      yield {
        day,
        month,
        year,
      };
    }
  }

  getDays(date: Date) {
    const dayMonthStarts = (() => {
      const day = date.getDay();
      if (this.firstDay === 'Sunday') return day;

      if (day === 0) return 6;
      return day - 1;
    })();

    const lastMonth = new Date(date);
    lastMonth.setMonth(date.getMonth() - 1);
    const lastMonthDays = [...this.getDaysOfMonth(lastMonth)];

    const thisMonthDays = [...this.getDaysOfMonth(date)];

    const nextMonth = new Date(date);
    nextMonth.setMonth(date.getMonth() + 1);
    const nextMonthDays = [...this.getDaysOfMonth(nextMonth)];

    // console.log('Days:', {lastMonthDays, thisMonthDays, nextMonthDays, dayMonthStarts})

    return [
      ...(dayMonthStarts === 0 ? [] : lastMonthDays.slice(dayMonthStarts * -1)),
      ...thisMonthDays,
      ...nextMonthDays.slice(0, 42 - thisMonthDays.length - dayMonthStarts),
    ];
  }

  ngOnInit(): void {}
}
