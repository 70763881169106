import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AssetFactoryFactory,
  IAssetFactory,
} from '@reactivereality/pictofit-web-sdk';
import { ConfigurationResultDTO } from '@reactivereality/cs-api-sdk';
import { GarmentDTO } from '../../../data/api';
import { WebSDKService } from '../../../data/services/web-sdk.service';
import { ILogger } from '../../../logging';
import {
  GarmentThumbnailPipe,
  ProductTypePipe,
} from '../../../utils/CommonPipes';
import { GarmentAssetFiles } from '../../../virtual-dressing-room/data/api';
import { Garment3DAsset } from '../../../virtual-dressing-room/data/model/GarmentAssets';
import { StylingOptionsComponent } from './styling-options/styling-options.component';
import { ConfigurationDisplayComponent } from '../configuration-display/configuration-display.component';
import { fromOriginalJointToMapped } from './jointMap';
import { ModalService } from '../../../modal/modal.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'app-garment-selection-entry',
  templateUrl: './garment-selection-entry.component.html',
  styleUrls: ['./garment-selection-entry.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ProductTypePipe,
    GarmentThumbnailPipe,
    StylingOptionsComponent,
    ConfigurationDisplayComponent,
    TooltipModule,
  ],
})
export class GarmentSelectionEntryComponent implements OnInit, AfterViewInit {
  @Input() public garment: GarmentDTO;
  @Input() public isOutfit2DMode?: boolean = false;
  @Output() public deleteClicked = new EventEmitter<GarmentDTO>();
  @Output() public tuckClicked = new EventEmitter<[GarmentDTO, string]>();
  @Output() public dressClicked = new EventEmitter<GarmentDTO>();

  public imageDataUrl;
  public stylingOptions: any;

  assetFactory: IAssetFactory;

  constructor(
    private httpClient: HttpClient,
    private logger: ILogger,
    private sdk: WebSDKService,
    public modalService: ModalService,
  ) {
    this.assetFactory = AssetFactoryFactory.createAssetFactory3D();
  }

  async ngOnInit(): Promise<void> {}

  dtoToAsset(garment: GarmentDTO) {
    const results: ConfigurationResultDTO[] = [];

    results.push(
      ...garment.results.filter((c) => {
        return c.format === 'web';
      }),
    );

    const fileList: GarmentAssetFiles = [];
    results.forEach((r) => {
      r.files.forEach((file) => {
        fileList.push({ filename: file.name, url: file.url });
      });
    });

    return new Garment3DAsset(fileList, this.httpClient, this.logger);
  }

  ngAfterViewInit(): void {
    this.imageDataUrl = this.garment.previewUrl;

    // get the styling options for the garment...
    const asset = this.dtoToAsset(this.garment);
    if (!this.isOutfit2DMode) {
      this.assetFactory
        .createGarment(asset)
        .then((garment) => {
          return this.sdk.computeServer.requestStylingTemplateParameterJSONSchema(
            garment as any,
          );
        })
        .then((schema) => {
          this.stylingOptions = this.sanitiseStylingOptions(JSON.parse(schema));
        })
        .catch(() => {
          this.stylingOptions = null;
        });
    }
  }

  private sanitiseStylingOptions(stylingOptions: any): any {
    // remove the form title
    delete stylingOptions.title;

    type JsonSchemaDef =
      | {
          type: 'array';
          items: JsonSchemaDef;
        }
      | {
          type: 'object';
          required: string[];
          properties: { [prop: string]: JsonSchemaDef };
        };

    const removeNonRequired = (obj: JsonSchemaDef) => {
      if (obj.type === 'array') {
        obj.items = removeNonRequired(obj.items);
      }

      if (obj.type === 'object') {
        obj.properties = Object.getOwnPropertyNames(obj.properties).reduce(
          (props, prop) => {
            if (obj.required?.includes(prop)) {
              props[prop] = obj.properties[prop];

              /// TODO: Very hacky and we should change the schema to provide these names somehow
              if (prop === 'rootJointName') {
                props[prop].enum = props[prop].enum.map(
                  fromOriginalJointToMapped,
                );
              }
            }

            if (prop === 'additionalWidth') {
              props[prop] = obj.properties[prop];
            }

            return props;
          },
          {},
        );
      }

      return obj;
    };

    // remove all non-required elements from the $defs
    const cleaned = Object.getOwnPropertyNames(stylingOptions['$defs']).reduce(
      (acc, def) => {
        acc[def] = removeNonRequired(stylingOptions['$defs'][def]);

        return acc;
      },
      {},
    );

    return {
      ...stylingOptions,
      $defs: cleaned,
    };
  }

  public deleteEntry($event: MouseEvent) {
    this.deleteClicked.emit(this.garment);
  }
  public tuckGarment($event: MouseEvent, action: string) {
    this.tuckClicked.emit([this.garment, action]);
  }
  public dressGarment($event: MouseEvent) {
    this.dressClicked.emit(this.garment);
  }
}
