import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';
import { IAuthService } from './api';
import { SigninRedirectCallbackComponent } from './components/signin-redirect-callback/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './components/signout-redirect-callback/signout-redirect-callback.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { NoPermissionPageComponent } from './pages/no-permission-page/no-permission-page.component';
import { PatientPageComponent } from './pages/patient/patient.page.component';
import { AuthService } from './services/auth.service';

export const AuthComponents = [
  SigninRedirectCallbackComponent,
  SignoutRedirectCallbackComponent,
  UnauthorizedComponent,
  PatientPageComponent,
  NoPermissionPageComponent,
];

export const AuthRoutes = [
  { path: 'signin-callback', component: SigninRedirectCallbackComponent },
  { path: 'signout-callback', component: SignoutRedirectCallbackComponent },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: { breadcrumb: 'Unauthorized' },
  },
  { path: '**', component: UnauthorizedComponent, data: { errorCode: '404' } },
];

export const AuthProviders: Provider[] = [
  {
    provide: IAuthService,
    useExisting: AuthService,
  },
  AuthService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
];

export { IAuthService } from './api';
export { AuthGuard } from './guards/auth.guard';
