/* eslint-disable max-lines-per-function */
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  RRFilterBarComponent,
  RRIFilterOutput,
  RR_FILTER_TYPE,
} from 'projects/web-ui-component-library/src';
import {
  AbstractFilterService,
  SelectedFilters,
  buildInputElement,
} from 'projects/web-ui-component-library/src/lib/filter';
import { TextInputElementComponent } from 'projects/web-ui-component-library/src/lib/filter/components/filter-bar/filter-bar-element/text-input-element/text-input-element.component';
import { IAppState } from '../../../app.state';

import { ILogger } from '../../../logging';

import {
  clearFilters,
  filtersChange,
} from '../../store/collections-overview.actions';

export type CollectionsFilters = {
  //createdAt: { from: Date; to: Date };
  name: string;
  //tags: { name: string; uuid: string }[];
  //customerId: string;
};

// Change the value output of the filter components to match the filter store...
function mapFilterValue<
  K extends keyof CollectionsFilters = keyof CollectionsFilters,
>(fieldName: K, value: CollectionsFilters[K]) {
  // switch (fieldName) {
  //   // the date formats need changing to a numeric tuple...
  //   case 'createdAt':
  //     if (
  //       value &&
  //       typeof value === 'object' &&
  //       'from' in value &&
  //       'to' in value
  //     ) {
  //       return [
  //         new Date(value.from).getTime() / 1000,
  //         new Date(value.to).getTime() / 1000,
  //       ];
  //     }
  //     return null;
  // }

  // no change required
  return value;
}
/*
  This function will convert the RequestFilters into an array of
  objects compatible with the API service.
*/
export function toRRIFilterArray(
  selectedFilters: SelectedFilters<CollectionsFilters>,
): RRIFilterOutput<any>[] {
  const newFilters: RRIFilterOutput<any>[] = [];
  for (const fieldName in selectedFilters) {
    const value = mapFilterValue(
      fieldName as keyof CollectionsFilters,
      selectedFilters[fieldName],
    );
    if (!value) continue;

    switch (fieldName) {
      case 'name':
        newFilters.push({
          type: RR_FILTER_TYPE.TEXT_SEARCH,
          filterBy: {
            fieldName,
            value,
            defaultValue: null,
          },
        });
        break;
    }
  }

  return newFilters;
}

class CollectionFilterService extends AbstractFilterService<CollectionsFilters> {
  constructor(private store: Store<IAppState>) {
    super({
      name: {
        id: 'name',
        label: 'Name',
        searchable: true,
        ...buildInputElement(TextInputElementComponent),
      },
    });
  }
}

// function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
//   return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
// }

@Component({
  selector: 'app-collection-list-action-bar',
  templateUrl: './collection-list-action-bar.component.html',
  styleUrls: ['./collection-list-action-bar.component.scss'],
})
export class CollectionListActionBarComponent {
  @Input() filterText: string;
  @Output() filterChange: EventEmitter<SelectedFilters<CollectionsFilters>> =
    new EventEmitter();
  @ViewChild('filterBar') filterBar: RRFilterBarComponent<CollectionsFilters>;

  collectionFilterService: CollectionFilterService;

  constructor(public store: Store<IAppState>, private logger: ILogger) {
    this.collectionFilterService = new CollectionFilterService(store);

    this.collectionFilterService.filterValues.subscribe((filters) => {
      this.logger.debug('CollectionListActionBar', 'filterValues:', filters);
      this.store.dispatch(filtersChange({ filters }));
    });
  }
  clearFilterValues() {
    this.store.dispatch(clearFilters());
    this.collectionFilterService.filterValues.next({});
  }
}
