<article>
  <form
    #stylingOptionsForm
    [formGroup]="form"
    *ngIf="fields"
    (ngSubmit)="setOptions()"
  >
    <formly-form
      [form]="form"
      [fields]="fields"
      [model]="stylingOptions"
      [options]="options"
    ></formly-form>
    <button class="btn btn-primary" type="submit" [disabled]="!form.valid">
      Apply
    </button>
  </form>
</article>
