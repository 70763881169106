<app-profile-menu [profileStore]="profileStore"></app-profile-menu>

<div class="panel">
  <ng-container
    #currentPanel
    *ngIf="profileStore.currentPage$ | async as currentPage"
    [ngTemplateOutlet]="showTemplate(currentPage)"
  ></ng-container>
</div>

<ng-template #userInfo>
  <h2>User Information</h2>
  <app-profile-user-information [user$]="user$"></app-profile-user-information>
</ng-template>

<ng-template #security>
  <h2>Security</h2>
  <app-profile-security></app-profile-security>
</ng-template>

<ng-template #apiKeys>
  <h2>API Keys</h2>
  <app-profile-api-keys></app-profile-api-keys>
</ng-template>
