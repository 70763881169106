<!-- Top App Bar -->
<rr-top-app-bar (logout)="logout()"></rr-top-app-bar>

<rr-simple-alert [alerts]="alerts"> </rr-simple-alert>

<rr-modal-container></rr-modal-container>

<router-outlet></router-outlet>

<app-create-request-modal></app-create-request-modal>

<ng-template #createNewRequest>
  <app-create-request-select-type-modal
    [selectedModalGroup$]="selectedModalGroup$"
  ></app-create-request-select-type-modal>
</ng-template>

<ng-template #createNewRequestHeader>
  <div class="header-ctn">
    <div class="header d-flex flex-row justify-content-center">
      <h2
        class="header-item"
        id="category-select-products"
        [ngClass]="{ 'sel-item': selectedModalGroup$.value === 0 }"
        (click)="selectedModalGroup$.next(0)"
      >
        New Request
      </h2>
    </div>
  </div>
</ng-template>

<app-user-data-permission></app-user-data-permission>
<ng-template #logoutPrompt>
  <div class="logout-prompt d-flex flex-column">
    <span class="logout-prompt-text mt-4 mb-4"
      >Do you really want to logout?</span
    >
    <div class="buttons d-flex flex-row justify-content-around">
      <button class="btn btn-outline-primary" (click)="cancelClicked()">
        Cancel
      </button>
      <button class="btn btn-primary" (click)="logoutClicked()">Log out</button>
    </div>
  </div>
</ng-template>
