import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  JSON: { input: any; output: any };
  JSONObject: { input: any; output: any };
};

export enum AccessPolicy {
  /** The means, that the product can't be fetched from the public API */
  Organization = 'ORGANIZATION',
  /** The means, that the product can be fetched from the public API to be used in the virtual dressing room */
  Public = 'PUBLIC',
}

export enum CostType {
  /** A credit topup */
  CreditPurchase = 'CREDIT_PURCHASE',
  /** An unkown entry */
  Unkown = 'UNKOWN',
  /** A Garment 2D virtual asset */
  VaGarment_2D = 'VA_GARMENT_2D',
  /** An other virtual asset */
  VaGeneric = 'VA_GENERIC',
  /** A VDR session */
  VdrSession = 'VDR_SESSION',
}

export type CreateFileInput = {
  fileType: FileType;
  name: Scalars['String']['input'];
};

export type CreateRequestResponse = {
  __typename?: 'CreateRequestResponse';
  id: Scalars['String']['output'];
  newFiles: Array<FileToUpload>;
  /** @deprecated The field will be removed in the near future. Don't use it anymore. */
  product?: Maybe<Product>;
};

/** The filter options for selecting ledger entries */
export type CreditLedgerFilter = {
  /** The cost types that are searched for in the ledger */
  costTypes?: InputMaybe<Array<CostType>>;
  /** The ISO-8601 string of the datetime the ledger entries should have happened not after */
  notAfter?: InputMaybe<Scalars['String']['input']>;
  /** The ISO-8601 string of the datetime the ledger entries should have happened not before */
  notBefore?: InputMaybe<Scalars['String']['input']>;
};

export type CreditPrice = {
  __typename?: 'CreditPrice';
  costType: CostType;
  price: Scalars['Float']['output'];
};

export enum Event {
  /** A potential status change for an external reference */
  ExternalReferenceStatusChange = 'EXTERNAL_REFERENCE_STATUS_CHANGE',
}

/** Representing a product */
export type EventWebhook = {
  __typename?: 'EventWebhook';
  eventType: Event;
  extReferenceStatusChangeOptions?: Maybe<ExternalReferenceStatusChangeOptions>;
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ExternalReference = {
  __typename?: 'ExternalReference';
  id: Scalars['String']['output'];
  reference: Scalars['String']['output'];
};

export type ExternalReferenceStatusChangeInput = {
  headers: Scalars['JSONObject']['input'];
  organizationId: Scalars['String']['input'];
  patternName: Scalars['String']['input'];
  regex: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

/** Webhooks */
export type ExternalReferenceStatusChangeOptions = {
  __typename?: 'ExternalReferenceStatusChangeOptions';
  patternName: Scalars['String']['output'];
  regex: Scalars['String']['output'];
};

export type File = {
  __typename?: 'File';
  /** The name of this file */
  fileName: Scalars['String']['output'];
  /** The url to call with a GET method to receive the content of this file */
  getLocation: Scalars['String']['output'];
  /** The url to call with a HEAD method to receive meta information of this file */
  headLocation: Scalars['String']['output'];
  /** The id of this file */
  id: Scalars['String']['output'];
};

export type FileToUpload = {
  __typename?: 'FileToUpload';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum FileType {
  AdditionalView = 'ADDITIONAL_VIEW',
  Avatar_3D = 'AVATAR_3D',
  Back = 'BACK',
  Backside_2D = 'BACKSIDE_2D',
  Bodymodel_2D = 'BODYMODEL_2D',
  CadFile = 'CAD_FILE',
  CameraMapping = 'CAMERA_MAPPING',
  CorrectedTexture = 'CORRECTED_TEXTURE',
  CustomerTechPack = 'CUSTOMER_TECH_PACK',
  Depth = 'DEPTH',
  FitmodelCharts = 'FITMODEL_CHARTS',
  Frontal = 'FRONTAL',
  Garment_3D = 'GARMENT_3D',
  GarmentClassification = 'GARMENT_CLASSIFICATION',
  HeadKeyframe = 'HEAD_KEYFRAME',
  Heatmap = 'HEATMAP',
  Keyframe = 'KEYFRAME',
  Landmarks_2D = 'LANDMARKS_2D',
  Landmarks_3D = 'LANDMARKS_3D',
  MaterialFile = 'MATERIAL_FILE',
  Matrix = 'MATRIX',
  MeasurementsConfig = 'MEASUREMENTS_CONFIG',
  Mesh = 'MESH',
  Metadata = 'METADATA',
  Opening_2D = 'OPENING_2D',
  PbmState = 'PBM_STATE',
  ReferenceAdditional = 'REFERENCE_ADDITIONAL',
  ReferenceMain = 'REFERENCE_MAIN',
  RejectionAttachment = 'REJECTION_ATTACHMENT',
  Rgba = 'RGBA',
  Segmentation = 'SEGMENTATION',
  SizeTable = 'SIZE_TABLE',
  SizeTableCreationConfig = 'SIZE_TABLE_CREATION_CONFIG',
  Slf_3D = 'SLF_3D',
  SlfTexture = 'SLF_TEXTURE',
  TemporalActivityCache = 'TEMPORAL_ACTIVITY_CACHE',
  Texture = 'TEXTURE',
  TexturedMesh_3D = 'TEXTURED_MESH_3D',
  TexturedPbm = 'TEXTURED_PBM',
  Thumbnail = 'THUMBNAIL',
  Ulg_3D = 'ULG_3D',
  Undefined = 'UNDEFINED',
  WebviewerConfig = 'WEBVIEWER_CONFIG',
  Xmp = 'XMP',
  Zephyr_3Dk = 'ZEPHYR_3DK',
}

export type LedgerEntry = {
  amount: Scalars['Float']['output'];
  createdAt: Scalars['String']['output'];
  creditCostType: CostType;
  id: Scalars['String']['output'];
};

export type LedgerGroupedEntry = {
  __typename?: 'LedgerGroupedEntry';
  amount: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
  creditCostType: CostType;
  timestamp: Scalars['String']['output'];
};

export type LedgerRequestEntry = LedgerEntry & {
  __typename?: 'LedgerRequestEntry';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['String']['output'];
  creditCostType: CostType;
  externalReferences: Array<ExternalReference>;
  id: Scalars['String']['output'];
  productType: Scalars['String']['output'];
};

export type LedgerSessionEntry = LedgerEntry & {
  __typename?: 'LedgerSessionEntry';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['String']['output'];
  creditCostType: CostType;
  id: Scalars['String']['output'];
};

export type LedgerTopUpEntry = LedgerEntry & {
  __typename?: 'LedgerTopUpEntry';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['String']['output'];
  creditCostType: CostType;
  externalReferences: Array<ExternalReference>;
  id: Scalars['String']['output'];
  provider: Scalars['String']['output'];
};

export type Message = {
  __typename?: 'Message';
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  modifiedAt: Scalars['String']['output'];
  readAt?: Maybe<Scalars['String']['output']>;
  source: MessageSource;
};

export enum MessageSource {
  /** The customer was the source of this message */
  Customer = 'CUSTOMER',
  /** Reactive Reality was the source of this message */
  ReactiveReality = 'REACTIVE_REALITY',
}

export type MessageThread = {
  __typename?: 'MessageThread';
  associatedRequestId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  messages: Array<Message>;
  subject: Scalars['String']['output'];
};

/** The filter options for selecting ledger entries */
export type MessageThreadFilter = {
  /** The ISO-8601 string of the datetime the ledger entries should have happened not after */
  notAfter?: InputMaybe<Scalars['String']['input']>;
  /** The ISO-8601 string of the datetime the ledger entries should have happened not before */
  notBefore?: InputMaybe<Scalars['String']['input']>;
  /** The cost types that are searched for in the ledger */
  search?: InputMaybe<Scalars['String']['input']>;
  /** The cost types that are searched for in the ledger */
  status?: InputMaybe<MessageThreadStatus>;
};

export enum MessageThreadStatus {
  /** Has unread messages */
  HasUnread = 'HAS_UNREAD',
}

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Add an external reference to a request
   *
   * # Arguments
   *
   * * `forRequest` - Identifies a request, this can either be an external reference or an id
   * * `theReference` - The reference string
   *
   */
  addExternalReference: ExternalReference;
  /**
   * Add an external reference to a setting
   *
   * # Arguments
   *
   * * `forSetting` - Identifies a setting by id
   * * `theReference` - The reference string
   *
   */
  addExternalReferenceToSetting: ExternalReference;
  /**
   * Create a new request in the PICTOFiT content service
   *
   * # Arguments
   *
   * * `forOrganization` - The organization id that should be used
   * * `forProduct` - A product reference, that should be used to create the request
   * * `ofProductType` - The request should be of the product type
   * * `withFiles` - A list of files that one plans to upload
   * * `withExternalReferences` - A list of external references to associate with the product
   *
   */
  createRequest: CreateRequestResponse;
  /**
   * Create a new setting for an organization
   *
   * ## Arguments
   * * `organizationId` - The organization id to search in
   * * `data` - The new data for the setting
   * * `settingType` - The type of the setting
   *
   */
  createSetting: Setting;
  /**
   * This enqueues a task that will sync in the requestId from the CS
   *
   * # Arguments
   *
   * * `requestIds` - The content service configuration id to sync (max. 20 items)
   *
   */
  enqueueRequestSynchronization: Array<Scalars['String']['output']>;
  /**
   * Tries to import a customer from the CS and return the result
   *
   * ## Arguments
   * * `organizationId` - An organization id to search for
   */
  findCustomer?: Maybe<Organization>;
  /**
   * Mark a message as read
   *
   * # Arguments
   *
   * * `id` - The message id
   *
   */
  markMessageRead: Scalars['Boolean']['output'];
  /**
   * Mark a request as ready for processing
   *
   * # Arguments
   *
   * * `id` - The request id, that is ready for processing
   *
   */
  readyRequest: Scalars['String']['output'];
  /**
   * Remove the following external references
   *
   * This does not delete the product itself, but just the reference
   *
   * # Arguments
   *
   * * `theReferences` - A list of references to remove (this takes the external reference id!)
   *
   */
  removeExternalReferences: Array<ExternalReference>;
  /**
   * Remove a setting
   *
   * ## Arguments
   * * `settingId` - The setting id to remove
   *
   */
  removeSetting: Scalars['Boolean']['output'];
  /**
   * Remove the following tags by id
   *
   * This removes tags by their ids
   *
   * # Arguments
   *
   * * `theTags` - A list of tag ids to be removed
   *
   */
  removeTags: Array<Tag>;
  /**
   * Report a problem with a request, this is once only operation per request
   *
   * # Arguments
   *
   * * `theRequest` - The request id that is ready for processing
   * * `msg` - The feedback msg
   *
   */
  sendFeedback: Scalars['String']['output'];
  /**
   * Sets external references for a request.
   *
   * If the `forOrganization` argument is given, the operation is scoped to this
   * organization otherwise the scope stretches accross all organizations a user
   * can access.
   *
   * If no `forRequest` argument is given all links of external references to
   * requests are removed, that match the `theReferences` argument.
   *
   * If a `forRequest` argument is given, all links of external references to
   * requests with the same product type like the request identified by `forRequest`
   * are removed, that match the `theReferences` argument and new links for the
   * `theReferences` to the `theRequest` are created.
   *
   * # Arguments
   *
   * * `forOrganization` - An organization id, which scopes this request
   * * `forRequest` - A request id, which the references should point to
   * * `theReferences` - A list of external references (the reference value)
   *
   */
  setExternalReference: Scalars['Boolean']['output'];
  /**
   * Set webhook for events
   *
   * ## Arguments
   * * `eventWebhook` - The settings for a specific event webhhok
   */
  setWebhook?: Maybe<EventWebhook>;
  /**
   * Update requests based on a list of product references
   *
   * # Arguments
   *
   * * `forRequests` - A list of request references
   * * `withPolicy` - The policy that should be set for the products
   * * `withTags` - The tags that should be set for the products, this will not remove additional tags
   *
   */
  updateRequests: Array<Request>;
  /**
   * Update a setting
   *
   * ## Arguments
   * * `settingId` - The setting id to update
   * * `data` - The new data for the setting
   * * `settingType` - The type of the setting
   *
   */
  updateSetting: Setting;
};

export type MutationAddExternalReferenceArgs = {
  forRequest: Scalars['String']['input'];
  theReference: Scalars['String']['input'];
};

export type MutationAddExternalReferenceToSettingArgs = {
  forSetting: Scalars['String']['input'];
  theReference: Scalars['String']['input'];
};

export type MutationCreateRequestArgs = {
  forOrganization: Scalars['String']['input'];
  forProduct?: InputMaybe<ProductReference>;
  ofProductType: ProductType;
  withExternalReferences?: InputMaybe<Array<Scalars['String']['input']>>;
  withFiles: Array<CreateFileInput>;
  withTags?: InputMaybe<Array<TagInput>>;
};

export type MutationCreateSettingArgs = {
  data: Scalars['JSON']['input'];
  organizationId: Scalars['String']['input'];
  settingType: Scalars['String']['input'];
};

export type MutationEnqueueRequestSynchronizationArgs = {
  requestIds: Array<Scalars['String']['input']>;
};

export type MutationFindCustomerArgs = {
  organizationId: Scalars['String']['input'];
};

export type MutationMarkMessageReadArgs = {
  id: Scalars['String']['input'];
};

export type MutationReadyRequestArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveExternalReferencesArgs = {
  theReferences: Array<Scalars['String']['input']>;
};

export type MutationRemoveSettingArgs = {
  settingId: Scalars['String']['input'];
};

export type MutationRemoveTagsArgs = {
  theTags: Array<Scalars['String']['input']>;
};

export type MutationSendFeedbackArgs = {
  msg: Scalars['String']['input'];
  theRequest: Scalars['String']['input'];
};

export type MutationSetExternalReferenceArgs = {
  forOrganization?: InputMaybe<Scalars['String']['input']>;
  forRequest?: InputMaybe<Scalars['String']['input']>;
  theReferences: Array<Scalars['String']['input']>;
};

export type MutationSetWebhookArgs = {
  eventWebhook: SetEventWebhook;
};

export type MutationUpdateRequestsArgs = {
  forRequests: Array<Scalars['String']['input']>;
  withPolicy?: InputMaybe<AccessPolicy>;
  withTags?: InputMaybe<Array<TagInput>>;
};

export type MutationUpdateSettingArgs = {
  data: Scalars['JSON']['input'];
  settingId: Scalars['String']['input'];
  settingType?: InputMaybe<Scalars['String']['input']>;
};

export type Organization = {
  __typename?: 'Organization';
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type OrganizationDetailed = {
  __typename?: 'OrganizationDetailed';
  creditBalance: Scalars['Float']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  overdrafts: Array<OverdraftAllowance>;
  prices: Array<CreditPrice>;
  slug: Scalars['String']['output'];
  topups: Array<LedgerTopUpEntry>;
};

export type OverdraftAllowance = {
  __typename?: 'OverdraftAllowance';
  costType: CostType;
  overdraft: Scalars['Float']['output'];
};

export type PagedLedgerGroupedResult = {
  __typename?: 'PagedLedgerGroupedResult';
  /** If this is not null, there is a next page, which can be fetched with this cursor */
  nextCursor?: Maybe<Scalars['String']['output']>;
  /** If this is not null, there is previous page, which can be fetched with this cursor */
  prevCursor?: Maybe<Scalars['String']['output']>;
  /** The credit ledger entries */
  results: Array<LedgerGroupedEntry>;
};

export type PagedLedgerResult = {
  __typename?: 'PagedLedgerResult';
  /** If this is not null, there is a next page, which can be fetched with this cursor */
  nextCursor?: Maybe<Scalars['String']['output']>;
  /** If this is not null, there is previous page, which can be fetched with this cursor */
  prevCursor?: Maybe<Scalars['String']['output']>;
  /** The credit ledger entries */
  results: Array<LedgerEntry>;
};

export type PagedMessageThread = {
  __typename?: 'PagedMessageThread';
  /** If this is not null, there is a next page, which can be fetched with this cursor */
  nextCursor?: Maybe<Scalars['String']['output']>;
  /** If this is not null, there is previous page, which can be fetched with this cursor */
  prevCursor?: Maybe<Scalars['String']['output']>;
  /** The message threads entries */
  results: Array<MessageThread>;
};

/** Representing a product */
export type Product = {
  __typename?: 'Product';
  /** The Access policy of the product */
  accessPolicy: AccessPolicy;
  externalReferences: Array<ExternalReference>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  requests: Array<Request>;
  sku?: Maybe<Scalars['String']['output']>;
};

export type ProductReference = {
  productID?: InputMaybe<Scalars['String']['input']>;
  productName?: InputMaybe<Scalars['String']['input']>;
};

export enum ProductType {
  Accessory_3D = 'ACCESSORY_3D',
  Animation = 'ANIMATION',
  Avatar_2D = 'AVATAR_2D',
  Avatar_3D = 'AVATAR_3D',
  Avatar_3DLegacy = 'AVATAR_3D_LEGACY',
  AvatarCreationData = 'AVATAR_CREATION_DATA',
  AvatarHead_2D = 'AVATAR_HEAD_2D',
  AvatarHead_3D = 'AVATAR_HEAD_3D',
  AvatarHeadLegacy = 'AVATAR_HEAD_LEGACY',
  Garment_2D = 'GARMENT_2D',
  Garment_3D = 'GARMENT_3D',
  Garment_3DCad = 'GARMENT_3D_CAD',
  GhostGarment_3D = 'GHOST_GARMENT_3D',
  Mannequin_3D = 'MANNEQUIN_3D',
  Material = 'MATERIAL',
  Outfit_2D = 'OUTFIT_2D',
  Outfit_2DParallax = 'OUTFIT_2D_PARALLAX',
  Outfit_3D = 'OUTFIT_3D',
  Scene = 'SCENE',
  SizingSettings = 'SIZING_SETTINGS',
  Undefined = 'UNDEFINED',
}

export type Query = {
  __typename?: 'Query';
  /**
   * Return all organizations that are available to the current account
   *
   */
  getAllOrganziations: Array<Organization>;
  /**
   * Get all settings for an organization
   *
   * ## Arguments
   * * `organizationId` - The organization id to search in
   *
   */
  getAllSettings: Array<Setting>;
  /**
   * Get all avatars for an organization
   *
   * ## Arguments
   * * `organizationId` - The organization id to search in
   *
   */
  getAvatars: Array<Request>;
  /**
   * Return a page of the credit ledger
   *
   * ## Arguments
   * * `organizationId` - An organization id to search for
   * * `pageSize` - The page size, has to be between 4 and 20
   * * `cursor` - The cursor to select the page
   * * `filter` - The search options
   */
  getCreditLeger: PagedLedgerResult;
  /**
   * Return a page of the credit ledger, where expenses are grouped
   * by cost type and day, expect for credit topups
   *
   * ## Arguments
   * * `organizationId` - An organization id to search for
   * * `pageSize` - The page size, has to be between 4 and 20
   * * `cursor` - The cursor to select the page
   * * `filter` - The search options
   */
  getGroupedLedger: PagedLedgerGroupedResult;
  /**
   * Get a message thread by id
   *
   * ## Arguments
   * * `threadId` - The id of the thread to fetch
   */
  getMessageThreadById?: Maybe<MessageThread>;
  /**
   * Get a list of message threads
   *
   * ## Arguments
   * * `threadId` - The id of the thread to fetch
   */
  getMessageThreads: PagedMessageThread;
  /**
   * Return an organization by id
   *
   * ## Arguments
   * * `organizationId` - An organization id to search for
   */
  getOrganziationById?: Maybe<OrganizationDetailed>;
  /**
   * Get all requests based on the references
   *
   * ## Arguments
   * * `references` - A list of references to look up, this can be external references or ids
   * * `organizationId` - An organization id to search for
   *
   */
  getRequests: Array<Request>;
  /**
   * Get all scenes for an organization
   *
   * ## Arguments
   * * `organizationId` - The organization id to search in
   *
   */
  getScenes: Array<Request>;
  /**
   * Get a setting by id
   *
   * ## Arguments
   * * `settingId` - The setting id
   *
   */
  getSettingById?: Maybe<Setting>;
};

export type QueryGetAllSettingsArgs = {
  organizationId: Scalars['String']['input'];
};

export type QueryGetAvatarsArgs = {
  organizationId: Scalars['String']['input'];
};

export type QueryGetCreditLegerArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CreditLedgerFilter>;
  organizationId: Scalars['String']['input'];
  pageSize: Scalars['Int']['input'];
};

export type QueryGetGroupedLedgerArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CreditLedgerFilter>;
  organizationId: Scalars['String']['input'];
  pageSize: Scalars['Int']['input'];
};

export type QueryGetMessageThreadByIdArgs = {
  threadId: Scalars['String']['input'];
};

export type QueryGetMessageThreadsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MessageThreadFilter>;
  organizationId: Scalars['String']['input'];
  pageSize: Scalars['Int']['input'];
};

export type QueryGetOrganziationByIdArgs = {
  organizationId: Scalars['String']['input'];
};

export type QueryGetRequestsArgs = {
  organizationId: Scalars['String']['input'];
  references: Array<Scalars['String']['input']>;
};

export type QueryGetScenesArgs = {
  organizationId: Scalars['String']['input'];
};

export type QueryGetSettingByIdArgs = {
  settingId: Scalars['String']['input'];
};

/**
 * Representing a request to create a virtual asset for a product
 * this defines for which type (2D, 3D) this asset can be used.
 */
export type Request = {
  __typename?: 'Request';
  /** The Access policy of the request */
  accessPolicy: AccessPolicy;
  externalReferences: Array<ExternalReference>;
  /** The feedback the user */
  feedback?: Maybe<RequestFeedback>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  /** The product type of the request */
  productType: ProductType;
  rejectionReason?: Maybe<RequestRejectionReason>;
  /** The generated assets split by platform (WEB, MOBILE, etc.) for this request */
  results: Array<Result>;
  sku?: Maybe<Scalars['String']['output']>;
  /** The state of the request */
  state: RequestState;
  tags: Array<Tag>;
  /** The url to call with a GET method to receive the content of this file */
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type RequestFeedback = {
  __typename?: 'RequestFeedback';
  createdAt: Scalars['String']['output'];
  feedback: Scalars['String']['output'];
  response?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type RequestRejectionReason = {
  __typename?: 'RequestRejectionReason';
  additional?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export enum RequestState {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Finished = 'FINISHED',
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED',
}

export type Result = {
  __typename?: 'Result';
  active: Scalars['Boolean']['output'];
  /** The files that should be used to initialize the SDK asset */
  files: Array<File>;
  /** The platform this result can be used with */
  format: ResultFormat;
  id: Scalars['String']['output'];
};

export enum ResultFormat {
  /** This should be used when working with a mobile SDK */
  Mobile = 'MOBILE',
  /** This is the raw result, other formats are derived from this */
  Raw = 'RAW',
  /** This should not happen */
  Undefined = 'UNDEFINED',
  /** This should be used when working with the web SDK */
  Web = 'WEB',
  /** This is the same as WEB but packaged into a zip file */
  WebZip = 'WEB_ZIP',
}

export type SetEventWebhook = {
  externalReferenceStatusChange?: InputMaybe<ExternalReferenceStatusChangeInput>;
};

export type Setting = {
  __typename?: 'Setting';
  data: Scalars['JSON']['output'];
  externalReferences: Array<ExternalReference>;
  id: Scalars['String']['output'];
  type: SettingType;
};

export type SettingType = {
  __typename?: 'SettingType';
  schema: Scalars['JSON']['output'];
  type: Scalars['String']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TagInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type GetAllSettingsQueryVariables = Exact<{
  org: Scalars['String']['input'];
}>;

export type GetAllSettingsQuery = {
  __typename?: 'Query';
  getAllSettings: Array<{
    __typename?: 'Setting';
    id: string;
    data: any;
    type: { __typename?: 'SettingType'; type: string; schema: any };
    externalReferences: Array<{
      __typename?: 'ExternalReference';
      id: string;
      reference: string;
    }>;
  }>;
};

export type CreateSettingMutationVariables = Exact<{
  org: Scalars['String']['input'];
  type: Scalars['String']['input'];
  data: Scalars['JSON']['input'];
}>;

export type CreateSettingMutation = {
  __typename?: 'Mutation';
  createSetting: {
    __typename?: 'Setting';
    data: any;
    id: string;
    type: { __typename?: 'SettingType'; type: string };
  };
};

export type AddExternalReferenceToSettingMutationVariables = Exact<{
  setting: Scalars['String']['input'];
  reference: Scalars['String']['input'];
}>;

export type AddExternalReferenceToSettingMutation = {
  __typename?: 'Mutation';
  addExternalReferenceToSetting: {
    __typename?: 'ExternalReference';
    id: string;
    reference: string;
  };
};

export type UpdateCollectionMutationVariables = Exact<{
  settingId: Scalars['String']['input'];
  data: Scalars['JSON']['input'];
}>;

export type UpdateCollectionMutation = {
  __typename?: 'Mutation';
  updateSetting: { __typename?: 'Setting'; id: string };
};

export type GetThumbnailQueryVariables = Exact<{
  org: Scalars['String']['input'];
  reference: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetThumbnailQuery = {
  __typename?: 'Query';
  getRequests: Array<{ __typename?: 'Request'; thumbnail?: string | null }>;
};

export type GetIdQueryVariables = Exact<{
  org: Scalars['String']['input'];
  reference: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetIdQuery = {
  __typename?: 'Query';
  getRequests: Array<{ __typename?: 'Request'; id: string }>;
};

export const GetAllSettingsDocument = gql`
  query getAllSettings($org: String!) {
    getAllSettings(organizationId: $org) {
      id
      type {
        type
        schema
      }
      data
      externalReferences {
        id
        reference
      }
    }
  }
`;
export const CreateSettingDocument = gql`
  mutation createSetting($org: String!, $type: String!, $data: JSON!) {
    createSetting(organizationId: $org, data: $data, settingType: $type) {
      type {
        type
      }
      data
      id
    }
  }
`;
export const AddExternalReferenceToSettingDocument = gql`
  mutation addExternalReferenceToSetting(
    $setting: String!
    $reference: String!
  ) {
    addExternalReferenceToSetting(
      forSetting: $setting
      theReference: $reference
    ) {
      id
      reference
    }
  }
`;
export const UpdateCollectionDocument = gql`
  mutation updateCollection($settingId: String!, $data: JSON!) {
    updateSetting(data: $data, settingId: $settingId) {
      id
    }
  }
`;
export const GetThumbnailDocument = gql`
  query getThumbnail($org: String!, $reference: [String!]!) {
    getRequests(organizationId: $org, references: $reference) {
      thumbnail
    }
  }
`;
export const GetIdDocument = gql`
  query getId($org: String!, $reference: [String!]!) {
    getRequests(organizationId: $org, references: $reference) {
      id
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
  _variables,
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    getAllSettings(
      variables: GetAllSettingsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAllSettingsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAllSettingsQuery>(
            GetAllSettingsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'getAllSettings',
        'query',
        variables,
      );
    },
    createSetting(
      variables: CreateSettingMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateSettingMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateSettingMutation>(
            CreateSettingDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'createSetting',
        'mutation',
        variables,
      );
    },
    addExternalReferenceToSetting(
      variables: AddExternalReferenceToSettingMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddExternalReferenceToSettingMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddExternalReferenceToSettingMutation>(
            AddExternalReferenceToSettingDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'addExternalReferenceToSetting',
        'mutation',
        variables,
      );
    },
    updateCollection(
      variables: UpdateCollectionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateCollectionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCollectionMutation>(
            UpdateCollectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updateCollection',
        'mutation',
        variables,
      );
    },
    getThumbnail(
      variables: GetThumbnailQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetThumbnailQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetThumbnailQuery>(GetThumbnailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getThumbnail',
        'query',
        variables,
      );
    },
    getId(
      variables: GetIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetIdQuery>(GetIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getId',
        'query',
        variables,
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
