import { NgModule } from '@angular/core';
import { RRBootstrapModule } from '../bootstrap/bootstrap.module';
import { RRSharedModule } from '../shared-module/shared.module';
import { TagComponent } from './components/tag/tag.component';
import { TagsComponent } from './components/tags/tags.component';

@NgModule({
  declarations: [TagComponent, TagsComponent],
  imports: [RRSharedModule, RRBootstrapModule],
  exports: [TagComponent, TagsComponent],
})
export class RRTagsModule {}
