import { BehaviorSubject, Observable } from 'rxjs';

import { IAccessorySelectionService } from './iaccessory-selection.service';
import { SelectedAccessories } from '../accessory-selection.component';

export class AccessorySelectionService extends IAccessorySelectionService {
  private _selectedAccessories$: BehaviorSubject<SelectedAccessories> =
    new BehaviorSubject<SelectedAccessories>({
      TOP: undefined,
      MIDDLE: undefined,
      BOTTOM: undefined,
    });
  public selectedGarments$: Observable<SelectedAccessories> =
    this._selectedAccessories$.asObservable();

  constructor() {
    super();
  }

  public currentSelectedGarments = (): SelectedAccessories => {
    return this._selectedAccessories$.value;
  };

  public setSelectedGarments = (accessories: SelectedAccessories) => {
    this._selectedAccessories$.next(accessories);
  };

  public updateGarmentForSlot = (ACCESSORY_SLOT: any, AccessoryDTO: any) => {
    this._selectedAccessories$.next({
      ...this._selectedAccessories$.value,
      [ACCESSORY_SLOT]: AccessoryDTO,
    });
  };
}
