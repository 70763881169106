import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'take' })
export class RRTakePipe implements PipeTransform {
  transform(value: number | true): string {
    if (value === true) return ' ';
    if (value <= 9) return new String(value).toString();

    return '9+';
  }
}
