<div class="btn-group">
  <button
    id="menu"
    type="button"
    class="nav-link menu-button"
    aria-controls="dropdown-basic"
    (click)="showMenu = !showMenu"
  >
    <span class="oi oi-menu"></span>
  </button>
  <div
    id="dropdown-basic"
    *ngIf="showMenu"
    class="dropdown-menu main-menu"
    role="menu"
    aria-labelledby="button-basic"
  >
    <div
      id="menu-dashboard"
      class="nav-item main-menu-entry d-flex flex-row row-gap"
      [routerLink]="'/requests'"
      (click)="showMenu = false"
    >
      <span class="rr-speed"></span>
      <span class="text">Dashboard</span>
    </div>

    <div
      *ngIf="auth.isCollectionCreator$ | async"
      id="menu-collections"
      class="nav-item main-menu-entry d-flex flex-row row-gap"
      [routerLink]="'/collections'"
      (click)="showMenu = false"
    >
      <span class="rr-collection" style="width: 20px"></span>
      <span class="text">Collections</span>
    </div>
    <div
      id="menu-new-request"
      class="nav-item main-menu-entry btn-new-request"
      (click)="createNewRequest()"
      *ngIf="(auth.isCustomer$ | async) || (auth.isEmployee$ | async)"
    >
      <span class="new-request">New request</span>
    </div>
    <div
      class="seperation"
      *ngIf="
        (loggedIn$ | async) &&
        ((auth.isCustomer$ | async) || (auth.isEmployee$ | async))
      "
    ></div>

    <div
      role="menuitem"
      class="nav-item main-menu-entry"
      *ngIf="(auth.isCustomer$ | async) || (auth.isEmployee$ | async)"
    >
      <app-customer-selector
        id="menu-customer-selection"
      ></app-customer-selector>
    </div>
    <div class="separation" *ngIf="loggedIn$ | async"></div>

    <div
      id="menu-to-profile"
      role="menuitem"
      class="nav-item main-menu-entry"
      *ngIf="loggedIn$ | async"
      (click)="navigateToProfile()"
    >
      <div class="main-text" *ngIf="currentUser$ | async as user">
        {{ user.name }}
      </div>
      <div
        class="second-text text-truncate"
        style="max-width: 8vw; display: inline-block"
      >
        {{ (currentUser$ | async).email }}
      </div>
    </div>
    <div
      id="menu-logout"
      class="nav-item main-menu-entry d-flex flex-row row-gap"
      *ngIf="loggedIn$ | async"
      (click)="logout()"
    >
      <span class="rr-logout"></span>
      <span>Sign out</span>
    </div>
  </div>
</div>
<div
  class="backdrop-selection"
  *ngIf="this.showMenu"
  (click)="showMenu = false"
></div>

<ng-template #profileModal>
  <app-profile-page></app-profile-page>
</ng-template>
