<span>
  <span class="oi oi-magnifying-glass"></span>

  <input
    #inputText
    type="text"
    placeholder="Find {{ label }}..."
    (keyup)="updateTagFilter()"
    (change)="updateTagFilter()"
  />
</span>

<span *ngIf="items.length === 0">No options available.</span>
<ul>
  <li
    *ngFor="let item of filteredItems"
    (click)="toggleResultCheck(item, $event)"
  >
    <input
      type="checkbox"
      [checked]="item.selected"
      (change)="toggleResultCheck(item, $event)"
    />
    <span>{{ item.text }}</span>
  </li>
</ul>
