import { Injectable } from '@angular/core';
import {
  ConfigurationDTO,
  ConfigurationEmployeeOnlyDTO,
  ConfigurationEmployeeOnlyDTOPage,
  ConfigurationPriorityDTO,
  ConfigurationResultDTO,
  ConfigurationStateLogDTO,
  ConfigurationsApi,
} from '@reactivereality/cs-api-sdk';
import { ILogger } from '../../logging';
import {
  IApiSdkService,
  ICsApiSdkConfiguration,
  IRequest,
  IRequestDetail,
  IRequestHistoryState,
  IRequestPrioritiy,
  IRequestResult,
  IRequestService,
  IRequestUpdate,
  IRequestsAggregate,
  PagingPublicRequestDTO,
  PagingRequestDTO,
} from '../api';
import { AxiosResponse } from 'axios';
import { BehaviorSubject, Observable, from, map } from 'rxjs';
import {
  RRIFilterOutput,
  RRIQueryParams,
  RRITableData,
  RR_FILTER_TYPE,
} from 'projects/web-ui-component-library/src';
import { PRODUCT_TYPE } from '../enums/product-type.enum';
import { DataService } from '../../core/services/data.service';
import { HttpClient } from '@angular/common/http';
import { NgDiscoveryService } from '../../core/services/ng-discovery.service';

@Injectable({
  providedIn: 'root',
})
export class RequestService
  extends DataService<IRequest, any>
  implements IRequestService
{
  public allPriorities_loading$: BehaviorSubject<boolean>;
  public readonly allPriorities$: BehaviorSubject<IRequestPrioritiy[]>;

  private _requestApi: ConfigurationsApi;
  private _apiVersion: string;

  private static endpointName = 'configurations';

  constructor(
    protected _http: HttpClient,
    protected _logger: ILogger,
    protected _apiSdkService: IApiSdkService,
    protected _discoveryService: NgDiscoveryService,
  ) {
    super(
      RequestService.endpointName,
      _http,
      _logger,
      _apiSdkService,
      _discoveryService,
    );
    this._apiSdkService.sdkConfig$.subscribe({
      next: this.initService,
      error: this.errorOnInitService,
    });
    this.allPriorities_loading$ = new BehaviorSubject<boolean>(false);
    this.allPriorities$ = new BehaviorSubject<Array<IRequestPrioritiy>>([]);
  }

  public getResponseFromUrl(url: string): Promise<Response> {
    return fetch(url);
  }

  public async getRequestByID(requestId: string): Promise<IRequestDetail> {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .configurationLink(requestId, this._apiVersion)
        .then((response: AxiosResponse<ConfigurationDTO>) => {
          if (response && response.data) {
            return response.data;
          }
          return undefined;
        });
    }

    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }
  public async getRequests(
    filter: string,
    page: number,
    pageSize: number,
    cancelSignal?: any,
    includeResult?: boolean,
  ): Promise<PagingRequestDTO> {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .vversionConfigurationsGet(
          this._apiVersion,
          page,
          pageSize,
          'createdAt_desc',
          filter,
          '',
          includeResult !== undefined ? includeResult : true,
          { signal: cancelSignal },
        )
        .then((response: AxiosResponse<any, any>) => {
          if (response && response.data) {
            return {
              configurations: response.data.results,
              paging: {
                currentPage: response.data.currentPage,
                pageCount: response.data.pageCount,
                pageSize: response.data.pageSize,
                recordCount: response.data.recordCount,
              },
            };
          }
          return undefined;
        });
    }

    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }
  public async getPublicRequests(
    filter: string,
    page: number,
    pageSize: number,
  ): Promise<PagingPublicRequestDTO> {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .vversionConfigurationsPublicGet(
          this._apiVersion,
          page,
          pageSize,
          'createdAt_desc',
          filter,
          true,
        )
        .then((response: AxiosResponse<any, any>) => {
          if (response && response.data) {
            return {
              configurations: response.data.results,
              paging: {
                currentPage: response.data.currentPage,
                pageCount: response.data.pageCount,
                pageSize: response.data.pageSize,
                recordCount: response.data.recordCount,
              },
            };
          }
          return undefined;
        });
    }

    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }

  public async getAllCustomerRequests(
    customerId: string,
    filter: string,
    page: number,
    pageSize: number,
    tags: string,
    inlcudedArchived?: boolean,
    cancelSignal?: AbortSignal,
  ): Promise<PagingPublicRequestDTO> {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .vversionConfigurationsAllforcustomerGet(
          customerId,
          this._apiVersion,
          page,
          pageSize,
          'createdAt_desc',
          filter,
          tags,
          true,
          inlcudedArchived,
          {
            signal: cancelSignal,
          },
        )
        .then((response: AxiosResponse<any, any>) => {
          if (response && response.data) {
            return {
              configurations: response.data.results,
              paging: {
                currentPage: response.data.currentPage,
                pageCount: response.data.pageCount,
                pageSize: response.data.pageSize,
                recordCount: response.data.recordCount,
              },
            };
          }
          return undefined;
        });
    }

    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }

  public async update(request: IRequestUpdate): Promise<IRequestDetail> {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .vversionConfigurationsIdPut(request.id, this._apiVersion, request)
        .then((response: AxiosResponse<ConfigurationDTO>) => {
          if (response) {
            return response.data;
          }

          return undefined;
        });
    }

    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }

  public async delete(request: IRequestDetail): Promise<IRequestDetail> {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .vversionConfigurationsIdDelete(request.id, this._apiVersion)
        .then((response: AxiosResponse<ConfigurationDTO>) => {
          if (response) {
            return response.data;
          }

          return undefined;
        });
    }

    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }

  public async approveRequest(requestId: string): Promise<void> {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .vversionConfigurationsIdApprovePost(requestId, this._apiVersion)
        .then((response: AxiosResponse<void>) => {
          return response.data;
        });
    }

    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }

  public async rejectRequest(
    request: IRequestDetail,
    metadata: { [key: string]: any },
  ): Promise<void> {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .vversionConfigurationsIdRejectPost(
          request.id,
          this._apiVersion,
          metadata,
        )
        .then((response: AxiosResponse<void>) => {
          return response.data;
        });
    }
    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }

  public async setReady(id: string): Promise<void> {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .vversionConfigurationsIdReadyPost(id, this._apiVersion)
        .then((response: AxiosResponse<void>) => {
          return response.data;
        });
    }

    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }

  public async getHistory(requestId: string): Promise<IRequestHistoryState[]> {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .vversionConfigurationsIdStatesGet(requestId, this._apiVersion)
        .then((response: AxiosResponse<ConfigurationStateLogDTO[]>) => {
          if (response && Array.isArray(response.data)) {
            return response.data.map(
              this.mapConfigurationStateDTOToRequestHistory,
            );
          }

          return [];
        });
    }

    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }

  public async getResult(requestResultId: string): Promise<IRequestResult> {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .vversionResultsResultIdGet(requestResultId, this._apiVersion)
        .then((response: AxiosResponse<any>) => {
          if (response && response.data) {
            return response.data;
          }

          return undefined;
        });
    }

    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }

  public async getResults(requestId: string): Promise<IRequestResult[]> {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .vversionConfigurationsIdResultsGet(requestId, this._apiVersion)
        .then((response: AxiosResponse<ConfigurationResultDTO[]>) => {
          if (response && Array.isArray(response.data)) {
            return response.data;
          }
          return undefined;
        });
    }

    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }

  public getData(
    params: RRIQueryParams<IRequest, any>,
  ): Observable<RRITableData<IRequest>> {
    let currentPage = undefined;
    let pageSize = undefined;
    let sort = undefined;
    let filters = undefined;
    let tags = undefined;
    let includeResults = undefined;

    if (params) {
      if (params.pagination) {
        if (params.pagination.currentPage) {
          currentPage = params.pagination.currentPage;
        }

        if (params.pagination.pageSize) {
          pageSize = params.pagination.pageSize;
        }
      }

      if (params.sort) {
        sort = this.formatSortBy(params.sort);
      }

      if (params.filters) {
        filters = this.formatFilters(params.filters);
      }

      if (params.includeResult) {
        includeResults = true;
      }

      // have to send as undefined if empty, or we only get results with tags
      if (params.tags && Object.keys(params.tags).length) {
        tags = JSON.stringify(params.tags);
      }
    }

    return from(
      this._requestApi.vversionConfigurationsGet(
        this._apiVersion,
        currentPage,
        pageSize,
        sort,
        filters,
        tags,
        includeResults,
      ),
    ).pipe(
      map((response: AxiosResponse<ConfigurationEmployeeOnlyDTOPage>) => {
        if (response && response.data) {
          return {
            pagination: {
              currentPage: response.data.currentPage,
              pageCount: response.data.pageCount,
              pageSize: response.data.pageSize,
              recordCount: response.data.recordCount,
            },
            results: response.data.results.map(
              this.mapConfigurationEmployeeOnlyDTOToIRequest,
            ),
          };
        }

        return undefined;
      }),
    );
  }

  public getRequestAggregates(
    filters?: RRIFilterOutput<any>[],
    tags?: RRIQueryParams<IRequest, any>['tags'],
  ): Observable<IRequestsAggregate> {
    // this.checkProductTypeFilter(filters);
    const filtersString = this.formatFilters(filters);
    const tagsString = tags ? JSON.stringify(tags) : undefined;

    return new Observable((observer) => {
      this._requestApi
        .vversionConfigurationsAggregatesGet(
          this._apiVersion,
          filtersString,
          tagsString,
        )
        .then((response: AxiosResponse<{ [key: string]: any }>) => {
          if (response) {
            observer.next(this.mapObjectToIRequestAggregate(response.data));
            observer.complete();
          }
        });
    });
  }

  public getRequestPageCount(filter: string) {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .vversionConfigurationsGet(
          this._apiVersion,
          1,
          -1,
          'createdAt',
          filter,
          '',
          false,
        )
        .then((response: AxiosResponse<any, any>) => {
          if (response && response.data) {
            return response.data.recordCount;
          }

          return undefined;
        });
    }

    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }
  public getPublicRequestsPageCount(filter: string) {
    if (this._requestApi && this._apiVersion) {
      return this._requestApi
        .vversionConfigurationsPublicGet(
          this._apiVersion,
          1,
          -1,
          'createdAt',
          filter,
          false,
        )
        .then((response: AxiosResponse<any, any>) => {
          if (response && response.data) {
            return response.data.recordCount;
          }

          return undefined;
        });
    }

    this._logger.warn(
      'There is no Request API Sdk Configuration or API version.',
    );
  }

  private loadAllPriorities(): void {
    if (this._requestApi && this._apiVersion) {
      this.allPriorities_loading$.next(true);

      this._requestApi
        .vversionConfigurationsPrioritiesGet(this._apiVersion)
        .then((response: AxiosResponse<ConfigurationPriorityDTO[]>) => {
          if (response && Array.isArray(response.data)) {
            this.allPriorities$.next(
              response.data.map((value: ConfigurationPriorityDTO) => ({
                id: value.name,
                name: value.name,
              })),
            );
          }
        })
        .catch((reason: any) => {
          this._logger.error('Error while fetching priorities.', reason);
        })
        .finally(() => {
          this.allPriorities_loading$.next(false);
        });
    } else {
      this._logger.warn(
        'There is no Request API Sdk Configuration or API version.',
      );
    }
  }

  private checkProductTypeFilter(filters?: RRIFilterOutput<any>[]) {
    const productTypeFilter = this.findFilterByFieldName(
      'productTypeId',
      filters,
    );
    if (!productTypeFilter) {
      filters.push({
        type: RR_FILTER_TYPE.TEXT_SEARCH,
        filterBy: {
          fieldName: 'productTypeId',
          value: [
            PRODUCT_TYPE.GARMENT_2D,
            PRODUCT_TYPE.GARMENT_3D,
            PRODUCT_TYPE.ACCESSORY_3D,
            PRODUCT_TYPE.OUTFIT_2D,
          ],
          defaultValue: '',
        },
      });
    } else if (
      !productTypeFilter.filterBy.value ||
      (productTypeFilter.filterBy.value && !productTypeFilter.filterBy.value.id)
    ) {
      productTypeFilter.filterBy.value = [
        PRODUCT_TYPE.GARMENT_2D,
        PRODUCT_TYPE.GARMENT_3D,
        PRODUCT_TYPE.ACCESSORY_3D,
        PRODUCT_TYPE.OUTFIT_2D,
      ];
    }
  }

  private mapConfigurationStateDTOToRequestHistory = (
    stateLogDto: ConfigurationStateLogDTO,
  ): IRequestHistoryState => ({
    configurationId: stateLogDto.configurationId,
    createdAt: stateLogDto.createdAt,
    entryId: stateLogDto.entryId,
    metadata: stateLogDto.metadata,
    state: stateLogDto.state,
    user: {
      ...stateLogDto.user,
      createdAt: stateLogDto.user?.createdAt ?? Date.now(),
      modifiedAt: stateLogDto.user?.modifiedAt ?? Date.now(),
    },
  });

  private mapObjectToIRequestAggregate = (object: {
    [key: string]: any;
  }): IRequestsAggregate => {
    if (object.statesCount && Array.isArray(object.statesCount)) {
      return {
        statesCount: object.statesCount,
      };
    }

    return { statesCount: [] };
  };

  private mapConfigurationEmployeeOnlyDTOToIRequest = (
    dto: ConfigurationEmployeeOnlyDTO,
  ): IRequest => ({
    ...dto,
    productTypeId: `${dto.productTypeId}`,
    ThumbnailImageID: dto.thumbnailImageID,
    tags: (dto.tags ?? []).map((t) => ({
      tagKeyColor: t.tagKeyColor ?? '#333',
      tagKeyName: t.tagKeyName,
      value: t.value ?? null,
    })),
  });

  protected initService = (sdkConfig: ICsApiSdkConfiguration) => {
    if (
      sdkConfig !== undefined &&
      sdkConfig.configurationsApiSdk &&
      sdkConfig.apiVersion
    ) {
      this._apiVersion = sdkConfig.apiVersion;
      this._requestApi = sdkConfig.configurationsApiSdk;
      this.loadAllPriorities();
    }
  };
}
