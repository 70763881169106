import { Component, ViewChild } from '@angular/core';

import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { AbstractFilterService, RRFilterBarComponent, RRFilterModule, buildInputElement } from 'projects/web-ui-component-library/src';
import { DateInputElementComponent } from 'projects/web-ui-component-library/src/lib/filter/components/filter-bar/filter-bar-element/date-input-element/date-input-element.component';
import { TextInputElementComponent } from 'projects/web-ui-component-library/src/lib/filter/components/filter-bar/filter-bar-element/text-input-element/text-input-element.component';
import { CollectionScreenStore } from '../../../../collection-screen.state';


@Component({
  selector: 'app-collection-search-filter-bar',
  standalone: true,
  imports: [RRFilterModule],
  templateUrl: './collection-search-filter-bar.component.html',
  styleUrls: ['./collection-search-filter-bar.component.scss'],
})
export class CollectionSearchFilterBarComponent {
  clearFilterValues() {
    this.store.setFilters({});
  }
  @ViewChild('filterBar') filterBar: RRFilterBarComponent<CollectionFilters>;

  requestFilterService: RequestFilterService;

  constructor(
    private logger: ILogger,
    public store: CollectionScreenStore,
  ) {

    this.requestFilterService = new RequestFilterService();
    
    this.store.filters$.subscribe((filters) => {
        this.requestFilterService.filterValues.next(filters);
    });
    
    this.requestFilterService.filterValues.subscribe((filters) => {
      this.logger.debug('RequestListActionBar', 'filterValues:', filters);
      this.store.setFilters(filters);
    });
  }
}

export type CollectionFilters = {
  createdAt: { from: Date; to: Date };
  name: string;
  sku: string;
};

class RequestFilterService extends AbstractFilterService<CollectionFilters> {
  constructor() {
    super({
      createdAt: {
        id: 'createdAt',
        label: 'Created',
        icon: 'calendar',
        ...buildInputElement(DateInputElementComponent),
      },
      sku: {
        id: 'sku',
        label: 'Reference',
        searchable: true,
        ...buildInputElement(TextInputElementComponent),
      },
      name: {
        id: 'name',
        label: 'Name',
        searchable: true,
        ...buildInputElement(TextInputElementComponent),
      },
    });
  }
}