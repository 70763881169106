import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  RRBootstrapModule,
  RRChartsModule,
  RRFilterModule,
  RRFilteredTableModule,
  RRLayoutModule,
  RRModalModule,
  RRSpinnerModule,
  RRTableModule,
} from 'projects/web-ui-component-library/src';
import { RequestListPageComponent } from './components/request-list-page/request-list.page.component';
import { RequestOverviewRoutingModule } from './request-overview.router.module';
import { StoreModule } from '@ngrx/store';
import { requestOverviewReducer } from './store/requests-overview.reducer';
import { EffectsModule } from '@ngrx/effects';
import { RequestsOverviewEffects } from './store/requests-overview.effects';
import { RequestOverviewService } from './services/request-overview.service';
import { RequestListActionBarComponent } from './components/request-list-action-bar/request-list-action-bar.component';
import { InputDropdownComponent } from '../components/inputs/input-dropdown/input-dropdown.component';
import { InputTextComponent } from '../components/inputs/input-text/input-text.component';
import { InputDateComponent } from '../components/inputs/input-date/input-date.component';
import { VirtualDressingRoomModule } from '../virtual-dressing-room/virtual-dressing-room.module';

import { AvatarSelectionEntryComponent } from '../components/avatar-selection-entry/avatar-selection-entry.component';
import {
  PrettyProductDatePipe,
  RequestListRowComponent,
} from './components/request-list-page/request-list-row/request-list-row.component';
import { FileUploadComponent } from '../components/file-upload/file-upload.component';
import { FileUploadFullComponent } from '../components/file-upload-full/file-upload-full.component';
import { GarmentSelectionComponent } from '../components/garment-selection/garment-selection.component';
import { AddGarmentModalComponent } from '../components/garment-selection/add-garment-modal/add-garment-modal.component';
import { RequestListPaginationComponent } from './components/request-list-page/request-list-pagination/request-list-pagination.component';
import { CommonInputsComponent } from './components/create-request-modal/common-inputs/common-inputs.component';
import { TagSelectionComponent } from '../components/tag-selection/tag-selection.component';
import {
  PrettyProductStatePipe,
  StateComponent,
} from './components/request-list-page/request-list-row/state/state.component';
import { TagComponent } from './components/request-list-page/request-list-row/tags/tag.component';
import {
  AssetPrettyProductTypePipe,
  RequestListAssetComponent,
} from './components/request-list-page/request-list-row/asset/asset.component';
import {
  PrettyProductTypePipe,
  ProductTypeComponent,
} from './components/request-list-page/request-list-row/product-type/product-type.component';
import { AddAvatarModalComponent } from '../components/avatar-selection-entry/add-avatar-modal/add-avatar-modal.component';
import { CreateRequestModalComponent } from './components/create-request-modal/create-request-modal.component';
import { CreateRequestSelectTypeModalComponent } from './components/create-request-select-type-modal/create-request-select-type-modal.component';


@NgModule({
  declarations: [
    RequestListPageComponent,
    RequestListActionBarComponent,
    RequestListRowComponent,
    StateComponent,
    TagComponent,
    PrettyProductStatePipe,
    ProductTypeComponent,
    AssetPrettyProductTypePipe,
    PrettyProductTypePipe,
    RequestListAssetComponent,
  ],
  providers: [RequestOverviewService],
  imports: [
    CommonModule,
    RequestOverviewRoutingModule,
    RRBootstrapModule,
    RRFilteredTableModule,
    RRModalModule,
    RRSpinnerModule,
    RRChartsModule,
    RRTableModule,
    StoreModule.forFeature('requestOverview', requestOverviewReducer),
    EffectsModule.forFeature([RequestsOverviewEffects]),

    RRLayoutModule,
    RRFilterModule,
    InputDropdownComponent,
    InputTextComponent,
    InputDateComponent,
    VirtualDressingRoomModule,
    PrettyProductDatePipe,
    AvatarSelectionEntryComponent,
    FileUploadComponent,
    FileUploadFullComponent,
    CommonInputsComponent,
    TagSelectionComponent,
    AddAvatarModalComponent,
    GarmentSelectionComponent,
    AddGarmentModalComponent,
    CreateRequestModalComponent,
    CreateRequestSelectTypeModalComponent,
    RequestListPaginationComponent,
    PrettyProductDatePipe,
  ],
})
export class RequestOverviewModule {}
