import { Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FilterBarInputElement } from './components/filter-bar/filter-bar-element/filter-bar-element.component';

type Resolvable<T> = T | Promise<T>;

// Define the filters available, and their data type
export interface Filters {
  [key: string]: any;
}

export type InputElementOptions<T> = T extends { options: infer O }
  ? O
  : unknown;

export const buildInputElement = <E extends FilterBarInputElement<any>>(
  inputComponent: Type<E>,
  inputOptions: InputElementOptions<E> = undefined,
) => {
  return {
    inputComponent,
    inputOptions,
  };
};

export type SelectedFilters<F extends Filters> = {
  [K in keyof F]?: F[K];
  // [K in keyof F]?: FilterConfig<F, K>;
};

// Define the way to configure the available filters, and their behaviour
export type FilterConfig<
  F,
  K extends keyof F = keyof F,
  T extends F[K] = F[K],
  U extends FilterBarInputElement<T> = FilterBarInputElement<T>,
> =
  | {
      id: K;
      label: string;
      icon?: string;
      searchable?: boolean;
      inputComponent: Type<U>;
      inputOptions: InputElementOptions<U>;
      // options?: Resolvable<T>;
    }
  | {
      id: K;
    };

export type FilterConfigCollection<F extends Filters> = {
  [K in keyof F]: FilterConfig<F, K>;
};

export type RRFilterEvent<F extends Filters> = {
  [K in keyof F]: F[K];
};

export abstract class AbstractFilterService<F extends Filters> {
  // public filterEvents: EventEmitter<{ action: 'Load'|'Save', /* args: Partial<RRFilterEvent<F>> */ }> = new EventEmitter();
  public filterValues: BehaviorSubject<SelectedFilters<F>> =
    new BehaviorSubject({});

  constructor(public readonly filters: FilterConfigCollection<F>) {}

  public setValue<K extends keyof F>(filter: K, value: F[K]) {
    const existing = this.filterValues.getValue();

    this.filterValues.next({
      ...existing,
      [filter]: value,
    });
  }
}
