import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  PageContext,
  PageContextService,
} from 'projects/web-ui-component-library/src/public-api';

@Component({
  selector: 'rr-top-app-bar',
  templateUrl: './top-app-bar.component.html',
  styleUrls: ['./top-app-bar.component.scss'],
})
export class RRTopAppBarComponent implements AfterViewInit {
  @Input() titleText: string;
  @Input() parentLink?: { title: string; url: string };

  @Output() logout: EventEmitter<void> = new EventEmitter();

  @ViewChild('actionBar', { read: ViewContainerRef, static: false })
  actionBar: ViewContainerRef;

  actionBarComponent: PageContext['actionBar'];

  constructor(
    private cdr: ChangeDetectorRef,
    private pageContext: PageContextService,
  ) {}

  ngAfterViewInit(): void {
    this.pageContext.subscribe((context) => {
      this.titleText = context.title;
      this.parentLink = context.parent;

      this.cdr.detectChanges();

      if (this.actionBarComponent?.type !== context.actionBar?.type) {
        this.actionBarComponent = context.actionBar;
      }

      if (this.actionBarComponent && this.actionBar) {
        this.actionBar.clear();
        const ref = this.actionBar.createComponent(
          this.actionBarComponent.type,
        );
        this.actionBarComponent.onCreate(ref.instance);
      }
    });
  }

  public get showActionBar(): boolean {
    return this.actionBarComponent !== undefined;
  }

  doLogout() {
    this.logout.emit();
  }

  // navigateTo(parentLink: { title: string; url: string; }) {
  //   this.router.navigate([ parentLink.url ])
  // }
}
