<div class="menu">
  <span
    class="badgey"
    *ngIf="filterCount > 0"
    (click)="showFilterList = !showFilterList"
    >{{ filterCount }}</span
  >
  <span
    class="rr-selection-filter-menu"
    (click)="showFilterList = !showFilterList"
  ></span>
</div>

<div
  class="backdrop"
  *ngIf="showFilterList"
  (click)="showFilterList = false; store.updateVisibleFilter('')"
></div>

<div class="filterList" *ngIf="showFilterList">
  <rr-card>
    <li class="filterHeader">Filter by</li>
    <li
      class="filterEntry"
      (click)="store.updateVisibleFilter(filter.id)"
      *ngFor="let filter of filters"
    >
      <span>{{ filter.text }}</span>
      <span
        (click)="this.clearFilter($event, filter.id)"
        *ngIf="(store.filters$ | async)[filter.id] !== undefined"
        class="rr-selection-search-delete ml-auto"
      ></span>
    </li>
    <li class="clearFilter" *ngIf="filterCount > 0" (click)="clearAllFilters()">
      Clear all filters
    </li>
  </rr-card>
</div>

<div class="filterContainer">
  <ng-template #filterContainer></ng-template>
</div>
