import { ComponentStore } from '@ngrx/component-store';
import { ApiKeyDto } from '@reactivereality/cs-api-sdk';
import { IUserService } from '../../../../data';
import { Injectable } from '@angular/core';

interface IApiKeyStoreState {
  keys: Array<ApiKeyDto>;
  loading: boolean;
  token: string | null;
  lastUpdate: Date | null;
}

@Injectable()
export class ApiKeyStore {
  private store = new ComponentStore<IApiKeyStoreState>({
    keys: [],
    loading: false,
    token: null,
    lastUpdate: null,
  });
  constructor(private userService: IUserService) {}

  public keys$ = this.store.select((s) => s.keys);
  public isLoading$ = this.store.select((s) => s.loading);
  public lastUpdate$ = this.store.select((s) => s.lastUpdate);
  public token$ = this.store.select((s) => s.token);

  public setLoading = (loading: boolean) => {
    this.store.patchState((state) => ({
      loading,
    }));
  };

  public refreshKeys = (): Promise<void> => {
    this.setLoading(true);

    return this.userService
      .listApiKeys()
      .then((keys) => {
        this.store.patchState((state) => ({
          keys,
          lastUpdate: new Date(),
        }));
      })
      .then(() => {
        this.setLoading(false);
      });
  };

  public deleteKey = (id: string): Promise<void> => {
    this.setLoading(true);

    return this.userService.revokeApiKey(id).then(() => {
      return this.refreshKeys();
    });
  };

  public addKey = (name?: string): Promise<void> => {
    this.setLoading(true);

    return this.userService
      .newApiKey(name)
      .then((response) => {
        this.store.patchState((state) => ({
          token: response.key,
        }));
      })
      .then(() => {
        return this.refreshKeys();
      });
  };
}
