<div class="d-flex flex-row mb-4 assetCard pos-relative">
  <app-row-buttons
    [reference]="reference"
    (moveRowDown)="this.moveReferenceDown.emit(this.reference)"
    (moveRowUp)="this.moveReferenceUp.emit(this.reference)"
    (removeRow)="this.removeReference.emit(this.reference)"
  ></app-row-buttons>
  <div class="collectionThumbnail">
    <img src="{{ reference.thumbnail }}" class="collectionThumbnail" />
  </div>
  <div class="d-flex flex-column ml-3">
    <b>{{ reference.name }}</b>
    <span class="data-label mt-2 ml-1"
      ><b>Reference: </b>
      <span class="data-value">{{ reference.reference }}</span>
      <app-copy-to-clipboard
        data="{{ reference.reference }}"
      ></app-copy-to-clipboard
    ></span>
    <span class="data-label mt-2 ml-1"
      ><b>CS-ID: </b> <span class="data-value">{{ reference.id }}</span>
      <app-copy-to-clipboard data="{{ reference.id }}"></app-copy-to-clipboard
    ></span>
    <span class="data-label mt-2 ml-1"
      ><b>Outfit: </b
      ><a href="{{ reference.resultUrl }}" target="_blank">Download </a> or
      <a href="/requests/{{ reference.id }}" target="_blank"> Open Request</a>
    </span>
  </div>
  <span cdkDragHandle class="rr-drag dragZone"></span>
</div>
