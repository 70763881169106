import { Injectable } from '@angular/core';
import {
  Configuration,
  ConfigurationsApi,
  CustomersApi,
  FilesApi,
  ProcessApi,
  ProductsApi,
  TagsApi,
  UsersApi,
} from '@reactivereality/cs-api-sdk';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { IAuthService } from 'projects/content-service-cms/src/app/auth';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { IApiSdkService, ICsApiSdkConfiguration } from '../api';
import { User } from 'oidc-client';
import { NgDiscoveryService } from '../../core/services/ng-discovery.service';

@Injectable({
  providedIn: 'root',
})
export class ApiSdkService implements IApiSdkService {
  public readonly sdkConfig$: Observable<ICsApiSdkConfiguration | undefined>;

  private internal_SdkConfig$: BehaviorSubject<
    ICsApiSdkConfiguration | undefined
  >;

  constructor(
    private discovery: NgDiscoveryService,
    private _authService: IAuthService,
    private _logger: ILogger,
  ) {
    this._logger.info('Create API Sdk service ...');

    this.internal_SdkConfig$ = new BehaviorSubject<
      ICsApiSdkConfiguration | undefined
    >(undefined);
    this.sdkConfig$ = this.internal_SdkConfig$.asObservable();

    this._authService.loggedUser$.subscribe({
      next: this.initApiSdkConfig,
      error: this.errorOnInitSdk,
    });

    this.internal_SdkConfig$.pipe(filter((v) => v !== undefined)).subscribe({
      next: (value: ICsApiSdkConfiguration) => {
        this._logger.info(
          'The CS API Sdk is exposed in window.CSApiSdk:',
          value,
        );
        window.CSApiSdk = value;
      },
    });
  }

  initApiSdkConfig = (user: User) => {
    if (user) {
      //TODO: we should not need to remove the version from the base api, the base api should not include the api version
      const config = new Configuration({
        basePath: this.discovery.config.apiConfiguration.baseApiUrl.replace(
          `/${this.discovery.config.appConfiguration.apiVersion}`,
          '',
        ),
        apiKey: `Bearer ${user.access_token}`,
      });

      const processApiSdk = new ProcessApi(config);
      const productApiSdk = new ProductsApi(config);
      const customersApiSdk = new CustomersApi(config);
      const usersApiSdk = new UsersApi(config);
      const configurationsApiSdk = new ConfigurationsApi(config);
      const filesApiSdk = new FilesApi(config);
      const tagsApiSdk = new TagsApi(config);

      //TODO: we should not need to remove the 'v' from the api version, the api version should not include the 'v'
      this.internal_SdkConfig$.next({
        configuration: config,
        apiVersion: this.discovery.config.appConfiguration.apiVersion.replace(
          'v',
          '',
        ),
        processApiSdk,
        productApiSdk,
        customersApiSdk,
        usersApiSdk,
        configurationsApiSdk,
        filesApiSdk,
        tagsApiSdk,
      });
    } else {
      this.internal_SdkConfig$.next(undefined);
    }
  };

  errorOnInitSdk = (error: any) => {
    this._logger.error('Cannot init API SDK.', error);
  };
}
