import { NgModule } from '@angular/core';
import { SimpleAlertComponent } from './components/simple-alert/simple-alert.component';
import { RRSharedModule } from '../shared-module/shared.module';
import { RRBootstrapModule } from '../bootstrap/bootstrap.module';

@NgModule({
  declarations: [SimpleAlertComponent],
  imports: [RRSharedModule, RRBootstrapModule],
  exports: [SimpleAlertComponent],
})
export class RRAlertsModule {}
