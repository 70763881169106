import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ConfigurationHasTagKeyDTO,
  TagKeyDTO,
} from '@reactivereality/cs-api-sdk';
import { v4 as uuid } from 'uuid';
import {
  RRSpinnerModel,
  RRSpinnerModule,
} from 'projects/web-ui-component-library/src';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ITagService } from '../../data/api';
import { ILogger } from '../../logging';

export interface Color {
  name: string;
  color: string;
}
@Component({
  selector:
    'app-tag-selection[cutstomerId][configurationId][configurationTags]',
  templateUrl: './tag-selection.component.html',
  styleUrls: ['./tag-selection.component.scss'],
  standalone: true,
  imports: [RRSpinnerModule, CommonModule, FormsModule],
})
export class TagSelectionComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('inputSpan') input: ElementRef;
  public inputText: string = '';
  public spinnerTextBox: RRSpinnerModel = {
    show: false,
    text: 'Create Tag',
  };
  public spinnerSelectionBox: RRSpinnerModel = {
    show: false,
    text: 'Loading',
  };
  public selected: TagKeyDTO[] = [];
  public itemList: string[] = ['Color', 'Public', 'Content', 'Size'];

  public colorList: Color[] = [
    {
      name: 'Grey',
      color: '#D9D9D9',
    },
    {
      name: 'Yellow',
      color: '#FDE9C1',
    },
    {
      name: 'Blue',
      color: '#CDE1ED',
    },
    {
      name: 'Red',
      color: '#FFDED8',
    },
    {
      name: 'Light gray',
      color: '#F2F2F2',
    },
    {
      name: 'Brown',
      color: '#F1DED6',
    },
    {
      name: 'Orange',
      color: '#F9E1CC',
    },
    {
      name: 'Green',
      color: '#E0F0DE',
    },
    {
      name: 'Blue',
      color: '#CCE6F5',
    },
    {
      name: 'Purple',
      color: '#E6DCEF',
    },
    {
      name: 'Pink',
      color: '#F5DFEA',
    },
  ];
  public allTags: TagKeyDTO[] = [];
  public filteredTags: TagKeyDTO[] = [];
  public editItem: TagKeyDTO;
  public editLeft: number;
  public editTop: number;
  public listOpacity: boolean;

  @Input() configurationTags: ConfigurationHasTagKeyDTO[];
  @Input() configurationId: uuid;
  @Input() cutstomerId: uuid;
  @Input() disabled: boolean;

  @Output() addToSelectionEvent: EventEmitter<TagKeyDTO> = new EventEmitter();
  @Output() addExistingTagToSelectionEvent: EventEmitter<TagKeyDTO> =
    new EventEmitter();
  @Output() removeFromSelectionEvent: EventEmitter<TagKeyDTO> =
    new EventEmitter();
  @Output() updateOrCreateSelectionEvent: EventEmitter<TagKeyDTO> =
    new EventEmitter();
  @Output() deleteTagEvent: EventEmitter<TagKeyDTO> = new EventEmitter();

  constructor(private _tagService: ITagService, private _logger: ILogger) {}

  ngAfterViewInit(): void {
    const observer = new MutationObserver((list) => {
      const charData = list.find((o) => o.type === 'characterData');
      if (this.disabled) {
        this.input.nativeElement.innerHTML = '';
        this.listOpacity = false;
        return;
      }
      if (charData) {
        this.inputText = charData.target?.nodeValue;

        if (this.inputText === '') {
          this.filteredTags = this.allTags;
        }
        this.filteredTags = this.allTags.filter((o) =>
          o.name.toLowerCase().includes(this.inputText.toLowerCase()),
        );
      }
    });
    const input = document.getElementById('select-input');

    observer.observe(this.input.nativeElement, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: !this.disabled,
    });
  }

  async ngOnInit(): Promise<void> {
    try {
      this.spinnerSelectionBox.show = true;
      this.allTags = await this._tagService.getAllTagsByCustomerId(
        this.cutstomerId,
      );
      this.filteredTags = this.allTags;
      this._logger.debug('configurationTags:', this.configurationTags);

      for (const tag of this.configurationTags) {
        const tagItem = await this._tagService.getTagOnlyForCustomer(
          tag.tagKeyId,
          this.cutstomerId,
        );
        if (tagItem) {
          this.selected.push(tagItem);
        }
      }
    } catch (error) {
      this._logger.error(error.message);
    } finally {
      this.spinnerSelectionBox.show = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reloadTags();
  }

  public async addToSelection(event: KeyboardEvent) {
    try {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (this.inputText === '') {
          return;
        }
        this.spinnerSelectionBox.show = true;
        this.spinnerSelectionBox.text = 'Add tag';
        this.clearInput();

        const tag: TagKeyDTO = {
          id: uuid(),
          name: this.inputText,
          customerId: this.cutstomerId,
          color: this.colorList[Math.round(Math.random() * 10)].color,
        };
        this.addTagtoConfiguration(tag);
        this.addToSelectionEvent.emit(tag);

        this.filteredTags = this.allTags;
      }
    } catch (error) {
      this._logger.error(error.message);
    } finally {
      this.spinnerSelectionBox.show = false;
      this.inputText = '';
    }
  }

  public async removeItem(selectedItem: TagKeyDTO) {
    try {
      this.spinnerSelectionBox.show = true;
      this.spinnerSelectionBox.text = 'Remove tag';

      this.selected = this.selected.filter((o) => o.id !== selectedItem.id);
      this.removeFromSelectionEvent.emit(selectedItem);
    } catch (error) {
      this._logger.error(error.message);
    } finally {
      this.spinnerSelectionBox.show = false;
    }
  }
  public async addTagToSelected(tag: TagKeyDTO) {
    try {
      this.spinnerTextBox.show = true;
      this.spinnerTextBox.text = 'Add tag';

      if (!this.selected.find((o) => o.id === tag.id)) {
        this.addExistingTagToSelectionEvent.emit(tag);
        this.addTagtoConfiguration(tag);
      }

      this.filteredTags = this.allTags;
      this.clearInput();
    } catch (error) {
      this._logger.error(error.message);
    } finally {
      this.spinnerTextBox.show = false;
    }
  }
  public editTag(item: TagKeyDTO, event: MouseEvent) {
    this.listOpacity = true;
    this.editItem = item;
    if (window.innerWidth > event.x + 305) {
      this.editLeft = event.x - 140;
    } else {
      this.editLeft = event.x - 140;
    }
    if (window.innerHeight > event.y + 355) {
      this.editTop = event.y + 20;
    } else {
      this.editTop = window.innerHeight - 350;
    }
  }
  public backdropListClicked() {
    this.listOpacity = false;
  }
  public backdropClicked() {
    this.editItem = undefined;
    document.getElementById('select-input').focus();
  }
  public updateTag() {
    try {
      this.spinnerTextBox.show = true;
      this.spinnerTextBox.text = 'Update tag';
      this.updateOrCreateSelectionEvent.emit(this.editItem);
      this.reloadSelectedItems();
    } catch (error) {
      this._logger.error(error.message);
    } finally {
      this.spinnerTextBox.show = false;
    }
  }
  public changeColor(color: Color) {
    this.editItem.color = color.color;
    this.updateTag();
  }
  public async deleteTag(tag: TagKeyDTO) {
    try {
      this.spinnerTextBox.show = true;
      this.spinnerTextBox.text = 'Delete tag';
      this.deleteTagEvent.emit(tag);
      this.editItem = undefined;
      await this.reloadTags();
    } catch (error) {
      this._logger.error(error.message);
    } finally {
      this.spinnerTextBox.show = false;
    }
  }
  private async reloadTags() {
    this.allTags = await this._tagService.getAllTagsByCustomerId(
      this.cutstomerId,
    );
    this.filteredTags = this.allTags;
    this.selected = this.selected
      .map((o) => this.allTags.find((t) => t.id === o.id))
      .filter((o) => o);
  }
  private async addTagtoConfiguration(tag: TagKeyDTO) {
    this.selected.push(tag);
  }
  private reloadSelectedItems() {
    this.selected = this.selected.map((o) => {
      return this.allTags.find((t) => t.id === o.id);
    });
  }
  public focusInput() {
    this.input.nativeElement.focus();
    const input = document.getElementById('select-input');
    if (input && !this.disabled) {
      this.listOpacity = true;
    }
  }
  public clearInput() {
    this._logger.debug('clearInput:', this.input);
    this.input.nativeElement.innerHTML = '';
  }
}
