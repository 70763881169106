import { Component, OnDestroy, OnInit } from '@angular/core';
import { CollectionInfoComponent } from './components/collection-info/collection-info.component';
import {
  PageContextService,
  RRLayoutModule,
} from 'projects/web-ui-component-library/src';
import { ReferenceSelectionComponent } from './components/reference-search/reference-selection.component';
import { CollectionMainComponent } from './components/collection-main/collection-main.component';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from '../app.state';
import { selectCollectionById } from '../collection-overview/store/collections-overview.selector';
import { Subject, takeUntil } from 'rxjs';
import { loadCollections } from '../collection-overview/store/collections-overview.actions';
import { CollectionScreenStore } from './collection-screen.state';
import { ICustomerService } from '../customer';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-collection-screen',
  standalone: true,
  templateUrl: './collection-screen.component.html',
  styleUrl: './collection-screen.component.scss',
  imports: [
    CollectionInfoComponent,
    ReferenceSelectionComponent,
    CollectionMainComponent,
    RRLayoutModule,
    DragDropModule,
  ],
})
export class CollectionScreenComponent implements OnInit, OnDestroy {
  private _collectionId = undefined;
  private _onDestroy$ = new Subject<void>();
  constructor(
    private _pageContextService: PageContextService,
    private collectionScreenStore: CollectionScreenStore,
    private _customerService: ICustomerService,
    private route: ActivatedRoute,
    private store: Store<IAppState>,
  ) {}
  ngOnInit(): void {
    this._onDestroy$ = new Subject();
    this._customerService.currentCustomer$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((customer) => {
        if (customer) {
          this.store.dispatch(
            loadCollections({
              pagination: {
                pageSize: 10,
                currentPage: 1,
                pageCount: 1,
                recordCount: 0,
                recordCountHidden: 1,
              },
              sort: { fieldName: 'test' },
            }),
          );
        }
      });

    this.route.params.pipe(takeUntil(this._onDestroy$)).subscribe((params) => {
      if (params['id']) {
        this._collectionId = params['id'];
        if (this._collectionId) {
          const collections$ = this.store.select(
            selectCollectionById(this._collectionId),
          );
          collections$
            .pipe(takeUntil(this._onDestroy$))
            .subscribe((collection) => {
              if (collection) {
                this.collectionScreenStore.setCollection(
                  this._collectionId,
                  collection.collection,
                );
              }
            });
        }
      }
    });

    this._pageContextService.clearPageActionBar();
  }

  ngOnDestroy(): void {
    if (this._onDestroy$) {
      this._onDestroy$.next();
      this._onDestroy$.complete();
      this._onDestroy$.unsubscribe();
      this._onDestroy$ = undefined;
    }
  }
}
