import { Provider } from '@angular/core';
import {
  IApiSdkService,
  ICentralAssetPlatformService,
  ICustomerService,
  IFileDownloadService,
  IFileService,
  IFileUploadService,
  IGarmentService,
  IProcessService,
  IProductService,
  IPublicRequestService,
  IRequestService,
  ISimpleFileService,
  ITagService,
  IUserService,
} from './api';

import { ApiSdkService } from './services/api-sdk.service';
import { CustomerService } from './services/customer.service';
import { FileDownloadService } from './services/file-download.service';
import { FileUploadService } from './services/file-upload.service';
import { FilesService } from './services/files.service';
import { GarmentService } from './services/garment.service';
import { ProcessService } from './services/process.service';
import { ProductService } from './services/product.service';
import { PublicRequestService } from './services/public-request.service';
import { RequestService } from './services/request.service';
import { TagService } from './services/tag.service';
import { UserService } from './services/user.service';
import { CentralAssetPlatformService } from './services/central-asset-platform.service';
import { SimpleFileService } from '../collection-screen/services/file.service';

export const DataProviders: Provider[] = [
  {
    provide: IApiSdkService,
    useExisting: ApiSdkService,
  },
  ApiSdkService,
  {
    provide: ICustomerService,
    useExisting: CustomerService,
  },
  CustomerService,
  {
    provide: IProductService,
    useExisting: ProductService,
  },
  ProductService,
  {
    provide: IProcessService,
    useExisting: ProcessService,
  },
  ProcessService,
  {
    provide: ITagService,
    useExisting: TagService,
  },
  TagService,
  {
    provide: IUserService,
    useExisting: UserService,
  },
  UserService,
  {
    provide: IRequestService,
    useExisting: RequestService,
  },
  RequestService,
  {
    provide: IFileUploadService,
    useExisting: FileUploadService,
  },
  FileUploadService,
  {
    provide: IPublicRequestService,
    useExisting: PublicRequestService,
  },
  PublicRequestService,
  {
    provide: IGarmentService,
    useExisting: GarmentService,
  },
  GarmentService,
  PublicRequestService,
  {
    provide: IFileDownloadService,
    useExisting: FileDownloadService,
  },
  FileDownloadService,
  {
    provide: IFileService,
    useExisting: FilesService,
  },
  FilesService,
  {
    provide: ICentralAssetPlatformService,
    useExisting: CentralAssetPlatformService,
  },
  CentralAssetPlatformService,
  {
    provide: ISimpleFileService,
    useExisting: SimpleFileService,
  },
  SimpleFileService,
];

export {
  IDiscoveryConfiguration,
  IApiSdkService,
  ICsApiSdkConfiguration,
  ICustomerService,
  ICustomer,
  IProduct,
  IProductType,
  IProductService,
  IServiceRejectionReason,
  IProcessService,
  IUser,
  IUserService,
  IRequest,
  IResquestStateCount,
  IRequestsAggregate,
  IRequestDetail,
  IRequestHistoryState,
  IRequestPrioritiy,
  IRequestState,
  IRequestResult,
  IRequestResultFile,
  IRequestService,
  IFileInformation,
  IFileUploadService,
  IPublicRequestService,
  IGarmentService,
  IFileDownloadService,
  IFileService,
} from './api';

//HELPERS
export { ProductHelper } from './helpers/product.helper';
export { RequestStateHelper } from './helpers/request-state.helper';

// ENUMS
export { PRODUCT_TYPE } from './enums/product-type.enum';
export { PRODUCT_STATE } from './enums/product-state.enum';
export { USER_FEATURE_FLAGS } from './enums/user-feature-flags.enum';
export { REQUEST_STATE_INTERNAL_ID } from './enums/request-state-internal.enum';
export { REQUEST_STATE_CMS } from './enums/request-state-cms.enum';
export { REQUEST_STATE_INTERNAL_STRING } from './enums/request-state-internal-string.enum';
