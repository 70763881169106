import { createSelector } from '@ngrx/store';
import { IAppState } from '../../app.state';
import { ICollectionsOverviewState } from './collections-overview.state';

export const selectCollectionOverview = (state: IAppState) =>
  state.collectionOverview;

export const selectCollectionsOverview = createSelector(
  selectCollectionOverview,
  (state: ICollectionsOverviewState) => ({
    collections: state.collections,
    recordCount: state.recordCount,
  }),
);

export const selectCollectionById = (id: string) =>
  createSelector(selectCollectionOverview, (state: ICollectionsOverviewState) =>
    state.collections.find((c) => c.id === id),
  );

export const selectCollectionsFilter = createSelector(
  selectCollectionOverview,
  (state: ICollectionsOverviewState) => state.filters,
);

export const selectSort = createSelector(
  selectCollectionOverview,
  (state: ICollectionsOverviewState) => state.sort,
);

export const selectCollectionsError = createSelector(
  selectCollectionOverview,
  (state: ICollectionsOverviewState) => state.error,
);
