import { Injectable } from '@angular/core';
import * as Oidc from 'oidc-client';
import { User, UserManager, UserManagerSettings } from 'oidc-client';
import { Observable, Subject, filter, map, take } from 'rxjs';
import { IDiscoveryConfiguration } from 'projects/content-service-cms/src/app/data';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { IAuthService } from '../api';
import * as _ from 'lodash';
import { AuthState } from './auth-state';
import { NgDiscoveryService } from '../../core/services/ng-discovery.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements IAuthService {
  public loginChanged$: Observable<boolean>;
  public loggedUser$: Observable<User>;
  private _logOutClicked$: Subject<void>;
  public logOutClicked$: Observable<void>;
  private state: AuthState;

  private _userManager: UserManager;
  private _authSettings: UserManagerSettings;

  constructor(
    private _discovery: NgDiscoveryService,
    private _logger: ILogger,
  ) {
    this._logger.info('Creating auth service ...');

    this.state = new AuthState();
    this.loggedUser$ = this.state.user$.pipe(
      map((u) => {
        if (u && !u.profile.name) {
          u.profile.name = 'New User';
        }

        return u;
      }),
    );
    this._logOutClicked$ = new Subject();
    this.logOutClicked$ = this._logOutClicked$.asObservable();
    this.loginChanged$ = this.state.user$.pipe(map((u) => this.isUserValid(u)));

    this.state.setDiscoveryConfiguration(this._discovery.config);
    this.initAuthentication(this._discovery.config);

    this.canApprove$ = this.state
      .select((s) => s.features?.canApprove)
      .pipe(filter((s) => s !== undefined));
    this.canReject$ = this.state
      .select((s) => s.features?.canReject)
      .pipe(filter((s) => s !== undefined));
    this.isCustomer$ = this.state
      .select((s) => s.features?.isCustomer)
      .pipe(filter((s) => s !== undefined));
    this.isEmployee$ = this.state
      .select((s) => s.features?.isEmployee)
      .pipe(filter((s) => s !== undefined));
    this.isCollectionCreator$ = this.state.select(
      (s) => s.features?.isCollectionCreator,
    );
  }

  public canApprove$: Observable<boolean>;
  public canReject$: Observable<boolean>;
  public isCustomer$: Observable<boolean>;
  public isEmployee$: Observable<boolean>;
  public isCollectionCreator$: Observable<boolean>;

  public getToken(): string | undefined {
    return this.state.access_token();
  }

  public getToken$(): Observable<string> {
    return this.state.access_token$;
  }
  public logOutClicked() {
    this._logOutClicked$.next();
  }
  public async isAuthenticated(): Promise<boolean> {
    if (!this._userManager) {
      this._logger.error('The user is not authenticated.');
    }
    return this.fetchUser().then((user) => {
      if (!_.isEqual(this.state.user, user)) {
        this.setCurrentUser(user);
      }
      return this.isUserValid(user);
    });
  }

  public async login(): Promise<void> {
    if (!this._userManager) {
      this._logger.error('The user is not authenticated.');
    }
    this._userManager.signinRedirect();
  }

  public async signinRedirectCallback(): Promise<void> {
    if (!this._userManager) {
      this._logger.error('The user is not authenticated.');
    }

    return this._userManager
      .signinRedirectCallback()
      .then((user) => {
        this._logger.info('signinRedirectCallback done');
        window.history.replaceState(
          {},
          window.document.title,
          window.location.origin + window.location.pathname,
        );
        this.setCurrentUser(user);
        return user;
      })
      .catch((err) => {
        this._logger.error('ERROR Signin Redirect Callback: ', err);
        return null;
      });
  }

  public async logout(): Promise<void> {
    if (!this._userManager) {
      this._logger.error(
        'No discovery document. Need to retrieve discovery document.',
      );
    }

    this.state.user$.pipe(take(1)).subscribe((user) => {
      this._userManager.signoutRedirect();

      window.location.href = new URL(
        `/oidc/logout?id_token_hint=${user.id_token}&post_logout_redirect_uri=${this._discovery.config.apiConfiguration.signOutCallback}`,
        this._discovery.config.apiConfiguration.baseAuthServiceUrl,
      ).toString();
    });
  }

  public async finishLogout(): Promise<void> {
    if (!this._userManager) {
      this._logger.error(
        'No discovery document. Need to retrieve discovery document.',
      );
    }

    this.setCurrentUser(undefined);
    await this._userManager.signoutRedirectCallback();
  }

  public fetchUser(): Promise<User> {
    return this._userManager.getUser();
  }

  private async signinSilent(): Promise<void> {
    if (!this._userManager) {
      this._logger.error(
        'No discovery document. Need to retrieve discovery document.',
      );
    }
    return await this._userManager
      .signinSilent()
      .then((user: User) => {
        this.setCurrentUser(user);
      })
      .catch((error) => {
        this._logger.error('Silent signin error: ', error);
      });
  }

  private isUserValid(user: User): boolean {
    return !!user && !user.expired;
  }

  private setCurrentUser(user: User | undefined | null): void {
    if (user) {
      this.state.setUser(user);
    } else {
      this.state.setUser(undefined);
    }
  }

  private async initAuthentication(
    cfg: IDiscoveryConfiguration,
  ): Promise<void> {
    this._authSettings = {
      authority: cfg.apiConfiguration.baseAuthServiceUrl,
      client_id: cfg.appConfiguration.clientId,
      redirect_uri: cfg.apiConfiguration.signInCallback,
      scope: [cfg.apiConfiguration.authRequestScopes, 'email'].join(' '),
      response_type: cfg.appConfiguration.responseType,
      post_logout_redirect_uri: cfg.apiConfiguration.signOutCallback,
      loadUserInfo: true,
      userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
      monitorSession: false,
      extraQueryParams: {
        audience: cfg.apiConfiguration.authRequestAudience,
      },
    };

    this._userManager = new UserManager(this._authSettings);

    this.setCurrentUser(await this._userManager.getUser());

    this._userManager.events.addAccessTokenExpiring(() => {
      this._logger.warn('Access Token is about to expire');
      this.signinSilent();
    });

    this._userManager.events.addAccessTokenExpired(() => {
      this._logger.warn('Access Token has expired');
      this.signinSilent().catch(() => {
        this.logout();
      });
    });
  }
}
