<article>
  <div class="m-3 main-modal">
    <div class="create-request-options-container">
      <div
        class="create-request-option"
        *ngFor="let requestOption of requests"
        (click)="
          goToCreateRequestPage(
            requestOption.type,
            requestOption.additionalInfo
          )
        "
      >
        <img [src]="requestOption.imageIconUrl" />
        <div class="request-type-text">{{ requestOption.title }}</div>
        <div class="request-type-description">
          {{ requestOption.description }}
        </div>
        <button
          class="btn btn-outline-primary mt-3"
          id="btn-create-{{ requestOption.type }}"
        >
          {{ 'Create ' + requestOption.title }}
        </button>
      </div>
    </div>
  </div>
</article>
