import { REQUEST_STATE_CMS } from '../enums/request-state-cms.enum';
import { REQUEST_STATE_INTERNAL_STRING } from '../enums/request-state-internal-string.enum';
import { REQUEST_STATE_INTERNAL_ID } from '../enums/request-state-internal.enum';

export class RequestStateHelper {
  static getInternalStateLabel(stateId: REQUEST_STATE_INTERNAL_ID): string {
    switch (stateId) {
      case REQUEST_STATE_INTERNAL_ID.READY_FOR_PROCESSING:
        return 'Ready';
      case REQUEST_STATE_INTERNAL_ID.FETCHED:
        return 'Fetched';
      case REQUEST_STATE_INTERNAL_ID.PROCESSED:
        return 'Processed';
      case REQUEST_STATE_INTERNAL_ID.REPORTED:
        return 'Reported';
      case REQUEST_STATE_INTERNAL_ID.APPROVED:
        return 'Approved';
      case REQUEST_STATE_INTERNAL_ID.APPROVED_BY_CUSTOMER:
        return 'Approved (Customer)';
      case REQUEST_STATE_INTERNAL_ID.FETCHED_FOR_REVIEW:
        return 'In Review';
      case REQUEST_STATE_INTERNAL_ID.REJECTED_BY_CUSTOMER:
        return 'Rejected (Customer)';
      case REQUEST_STATE_INTERNAL_ID.REJECTED_BY_SERVICE:
        return 'Rejected (Service)';
      case REQUEST_STATE_INTERNAL_ID.LOCKED:
        return 'Locked';
      case REQUEST_STATE_INTERNAL_ID.NOT_READY_FOR_PROCESSING:
        return 'Not Ready';
      case REQUEST_STATE_INTERNAL_ID.IN_PROGRESS:
        return 'In progress';
      case REQUEST_STATE_INTERNAL_ID.REJECTED_BY_REVIEWER:
        return 'Rejected (Revise)';
      case REQUEST_STATE_INTERNAL_ID.CANCELED_BY_CUSTOMER:
        return 'Canceled (Customer)';
      case REQUEST_STATE_INTERNAL_ID.RESET_EVENT:
        return 'Reseted';
      case REQUEST_STATE_INTERNAL_ID.EXPORTING:
        return 'Exporting';
      default:
        return 'Not Ready';
    }
  }

  static getInternalStateColorClass(
    stateId: REQUEST_STATE_INTERNAL_ID,
  ): string {
    switch (stateId) {
      case REQUEST_STATE_INTERNAL_ID.READY_FOR_PROCESSING:
        return 'bg-state-yellow';
      case REQUEST_STATE_INTERNAL_ID.FETCHED:
        return 'bg-state-deepskyblue';
      case REQUEST_STATE_INTERNAL_ID.PROCESSED:
        return 'bg-state-blue';
      case REQUEST_STATE_INTERNAL_ID.REPORTED:
        return 'bg-state-orange';
      case REQUEST_STATE_INTERNAL_ID.APPROVED:
        return 'bg-state-green';
      case REQUEST_STATE_INTERNAL_ID.FETCHED_FOR_REVIEW:
        return 'bg-state-navy';
      case REQUEST_STATE_INTERNAL_ID.REJECTED_BY_CUSTOMER:
        return 'bg-state-red';
      case REQUEST_STATE_INTERNAL_ID.REJECTED_BY_SERVICE:
        return 'bg-state-indianred';
      case REQUEST_STATE_INTERNAL_ID.LOCKED:
        return 'bg-state-purple';
      case REQUEST_STATE_INTERNAL_ID.NOT_READY_FOR_PROCESSING:
        return 'bg-state-gray';
      case REQUEST_STATE_INTERNAL_ID.IN_PROGRESS:
        return 'bg-state-lightblue';
      case REQUEST_STATE_INTERNAL_ID.REJECTED_BY_REVIEWER:
        return 'bg-state-darkorange';
      case REQUEST_STATE_INTERNAL_ID.APPROVED_BY_CUSTOMER:
        return 'bg-state-darkgreen';
      case REQUEST_STATE_INTERNAL_ID.CANCELED_BY_CUSTOMER:
        return 'bg-state-darkgray';
      case REQUEST_STATE_INTERNAL_ID.RESET_EVENT:
        return 'bg-state-gray';
      case REQUEST_STATE_INTERNAL_ID.EXPORTING:
        return 'bg-state-navy';
      default:
        return 'bg-dark';
    }
  }

  static mapInternalStateStringToEnum(
    stateString: REQUEST_STATE_INTERNAL_STRING,
  ): REQUEST_STATE_INTERNAL_ID {
    switch (stateString.toUpperCase()) {
      case REQUEST_STATE_INTERNAL_STRING.READY_FOR_PROCESSING:
        return REQUEST_STATE_INTERNAL_ID.READY_FOR_PROCESSING;
      case REQUEST_STATE_INTERNAL_STRING.FETCHED:
        return REQUEST_STATE_INTERNAL_ID.FETCHED;
      case REQUEST_STATE_INTERNAL_STRING.PROCESSED:
        return REQUEST_STATE_INTERNAL_ID.PROCESSED;
      case REQUEST_STATE_INTERNAL_STRING.REPORTED:
        return REQUEST_STATE_INTERNAL_ID.REPORTED;
      case REQUEST_STATE_INTERNAL_STRING.APPROVED:
        return REQUEST_STATE_INTERNAL_ID.APPROVED;
      case REQUEST_STATE_INTERNAL_STRING.APPROVED_BY_CUSTOMER:
        return REQUEST_STATE_INTERNAL_ID.APPROVED_BY_CUSTOMER;
      case REQUEST_STATE_INTERNAL_STRING.FETCHED_FOR_REVIEW:
        return REQUEST_STATE_INTERNAL_ID.FETCHED_FOR_REVIEW;
      case REQUEST_STATE_INTERNAL_STRING.REJECTED_BY_CUSTOMER:
        return REQUEST_STATE_INTERNAL_ID.REJECTED_BY_CUSTOMER;
      case REQUEST_STATE_INTERNAL_STRING.REJECTED_BY_SERVICE:
        return REQUEST_STATE_INTERNAL_ID.REJECTED_BY_SERVICE;
      case REQUEST_STATE_INTERNAL_STRING.LOCKED:
        return REQUEST_STATE_INTERNAL_ID.LOCKED;
      case REQUEST_STATE_INTERNAL_STRING.NOT_READY_FOR_PROCESSING:
        return REQUEST_STATE_INTERNAL_ID.NOT_READY_FOR_PROCESSING;
      case REQUEST_STATE_INTERNAL_STRING.IN_PROGRESS:
        return REQUEST_STATE_INTERNAL_ID.IN_PROGRESS;
      case REQUEST_STATE_INTERNAL_STRING.REJECTED_BY_REVIEWER:
        return REQUEST_STATE_INTERNAL_ID.REJECTED_BY_REVIEWER;
      case REQUEST_STATE_INTERNAL_STRING.CANCELED_BY_CUSTOMER:
        return REQUEST_STATE_INTERNAL_ID.CANCELED_BY_CUSTOMER;
      case REQUEST_STATE_INTERNAL_STRING.RESET_EVENT:
        return REQUEST_STATE_INTERNAL_ID.RESET_EVENT;
      case REQUEST_STATE_INTERNAL_STRING.EXPORTING:
        return REQUEST_STATE_INTERNAL_ID.EXPORTING;
      default:
        return null;
    }
  }

  static mapInternatStateToCustomerState(
    stateId: REQUEST_STATE_INTERNAL_ID,
  ): REQUEST_STATE_CMS {
    if (typeof stateId === 'string') {
      stateId = Number.parseInt(stateId);
    }

    switch (stateId) {
      case REQUEST_STATE_INTERNAL_ID.NOT_READY_FOR_PROCESSING:
        return REQUEST_STATE_CMS.CREATED;
      case REQUEST_STATE_INTERNAL_ID.READY_FOR_PROCESSING:
      case REQUEST_STATE_INTERNAL_ID.FETCHED:
      case REQUEST_STATE_INTERNAL_ID.PROCESSED:
      case REQUEST_STATE_INTERNAL_ID.REPORTED:
      case REQUEST_STATE_INTERNAL_ID.FETCHED_FOR_REVIEW:
      case REQUEST_STATE_INTERNAL_ID.REJECTED_BY_CUSTOMER:
      case REQUEST_STATE_INTERNAL_ID.LOCKED:
      case REQUEST_STATE_INTERNAL_ID.IN_PROGRESS:
      case REQUEST_STATE_INTERNAL_ID.REJECTED_BY_REVIEWER:
      case REQUEST_STATE_INTERNAL_ID.RESET_EVENT:
      case REQUEST_STATE_INTERNAL_ID.EXPORTING:
        return REQUEST_STATE_CMS.IN_PROGRESS;
      case REQUEST_STATE_INTERNAL_ID.REJECTED_BY_SERVICE:
        return REQUEST_STATE_CMS.REJECTED;
      case REQUEST_STATE_INTERNAL_ID.CANCELED_BY_CUSTOMER:
        return REQUEST_STATE_CMS.CANCELED;
      case REQUEST_STATE_INTERNAL_ID.APPROVED:
        return REQUEST_STATE_CMS.FINISHED;
      case REQUEST_STATE_INTERNAL_ID.APPROVED_BY_CUSTOMER:
        return REQUEST_STATE_CMS.APPROVED;
      default:
        return REQUEST_STATE_CMS.IN_PROGRESS;
    }
  }

  static mapCustomerStateToInternalState(
    stateId: REQUEST_STATE_CMS,
  ): REQUEST_STATE_INTERNAL_ID | REQUEST_STATE_INTERNAL_ID[] {
    switch (stateId) {
      case REQUEST_STATE_CMS.CREATED:
        return REQUEST_STATE_INTERNAL_ID.NOT_READY_FOR_PROCESSING;
      case REQUEST_STATE_CMS.IN_PROGRESS:
        return [
          REQUEST_STATE_INTERNAL_ID.READY_FOR_PROCESSING,
          REQUEST_STATE_INTERNAL_ID.FETCHED,
          REQUEST_STATE_INTERNAL_ID.PROCESSED,
          REQUEST_STATE_INTERNAL_ID.REPORTED,
          REQUEST_STATE_INTERNAL_ID.FETCHED_FOR_REVIEW,
          REQUEST_STATE_INTERNAL_ID.REJECTED_BY_CUSTOMER,
          REQUEST_STATE_INTERNAL_ID.LOCKED,
          REQUEST_STATE_INTERNAL_ID.IN_PROGRESS,
          REQUEST_STATE_INTERNAL_ID.REJECTED_BY_REVIEWER,
          REQUEST_STATE_INTERNAL_ID.RESET_EVENT,
          REQUEST_STATE_INTERNAL_ID.EXPORTING,
        ];
      case REQUEST_STATE_CMS.REJECTED:
        return REQUEST_STATE_INTERNAL_ID.REJECTED_BY_SERVICE;
      case REQUEST_STATE_CMS.CANCELED:
        return REQUEST_STATE_INTERNAL_ID.CANCELED_BY_CUSTOMER;
      case REQUEST_STATE_CMS.FINISHED:
        return REQUEST_STATE_INTERNAL_ID.APPROVED;
      case REQUEST_STATE_CMS.APPROVED:
        return REQUEST_STATE_INTERNAL_ID.APPROVED_BY_CUSTOMER;
      default:
        return null;
    }
  }

  static getCustomerStateLabel(stateId: REQUEST_STATE_CMS): string {
    switch (stateId) {
      case REQUEST_STATE_CMS.CREATED:
        return 'Created';
      case REQUEST_STATE_CMS.IN_PROGRESS:
        return 'In Progress';
      case REQUEST_STATE_CMS.FINISHED:
        return 'Finished';
      case REQUEST_STATE_CMS.APPROVED:
        return 'Approved';
      case REQUEST_STATE_CMS.REJECTED:
        return 'Rejected';
      case REQUEST_STATE_CMS.CANCELED:
        return 'Canceled';
      default:
        return '';
    }
  }

  static getCustomerStateColorClass(stateId: REQUEST_STATE_CMS): string {
    switch (stateId) {
      case REQUEST_STATE_CMS.CREATED:
        return 'bg-state-created';
      case REQUEST_STATE_CMS.IN_PROGRESS:
        return 'bg-state-in-progress';
      case REQUEST_STATE_CMS.FINISHED:
        return 'bg-state-finished';
      case REQUEST_STATE_CMS.APPROVED:
        return 'bg-state-approved';
      case REQUEST_STATE_CMS.REJECTED:
        return 'bg-state-rejected';
      case REQUEST_STATE_CMS.CANCELED:
        return 'bg-state-canceled';
      default:
        return 'bg-dark';
    }
  }

  static getCustomerStateColor(stateId: REQUEST_STATE_CMS): string {
    switch (stateId) {
      case REQUEST_STATE_CMS.CREATED:
        return 'state-created';
      case REQUEST_STATE_CMS.IN_PROGRESS:
        return 'state-in-progress';
      case REQUEST_STATE_CMS.FINISHED:
        return 'state-finished';
      case REQUEST_STATE_CMS.APPROVED:
        return 'state-approved';
      case REQUEST_STATE_CMS.REJECTED:
        return 'state-rejected';
      case REQUEST_STATE_CMS.CANCELED:
        return 'state-canceled';
      default:
        return 'state-primary';
    }
  }
}
