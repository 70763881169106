<span>
  <span class="oi oi-magnifying-glass"></span>

  <input
    #inputText
    type="text"
    placeholder="Find {{ label }}..."
    (keyup)="detectChanges($event)"
    (change)="detectChanges($event)"
    [value]="this.filterValue"
  />
</span>
