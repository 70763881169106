<div class="configuration-entry d-flex flex-row align-items-center">
  <div class="configuration-image">
    <div class="thumbnail-hover">
      <img
        class="thumbnail ml-3"
        src="{{
          (!garment.archived ? garment?.previewUrl : undefined)
            | garmentThumbnailAvailable
        }}"
      />
      <span>
        <img
          class="ml-3"
          src="{{
            (!garment.archived ? garment?.previewUrl : undefined)
              | garmentThumbnailAvailable
          }}"
        />
      </span>
    </div>
  </div>
  <div class="d-flex flex-column text-area">
    <span class="">{{ garment?.name }}</span>
    <span class="type">{{ garment?.productTypeId | productType }}</span>
  </div>
</div>
