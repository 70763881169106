import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { RRIPagination } from 'projects/web-ui-component-library/src';
import { FilterOutput } from 'projects/web-ui-component-library/src/lib/filter-elements/interfaces/filter.interface';

export interface FilterConfiguration {
  name: string;
  selected: string | string[];
}

export interface SelectionModalState {
  pagination: RRIPagination;
  searchPattern: string;
  filters?: { [key: string]: string | [] };
  visibleFilter: string;
}

const defaultState: SelectionModalState = {
  pagination: {
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    recordCount: 1,
  },
  searchPattern: '',
  filters: {},
  visibleFilter: '',
};

@Injectable()
export class SelectionModalStore extends ComponentStore<SelectionModalState> {
  constructor() {
    super(defaultState);
  }

  readonly currentPage$ = this.select(
    ({ pagination }) => pagination.currentPage,
  );

  readonly searchPattern$ = this.select(
    ({ searchPattern: searchPattern }) => searchPattern,
  );

  readonly filters$ = this.select(({ filters: filters }) => filters);

  readonly countFilters$ = this.select(({ filters: f }) => {
    return Object.keys(f).length;
  });

  readonly visibleFilter$ = this.select(
    ({ visibleFilter: visibleFilter }) => visibleFilter,
  );

  readonly updateFilters = this.updater((state, filter: FilterOutput) => {
    const f = JSON.parse(JSON.stringify(state.filters));
    if (
      filter.value === undefined ||
      filter.value === '' ||
      filter.value === '[]'
    ) {
      delete f[filter.id];
    } else {
      f[filter.id] = filter.value;
    }
    return {
      ...state,
      filters: f,
    };
  });

  readonly clearFilters = this.updater((state) => {
    return { ...state, filters: {} };
  });

  readonly clearFilter = this.updater((state, id: string) => {
    const f = JSON.parse(JSON.stringify(state.filters));
    delete f[id];
    return {
      ...state,
      filters: f,
    };
  });

  readonly updateCurrentPage = this.updater(
    (state, currentPage: number | null) => ({
      ...state,
      pagination: { ...state.pagination, currentPage: currentPage },
    }),
  );

  readonly updatePageSize = this.updater((state, pageSize: number | null) => ({
    ...state,
    pagination: { ...state.pagination, pageSize: pageSize },
  }));

  readonly updatePageCount = this.updater(
    (state, pageCount: number | null) => ({
      ...state,
      pagination: { ...state.pagination, pageCount: pageCount },
    }),
  );

  readonly updateSearchPattern = this.updater(
    (state, searchPattern: string) => ({
      ...state,
      pagination: { ...state.pagination, currentPage: 1 },
      searchPattern: searchPattern,
    }),
  );

  readonly updateVisibleFilter = this.updater(
    (state, visibleFilterId: string) => ({
      ...state,
      visibleFilter: visibleFilterId,
    }),
  );
}
