<div class="d-flex flex-row mb-4 assetCard pos-relative">
  <app-row-buttons
    [reference]="reference"
    (moveRowDown)="this.moveReferenceDown.emit(this.reference)"
    (moveRowUp)="this.moveReferenceUp.emit(this.reference)"
    (removeRow)="this.removeReference.emit(this.reference)"
  ></app-row-buttons>
  <div class="collectionThumbnail">
    <img src="{{ reference.thumbnail }}" class="collectionThumbnail" />
  </div>
  <div class="d-flex flex-column ml-3">
    <app-input-text
      id="ref-name-txt"
      placeholder="Name"
      class="inputField"
      [value]="reference.name"
      (valueChange)="onNameChanged($event)"
    ></app-input-text>
    <app-input-text
      id="garment-link"
      placeholder="Shop Link"
      class="inputField"
      [value]="reference.garmentLink"
      (valueChange)="onGarmentLinkChanged($event)"
    >
    </app-input-text>
    <div class="d-flex flex-row">
      <app-input-text
        id="garment-price"
        placeholder="Price"
        class="col-3"
        [value]="reference.price?.price?.toString()"
        (valueChange)="onPriceChanged($event)"
      ></app-input-text>
      <app-input-text
        id="garment-currency"
        placeholder="Currency"
        class="ml-1 col-3"
        [value]="reference.price?.currency"
        (valueChange)="onCurrencyChanged($event)"
      ></app-input-text>
    </div>
  </div>
  <span cdkDragHandle class="rr-drag dragZone"></span>
</div>
