import { NgModule } from '@angular/core';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { FormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { RRSharedModule } from '../shared-module/shared.module';

@NgModule({
  declarations: [],
  imports: [
    RRSharedModule,
    FormsModule,
    NgxSliderModule,
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    TimepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
  ],
  exports: [
    FormsModule,
    NgxSliderModule,
    TabsModule,
    BsDropdownModule,
    AccordionModule,
    CollapseModule,
    TooltipModule,
    PaginationModule,
    AlertModule,
    ModalModule,
    BsDatepickerModule,
    TypeaheadModule,
    TimepickerModule,
    ProgressbarModule,
  ],
})
export class RRBootstrapModule {}
