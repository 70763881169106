import { Component, Input } from '@angular/core';
import { ISpinnerModel } from '../../interfaces/spinner.model';

@Component({
  selector: 'app-overlay-spinner',
  templateUrl: './overlay-spinner.component.html',
  styleUrls: ['./overlay-spinner.component.scss'],
})
export class OverlaySpinnerComponent {
  @Input() spinnerStatus: ISpinnerModel;

  constructor() {}
}
