import { Observable } from 'rxjs';
import { GarmentDTO } from '../../../data/api';

export type DressableGarment = GarmentDTO & {
  stylingOptions?: any;
};

export abstract class IGarmentSelectionService {
  public abstract tuckedInGarment$: Observable<DressableGarment>;
  public abstract selectedGarments$: Observable<DressableGarment[]>;
  public abstract dressed$: Observable<DressableGarment[]>;
  public abstract currentSelectedGarments: () => DressableGarment[];
  public abstract currentDressed: () => DressableGarment[];
  public abstract setSelectedGarments: (garments: DressableGarment[]) => void;
  public abstract setDressedGarments: (garments: DressableGarment[]) => void;
  public abstract setTuckedInGarment: (garment: DressableGarment) => void;
  public abstract setGarmentStylingOptions: (
    garment: GarmentDTO,
    stylingOptions: any,
  ) => void;
}
