import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-base-list-row',
  standalone: true,
  imports: [],
  template: '',
})
export class BaseListRowComponent<T> {
  @Input() reference: T;
  @Input() extended: boolean = false;
  @Output() updateReference: EventEmitter<T> = new EventEmitter();
  @Output() removeReference: EventEmitter<T> = new EventEmitter();
  @Output() moveReferenceUp: EventEmitter<T> = new EventEmitter();
  @Output() moveReferenceDown: EventEmitter<T> = new EventEmitter();

  constructor() {}
  onNameChanged($event: { value: string; valid: boolean }) {
    if ($event.valid) {
      this.updateReference.emit({ ...this.reference, name: $event.value });
    }
  }
}
