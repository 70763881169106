import { AssetType, IAsset } from '@reactivereality/pictofit-web-sdk';
import { HttpClient } from '@angular/common/http';
import { AssetFiles } from '../api';
import { ILogger } from '../../../logging';

export class Avatar3DAsset implements IAsset {
  constructor(
    protected avatar: AssetFiles,
    protected httpClient: HttpClient,
    protected logger: ILogger,
  ) {}
  preloadComponents(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  preloadComponent(key: string): Promise<void> {
    throw new Error('Method not implemented.');
  }

  private findFile(key: string) {
    this.logger.debug(`[Avatar3DAsset] Loading key: ${key}`);
    return this.avatar.find((file) => file.filename === key);
  }

  protected getLocation = async (key: string): Promise<string | null> => {
    const fileInQuestion = this.findFile(key);

    if (!fileInQuestion) {
      return null;
    }

    return fileInQuestion.url;
  };

  public getComponent = async (key: string): Promise<Blob> => {
    const location = await this.getLocation(key);

    if (location == null) {
      throw new Error(`Key '${key}' is not available in Avatar3DAsset`);
    }

    const response = await this.httpClient
      .get(location, { observe: 'response', responseType: 'blob' })
      .toPromise();

    if (response.status !== 200) {
      const responseText =
        response.body !== null
          ? await response.body.text()
          : 'Unkown response text';
      throw new Error(
        `Failed to fetch key '${key}' for Avatar3DAsset ${responseText}`,
      );
    }

    if (response.body === null) {
      throw new Error(`Key '${key}' for product resulted in null blob content`);
    }

    return response.body;
  };

  /**
   * Get the location of a component of this asset associated with the specified key.
   * Will return null incase an location could not be resolved. This could sometimes be the case when a component is only available locally but has no public location.
   * @param key
   * @returns The location of the component or null. Does not mean that the component behind the url is available.
   */
  public getComponentLocation = async (key: string): Promise<string | null> => {
    const location = await this.getLocation(key);

    if (
      location !== null &&
      !location.startsWith('http') &&
      key !== 'material.json'
    ) {
      return null;
    }
    return location;
  };

  /**
   * Get all keys of this asset.
   */
  public getKeys = async (): Promise<string[]> => {
    return this.avatar.map((f) => f.filename);
  };

  /**
   * The type of an asset.
   */
  public getType = async (): Promise<AssetType> => {
    return AssetType.AVATAR_3D;
  };
}

export class Generated3DAvatar implements IAsset {
  constructor(protected avatar: IAsset, protected httpClient: HttpClient) {}
  preloadComponents(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  preloadComponent(key: string): Promise<void> {
    throw new Error('Method not implemented.');
  }

  public getComponent = async (key: string): Promise<Blob> => {
    return this.avatar.getComponent(key);
  };

  /**
   * Get the location of a component of this asset associated with the specified key.
   * Will return null incase an location could not be resolved. This could sometimes be the case when a component is only available locally but has no public location.
   * @param key
   * @returns The location of the component or null. Does not mean that the component behind the url is available.
   */
  public getComponentLocation = async (key: string): Promise<string | null> => {
    return this.avatar.getComponentLocation(key);
  };

  /**
   * Get all keys of this asset.
   */
  public getKeys = async (): Promise<string[]> => {
    return [...(await this.avatar.getKeys()), 'material.json'];
  };

  /**
   * The type of an asset.
   */
  public getType = async (): Promise<AssetType> => {
    return AssetType.AVATAR_3D;
  };
}
