<article id="garment-selection-modal">
  <div class="d-flex mt-3 ml-3 mb-1 justify-content-between align-self-center">
    <h2 class="mb-auto mt-auto mr-5">Garments</h2>

    <app-filter-selection
      class="mb-auto mt-auto ml-auto"
      [filters]="filterConfiguration"
    ></app-filter-selection>

    <span class="d-flex align-items-center">
      <span
        class="magGlass oi oi-magnifying-glass"
        (click)="input.focus()"
      ></span>
      <input
        #input
        type="text"
        placeholder="Search"
        (input)="searchPatternChanged($event)"
        [value]="searchPattern ? searchPattern : ''"
      />
      <span
        [hidden]="searchPattern === ''"
        class="clear rr-selection-search-delete"
        (click)="clearSearchInput()"
      ></span>
    </span>
  </div>
  <ngx-skeleton-loader
    *ngIf="spinnerStatus.show"
    class="loader"
    count="10"
    appearance="line"
    [theme]="{
      'border-radius': '8px',
      'background-color': '#f5f0eb'
    }"
  ></ngx-skeleton-loader>
  <div class="modal-body mb-3 garment-modal" *ngIf="!spinnerStatus.show">
    <ng-container *ngFor="let garment of allGarments">
      <div
        id="garment-selection-modal-item-{{ garment.id }}"
        class="garment-entry d-flex flex-row align-items-center pt-2 pb-2"
        (click)="cb_selected.click()"
        [ngClass]="!garment.tryonable ? 'notTryonable' : ''"
      >
        <span class="tttext" *ngIf="!garment.archived"
          >This garment cannot be dressed.</span
        >
        <span class="tttext" *ngIf="garment.archived"
          >This garment has been archived.</span
        >
        <input
          #cb_selected
          class="ml-3"
          type="checkbox"
          name="cb"
          (click)="onCheckboxChanged($event, garment)"
          [checked]="garment.selected"
          [disabled]="!garment.tryonable"
        />
        <app-configuration-display
          [garment]="garment"
        ></app-configuration-display>
      </div>
    </ng-container>
  </div>
  <rr-request-list-pagination></rr-request-list-pagination>
  <div class="button-container">
    <div class="row mt-auto d-flex flex-row justify-content-end mb-3 mr-3">
      <button
        id="garment-selection-modal-btn-cancel"
        (click)="closeModal()"
        type="button"
        class="btn btn-primary mr-3"
      >
        Cancel
      </button>
      <button
        id="garment-selection-modal-btn-select"
        (click)="onSelectedGarments()"
        type="button"
        class="btn btn-primary"
      >
        Select
      </button>
    </div>
  </div>
</article>
