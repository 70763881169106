import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ConfigurationDTO, CustomerDTO } from '@reactivereality/cs-api-sdk';
import { Subject, takeUntil } from 'rxjs';
import { PRODUCT_TYPE } from '../../data';
import { IRequestService, SceneDTO } from '../../data/api';
import { ModalService } from '../../modal/modal.service';
import { CreateRequestModalStore } from '../../request-overview/components/create-request-modal/create-request-modal.state';
import {
  GarmentThumbnailPipe,
  ProductTypePipe,
  SceneThumbnailPipe,
} from '../../utils/CommonPipes';
import { AddItemComponent } from '../add-item/add-item.component';
import { AddSceneModalComponent } from './add-scene-modal/add-scene-modal.component';
import { SceneSelectionService } from './scene-selection.service';
import { NgStorageManagerService } from '../../core/services/ng-storage-manager.service';
import { ICustomerService } from '../../customer';

const LOCAL_STORAGE_KEY = 'sceneSelection';
const LAST_USED_SCENE = 'lastUsedScene';

@Component({
  selector: 'app-scene-selection-entry',
  templateUrl: './scene-selection-entry.component.html',
  styleUrls: ['./scene-selection-entry.component.scss'],
  imports: [
    CommonModule,
    AddItemComponent,
    GarmentThumbnailPipe,
    SceneThumbnailPipe,
    ProductTypePipe,
    AddSceneModalComponent,
  ],
  standalone: true,
})
export class SceneSelectionEntryComponent implements OnDestroy, OnInit {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  public sceneType: PRODUCT_TYPE.SCENE;
  @Input() public selectedScene: SceneDTO;
  @Output() public changeClicked = new EventEmitter<ConfigurationDTO>();
  @ViewChild('selectScene') selectScene: TemplateRef<any>;

  private _currentCustomer: CustomerDTO;

  constructor(
    public createRequestModalStore: CreateRequestModalStore,
    public modalService: ModalService,
    public sceneSelectionService: SceneSelectionService,
    private _localStorageService: NgStorageManagerService,
    private _customerService: ICustomerService,
    private _requestService: IRequestService,
  ) {
    this.sceneSelectionService.onSelect
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.modalService.show({
          title: 'Select Scene',
          template: this.selectScene,
        });
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async ngOnInit(): Promise<void> {
    this._customerService.currentCustomer$.subscribe((customer) => {
      this._currentCustomer = customer;
    });
    if (!this.selectedScene) {
      const lastUsedSceneId = this._localStorageService.getItem(
        [
          LOCAL_STORAGE_KEY,
          LAST_USED_SCENE,
          PRODUCT_TYPE.SCENE,
          this._currentCustomer.id,
        ].join('.'),
        'local',
      );
      if (lastUsedSceneId === undefined) {
        return;
      }
      const scene = await this._requestService.getRequestByID(lastUsedSceneId);
      this.selectedScene = scene as SceneDTO;
      this.createRequestModalStore.setScene(this.selectedScene);
    }
  }

  public onSceneSelected($event: SceneDTO) {
    this._localStorageService.setItem(
      [
        LOCAL_STORAGE_KEY,
        LAST_USED_SCENE,
        PRODUCT_TYPE.SCENE,
        this._currentCustomer.id,
      ].join('.'),
      $event?.id,
      'local',
    );
    this.createRequestModalStore.setScene($event);
  }
}
