import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectionModalStore } from '../components/create-request-modal/store/selection-modal-store';
import { IPaginationDataProviderInterface } from './pagination-data-provider.interface';

@Injectable({
  providedIn: 'root',
})
export class ComponentPaginationDataProvider
  implements IPaginationDataProviderInterface
{
  public readonly currentPage$: Observable<number>;
  public readonly pageCount$: Observable<number>;
  public readonly totalRecords$: Observable<number>;
  public readonly pageSize$: Observable<number>;

  constructor(private store: SelectionModalStore) {
    this.currentPage$ = this.store.select(
      (state) => state.pagination.currentPage,
    );
    this.pageCount$ = this.store.select((state) => {
      return state.pagination.pageCount;
    });

    this.totalRecords$ = this.store.select((state) => {
      return state.pagination.recordCount;
    });
    this.pageSize$ = this.store.select((state) => {
      return state.pagination.pageSize;
    });
  }
  public setCurrentPage(page: number) {
    this.store.updateCurrentPage(page);
  }
  public setPageSize(page: number) {
    this.store.updatePageSize(page);
  }
}
