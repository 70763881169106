import { Injectable } from '@angular/core';
import {
  DressableGarment,
  IGarmentSelectionService,
} from 'projects/content-service-cms/src/app/components/garment-selection/services/i-garment-selection.service';
import { GarmentDTO } from 'projects/content-service-cms/src/app/data/api';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { Observable } from 'rxjs';
import { CreateRequestModalStore } from '../create-request-modal.state';

@Injectable()
export class CreateRequestGarmentSelectionService extends IGarmentSelectionService {
  constructor(private store: CreateRequestModalStore, private logger: ILogger) {
    super();
    this.logger.debug(
      `Initializing garment selection service for component store`,
    );
  }
  public tuckedInGarment$: Observable<DressableGarment> =
    this.store.tuckedInGarment$;

  public selectedGarments$: Observable<GarmentDTO[]> =
    this.store.selectedGarments$;

  public dressed$: Observable<GarmentDTO[]> = this.store.garments$;

  public currentSelectedGarments = (): GarmentDTO[] => {
    this.logger.debug(`Getting current garments from store`);
    return this.store.current().dressingRoom.selectedGarments;
  };

  public currentDressed = (): GarmentDTO[] => {
    return this.store.current().dressingRoom.garments;
  };

  public setSelectedGarments = (garments: GarmentDTO[]) => {
    this.logger.debug(`Setting garments in the adapter!`, garments);
    this.store.setSelectedGarments(garments);
  };

  public setDressedGarments = (garments: GarmentDTO[]) => {
    this.logger.debug(`Setting dressed garments in the adapter!`, garments);
    this.store.setGarments(garments);
  };
  public setTuckedInGarment = (garment: GarmentDTO) => {
    this.logger.debug(`Setting tucked in garment in the adapter!`, garment);
    this.store.setTuckedInGarment(garment);
  };
  public setGarmentStylingOptions = (garment: GarmentDTO, options: any) => {
    this.logger.debug(`Setting garment styling options!`, garment, options);
    this.store.setGarmentStylingOptions({ garment, options });
  };
}
