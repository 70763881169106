import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  DataSource,
  PageContextService,
  RRIFilterOutput,
  RRIPagination,
  RRISort,
  RRIStackedBarData,
  RRSpinnerModel,
  RR_THUMBNAIL,
  TableConfiguration,
} from 'projects/web-ui-component-library/src';
import { map, take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RRIDropdownOptionTemplate } from 'projects/web-ui-component-library/src/lib/form-inputs/interfaces/dropdown-option-template.interface';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  interval,
} from 'rxjs';
import { FilesService } from 'projects/content-service-cms/src/app/data/services/files.service';
import { NgStorageManagerService } from '../../../core/services/ng-storage-manager.service';
import {
  IRequest,
  IRequestsAggregate,
  PRODUCT_TYPE,
  ProductHelper,
  REQUEST_STATE_CMS,
  RequestStateHelper,
} from 'projects/content-service-cms/src/app/data';

import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { Store } from '@ngrx/store';
import {
  clearFilters,
  loadFiltersAction,
  loadRequests,
  loadRequestsAggregate,
  paginationChange,
  sortChange,
} from '../../store/requests-overview.actions';
import {
  selectLastUpdated,
  selectRequestsErrorOverview,
  selectRequestsOverview,
  selectRequestsOverviewAggregate,
  selectRequestsOverviewAggregateError,
  selectRequestsOverviewShowLoader,
  selectRequestsOverviewSort,
} from '../../store/requests-overview.selectors';
import { RequestOverviewService } from '../../services/request-overview.service';
import { AlertService } from '../../../core/services/alert.service';
import { RequestListActionBarComponent } from '../request-list-action-bar/request-list-action-bar.component';
import { RequestListRowComponent } from './request-list-row/request-list-row.component';
import { IAppState } from '../../../app.state';
import { createNewRequestAction } from '../../store/requests-overview.actions';

export interface RequestRecord {
  id: string;
  name: string;
  sku: string;
  createdAt: number;
  state: REQUEST_STATE_CMS;
  productType: PRODUCT_TYPE;
  tags: { colour: string; name: string; value: string | null }[];
  thumbnail?: string;
  linked?: RequestRecord[];
}
import * as _ from 'lodash';
import { CreateRequestInputData } from '../create-request-modal/create-request-input.interface';
import { IPaginationDataProviderInterface } from '../../services/pagination-data-provider.interface';
import { GlobalStatePaginationDataProvider } from '../../services/global-state-pagination-data-provider';
import { CreateRequestModalStore } from '../create-request-modal/create-request-modal.state';

const moment = require('moment');

@Component({
  selector: 'rr-request-list',
  templateUrl: './request-list.page.component.html',
  styleUrls: ['./request-list.page.component.scss'],
  providers: [
    {
      provide: IPaginationDataProviderInterface,
      useClass: GlobalStatePaginationDataProvider,
    },
  ],
})
export class RequestListPageComponent implements OnInit, OnDestroy {
  public readonly RequestStateIdHelper = RequestStateHelper;

  public createInput$: BehaviorSubject<CreateRequestInputData> =
    new BehaviorSubject(undefined);
  public spinnerStatusChart: RRSpinnerModel = {
    show: false,
    text: 'Loading...',
  };
  public spinnerStatus: RRSpinnerModel;
  public showRejectedModal: boolean;
  public statesDataset: RRIStackedBarData[];
  public request: IRequest;
  public displayedRequests: BehaviorSubject<IRequest[]> = new BehaviorSubject(
    [],
  );
  private _onDestroy$: Subject<void>;
  private _spinnerCounter: number;
  private _spinnerCounterBarChart: number;
  public showSkeletonLoader: Observable<boolean>;
  @ViewChild('statusOptionTemplate')
  statusOptionTemplate: TemplateRef<RRIDropdownOptionTemplate<string>>;

  currentFilters: RRIFilterOutput<any>[] = [];
  requestTableConfiguration: TableConfiguration<RequestRecord> = {
    rowComponent: RequestListRowComponent,
    columns: [
      {
        title: '',
        hideSort: true,
      },
      {
        title: 'Asset',
      },
      {
        title: 'Status',
      },
      {
        title: 'Product Type',
      },
      {
        title: 'Created At',
      },
      {
        title: 'Tags',
        hideSort: true,
      },
      {
        title: '',
        hideSort: true,
      },
    ],
  };

  requestDatasource: DataSource<RequestRecord> = {
    fetch: (opts) => {
      const mapRequestToRecord = (r: IRequest): RequestRecord => ({
        id: r.id,
        createdAt: r.createdAt,
        name: r.name,
        sku: r.sku,
        productType: ProductHelper.mapProductTypeFromStringToEnum(
          r.productTypeId,
        ),
        state: RequestStateHelper.mapInternatStateToCustomerState(r.stateId),
        tags: r.tags.map((t) => ({
          colour: t.tagKeyColor,
          name: t.tagKeyName,
          value: t.value,
        })),
        thumbnail: r.previewUrl ?? RR_THUMBNAIL,
      });

      return this.displayedRequests.pipe(
        map((requests) => ({
          data: requests.map(mapRequestToRecord),
        })),
      );
    },
  };

  public updated_text: Observable<string>;
  public updated_date: Observable<number>;

  constructor(
    public store: Store<IAppState>,
    public fileService: FilesService<IRequest>,
    private _router: Router,
    private _storageService: NgStorageManagerService,
    private _logger: ILogger,
    private _requestOverviewService: RequestOverviewService,
    private _alertService: AlertService,
    private _pageContextService: PageContextService,
    public createRequestModalStore: CreateRequestModalStore,
  ) {
    this.store
      .select((state) => state.requestOverview.showLoader)
      .subscribe((show) => this.manageShowBarChartSpinner(show));

    this.spinnerStatus = {
      show: false,
      text: 'Loading...',
      id: 'overview-page',
    };
    // this._storageService.setStorageName('requests_overview');
    this._spinnerCounter = 0;
    this._spinnerCounterBarChart = 0;
    this.showRejectedModal = false;

    // load the filters from application storage
    this.store.dispatch(loadFiltersAction());

    this.updated_text = combineLatest([
      this.store.select(selectLastUpdated),
      interval(5000),
    ]).pipe(
      map(([u, _]) => {
        if (u) {
          const mom = moment(u);
          const info = mom.fromNow();
          return `Last updated ${info}`;
        }
        return '';
      }),
    );

    this.updated_date = this.store.select(selectLastUpdated).pipe(
      map((u) => {
        if (u) {
          return u.getTime() / 1000;
        }
        return null;
      }),
    );

    // load the tags

    this._pageContextService
      .setPageParent()
      .setPageActionBar(RequestListActionBarComponent, (ref) => {
        // bind the filter changes
        // ref.filterChange.subscribe((evt) => {
        //   this.onChangeFilter(evt);
        // });
      })
      .setPageTitle('Dashboard');
  }

  ngOnInit(): void {
    this.manageShowSpinner(true);
    this._onDestroy$ = new Subject<void>();
    this._logger.debug('RequestOverviewPage', 'initPromises');
    const sort$ = this.store.select(selectRequestsOverviewSort);
    sort$.pipe(takeUntil(this._onDestroy$)).subscribe((data) => {
      this.setSortColumn(data);
    });
    const requests$ = this.store.select(selectRequestsOverview);
    const requestsAggregate$ = this.store.select(
      selectRequestsOverviewAggregate,
    );
    const isLoading$ = this.store.select(selectRequestsOverviewShowLoader);
    this.showSkeletonLoader = isLoading$;

    const requestsError$ = this.store.select(selectRequestsErrorOverview);
    const requestsAggregateError$ = this.store.select(
      selectRequestsOverviewAggregateError,
    );

    requests$.pipe(takeUntil(this._onDestroy$)).subscribe({
      next: this.onRequestsChanged,
    });

    requestsAggregate$.pipe(takeUntil(this._onDestroy$)).subscribe({
      next: this.onRequestsAggregateChanged,
    });

    requestsError$.pipe(takeUntil(this._onDestroy$)).subscribe({
      next: (error: string) => {
        if (error) this._alertService.showErrorAlert(error);
        this.manageShowSpinner(false);
      },
    });

    requestsAggregateError$.pipe(takeUntil(this._onDestroy$)).subscribe({
      next: (error: string) => {
        if (error) this._alertService.showErrorAlert(error);
      },
    });
  }

  ngOnDestroy(): void {
    if (this._onDestroy$) {
      this._onDestroy$.next();
      this._onDestroy$.complete();
      this._onDestroy$.unsubscribe();
      this._onDestroy$ = undefined;
    }
  }

  @ViewChild('scrollArea')
  private scrollArea: ElementRef<HTMLDivElement>;

  public manageShowSpinner(isLoading: boolean): void {
    if (isLoading) {
      this._spinnerCounter++;
    }

    if (!isLoading && this._spinnerCounter > 0) {
      this._spinnerCounter--;
    }

    this.spinnerStatus.show = this._spinnerCounter !== 0;
  }

  public manageShowBarChartSpinner(isLoading: boolean): void {
    if (isLoading) {
      this._spinnerCounterBarChart++;
    }

    if (!isLoading && this._spinnerCounterBarChart > 0) {
      this._spinnerCounterBarChart--;
    }

    this.spinnerStatusChart.show = this._spinnerCounterBarChart !== 0;
  }

  public onShowRejectModalChanged(showModal: boolean): void {
    this.showRejectedModal = showModal;
  }

  public onCreateRequestTypeSelected(productType: PRODUCT_TYPE): void {
    this.store.dispatch(
      createNewRequestAction({
        productType,
      }),
    );
  }

  public onCreateNewClicked(): void {
    this.createRequestModalStore.startRequestAction();
  }
  public onPaginationChange(uiPagination: RRIPagination): void {
    const pagination = Object.assign({}, uiPagination);
    this.store.dispatch(paginationChange({ pagination }));
  }

  public onSortByChange(uiSort: RRISort<IRequest>): void {
    const sort = Object.assign({}, uiSort);
    this.store.dispatch(sortChange({ sort }));
  }

  public onCreateRequestSelected() {
    throw new Error('Method not implemented.');
  }

  public reloadData() {
    this.loadRequests();
  }

  private loadRequests() {
    return this.store
      .select((state) => state.requestOverview)
      .pipe(
        take(1),
        map(({ filters, pagination, sort }) => ({
          filters: filters ?? {},
          pagination,
          sort,
        })),
      )
      .subscribe(({ filters, sort, pagination }) => {
        this._logger.debug('Loading requests...');
        this.manageShowSpinner(true);
        this.manageShowBarChartSpinner(true);

        this.store.dispatch(loadRequests({ pagination, sort, filters }));
        this.store.dispatch(loadRequestsAggregate({ filters }));
      });
  }

  private onRequestsChanged = ({
    requests,
    recordCount,
  }: {
    requests: IRequest[];
    recordCount: number;
  }) => {
    if (_.isEqual(this.displayedRequests.value, requests)) return;
    this.displayedRequests.next(requests);

    this.manageShowSpinner(false);
  };

  private onRequestsAggregateChanged = (aggregates: IRequestsAggregate) => {
    if (aggregates && aggregates.statesCount) {
      this.statesDataset =
        this._requestOverviewService.buildRequestsOverviewChartData(
          aggregates.statesCount,
        );
    }
    this.manageShowBarChartSpinner(false);
  };
  onSortColumn(col: {
    title: string;
    hideSort?: boolean;
    sort?: 'ASC' | 'DESC' | '';
    sortable?: (a: RequestRecord, b: RequestRecord) => number;
  }) {
    this.onSortByChange(this.getSortObject(col));
  }

  getSortObject(col: {
    title: string;
    hideSort?: boolean;
    sort?: 'ASC' | 'DESC' | '';
    sortable?: (a: RequestRecord, b: RequestRecord) => number;
  }): RRISort<IRequest> {
    switch (col.title) {
      case 'Asset':
        return {
          fieldName: 'name',
          desc: col.sort === 'DESC' ? true : false,
        };
      case 'Status':
        return {
          fieldName: 'stateId',
          desc: col.sort === 'DESC' ? true : false,
        };
      case 'Product Type':
        return {
          fieldName: 'productTypeId',
          desc: col.sort === 'DESC' ? true : false,
        };
      case 'Created At':
        return {
          fieldName: 'createdAt',
          desc: col.sort === 'DESC' ? true : false,
        };
    }
  }
  private setSortColumn(sort: RRISort<IRequest>): void {
    switch (sort.fieldName) {
      case 'name':
        const asset = this.requestTableConfiguration.columns.find(
          (o) => o.title === 'Asset',
        );
        if (asset) {
          asset.sort = sort.desc ? 'DESC' : 'ASC';
        }
        break;
      case 'stateId':
        const status = this.requestTableConfiguration.columns.find(
          (o) => o.title === 'Status',
        );
        if (status) {
          status.sort = sort.desc ? 'DESC' : 'ASC';
        }
        break;
      case 'productTypeId':
        const productType = this.requestTableConfiguration.columns.find(
          (o) => o.title === 'Product Type',
        );
        if (productType) {
          productType.sort = sort.desc ? 'DESC' : 'ASC';
        }
        break;

      case 'createdAt':
        const createdAt = this.requestTableConfiguration.columns.find(
          (o) => o.title === 'Created At',
        );
        if (createdAt) {
          createdAt.sort = sort.desc ? 'DESC' : 'ASC';
        }
        break;
    }
  }
  clearFilter() {
    this.store.dispatch(clearFilters());
  }
}
