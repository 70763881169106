import { Injectable } from '@angular/core';
import { ProcessApi } from '@reactivereality/cs-api-sdk';
import { AxiosResponse } from 'axios';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import {
  IApiSdkService,
  ICsApiSdkConfiguration,
  IProcessService,
  IServiceRejectionReason,
} from '../api';
import { PRODUCT_TYPE } from '../enums/product-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ProcessService implements IProcessService {
  public readonly rejectionReasonsGarment2D$: Observable<
    Array<IServiceRejectionReason>
  >;
  public readonly rejectionReasonsGarment3D$: Observable<
    Array<IServiceRejectionReason>
  >;
  public readonly rejectionReasonsOutfit2D$: Observable<
    Array<IServiceRejectionReason>
  >;
  public readonly rejectionReasonsAccessory3D$: Observable<
    Array<IServiceRejectionReason>
  >;

  private _apiVersion: string;
  private _processApi: ProcessApi;

  private _rejectionReasonsGarment2D$: BehaviorSubject<
    Array<IServiceRejectionReason> | undefined
  >;
  private _rejectionReasonsGarment3D$: BehaviorSubject<
    Array<IServiceRejectionReason> | undefined
  >;
  private _rejectionReasonsOutfit2D$: BehaviorSubject<
    Array<IServiceRejectionReason> | undefined
  >;
  private _rejectionReasonsAccessory3D$: BehaviorSubject<
    Array<IServiceRejectionReason> | undefined
  >;

  constructor(
    private _apiSdkService: IApiSdkService,
    private _logger: ILogger,
  ) {
    this._logger.info('Creating process service ...');

    this._rejectionReasonsGarment2D$ = new BehaviorSubject<
      Array<IServiceRejectionReason | undefined>
    >(undefined);
    this.rejectionReasonsGarment2D$ = this._rejectionReasonsGarment2D$
      .asObservable()
      .pipe(filter((v) => v !== undefined));

    this._rejectionReasonsGarment3D$ = new BehaviorSubject<
      Array<IServiceRejectionReason> | undefined
    >(undefined);
    this.rejectionReasonsGarment3D$ = this._rejectionReasonsGarment3D$
      .asObservable()
      .pipe(filter((v) => v !== undefined));

    this._rejectionReasonsOutfit2D$ = new BehaviorSubject<
      Array<IServiceRejectionReason> | undefined
    >(undefined);
    this.rejectionReasonsOutfit2D$ = this._rejectionReasonsOutfit2D$
      .asObservable()
      .pipe(filter((v) => v !== undefined));

    this._rejectionReasonsAccessory3D$ = new BehaviorSubject<
      Array<IServiceRejectionReason> | undefined
    >(undefined);
    this.rejectionReasonsAccessory3D$ = this._rejectionReasonsAccessory3D$
      .asObservable()
      .pipe(filter((v) => v !== undefined));

    this._apiSdkService.sdkConfig$.subscribe({
      next: this.initProcessService,
      error: this.initProcessServiceHasError,
    });
  }

  public refreshRejectionReasonsGarment2D(): void {
    this.loadGarment2DRejectionReasons();
  }
  public refreshRejectionReasonsGarment3D(): void {
    this.loadGarment3DRejectionReasons();
  }
  public refreshRejectionReasonsOutfit2D(): void {
    this.loadOutfit2DRejectionReasons();
  }
  public refreshRejectionReasonsAccessory3D(): void {
    this.loadAccessory3DRejectionReasons();
  }

  private async loadGarment2DRejectionReasons(): Promise<void> {
    this.fetchServiceRejectionReasons(PRODUCT_TYPE.GARMENT_2D).then(
      (reasons: Array<IServiceRejectionReason>) => {
        this._rejectionReasonsGarment2D$.next(reasons);
      },
    );
  }

  private async loadGarment3DRejectionReasons(): Promise<void> {
    this.fetchServiceRejectionReasons(PRODUCT_TYPE.GARMENT_3D).then(
      (reasons: Array<IServiceRejectionReason>) => {
        this._rejectionReasonsGarment3D$.next(reasons);
      },
    );
  }

  private async loadAccessory3DRejectionReasons(): Promise<void> {
    this.fetchServiceRejectionReasons(PRODUCT_TYPE.ACCESSORY_3D).then(
      (reasons: Array<IServiceRejectionReason>) => {
        this._rejectionReasonsAccessory3D$.next(reasons);
      },
    );
  }

  private async loadOutfit2DRejectionReasons(): Promise<void> {
    this.fetchServiceRejectionReasons(PRODUCT_TYPE.OUTFIT_2D).then(
      (reasons: Array<IServiceRejectionReason>) => {
        this._rejectionReasonsOutfit2D$.next(reasons);
      },
    );
  }

  private async fetchServiceRejectionReasons(
    productType: PRODUCT_TYPE,
  ): Promise<Array<IServiceRejectionReason> | undefined> {
    if (this._processApi) {
      return this._processApi
        .vversionProcessServicerejectionreasonsProductTypeIdGet(
          productType,
          this._apiVersion,
        )
        .then((response: AxiosResponse<Array<object>>) => {
          if (response && Array.isArray(response.data)) {
            return response.data.map(
              this.mapServiceRejectionReasonDTOToIServiceRejectionReason,
            );
          }

          return undefined;
        })
        .catch((reason: any) => {
          this._logger.error(
            `Error while fetching the service rejection reasons for ${productType}`,
            reason,
          );
          return undefined;
        });
    }

    return Promise.reject('Error there is no process api.');
  }

  private initProcessService = (apiSdkConfig: ICsApiSdkConfiguration) => {
    if (apiSdkConfig && apiSdkConfig.apiVersion && apiSdkConfig.processApiSdk) {
      this._apiVersion = apiSdkConfig.apiVersion;
      this._processApi = apiSdkConfig.processApiSdk;

      this.loadGarment2DRejectionReasons();
      this.loadGarment3DRejectionReasons();
      this.loadAccessory3DRejectionReasons();
      this.loadOutfit2DRejectionReasons();
    } else {
      this.initProcessServiceHasError(
        new Error('There is no Process API Sdk Configuration or API version.'),
      );
    }
  };

  private initProcessServiceHasError = (error: Error) => {
    this._logger.error('Cannot initate process API.', error);
  };

  private mapServiceRejectionReasonDTOToIServiceRejectionReason = (reason: {
    key?: string;
    description?: string;
  }): IServiceRejectionReason => {
    return {
      key: reason.key || '',
      description: reason.description || '',
    };
  };
}
