import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DateInputElementComponent,
  RRSpinnerModel,
  RRSpinnerModule,
  SelectInputElementComponent,
} from 'projects/web-ui-component-library/src';
import {
  AvatarThumbnailPipe,
  GarmentThumbnailPipe,
  ProductTypePipe,
} from '../../../utils/CommonPipes';
import { AddItemComponent } from '../../add-item/add-item.component';

import { PRODUCT_TYPE } from '../../../data';
import { AvatarDTO, PagingData } from '../../../data/api';
import { PrettyProductDatePipe } from '../../../request-overview/components/request-list-page/request-list-row/request-list-row.component';

import { CustomerStorageService } from '../../../customer/service/customer-storage.service';
import { AvatarService } from '../../../virtual-dressing-room/data/services/avatar.service';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { IPaginationDataProviderInterface } from '../../../request-overview/services/pagination-data-provider.interface';
import { ComponentPaginationDataProvider } from '../../../request-overview/services/component-pagination-data-provider';
import { RequestListPaginationComponent } from '../../../request-overview/components/request-list-page/request-list-pagination/request-list-pagination.component';
import { SelectionModalStore } from '../../../request-overview/components/create-request-modal/store/selection-modal-store';
import { ModalService } from '../../../modal/modal.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  from,
  switchMap,
} from 'rxjs';
import { FilterSelectionComponent } from '../../filter-selection/filter-selection.component';
import { IFilterConfiguration } from '../../filter-selection/types/filter-selection.types';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../app.state';

@Component({
  selector: 'app-add-avatar-modal',
  templateUrl: './add-avatar-modal.component.html',
  styleUrls: ['./add-avatar-modal.component.scss'],
  imports: [
    CommonModule,
    RRSpinnerModule,
    AddItemComponent,
    GarmentThumbnailPipe,
    AvatarThumbnailPipe,
    ProductTypePipe,
    PrettyProductDatePipe,
    PaginationModule,
    RequestListPaginationComponent,
    NgxSkeletonLoaderModule,
    FilterSelectionComponent,
  ],
  standalone: true,
  providers: [
    [
      SelectionModalStore,
      {
        provide: IPaginationDataProviderInterface,
        useClass: ComponentPaginationDataProvider,
      },
    ],
  ],
})
export class AddAvatarModalComponent implements OnInit, AfterViewInit {
  @Input() public productType: PRODUCT_TYPE.AVATAR_2D | PRODUCT_TYPE.AVATAR_3D;
  @Output() public selectedAvatar: EventEmitter<AvatarDTO> =
    new EventEmitter<AvatarDTO>();

  @ViewChild('selectAvatar') modal;

  public allAvatars: AvatarDTO[];
  public spinnerStatus: RRSpinnerModel;
  public selAvatar: AvatarDTO;
  public currentPage: number;
  public searchPattern: string;
  public readonly pageSize = 10;

  public filterConfiguration: IFilterConfiguration<any>[];
  private _allTags$ = new BehaviorSubject<{ value: string; text: string }[]>(
    undefined,
  );
  private _avatarLookup$: Subject<void> = new Subject();
  private _abortController: AbortController;
  private _filter = {};
  constructor(
    private _customerStorageService: CustomerStorageService,
    private _avatarService: AvatarService,
    private _store: SelectionModalStore,
    private _modalService: ModalService,

    private _appStore: Store<IAppState>,
  ) {
    this.spinnerStatus = {
      show: false,
      text: 'Loading...',
    };

    this.filterConfiguration = [
      {
        id: 'tags',
        text: 'Tags',
        placeholder: 'Find Tags...',
        type: SelectInputElementComponent,
        data: this._allTags$,
      },
      {
        id: 'createdAt',
        text: 'Created at',
        placeholder: 'Select date',
        type: DateInputElementComponent,
      },
    ];
  }
  async ngAfterViewInit(): Promise<void> {
    combineLatest([
      this._store.currentPage$,
      this._store.searchPattern$,
      this._store.filters$,
    ])
      .pipe(debounceTime(100))
      .subscribe(([currentPage, searchPattern, filter]) => {
        if (currentPage && searchPattern != undefined && filter != undefined) {
          this.currentPage = currentPage;
          this.searchPattern = searchPattern;
          this._filter = filter;
          this._avatarLookup$.next();
        }
      });

    //request the garments and enable aborting the request with a new request
    this._avatarLookup$
      .pipe(
        debounceTime(100),
        switchMap(() => {
          this.spinnerStatus.show = true;
          if (this._abortController !== undefined) {
            this._abortController.abort();
          }
          return this.getAvatars();
        }),
      )
      .subscribe(async (result) => {
        (this.allAvatars = result.avatars),
          this._store.updateCurrentPage(this.currentPage);
        this._store.updatePageCount(result.paging.pageCount);
        this.spinnerStatus.show = false;
      });

    this.currentPage = 1;
    this.searchPattern = '';

    // this._paginationProvider.currentPage$.subscribe((currentPage) => {
    //   if (this.currentPage != currentPage) {
    //     this.currentPage = currentPage;
    //     this.getAvatars();
    //   }
    // });

    //read all tags from app store
    this._appStore
      .select((state) => state.requestOverview.tags)
      .subscribe((value) => {
        const data = value.map((v) => {
          return { value: v.uuid, text: v.name };
        });
        this._allTags$.next(data);
      });
  }

  private getAvatars(): Observable<{
    avatars: AvatarDTO[];
    paging: PagingData;
  }> {
    this.spinnerStatus.show = true;
    let requestFilter = {
      productTypeId: this.productType,
      stateId: [4, 13],
    };

    //extract the tags from the filters
    let tags = this._filter['tags'];
    const filters = JSON.parse(JSON.stringify(this._filter));
    if (tags !== undefined) {
      tags = this.convertToTagFormat(tags);
      delete filters['tags'];
    }

    requestFilter = { ...requestFilter, ...filters };

    if (this.searchPattern !== undefined && this.searchPattern !== '') {
      requestFilter = {
        ...requestFilter,
        ...{ 'name,sku': this.searchPattern },
      };
    }

    this._abortController = new AbortController();
    return from(
      this._avatarService.getAvatarsForCustomer(
        JSON.stringify(requestFilter),
        this.productType,
        this._customerStorageService.getSelectedCustomer(),
        this.currentPage,
        this.pageSize,
        tags,
        this._abortController.signal,
      ),
    );
  }

  ngOnInit(): void {}

  onCheckboxChanged($event: any, avatar: AvatarDTO) {
    const cbs = document.getElementsByName('cb');
    const selElement = $event.target.checked;
    cbs.forEach((data: HTMLInputElement) => {
      data.checked = false;
    });
    if (selElement) {
      if (avatar) {
        this.selAvatar = avatar;
        $event.target.checked = true;
      }
    } else {
      if (avatar) {
        $event.target.checked = false;
        this.selAvatar = undefined;
      }
    }
    $event.stopPropagation();
  }

  public closeModal() {
    this._modalService.close();
  }

  public selectAvatarClicked() {
    if (this.selAvatar != undefined) {
      this.selectedAvatar.emit(this.selAvatar);
    }
    this.closeModal();
  }

  public async searchPatternChanged(event: any) {
    if (event.target?.value === '' || event.target?.value?.length > 0) {
      this.allAvatars = [];
      this._store.updateSearchPattern(event.target.value);
    }
  }

  public clearSearchInput() {
    this.allAvatars = [];
    this._store.updateSearchPattern('');
  }

  private convertToTagFormat(tags: string): string {
    const values = JSON.parse(tags) as {
      value: string;
      id: string;
    }[];
    const tagId = values.map((v) => {
      return v.id;
    });
    let v = {};
    for (const id of tagId) {
      v = { ...v, [id]: ['*'] };
    }
    return JSON.stringify(v);
  }
}
