import { ElementRef } from '@angular/core';
import {
  IAsset,
  PersonalisedMannequinCreatorCustomiseParameters,
  SizeRecommendationResponse,
  VDRSizeRecommendationConfiguration,
} from '@reactivereality/pictofit-web-sdk';
import { ConfigurationDTO } from '@reactivereality/cs-api-sdk';
import { BehaviorSubject, Observable } from 'rxjs';

// Discovery
export class IAppConfiguration {
  config: string;
  garments_type: string;
  default_2d_scene: string;
  default_3d_scene: string;
}

export class IApiConfiguration {
  graphql_endpoint: string;
  websdk_endpoint: string;
}

export interface IConfiguration {
  appConfiguration: IAppConfiguration;
  apiConfiguration: IApiConfiguration;
}

export abstract class IConfigurationService {
  abstract getConfiguration(): Promise<IConfiguration | undefined>;
  abstract getObservableConfiguration(): Observable<IConfiguration | undefined>;
}

//export type GqlSdk = ReturnType<typeof getSdk>;

// Brands
export interface IBrand {
  id: string;
  name: string;
  previewFiles: Array<{
    id: string;
    filename: string;
    mimetype: string;
  }>;
}

export interface IDisplaySetting {
  id: string;
  name: string;
  dress_on_avatar: boolean;
  scene_id: string;
}

export interface ICategory {
  id: string;
  name: string;
  render_2d?: IDisplaySetting;
  render_3d?: IDisplaySetting;
}

export interface IUserProfile {
  id: string;
  email: string;
}

export type AssetFiles = Array<{
  filename: string;
  mimetype: string;
  url: string;
}>;

export enum Gender {
  MALE,
  FEMALE,
}

export interface IAvatar {
  id: string;
  name: string;
  gender: Gender;
  request?: PersonalisedMannequinCreatorCustomiseParameters;
  asset: AssetFiles | IAsset;
  thumbnail?:
    | string
    | {
        filename: string;
        mimetype: string;
        url: string;
      };
  userSpecific: boolean;
}

export interface IPreviewAvatar extends IAvatar {
  preview: {
    url: Promise<string>;
    mimetype: Promise<string>;
  };
}

export interface IScene {
  id: string;
  name: string;
  description: string;
  supports_2d: boolean;
  supports_3d: boolean;
  asset: AssetFiles;
}
export interface ITuckedIn {
  garmentId: string;
  tuckedIn: boolean;
}

// Garments

export interface IGarmentDetails extends GarmentDetailsFragment {
  garment_3d_assets: Array<{
    asset: DownloadAbleAssetsFragment;
  }>;
  garment_2d_assets: Array<{
    asset: DownloadAbleAssetsFragment;
  }>;
}

export interface IAssetMetadata {
  garmentGroup: number;
  tryonable: boolean;
  productTypeId?: string;
  assetComponentKeys?: Array<string>;
  //supportSizing: boolean;
}

export type IGarmentMetadata = {
  '2D': IAssetMetadata | undefined;
  '3D': IAssetMetadata | undefined;
};

export interface IPreviewGarmentDetails extends IGarmentDetails {
  metadata: IGarmentMetadata;
  preview: {
    url: Promise<string>;
    mimetype: Promise<string>;
  };
}

export interface PagedGarmentResponse
  extends IPagedResult<IPreviewGarmentDetails> {}

export interface IPagedResult<T> {
  data: Array<T>;
  pageSize: number;
  offset: number;
  total: number;
}

// Outfits

export interface IOutfitDetail extends OutfitDetailFragment {
  preview: {
    url: Promise<string>;
    mimetype: Promise<string>;
  };
}

export interface IStoreOutfitDetail {
  id?: string;
  name: string;
  avatar: string;
  garments: string[];
  screenshot: Blob;
}

export enum ViewerMode {
  MODE_2D_PARALLAX = '2D_PARALLAX',
  MODE_3D = '3D',
  MODE_2D = '2D',
}

export abstract class IBrandService {
  abstract readonly allBrands_loading$: BehaviorSubject<boolean>;
  abstract readonly allBrands$: Observable<Array<IBrand>>;
}

export abstract class ITagsService {
  abstract readonly allFilterableTags_loading$: BehaviorSubject<boolean>;
  abstract readonly allFilterableTags$: Observable<Array<IBrand>>;
}

export abstract class ICategoryService {
  abstract readonly allCategories_loading$: BehaviorSubject<boolean>;
  abstract readonly allCategories$: BehaviorSubject<Array<ICategory>>;
}

export abstract class IGarmentService {
  public abstract readonly current_garments_loading$: BehaviorSubject<boolean>;
  public abstract readonly current_garments$: Observable<PagedGarmentResponse>;
  public abstract readonly current_query$: BehaviorSubject<GetGarmentsOverviewQueryVariables>;

  public abstract readonly garment_detail_loading$: BehaviorSubject<boolean>;
  public abstract readonly garment_detail_garments$: Observable<
    IPreviewGarmentDetails | undefined
  >;
  public abstract readonly garment_detail_query$: BehaviorSubject<
    string | undefined
  >;

  public abstract getDisplaySettings(
    garment: IPreviewGarmentDetails,
    mode: ViewerMode,
  ): Promise<IDisplaySetting>;

  public abstract getGarmentMetadata(
    garment: IPreviewGarmentDetails,
    mode: ViewerMode,
  ): IAssetMetadata | undefined;

  public abstract supportedViewerModes(
    garment: IGarmentDetails,
  ): Promise<Array<ViewerMode>>;
  public abstract toSdkAsset(
    garment: IGarmentDetails,
    mode: ViewerMode,
  ): Promise<IAsset>;
  public abstract getSizes(
    garment: IGarmentDetails,
    mode: ViewerMode,
  ): Promise<string[]>;
  public abstract getSizeRecommendation(
    garment: IGarmentDetails,
    avatar: IAvatar,
    request: VDRSizeRecommendationConfiguration,
    mode: ViewerMode,
    sizes?: Set<string>,
  ): Promise<SizeRecommendationResponse>;

  public abstract fetchGarmentById(
    garmentIds: string,
  ): Promise<IPreviewGarmentDetails | undefined>;
  public abstract fetchGarmentsByIds(
    garmentIds: string[],
  ): Promise<IPreviewGarmentDetails[]>;
  public abstract resolvePreview(
    garment: IGarmentDetails,
  ): Promise<IPreviewGarmentDetails>;
}

export abstract class IAvatarService {
  abstract readonly defaultAvatarId$: BehaviorSubject<string>;
  readonly allUserAvatars3d$: BehaviorSubject<Array<ConfigurationDTO>>;
  readonly allUserAvatars2d$: BehaviorSubject<Array<ConfigurationDTO>>;

  abstract toSdkAsset(
    avatar: ConfigurationDTO,
    mode: ViewerMode,
  ): Promise<IAsset>;
  abstract loadCustomerAvatars2D(
    customerId: string,
    currentPage: number,
    pageSize: number,
  ): Promise<Array<ConfigurationDTO>>;
  abstract loadCustomerAvatars3D(
    customerId: string,
    currentPage: number,
    pageSize: number,
  ): Promise<Array<ConfigurationDTO>>;
  abstract loadPublicAvatars2D(): Promise<Array<ConfigurationDTO>>;
  abstract loadPublicAvatars3D(): Promise<Array<ConfigurationDTO>>;
}

export abstract class ISceneService {
  abstract readonly allScenes_loading$: BehaviorSubject<boolean>;
  abstract readonly allScenes$: BehaviorSubject<Array<IScene>>;
  abstract toSdkAsset(avatar: IScene, mode: ViewerMode): Promise<IAsset>;
}

export abstract class IUserService {
  abstract readonly userProfile$: BehaviorSubject<IUserProfile | undefined>;
}

export abstract class IDownloadUrlCacheService {
  abstract attachCachedUrlsToAsset(
    asset: DownloadAbleAssetsFragment,
  ): Promise<DownloadAbleAssetsFragment>;
  abstract resolveDownloadURL(fileId: string, value: string): Promise<string>;
}

export abstract class IGarmentCacheService {
  public abstract fetchGarmentById(
    garmentIds: string,
  ): Promise<IGarmentDetails | undefined>;
  public abstract fetchGarmentsByIds(
    garmentIds: string[],
  ): Promise<IGarmentDetails[]>;
}

export abstract class IOutfitService {
  abstract readonly userOutfits$: BehaviorSubject<IOutfitDetail[]>;
  abstract readonly selectedUserOutfit$: BehaviorSubject<
    IOutfitDetail | undefined
  >;

  abstract storeOutfit(outfit: IStoreOutfitDetail): Promise<void>;
  abstract deleteOutfit(outfit: IOutfitDetail): Promise<void>;
  public abstract undressGarment(outfit: IOutfitDetail | string): void;
}

export abstract class IShoppingBagService {
  public readonly bagGarments$: BehaviorSubject<string[]>;
  public abstract animateShoppingBagIcon(
    reverse: boolean,
    from: ElementRef,
    to?: ElementRef,
  );

  public abstract isInShoppingBag(garment: IGarmentDetails | string): boolean;
  public abstract addGarment(
    garment: IGarmentDetails[] | IGarmentDetails | string | string[],
  ): void;
  public abstract delGarment(garment: IGarmentDetails | string): void;
}

export abstract class IDressingRoomService {
  public readonly selectedOutfit$: BehaviorSubject<IOutfitDetail>;
  /**
   * Garments that are currently selected to be in the dressing room
   */
  public readonly selectedGarments$: BehaviorSubject<IPreviewGarmentDetails[]>;
  /**
   * Garments that are currently dressed in the dressing room
   */
  public readonly dressedGarments$: BehaviorSubject<IPreviewGarmentDetails[]>;
  public readonly checkFitGarment$: BehaviorSubject<string | undefined>;

  /**
   * Garments that are currently tucked in
   */
  public readonly tuckedInGarment$: BehaviorSubject<string[]>;

  public abstract tryonOnOutfit(outfit: IOutfitDetail);
  public abstract supportDressingRoom(garment: IGarmentDetails): boolean;
  public abstract isInDressingRoom(garment: IGarmentDetails | string): boolean;
  /**
   * Adds garment into dressing room
   * @param garment
   */
  public abstract addGarment(
    garment: IGarmentDetails[] | IGarmentDetails | string | string[],
  ): void;

  /**
   * Removes garment from dressing room
   * @param garment
   */
  public abstract delGarment(garment: IGarmentDetails | string): void;

  /**
   * Tries on garment
   * @param garment
   */
  public abstract dressGarment(
    garment: IGarmentDetails[] | IGarmentDetails | string | string[],
  ): void;

  public abstract getInitialGarments(): string[];

  /**
   * Change tuck in state of the garment
   * @param garment
   * @param tuckedIn
   */
  public abstract tuckIn(garment: IGarmentDetails, tuckedIn: boolean): void;

  /**
   * Undress garment
   * @param garment
   */
  public abstract undressGarment(garment: IGarmentDetails | string): void;

  public abstract animateDressingRoomIcon(
    reverse: boolean,
    from: ElementRef,
    to?: ElementRef,
  ): void;
}

// THIS DOESN"T WORK YET!!
export abstract class ISizeRecommendationService {
  public abstract isShown(): boolean;
  public abstract show(garment: IGarmentDetails): void;
  public abstract hide(): void;
  //public abstract setComponent(component: SizeRecommendationComponent): void;
}

//Todo: DUMMYS:
class GarmentDetailsFragment {}
class DownloadAbleAssetsFragment {}
class OutfitDetailFragment {}
class GetGarmentsOverviewQueryVariables {}

export type GarmentAssetFiles = Array<{
  filename: string;
  url: string;
}>;
