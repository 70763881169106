import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  IRequestDetail,
  PRODUCT_TYPE,
} from 'projects/content-service-cms/src/app/data';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { DataService } from '../../core/services/data.service';
import { NgDiscoveryService } from '../../core/services/ng-discovery.service';
import {
  GarmentDTO,
  IApiSdkService,
  ICsApiSdkConfiguration,
  IGarmentService,
  IRequestResult,
} from '../api';

@Injectable({
  providedIn: 'root',
})
export class GarmentService
  extends DataService<IRequestDetail, any>
  implements IGarmentService
{
  private static endpointName = 'configurations/public';

  constructor(
    protected _http: HttpClient,
    protected _logger: ILogger,
    protected _apiSdkService: IApiSdkService,
    protected _discoveryService: NgDiscoveryService,
  ) {
    super(
      GarmentService.endpointName,
      _http,
      _logger,
      _apiSdkService,
      _discoveryService,
    );
    this._apiSdkService.sdkConfig$.subscribe({
      next: this.initService,
      error: this.errorOnInitService,
    });
  }

  protected initService = (sdkConfig: ICsApiSdkConfiguration) => {};

  public async getMetadata<T>(
    webResult: IRequestResult,
  ): Promise<T | undefined> {
    if (!webResult) {
      return;
    }
    const metadataFile = webResult.files.find(
      (o) => o.name === 'metadata.json',
    );
    if (!metadataFile) {
      return;
    }
    const metadataResponse = await fetch(metadataFile.url);
    if (!metadataResponse.ok) {
      return;
    }
    return (await metadataResponse.json()) as T;
  }

  public async mapRequestsToGarments(
    request: IRequestDetail,
    additional?: {
      isPublic?: boolean;
      isSelected?: boolean;
      dressed?: boolean;
      tuckedIn?: boolean;
    },
  ): Promise<GarmentDTO> {
    const webresults = request.results.filter((c) => c.format === 'web');
    if (webresults?.length === 0) {
      throw new Error(`Can't map request to GarmentDTO, no web result`);
    }

    const metadata = await this.getMetadata<{
      garmentGroup?: number;
      tryonable?: boolean;
    }>(webresults[0]);
    if (!metadata) {
      throw new Error(
        `Can't map request to GarmentDTO, no valid metadata.json`,
      );
    }

    if (metadata.garmentGroup === undefined || metadata.garmentGroup === null) {
      throw new Error(
        `Can't map request to GarmentDTO, no valid metadata.json [missing garmentGroup]`,
      );
    }
    const tryonable =
      request.productTypeId === PRODUCT_TYPE.OUTFIT_2D ||
      (metadata.tryonable ?? false);

    return {
      ...request,
      isPublic: additional?.isPublic ?? false,
      selected: additional?.isSelected ?? false,
      groupId: metadata.garmentGroup,
      tuckedIn: additional?.tuckedIn ?? false,
      dressed: additional?.dressed ?? false,
      tryonable: tryonable,
    };
  }
}
