import { Component } from '@angular/core';
import { AssetListRowComponent as AssetListRowComponent } from '../asset-list-row/asset-list-row.component';
import { CommonModule } from '@angular/common';

import { AssetReference } from '../../collection-screen.state';
import { BaseListComponent } from '../base-list/base-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-asset-list',
  standalone: true,
  imports: [AssetListRowComponent, CommonModule, DragDropModule],
  templateUrl: './asset-list.component.html',
  styleUrl: '../base-list/base-list.scss',
})
export class AssetListComponent extends BaseListComponent<AssetReference> {
  constructor() {
    super();
  }
}
