<div class="row rowcontent">
  <div class="col-6 ml-5 mr-5 d-flex flex-column">
    <h2>Ghost mannequin shot</h2>
    <app-file-upload-full
      class="mb-5"
      title="Front (required)"
      [initialFiles]="initialFrontFiles"
      [multipleFiles]="false"
      (filesSelected)="onSelectedFilesChanged('front', $event)"
    ></app-file-upload-full>
    <app-file-upload-full
      class="mb-5"
      title="Back"
      [initialFiles]="initialBackFiles"
      [multipleFiles]="false"
      (filesSelected)="onSelectedFilesChanged('back', $event)"
    ></app-file-upload-full>
    <app-file-upload-full
      class="mb-5"
      [initialFiles]="initialReferenceFiles"
      title="Reference images"
      (filesSelected)="onSelectedFilesChanged('ref', $event)"
    ></app-file-upload-full>
  </div>
  <div class="col-5 d-flex flex-column">
    <app-common-inputs class="d-flex flex-column"></app-common-inputs>
    <div class="mt-5">
      <input
        class="mr-3"
        type="checkbox"
        id="performQc"
        name="performQc"
        [disabled]="createRequestModalStore.existingRequest$ | async"
        [checked]="!(createRequestModalStore.skipManualStep$ | async)"
        (change)="createRequestModalStore.switchSkipManualStep()"
      />
      <span>Manual quality control</span>
    </div>
    <app-action-buttons></app-action-buttons>
  </div>
</div>
