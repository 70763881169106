import { AssetFiles } from '../api';
//import { DownloadAbleAssetsFragment } from '../../../graphql-db-connection/types';
import { AssetType, IAsset } from '@reactivereality/pictofit-web-sdk';
import { HttpClient } from '@angular/common/http';
import { ILogger } from '../../../logging';

export const toAssetFiles = (data: AssetFiles): AssetFiles => {
  if (Array.isArray(data)) {
    return data;
  }
};

export class BaseAsset implements IAsset {
  constructor(
    protected avatar: AssetFiles,
    protected httpClient: HttpClient,
    protected logger: ILogger,
    protected assetType: AssetType,
  ) {}
  preloadComponents(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  preloadComponent(key: string): Promise<void> {
    throw new Error('Method not implemented.');
  }

  private findFile(key: string) {
    //this.logger.debug(`[BaseAsset] Loading key: ${key}`);
    return this.avatar.find((file) => file.filename === key);
  }

  private getLocation = async (key: string): Promise<string | null> => {
    const fileInQuestion = this.findFile(key);

    if (!fileInQuestion) {
      return null;
    }

    return fileInQuestion.url;
  };

  /**
   * Get a component of this asset associated with the specified key.
   * Returns a blob incase the component is available, otherwise it will throw an error.
   * This is the entrypoint from which components are requested for local purposes. (aka. when no compute server is involved.)
   * @param key The key that the component is associated with.
   * @returns A promise that resolves to the components blob.
   */
  public getComponent = async (key: string): Promise<Blob> => {
    const location = await this.getLocation(key);

    if (location == null) {
      throw new Error(`Key '${key}' is not available in BaseAsset`);
    }

    //this.logger.debug(`[BaseAsset] getComponent: ${key}`);

    const response = await this.httpClient
      .get(location, { observe: 'response', responseType: 'blob' })
      .toPromise();

    if (response.status !== 200) {
      const responseText =
        response.body !== null
          ? await response.body.text()
          : 'Unkown response text';
      throw new Error(
        `Failed to fetch key '${key}' for BaseAsset ${responseText}`,
      );
    }

    if (response.body === null) {
      throw new Error(`Key '${key}' for product resulted in null blob content`);
    }

    return response.body;
  };

  /**
   * Get the location of a component of this asset associated with the specified key.
   * Will return null incase an location could not be resolved. This could sometimes be the case when a component is only available locally but has no public location.
   * @param key
   * @returns The location of the component or null. Does not mean that the component behind the url is available.
   */
  public getComponentLocation = async (key: string): Promise<string | null> => {
    const location = await this.getLocation(key);

    // this.logger.debug(
    //   `[BaseAsset] getComponentLocation: ${key} => ${location}`
    // );
    return location;
  };

  /**
   * Get all keys of this asset.
   */
  public getKeys = async (): Promise<string[]> => {
    return this.avatar.map((f) => f.filename);
  };

  /**
   * The type of an asset.
   */
  public getType = async (): Promise<AssetType> => {
    return this.assetType;
  };
}
