import { Injectable } from '@angular/core';
import {
  ConfigurationResultFileDTO,
  FilesApi,
} from '@reactivereality/cs-api-sdk';
import { ILogger } from '../../logging';
import {
  IApiSdkService,
  ICsApiSdkConfiguration,
  IFileInformation,
  IFileUpload,
  IFileUploadService,
  IResultFileUpload,
} from '../api';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { NgDiscoveryService } from '../../core/services/ng-discovery.service';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService implements IFileUploadService {
  private _filesApi: FilesApi;
  private _apiVersion: string;

  constructor(
    private _http: HttpClient,
    private _logger: ILogger,
    private _apiSdkService: IApiSdkService,
    private _discoveryService: NgDiscoveryService,
  ) {
    this._apiSdkService.sdkConfig$.subscribe({
      next: this.initFileUploadService,
      error: this.errorOnInitFileUploadService,
    });
  }

  public async uploadJsonFile(
    fileInfo: IFileInformation,
    jsonObject: object,
  ): Promise<void> {
    const file = this.jsonToBlob(jsonObject);
    this.uploadFile(fileInfo, file);
  }

  public async uploadBase64File(
    fileInfo: IFileInformation,
    base64Data: string,
  ): Promise<void> {
    const file = this.dataURIToBlob(base64Data);
    this.uploadFile(fileInfo, file);
  }

  public async uploadFile(
    fileInfo: IFileInformation,
    fileBlob: Blob,
  ): Promise<ConfigurationResultFileDTO> {
    const formData: FormData = new FormData();
    if (fileInfo.id) {
      formData.append('id', fileInfo.id);
    }

    formData.append('file', fileBlob, fileInfo.fileName);
    formData.append('configurationId', fileInfo.configurationId);
    formData.append('name', fileInfo.fileName);
    formData.append('typeId', `${fileInfo.typeId}`);
    formData.append('mimeType', fileInfo.mimeType);

    const discoveryConfig = await this._discoveryService.config;
    const url = `${discoveryConfig.apiConfiguration.baseApiUrl}/Files`;

    return lastValueFrom(this._http.post(url, formData)).then(
      (response: ConfigurationResultFileDTO) => {
        return response;
      },
    );
  }

  public async fileUpload(fileInfo: IFileUpload, file: Blob): Promise<void> {
    this.putFileUpload('', fileInfo.mimeType, fileInfo.name, file).then(() => {
      this.patchFileUpload('', '');
    });
  }

  public async resultFileUpload(
    fileInfo: IResultFileUpload,
    file: Blob,
  ): Promise<void> {
    this.putFileUpload('', fileInfo.mimeType, fileInfo.name, file).then(() => {
      this.patchFileUpload('', '');
    });
  }

  private async putFileUpload(
    url: string,
    mimeType: string,
    fileName: string,
    fileBlob: Blob,
  ): Promise<void> {
    const formData: FormData = new FormData();
    formData.append('file', fileBlob, fileName);

    return lastValueFrom(this._http.put(url, formData)).then(() => {
      return null;
    });
  }

  private async patchFileUpload(url: string, fileId: string): Promise<void> {
    return lastValueFrom(this._http.patch(url, { fileId })).then(() => {
      return null;
    });
  }

  private jsonToBlob(jsonObj: object) {
    const str = JSON.stringify(jsonObj);
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
      type: 'application/json',
    });
    return blob;
  }

  private dataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  private initFileUploadService = (sdkConfig: ICsApiSdkConfiguration) => {
    if (
      sdkConfig !== undefined &&
      sdkConfig.filesApiSdk &&
      sdkConfig.apiVersion
    ) {
      this._apiVersion = sdkConfig.apiVersion;
      this._filesApi = sdkConfig.filesApiSdk;
    } else {
      this.errorOnInitFileUploadService(
        new Error('There is no Files API Sdk Configuration or API version.'),
      );
    }
  };

  private errorOnInitFileUploadService = (error: Error) => {
    this._logger.error('Cannot initate files API.', error);
  };
}
