import {
  Component,
  ElementRef,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ILogger } from '../../logging';
import { ICustomerService, IRequestService } from '../../data';
import { NgStorageManagerService } from '../../core/services/ng-storage-manager.service';
import { CreateRequestModalStore } from '../../request-overview/components/create-request-modal/create-request-modal.state';
import { ModalService } from '../../modal/modal.service';
import { CommonModule } from '@angular/common';
import { AddGarmentModalComponent } from '../garment-selection/add-garment-modal/add-garment-modal.component';
import { AddItemComponent } from '../add-item/add-item.component';
import { ConfigurationDisplayComponent } from '../garment-selection/configuration-display/configuration-display.component';
import { RRSpinnerModule } from 'projects/web-ui-component-library/src';
import { IAccessorySelectionService } from './service/iaccessory-selection.service';
import { AccessorySelectionEntryComponent } from './accessory-selection-entry/accessory-selection-entry.component';
import { AccessoryDTO } from '../../data/api';
import { Subject, takeUntil } from 'rxjs';

export enum ACCESSORY_SLOT {
  TOP = 'TOP',
  MIDDLE = 'MIDDLE',
  BOTTOM = 'BOTTOM',
}

export type SelectedAccessories = {
  TOP: AccessoryDTO | undefined;
  MIDDLE: AccessoryDTO | undefined;
  BOTTOM: AccessoryDTO | undefined;
};

@Component({
  selector: 'app-accessory-selection',
  standalone: true,
  imports: [
    CommonModule,
    AddGarmentModalComponent,
    AddItemComponent,
    AccessorySelectionEntryComponent,
    ConfigurationDisplayComponent,
    RRSpinnerModule,
  ],
  templateUrl: './accessory-selection.component.html',
  styleUrl: './accessory-selection.component.scss',
})
export class AccessorySelectionComponent implements OnDestroy {
  @ViewChild('addGarments') addGarments: TemplateRef<any>;

  private _onDestroy$: Subject<void>;
  public selectedAccessories: SelectedAccessories = {
    TOP: undefined,
    MIDDLE: undefined,
    BOTTOM: undefined,
  };
  public currentSlot: ACCESSORY_SLOT | undefined = undefined;

  constructor(
    public accessorySelectionService: IAccessorySelectionService,
    private logger: ILogger,
    private _requestService: IRequestService,
    private _customerService: ICustomerService,
    private _localStorageService: NgStorageManagerService,
    private _elementRef: ElementRef,
    public createRequestModalStore: CreateRequestModalStore,
    public modalService: ModalService,
  ) {
    this._onDestroy$ = new Subject<void>();

    this.accessorySelectionService.selectedGarments$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe({
        next: this.onAccessorySelected,
        error: console.error,
      });
  }

  ngOnDestroy(): void {
    this.accessorySelectionService.updateGarmentForSlot(
      ACCESSORY_SLOT.TOP,
      undefined,
    );
    this.accessorySelectionService.updateGarmentForSlot(
      ACCESSORY_SLOT.MIDDLE,
      undefined,
    );
    this.accessorySelectionService.updateGarmentForSlot(
      ACCESSORY_SLOT.BOTTOM,
      undefined,
    );
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._onDestroy$.unsubscribe();
    this._onDestroy$ = undefined;
  }

  private onAccessorySelected = (accessories: SelectedAccessories) => {
    this.logger.debug('onAccessorySelected', accessories);
    if (this.currentSlot !== undefined) {
      this.selectedAccessories[this.currentSlot] = accessories[0];
    }
  };

  setGarmentForCurrentSlot($event: AccessoryDTO[]) {
    this.accessorySelectionService.updateGarmentForSlot(
      this.currentSlot,
      $event[0],
    );
    this.createRequestModalStore.setAccessories(
      this.accessorySelectionService.currentSelectedGarments(),
    );
  }

  addAccessoriesClicked(slot: string) {
    this.currentSlot = ACCESSORY_SLOT[slot.toUpperCase()];
    this.modalService.show({
      title: 'Select Garments',
      template: this.addGarments,
    });
  }
  deleteAllAccessories() {
    this.accessorySelectionService.setSelectedGarments({
      TOP: undefined,
      MIDDLE: undefined,
      BOTTOM: undefined,
    });
  }
  // dressAccessory(accessory: AccessoryDTO) {
  //   let toBeDressed: AccessoryDTO[] = [];
  //   const currentDressed = this.accessorySelectionService.currentDressed();
  //   if (currentDressed.find((g) => g.id === accessory.id)) {
  //     toBeDressed = currentDressed.filter((g) => g.id !== accessory.id);
  //   } else {
  //     toBeDressed = [...currentDressed, accessory];
  //   }
  //   this.accessorySelectionService.setDressedGarments(toBeDressed);
  //   this.updateSelectedAccessories();
  // }
  deleteAccessoryEntry(slot: string) {
    this.accessorySelectionService.updateGarmentForSlot(slot, undefined);
    this.createRequestModalStore.setAccessories(
      this.accessorySelectionService.currentSelectedGarments(),
    );
  }
  productType: any;
  showRecentlyUsed() {
    throw new Error('Method not implemented.');
  }

  private updateSelectedAccessories() {
    // this.selectedAccessories.forEach((g) => (g.dressed = false));
    // this.selectedAccessories.forEach((selected) => {
    //   if (
    //     this.accessorySelectionService
    //       .currentDressed()
    //       .find((dressed) => dressed.id === selected.id)
    //   ) {
    //     selected.dressed = true;
    //   }
    // });
  }
}
