import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class SceneSelectionService {
  public onSelect: EventEmitter<void> = new EventEmitter();

  constructor() {}

  public selectScene(): void {
    this.onSelect.emit();
  }
}
