import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AbstractFilterService, FilterConfig, Filters } from '../..';

@Component({
  selector: 'rr-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
})
export class RRFilterBarComponent<F extends Filters> implements AfterViewInit {
  @Input() filterService: AbstractFilterService<F>;
  @Output() clearFilterValues: EventEmitter<boolean> = new EventEmitter();
  filters: FilterConfig<F>[] = [];

  constructor() {}

  filterChanged(key: keyof F, value: any) {}

  ngAfterViewInit(): void {
    this.populateFilters();
  }

  private populateFilters() {
    if (!this.filterService) return;

    this.filters = Object.getOwnPropertyNames(this.filterService.filters)
      .map((key) => ({
        key,
        ...this.filterService.filters[key],
      }))
      .filter((v) => 'label' in v);
    console.log('RRFilterBarComponent', 'populateFilters:', this.filters);
  }
  public clearFilter() {
    this.clearFilterValues.emit(true);
  }
}
