import { Component } from '@angular/core';
import { AssetListComponent } from '../asset-list/asset-list.component';
import { OutfitListRowComponent } from '../outfit-list-row/outfit-list-row.component';
import { OutfitReferenceJson } from '../../collection-screen.state';
import { CommonModule } from '@angular/common';
import { BaseListComponent } from '../base-list/base-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-outfit-list',
  standalone: true,
  imports: [
    AssetListComponent,
    OutfitListRowComponent,
    CommonModule,
    DragDropModule,
  ],
  templateUrl: './outfit-list.component.html',
  styleUrl: '../base-list/base-list.scss',
})
export class OutfitListComponent extends BaseListComponent<OutfitReferenceJson> {
  constructor() {
    super();
  }
}
