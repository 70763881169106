// ===== File globals.ts
//

export const GlobalVariable = Object.freeze({
  CLIENT_VERSION: require('/src/version.json').version,
  GET_CUSTOMER_IDS: 'customerIds',
  FILE_TYPES: 'fileTypes',
  FILE_MIME_TYPES: 'fileMimeTypes',
  FILTER_USER_LIST: 'userLists',
  SCREENSHOT_NAME: 'Screenshot',
});
