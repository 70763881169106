import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAuthService } from '../api';
import { ILogger } from 'projects/content-service-cms/src/app/logging';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _authService: IAuthService,
    private _logger: ILogger,
    private _injector: Injector,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let requestToForward = req;

    if (this._authService === undefined) {
      this._authService = this._injector.get(IAuthService);
    }
    if (this._authService !== undefined) {
      const token = this._authService.getToken();
      if (token) {
        const tokenValue = 'Bearer ' + token;
        if (req.url.includes('pictofit.com')) {
          //TODO: Temporary solution to not set auth header for calling the MS blob storage, auth interceptor will not be required later on
          requestToForward = req.clone({
            setHeaders: { Authorization: tokenValue },
          });
        }
      }
    } else {
      this._logger.debug('AuthService undefined: NO auth header!');
    }

    return next.handle(requestToForward);
  }
}
