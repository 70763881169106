<span (click)="toggleInputState()" (blur)="toggleInputState()" *ngIf="label">
  <button class="icon" [ngClass]="badge ? 'active' : ''">
    <span class="oi oi-{{ icon | defaultValue : 'list' }}"></span>
    <img
      src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
    />
  </button>
  <span class="label">{{ label }}</span>
  <span class="badgey" *ngIf="badge">{{ badge | take }}</span>
</span>

<rr-card class="input input-{{ state }}">
  <ng-container #inputCard></ng-container>
</rr-card>
