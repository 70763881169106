<div class="alerts-container top-alert" *ngIf="simpleAlerts.length">
  <div *ngFor="let alert of simpleAlerts">
    <alert
      class="alert-box"
      [type]="alert.type"
      [dismissible]="alert.dismissible"
      [dismissOnTimeout]="
        alert.timeout && alert.timeout === -1 ? null : alert.timeout
      "
      (onClosed)="onDismissAlert(alert)"
      >{{ alert.message }}</alert
    >
  </div>
</div>
