import { NgModule } from '@angular/core';
import { TableFiltersContainerComponent } from './components/table-filters-container/table-filters-container.component';
import { TextSearchFilterComponent } from './components/text-search-filter/text-search-filter.component';
import { DateRangeFilterComponent } from './components/date-range-filter/date-range-filter.component';
import { DropdownFilterComponent } from './components/dropdown-filter/dropdown-filter.component';
import { RadioSelectFilterComponent } from './components/radio-select-filter/radio-select-filter.component';
import { CheckboxSelectFilterComponent } from './components/checkbox-select-filter/checkbox-select-filter.component';
import { FormsModule } from '@angular/forms';
import { RRFormInputsModule } from '../form-inputs/form-inputs.module';
import { MultiSelectFilterComponent } from './components/multi-select-filter/multi-select-filter.component';
import { TagsFilterComponent } from './components/tags-filter/tags-filter.component';
import { RRTagsModule } from '../tags/tags.module';
import { RRSharedModule } from '../shared-module/shared.module';
import { RRBootstrapModule } from '../bootstrap/bootstrap.module';

@NgModule({
  declarations: [
    TableFiltersContainerComponent,
    TextSearchFilterComponent,
    DateRangeFilterComponent,
    DropdownFilterComponent,
    RadioSelectFilterComponent,
    CheckboxSelectFilterComponent,
    MultiSelectFilterComponent,
    TagsFilterComponent,
  ],
  imports: [
    RRSharedModule,
    RRBootstrapModule,
    RRFormInputsModule,
    FormsModule,
    RRTagsModule,
  ],
  exports: [
    TableFiltersContainerComponent,
    TextSearchFilterComponent,
    DateRangeFilterComponent,
    DropdownFilterComponent,
    RadioSelectFilterComponent,
    CheckboxSelectFilterComponent,
    MultiSelectFilterComponent,
    TagsFilterComponent,
  ],
})
export class RRTableFiltersModule {}
