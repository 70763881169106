import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class AddItemComponent {
  @Input() info: string;
  @Output() addItemClicked: EventEmitter<boolean>;

  constructor() {
    this.addItemClicked = new EventEmitter<boolean>();
  }
}
