import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAuthService } from '../../api';

@Component({
  selector: 'rr-signin-redirect-callback',
  template: '<div></div>',
})
export class SigninRedirectCallbackComponent implements OnInit {
  constructor(private _authService: IAuthService, private _router: Router) {}

  async ngOnInit(): Promise<void> {
    this._authService.signinRedirectCallback().then(() => {
      this._router.navigate(['/'], { replaceUrl: true });
    });
  }
}
