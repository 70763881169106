import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MIMETypeDTO } from '@reactivereality/cs-api-sdk';
import {
  FILE_UPLOAD_STATE,
  UploadData,
} from '../../core/interfaces/upload-data.interface';
import { IFileInformation, IFileService } from '../../data';
import { FileUploadEntryComponent } from '../file-upload-entry/file-upload-entry.component';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { v4 as uuid } from 'uuid';
import { RequestFilesHelper } from '../../core/helpers/request/request-files.helper';
import { Observable, Subject } from 'rxjs';
import { ILogger } from '../../logging';
import { AlertService } from '../../core/services/alert.service';

@Component({
  selector: 'app-file-upload-full',
  templateUrl: './file-upload-full.component.html',
  styleUrls: ['./file-upload-full.component.scss'],
  standalone: true,
  imports: [CommonModule, FileUploadComponent, FileUploadEntryComponent],
})
export class FileUploadFullComponent implements OnInit {
  @Input() requestId: uuid;
  @Input() title: string;
  @Input() multipleFiles: boolean = true;
  @Input() initialFiles: Observable<UploadData[]>;
  @Output() filesSelected: EventEmitter<UploadData[]> = new EventEmitter();

  private _validFiletypes: any[];
  public selectedFiles: UploadData[] = [];

  constructor(
    private _fileService: IFileService<any>,
    private _logger: ILogger,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this._validFiletypes = [];
    if (this.initialFiles) {
      this.initialFiles.subscribe((f) => {
        this.selectedFiles = f;
      });
    }
    this._fileService.getMimeTypes().then((mimeTypes: MIMETypeDTO[]) => {
      mimeTypes?.forEach((type) => {
        if (type.name !== undefined && type.name !== null && type.name !== '') {
          this._validFiletypes.push(type.name);
        }
      });
    });
  }

  onFilesSelected(files: File[] | Event) {
    if (files instanceof Event) {
      const target = <HTMLInputElement>files.target;
      this.prepareForUpload(Array.from(target.files));
      this.filesSelected.emit(this.selectedFiles);
    } else {
      if (files && files.length > 0) {
        this.prepareForUpload(Array.from(files));
        this.filesSelected.emit(this.selectedFiles);
      }
    }
  }

  singleFileDeleteClicked($event: UploadData) {
    this.selectedFiles = this.selectedFiles.filter((value) => {
      return value.fileinformation.id !== $event.fileinformation.id;
    });
    this.filesSelected.emit(this.selectedFiles);
  }

  removeAllClicked() {
    this.selectedFiles = [];
    this.filesSelected.emit(this.selectedFiles);
  }

  public prepareForUpload(files: File[]) {
    files.forEach((file) => {
      this._logger.debug('File:', file);
    });
    const failedFiles: Array<File> = [];
    files.forEach((file) => {
      if (file.type === '' || this._validFiletypes.includes(file.type)) {
        const fileInfo: IFileInformation = {
          id: uuid(),
          typeId: RequestFilesHelper.MannequinShotFileType.id,
          configurationId: undefined,
          fileName: file.name,
          mimeType: file.type === '' ? 'application/octet-stream' : file.type,
        };
        this.selectedFiles.push({
          fileinformation: fileInfo,
          file: file,
          uploadState: new Subject<FILE_UPLOAD_STATE>(),
        });
      } else {
        failedFiles.push(file);
      }
    });

    this._logger.debug('Failed files', failedFiles);
    if (failedFiles.length > 0) {
      this.alertService.showErrorAlert(
        `Failed to add the following files, because they are of the wrong type: ${failedFiles
          .map((f) => f.name)
          .join(', ')}`,
      );
    }
  }
}
