import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectedAccessories } from '../accessory-selection.component';

@Injectable({
  providedIn: 'root',
})
export abstract class IAccessorySelectionService {
  public abstract selectedGarments$: Observable<SelectedAccessories>;
  public abstract currentSelectedGarments: () => SelectedAccessories;
  public abstract setSelectedGarments: (
    accessories: SelectedAccessories,
  ) => void;
  public abstract updateGarmentForSlot: (ACCESSORY_SLOT, AccessoryDTO) => void;
}
