import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RRCardComponent } from './card/card.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [RRCardComponent],
  imports: [CommonModule, RouterModule],
  exports: [RRCardComponent],
})
export class RRLayoutModule {}
