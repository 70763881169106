import { NgModule } from '@angular/core';
import { RRBootstrapModule } from '../bootstrap/bootstrap.module';
import { RRSharedModule } from '../shared-module/shared.module';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [RRSharedModule, RRBootstrapModule],
  exports: [BreadcrumbComponent],
})
export class RRBreadcrumbModule {}
