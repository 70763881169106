<h3>Change Password</h3>
<p>
  You will receive a reset notification email with instructions on how to reset
  your password.
</p>
<rr-button
  class="btn btn-primary"
  [action]="triggerReset()"
  (done)="done($event)"
  >Reset Password</rr-button
>
