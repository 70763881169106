import { Component, Input, OnChanges } from '@angular/core';
import { RRAlertModel } from '../../models/alert.model';

@Component({
  selector: 'rr-simple-alert',
  templateUrl: './simple-alert.component.html',
  styleUrls: ['./simple-alert.component.scss'],
})
export class SimpleAlertComponent implements OnChanges {
  public simpleAlerts: RRAlertModel[];

  @Input() alerts: RRAlertModel;

  constructor() {
    this.simpleAlerts = [];
  }

  ngOnChanges() {
    if (this.alerts) {
      if (!this.alerts.timeout) {
        this.alerts.timeout = 3000;
      }
      this.simpleAlerts.push(this.alerts);
    }
  }
  onDismissAlert(dismissedAlert: any): void {
    this.simpleAlerts = this.simpleAlerts.filter(
      (alert) => alert !== dismissedAlert,
    );
  }
}
