import { NgModule } from '@angular/core';
import { FilteredTableComponent } from './components/filtered-table/filtered-table.component';
import { RRSharedModule } from '../shared-module/shared.module';
import { RRBootstrapModule } from '../bootstrap/bootstrap.module';
import { RRTableFiltersModule } from '../table-filters/table-filters.module';
import { RRTableContainerModule } from '../table-container/table-container.module';
import { RRSpinnerModule } from '../spinner/spinner.module';
import { RRProgressbarModule } from '../progressbar/progressbar.module';

@NgModule({
  declarations: [FilteredTableComponent],
  imports: [
    RRSharedModule,
    RRBootstrapModule,
    RRTableFiltersModule,
    RRTableContainerModule,
    RRSpinnerModule,
    RRProgressbarModule,
  ],
  exports: [FilteredTableComponent],
})
export class RRFilteredTableModule {}
