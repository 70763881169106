import {
  AfterViewInit,
  Component,
  Input,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {
  PRODUCT_TYPE,
  ProductHelper,
} from 'projects/content-service-cms/src/app/data';
import { RequestRecord } from '../../request-list.page.component';

@Pipe({
  name: 'prettyProductType',
})
export class PrettyProductTypePipe implements PipeTransform {
  transform(record: RequestRecord) {
    if (record.linked && record.linked.length > 0) {
      return 'Multiple';
    }
    return ProductHelper.mapProductTypeFromEnumToStringName(record.productType);
  }
}

@Component({
  selector: 'rr-product-type',
  templateUrl: './product-type.component.html',
  styleUrls: ['./product-type.component.scss'],
})
export class ProductTypeComponent implements AfterViewInit {
  @Input() record: RequestRecord;

  labelProductType: string = '';

  constructor() {}

  ngAfterViewInit(): void {
    if (!this.record) return;

    this.labelProductType = `label-${this.classForType(
      this.record.productType,
    )}`;
  }

  classForType(_state: PRODUCT_TYPE) {
    return 'state-gray';
  }
}
