import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, combineLatest, filter, map } from 'rxjs';
import { IAuthService } from '../../../auth';
import { IUserService } from '../../../data';
import { ProfileState, ProfileStore } from '../../profile-state';

export interface MutableUserInfo {
  email: string;
  givenName: string;
  familyName: string;
}

export interface ImmutableUserInfo {
  id: string;
}

export type UserInfo = ImmutableUserInfo & Partial<MutableUserInfo>;

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent implements OnInit {
  @ViewChild('userInfo') userInfoView: TemplateRef<any>;
  @ViewChild('apiKeys') apiKeysView: TemplateRef<any>;
  @ViewChild('security') securityView: TemplateRef<any>;

  protected profileStore: ProfileStore;
  public user$: Observable<UserInfo>;

  constructor(public auth: IAuthService, public userService: IUserService) {
    this.profileStore = new ProfileStore();

    this.user$ = combineLatest([
      userService.me.pipe(
        filter((me) => me !== undefined),
        map(({ id, email, firstName, lastName }) => ({
          id,
          email,
          givenName: firstName,
          familyName: lastName,
        })),
      ),
      auth.loggedUser$.pipe(
        filter((u) => u?.profile?.email !== undefined),
        map((u) => u.profile.email),
      ),
    ]).pipe(
      map(([user, email]) => ({
        ...user,
        email,
      })),
    );
  }

  showTemplate(currentPage: ProfileState['currentPage']): TemplateRef<any> {
    switch (currentPage) {
      case 'ApiKeys':
        return this.apiKeysView;
      case 'Security':
        return this.securityView;
      default:
        return this.userInfoView;
    }
  }

  ngOnInit(): void {}
}
