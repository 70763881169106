import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CalendarComponent } from 'projects/web-ui-component-library/src/public-api';
import { BehaviorSubject, map } from 'rxjs';
import { Filters } from '../../../..';
import {
  FilterBarElement,
  FilterBarInputElement,
} from '../filter-bar-element.component';

type DateRange = { from: Date; to: Date };

@Component({
  selector: 'rr-date-input-element',
  templateUrl: './date-input-element.component.html',
  styleUrls: ['./date-input-element.component.scss'],
})
export class DateInputElementComponent<
  F extends Filters,
  K extends keyof F = keyof F,
> implements AfterViewInit, FilterBarInputElement<DateRange | null>, OnInit
{
  @Input() filterValueSub: BehaviorSubject<DateRange>;
  @ViewChild('calendar') calendar: CalendarComponent;
  @Output() valueChanged: EventEmitter<DateRange> = new EventEmitter();

  private element: FilterBarElement<F, K>;

  constructor() {}

  setValue(value: DateRange) {
    if (!value?.from || !value?.to) {
      this.calendar.selected = {
        from: null,
        to: null,
      };
      this.updateBadge();
      return;
    }

    this.calendar.selected = value;
    this.updateBadge();
  }

  private today = new Date();
  presets = [
    {
      text: 'Today',
      range: () => ({
        from: new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate(),
          0,
          0,
          0,
        ),
        to: new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate(),
          23,
          59,
          59,
        ),
      }),
    },
    {
      text: 'Yesterday',
      range: () => ({
        from: new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate() - 1,
          0,
          0,
          0,
        ),
        to: new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate() - 1,
          23,
          59,
          59,
        ),
      }),
    },
    {
      text: 'This Week',
      range: () => {
        const from = this.getMondayForDate(this.today);
        const to = new Date(from);
        to.setDate(to.getDate() + 7);
        to.setSeconds(-1);

        return {
          from,
          to,
        };
      },
    },
    {
      text: 'Last Week',
      range: () => {
        const from = this.getMondayForDate(this.today);
        from.setDate(from.getDate() - 7);
        const to = new Date(from);
        to.setDate(to.getDate() + 7);
        to.setSeconds(-1);

        return {
          from,
          to,
        };
      },
    },
    {
      text: 'This Month',
      range: () => {
        const from = new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          1,
        );
        const to = new Date(from);
        to.setMonth(to.getMonth() + 1);
        to.setSeconds(-1);

        return {
          from,
          to,
        };
      },
    },
    {
      text: 'Last Month',
      range: () => {
        const from = new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          1,
        );
        from.setMonth(from.getMonth() - 1);
        const to = new Date(from);
        to.setMonth(to.getMonth() + 1);
        to.setSeconds(-1);

        return {
          from,
          to,
        };
      },
    },
  ];

  getMondayForDate(date: Date) {
    const monday = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    );

    monday.setDate(monday.getDate() - monday.getDay());
    monday.setDate(monday.getDate() + 1);

    return monday;
  }

  selectPreset(preset?: () => DateRange) {
    const { from, to } = preset();

    this.calendar.selected = { from, to };
    this.valueChanged.emit(this.calendar.selected);
  }

  clearRange() {
    this.calendar.selected = null;
    this.valueChanged.emit(null);
  }

  getValue() {
    return this.calendar.selected;
  }

  onChange(date: DateRange) {
    this.valueChanged.emit(date);
  }

  init(element: FilterBarElement<F, K>): void | Promise<void> {
    this.element = element;
  }

  updateBadge() {
    if (
      this.element &&
      this.calendar.selected?.from &&
      this.calendar.selected?.to
    ) {
      this.element.badge = true;
      return;
    }
    this.element.badge = false;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.filterValueSub
      .pipe(map((v) => this.fixDates(v)))
      .subscribe((value) => this.setValue(value));
  }

  private fixDates(value: DateRange): DateRange {
    if (typeof value?.from === 'string') value.from = new Date(value.from);
    if (typeof value?.to === 'string') value.to = new Date(value.to);

    return value;
  }
}
