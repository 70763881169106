import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncSubject, Observable, lastValueFrom } from 'rxjs';
import { ILogger } from '../../../logging';

import {
  IApiConfiguration,
  IAppConfiguration,
  IConfiguration,
  IConfigurationService,
} from '../api';
import { sleep } from '../../utils/utilities';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService implements IConfigurationService {
  private appConfiguration: AsyncSubject<IAppConfiguration | undefined>;

  private configuration: AsyncSubject<IConfiguration | undefined>;

  constructor(private httpClient: HttpClient, private logger: ILogger) {
    this.appConfiguration = new AsyncSubject();
    this.configuration = new AsyncSubject();

    this.appConfiguration.subscribe(
      (cfg) => {
        if (cfg === undefined) {
          this.logger.error(`Retreived discovery document is undefined!`);
          throw new Error(`Retreived discovery document is undefined!`);
        } else {
          this.loadApiConfiguration(0, cfg);
        }
      },
      (error) => {
        this.logger.error(
          `Failed to retreive api configuration, because the discover document wasn't found!`,
          error,
        );
      },
    );

    this.loadConfiguration(0);
  }
  private async loadApiConfiguration(counter: number, cfg: IAppConfiguration) {
    if (counter > 5) {
      this.configuration.error(
        new Error(
          `Failed to receive api configuration, can't start webshop application!`,
        ),
      );
      this.configuration.complete();
      return;
    }

    await sleep(counter * 100);

    this.httpClient
      .get<IApiConfiguration>(cfg.config, { responseType: 'json' })
      .subscribe(
        (api) => {
          this.logger.debug(`Api configuration found`, cfg);
          this.configuration.next({
            appConfiguration: cfg,
            apiConfiguration: api,
          });
          this.configuration.complete();
        },
        (error) => {
          this.logger.error(`Failed to retreive api configuration`, error);
          this.loadApiConfiguration(counter + 1, cfg);
        },
      );
  }

  private async loadConfiguration(counter: number) {
    if (counter > 5) {
      this.appConfiguration.error(
        new Error(
          `Failed to receive discovery document, can't start webshop application!`,
        ),
      );
      this.appConfiguration.complete();
      return;
    }

    await sleep(counter * 100);

    this.httpClient
      .get<IAppConfiguration>('/.well-known/app-configuration')
      .subscribe(
        (doc) => {
          this.logger.debug(`Discover document found`, doc);
          this.appConfiguration.next(doc);
          this.appConfiguration.complete();
        },
        (error) => {
          this.logger.warn(`Failed to retreive discovery document`, error);
          this.loadConfiguration(counter + 1);
        },
      );
  }

  public getConfiguration(): Promise<IConfiguration | undefined> {
    return lastValueFrom(this.configuration);
  }

  public getObservableConfiguration(): Observable<IConfiguration | undefined> {
    return this.configuration.asObservable();
  }
}
