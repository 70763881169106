import { GraphQLClient } from 'graphql-request';
import * as publicSdk from './public-sdk';
import * as merchantSdk from './merchant-sdk';

type MerchantSdk = merchantSdk.Sdk;
type PublicSdk = publicSdk.Sdk;

function getMerchantSdk(
  token: string,
  uri: string,
): MerchantSdk {
  const client = new GraphQLClient(uri);
  client.setHeader('Authorization', `Bearer ${token}`);
  return merchantSdk.getSdk(client);
}

function getPublicSdk(
  token: string,
  uri: string,
): PublicSdk {
  const client = new GraphQLClient(uri);
  return publicSdk.getSdk(client);
}

export * as MerchantSDK from './merchant-sdk';
export * as PublicSDK from './public-sdk';

export { getMerchantSdk, MerchantSdk, getPublicSdk, PublicSdk };
