<div class="component comp-unauthorized">
  <div class="container">
    <div class="inner">
      <div class="row justify-content-center">
        <div class="col-3 text-center">
          <br />
          <div class="bad-request-container" *ngIf="errorCode === '400'">
            <p class="text-center preline">(400)</p>
            <h1 class="small-headline">Bad Request</h1>
            <p>
              The server cannot or will not process the request due to an
              apparent client error
            </p>
          </div>
          <div
            class="unauthorized-container"
            *ngIf="!errorCode || errorCode === '401'"
          >
            <div>
              <p class="text-center preline">(401)</p>
              <h1 class="small-headline">Unauthorized</h1>
              <button
                (click)="login()"
                type="button"
                class="btn btn-outline-white black-color btn-black"
              >
                <span
                  class="oi oi-lock-unlocked"
                  tooltip="Log in"
                  placement="bottom"
                >
                </span>
                <br />
                Login
              </button>
            </div>
            <br />
            <div>
              <p>You must be logged in to continue.</p>
            </div>
          </div>
          <div class="forbidden-container" *ngIf="errorCode === '403'">
            <p class="text-center preline">(403)</p>
            <h1 class="small-headline">Forbidden</h1>
            <p>Your are not allowed to access this information.</p>
            <button (click)="clickBack()" class="btn btn-secondary">
              back
            </button>
          </div>
          <div class="not-found-container" *ngIf="errorCode === '404'">
            <p class="text-center preline">(404)</p>
            <h1 class="small-headline">Not Found</h1>
            <p>The requested resource could not be found.</p>
          </div>
          <div class="not-found-container" *ngIf="errorCode === '500'">
            <p class="text-center preline">(500)</p>
            <h1 class="small-headline">Internal Server Error</h1>
            <p>Server Error: Please contact the administrator</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
