import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  RRFilterBarComponent,
  RRIFilterOutput,
  RR_FILTER_TYPE,
} from 'projects/web-ui-component-library/src';
import {
  AbstractFilterService,
  SelectedFilters,
  buildInputElement,
} from 'projects/web-ui-component-library/src/lib/filter';
import { DateInputElementComponent } from 'projects/web-ui-component-library/src/lib/filter/components/filter-bar/filter-bar-element/date-input-element/date-input-element.component';
import { SelectInputElementComponent } from 'projects/web-ui-component-library/src/lib/filter/components/filter-bar/filter-bar-element/select-input-element/select-input-element.component';
import { TextInputElementComponent } from 'projects/web-ui-component-library/src/lib/filter/components/filter-bar/filter-bar-element/text-input-element/text-input-element.component';
import { Observable } from 'rxjs';
import { IAppState } from '../../../app.state';
import {
  PRODUCT_TYPE,
  ProductHelper,
  REQUEST_STATE_CMS,
  RequestStateHelper,
} from '../../../data';
import { ILogger } from '../../../logging';
import {
  clearFilters,
  filtersChange,
} from '../../store/requests-overview.actions';
import { selectRequestsFilter } from '../../store/requests-overview.selectors';

export type RequestFilters = {
  createdAt: { from: Date; to: Date };
  dueAt: { from: Date; to: Date };
  name: string;
  sku: string;
  productTypeId: PRODUCT_TYPE[];
  stateId: REQUEST_STATE_CMS[];
  tags: { name: string; uuid: string }[];
  customerId: string;
};

// Change the value output of the filter components to match the filter store...
function mapFilterValue<K extends keyof RequestFilters = keyof RequestFilters>(
  fieldName: K,
  value: RequestFilters[K],
) {
  switch (fieldName) {
    // the date formats need changing to a numeric tuple...
    case 'createdAt':
    case 'dueAt':
      if (
        value &&
        typeof value === 'object' &&
        'from' in value &&
        'to' in value
      ) {
        return [
          new Date(value.from).getTime() / 1000,
          new Date(value.to).getTime() / 1000,
        ];
      }
      return null;
    case 'stateId':
      if (Array.isArray(value)) {
        if (value.length === 0) return null;

        return value
          .map((v) =>
            // InProgress results in many values... ?
            RequestStateHelper.mapCustomerStateToInternalState(v),
          )
          .flat();
      }
      break;
  }

  // no change required
  return value;
}
/*
  This function will convert the RequestFilters into an array of
  objects compatible with the API service.
*/
export function toRRIFilterArray(
  selectedFilters: SelectedFilters<RequestFilters>,
): RRIFilterOutput<any>[] {
  const newFilters: RRIFilterOutput<any>[] = [];
  for (const fieldName in selectedFilters) {
    const value = mapFilterValue(
      fieldName as keyof RequestFilters,
      selectedFilters[fieldName],
    );
    if (!value) continue;

    switch (fieldName) {
      case 'createdAt':
      case 'dueAt':
        if (
          isNaN(value[0]) ||
          isNaN(value[1]) ||
          value[0] === 0 ||
          value[1] === 0
        )
          break;
        newFilters.push({
          type: RR_FILTER_TYPE.DATE_RANGE,
          filterBy: {
            fieldName,
            value,
            defaultValue: null,
          },
        });
        break;
      case 'sku':
      case 'customerId':
      case 'name':
        newFilters.push({
          type: RR_FILTER_TYPE.TEXT_SEARCH,
          filterBy: {
            fieldName,
            value,
            defaultValue: null,
          },
        });
        break;
      case 'productTypeId':
        newFilters.push({
          type: RR_FILTER_TYPE.DROPDOWN_SELECT,
          filterBy: {
            fieldName,
            value,
            defaultValue: null,
          },
        });
        break;
      case 'stateId':
        newFilters.push({
          type: RR_FILTER_TYPE.DROPDOWN_SELECT,
          filterBy: {
            fieldName,
            value,
            defaultValue: null,
          },
        });
        break;
    }
  }

  return newFilters;
}

class RequestFilterService extends AbstractFilterService<RequestFilters> {
  constructor(private store: Store<IAppState>) {
    super({
      createdAt: {
        id: 'createdAt',
        label: 'Created',
        icon: 'calendar',
        ...buildInputElement(DateInputElementComponent),
      },
      dueAt: {
        id: 'dueAt',
        label: 'Requested',
        icon: 'calendar',
        ...buildInputElement(DateInputElementComponent),
      },
      sku: {
        id: 'sku',
        label: 'SKU',
        searchable: true,
        ...buildInputElement(TextInputElementComponent),
      },
      name: {
        id: 'name',
        label: 'Name',
        searchable: true,
        ...buildInputElement(TextInputElementComponent),
      },
      tags: {
        id: 'tags',
        icon: 'tags',
        label: 'Tags',
        searchable: true,
        ...buildInputElement<
          SelectInputElementComponent<RequestFilters, 'tags'>
        >(SelectInputElementComponent, {
          formatItem: (item) => ({
            text: item.name,
          }),
          compareItem: (a, b) => a && b && a.uuid === b.uuid,
          selector: store.select((state) => state.requestOverview.tags),
        }),
      },
      productTypeId: {
        id: 'productTypeId',
        label: 'Type',
        icon: 'person',
        ...buildInputElement<
          SelectInputElementComponent<RequestFilters, 'productTypeId'>
        >(SelectInputElementComponent, {
          formatItem: (item) => {
            return {
              text: ProductHelper.mapProductTypeFromEnumToStringName(item),
            };
          },
          selector: new Observable((observer) => {
            observer.next(enumKeys(PRODUCT_TYPE).map((k) => PRODUCT_TYPE[k]));
            observer.complete();
          }),
        }),
      },
      customerId: {
        id: 'customerId',
      },
      stateId: {
        id: 'stateId',
        label: 'Status',
        icon: 'clock',
        ...buildInputElement<
          SelectInputElementComponent<
            RequestFilters,
            'stateId',
            REQUEST_STATE_CMS[]
          >
        >(SelectInputElementComponent, {
          formatItem: (item) => {
            return {
              text: RequestStateHelper.getCustomerStateLabel(item),
            };
          },
          selector: new Observable((observer) => {
            observer.next(
              enumKeys(REQUEST_STATE_CMS).map((k) => REQUEST_STATE_CMS[k]),
            );
            observer.complete();
          }),
        }),
      },
    });
  }
}

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

@Component({
  selector: 'app-request-list-action-bar',
  templateUrl: './request-list-action-bar.component.html',
  styleUrls: ['./request-list-action-bar.component.scss'],
})
export class RequestListActionBarComponent {
  @Input() filterText: string;
  @Output() filterChange: EventEmitter<SelectedFilters<RequestFilters>> =
    new EventEmitter();
  @ViewChild('filterBar') filterBar: RRFilterBarComponent<RequestFilters>;

  requestFilterService: RequestFilterService;

  constructor(public store: Store<IAppState>, private logger: ILogger) {
    this.requestFilterService = new RequestFilterService(store);

    const requestFilters = this.store.select(selectRequestsFilter);
    requestFilters.subscribe((filters) => {
      this.requestFilterService.filterValues.next(filters);
    });

    this.requestFilterService.filterValues.subscribe((filters) => {
      this.logger.debug('RequestListActionBar', 'filterValues:', filters);
      this.store.dispatch(filtersChange({ filters }));
    });
  }
  clearFilterValues() {
    this.store.dispatch(clearFilters());
  }
}
