<div class="request-overview d-flex flex-column">
  <div class="overview-scroll d-flex flex-column">
    <rr-card id="request-list">
      Your account was not activated yet. Our team will do this as soon as
      possible.<br />
      <hr />
      If you have any questions, please feel free to reach out to
      <a href="mailto:support@reactivereality.com" target="_blank"
        >support&#64;reactivereality.com</a
      >
    </rr-card>
  </div>
</div>
