import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import { uniqBy } from 'lodash';

import { Observable, Subject, takeUntil } from 'rxjs';
import { FilterInput } from '../interfaces/filter.interface';

type Item<R> = R extends Array<infer U> ? U : never;
type SelectableItem<R> = { text: string; value: Item<R>; selected?: boolean };

@Component({
  selector: 'rr-select-filter-input-element',
  templateUrl: './select-input-element.component.html',
  styleUrls: ['./select-input-element.component.scss'],
})
export class SelectInputElementComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() inputData$: Observable<
    FilterInput<
      {
        text: string;
        value: string;
      }[]
    >
  >;
  @Input() filterDelete$: Observable<boolean>;
  @Input() reset$: Observable<string>;

  @HostBinding('hidden')
  isHidden: boolean = true;

  public onDataChanged: (value: string) => void = (_) => {};
  public onBackClicked: () => void = () => {};

  private _onDestroy: Subject<void> = new Subject();
  private _id: string = '';

  items: SelectableItem<any>[] = [];
  filtered: Item<any>[] = [];

  @ViewChild('inputText') searchInput: ElementRef<HTMLInputElement>;
  searchInputValue: string = '';

  ngOnInit(): void {
    this._onDestroy = new Subject();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onDestroy = undefined;
  }

  ngAfterViewInit(): void {
    this.reset$.pipe(takeUntil(this._onDestroy)).subscribe((filterId) => {
      if (this._id === filterId || filterId === '') {
        this.items = [];
      }
    });
    this.inputData$.pipe(takeUntil(this._onDestroy)).subscribe((data) => {
      this.onDataChanged = data.onChange;
      this.onBackClicked = data.onBackClicked;
      this.isHidden = data.hidden;
      this._id = data.id;
      if (data.data) {
        this.items = uniqBy([...this.items, ...data.data], (o: any) => {
          return o.value;
        });
      }
    });
  }

  get filteredItems() {
    return this.searchInputValue.length
      ? this.items.filter((i) =>
          i.text.toLowerCase().includes(this.searchInputValue.toLowerCase()),
        )
      : this.items;
  }

  setValue(value) {
    this.filtered = value;
    if (!this.items || !this.filtered) return;
    this.updateSelectedItems();
  }

  updateSelectedItems() {
    if (!this.filtered) return;

    this.items = this.items.map((item) => {
      return {
        ...item,
        selected: this.filtered.some((v) => v === item.value),
      };
    });

    this.sortItems();
  }

  updateTagFilter() {
    this.searchInputValue = this.searchInput.nativeElement.value;
  }

  sortItems() {
    if (!this.items) return;

    this.items = this.items.sort((a, b) => {
      if (a.selected && !b.selected) return -1;
      if (!a.selected && b.selected) return 1;

      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;

      return 0;
    });
  }

  getValue() {
    return (this.items ?? [])
      .filter((item) => item.selected)
      .map((item) => item.value) as any; // shouldn't need this hack
  }

  toggleResultCheck(
    item: SelectableItem<Item<any>>,
    event: Event | MouseEvent,
  ) {
    event.stopPropagation();

    this.items = this.items.map((i) => {
      if (i.value !== item.value) return i;
      return {
        ...i,
        selected: i.selected !== undefined ? !i.selected : true,
      };
    });

    this.sortItems();
    this.updateFilter();
  }
  updateFilter() {
    const selected = this.items.filter(
      (value) => value.selected !== undefined && value.selected !== false,
    );

    this.onDataChanged(
      JSON.stringify(
        selected.map((v) => {
          return { value: v.text, id: v.value };
        }),
      ),
    );
  }
}
