import { NgModule } from '@angular/core';
import { RRSharedModule } from '../shared-module/shared.module';
import { RRFilterComponent } from './components/filter/filter.component';
import { RRFilterBarComponent } from './components/filter-bar/filter-bar.component';
import { FilterBarElementComponent } from './components/filter-bar/filter-bar-element/filter-bar-element.component';
import { RRPipesModule } from '../pipes/pipes.module';
import { RRLayoutModule } from '../layout/layout.module';
import { SearchInputElementComponent } from './components/filter-bar/filter-bar-element/search-input-element/search-input-element.component';
import { DateInputElementComponent } from './components/filter-bar/filter-bar-element/date-input-element/date-input-element.component';
import { TextInputElementComponent } from './components/filter-bar/filter-bar-element/text-input-element/text-input-element.component';
import { RRCalendarModule } from '../calendar/calendar.module';

@NgModule({
  declarations: [
    RRFilterComponent,
    RRFilterBarComponent,
    FilterBarElementComponent,
    SearchInputElementComponent,
    DateInputElementComponent,
    TextInputElementComponent,
  ],
  imports: [RRSharedModule, RRPipesModule, RRLayoutModule, RRCalendarModule],
  exports: [RRFilterComponent, RRFilterBarComponent],
})
export class RRFilterModule {}
