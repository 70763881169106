import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  combineLatest,
  concatMap,
  debounceTime,
  filter,
  from,
  map,
  of,
  switchMap,
} from 'rxjs';
import { IAppState } from '../../app.state';
import { IRequestService } from '../../data';
import { ITagService } from '../../data/api';
import { toRRIFilterArray } from '../components/request-list-action-bar/request-list-action-bar.component';
import { RequestOverviewService } from '../services/request-overview.service';
import {
  filtersChange,
  loadFiltersAction,
  loadFiltersCompleteAction,
  loadRequests,
  loadRequestsAggregate,
  loadTagsAction,
  loadTagsCompleteAction,
  reloadRequestsAction,
  retrievedRequests,
  retrievedRequestsAggregate,
  retrievedRequestsAggregateWithError,
  retrievedRequestsWithError,
  showLoaderAction,
  storeFiltersAction,
  storeFiltersCompleteAction,
} from './requests-overview.actions';
import {
  selectRequestsOverviewFilters,
  selectRequestsOverviewPagination,
  selectRequestsOverviewSort,
} from './requests-overview.selectors';
import { setCurrentCustomerAction } from '../../customer/store/customer.actions';

const formatTags = (
  tags: { name: string; uuid: string }[],
): { [tag: string]: string[] } | undefined => {
  if (!tags || tags.length === 0) return undefined;

  return tags.reduce((acc, curr) => {
    acc[curr.uuid] = ['*'];
    return acc;
  }, {});
};

@Injectable()
export class RequestsOverviewEffects {
  loadRequests$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadRequests),
      switchMap(({ filters, pagination, sort }) => {
        return this._newRequestService
          .getData({
            pagination,
            sort,
            filters: toRRIFilterArray(filters),
            tags: formatTags(filters?.tags),
          })
          .pipe(
            map(
              (data) =>
                retrievedRequests({
                  recordCount: data.pagination.recordCount,
                  pageCount: data.pagination.pageCount,
                  requests: data.results,
                }),
              catchError(() => of(retrievedRequestsWithError)),
            ),
          );
      }),
    );
  });

  loadRequestsAggregate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadRequestsAggregate),
      switchMap(({ filters }) =>
        this._newRequestService
          .getRequestAggregates(
            toRRIFilterArray(filters),
            formatTags(filters?.tags),
          )
          .pipe(
            map(
              (data) => retrievedRequestsAggregate({ requestAggregates: data }),
              catchError(() => of(retrievedRequestsAggregateWithError)),
            ),
          ),
      ),
    );
  });

  filtersChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(filtersChange),
      map(({ filters }) => storeFiltersAction({ filters })),
    );
  });

  loadOnChange$ = createEffect(() => {
    return combineLatest([
      this.store.select(selectRequestsOverviewFilters),
      this.store.select(selectRequestsOverviewPagination),
      this.store.select(selectRequestsOverviewSort),
    ]).pipe(
      debounceTime(500),
      concatMap(([filters, pagination, sort]) => [
        loadRequestsAggregate({ filters }),
        loadRequests({ pagination, filters, sort }),
        loadTagsAction({ customerId: filters?.customerId }),
      ]),
    );
  });

  storeFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(storeFiltersAction),
      switchMap(({ filters }) =>
        this._requestOverviewService.storeFilters(filters),
      ),
      map(() => storeFiltersCompleteAction()),
    );
  });

  loadFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFiltersAction),
      switchMap(() => this._requestOverviewService.loadFilters()),
      map((filters) => loadFiltersCompleteAction({ filters })),
    );
  });

  loadTags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadTagsAction),
      switchMap(({ customerId }) => {
        const tags = customerId
          ? this._tagsService.getAllTagsByCustomerId(customerId)
          : this._tagsService.getAllTags();
        return from(tags).pipe(
          map(
            (data) =>
              loadTagsCompleteAction({
                tags: data.map((t) => ({ name: t.name, uuid: t.id })),
              }),
            catchError(() => of(loadTagsCompleteAction({ tags: [] }))),
          ),
        );
      }),
    );
  });

  /*
    In order to prevent losing all filters when loading the app,
    the change customer action should only fire once the existing
    filters have been loaded. Only then can we determine if the customer
    filter value has changed and should reset the filters...
  */
  changeCustomerAction$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(setCurrentCustomerAction)),
      this.actions$.pipe(ofType(storeFiltersAction)),
    ]).pipe(
      filter(
        ([{ currentCustomer }, { filters }]) =>
          currentCustomer &&
          (!filters || filters.customerId !== currentCustomer.id),
      ),
      map(([{ currentCustomer }]) => {
        return filtersChange({
          filters: {
            customerId: currentCustomer.id,
          },
        });
      }),
    );
  });

  reloadRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reloadRequestsAction),
      switchMap(() =>
        combineLatest([
          this.store.select(selectRequestsOverviewFilters),
          this.store.select(selectRequestsOverviewPagination),
          this.store.select(selectRequestsOverviewSort),
        ]),
      ),
      concatMap(([filters, pagination, sort]) => [
        showLoaderAction(),
        loadRequestsAggregate({ filters }),
        loadRequests({ pagination, filters, sort }),
        loadTagsAction({ customerId: filters.customerId }),
      ]),
    ),
  );

  constructor(
    private actions$: Actions,
    private _newRequestService: IRequestService,
    private _tagsService: ITagService,
    private _requestOverviewService: RequestOverviewService,
    private store: Store<IAppState>,
  ) {}
}
