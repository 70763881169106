<input
  #fileinput
  id="fileinput"
  class="file-input"
  type="file"
  [attr.multiple]="multipleFiles === true ? '' : null"
  (change)="onFilesSelected($event)"
/>

<div
  class="d-flex row file-upload uploadBar align-items-center"
  rrDragAndDrop
  (click)="fileinput.click()"
  (filesDropped)="onFilesDropped($event)"
>
  <div class="p2 ml-4 mt-3 mb-3 rr-drop-files"></div>
  <div class="p2 ml-3 mt-3 mb-3">Drop or upload files</div>
  <div class="p2 ml-auto mt-3 mb-3 mr-3 rr-plus"></div>
</div>
