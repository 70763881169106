import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import {
  navigateToAction,
  navigateToCompleteAction,
} from './navigation.actions';

@Injectable()
export class NavigationEffects {
  constructor(private actions$: Actions, private router: Router) {}

  onNavigateTo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(navigateToAction),
      map(({ route }) => {
        this.router.navigate(route);
        return navigateToCompleteAction();
      }),
    );
  });
}
