import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ConfigurationHasTagKeyDTO,
  TagKeyDTO,
} from '@reactivereality/cs-api-sdk';
import { InputDateComponent } from 'projects/content-service-cms/src/app/components/inputs/input-date/input-date.component';
import {
  DropDownEntry,
  InputDropdownComponent,
} from 'projects/content-service-cms/src/app/components/inputs/input-dropdown/input-dropdown.component';
import { InputTextComponent } from 'projects/content-service-cms/src/app/components/inputs/input-text/input-text.component';
import { TagSelectionComponent } from 'projects/content-service-cms/src/app/components/tag-selection/tag-selection.component';
import { ICustomerService } from 'projects/content-service-cms/src/app/customer';
import {
  IRequestService,
  PRODUCT_TYPE,
} from 'projects/content-service-cms/src/app/data';
import {
  IPriority,
  ITagService,
} from 'projects/content-service-cms/src/app/data/api';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { CreateRequestModalStore } from '../create-request-modal.state';
import { FashionImageResolutionTypes } from '../data/fashion-image-size.types';

@Component({
  selector: 'app-common-inputs',
  templateUrl: './common-inputs.component.html',
  styleUrls: ['./common-inputs.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TagSelectionComponent,
    InputDropdownComponent,
    InputTextComponent,
    InputDateComponent,
  ],
})
export class CommonInputsComponent implements OnInit, OnDestroy {
  public tags: TagKeyDTO[] = [];
  public inputValid: boolean;
  private _onDestroy$: Subject<void>;
  private _allPriorities: IPriority[] = [];

  public prioritiesDDValues: DropDownEntry[] = [];
  public renderSizes: DropDownEntry[] = [];
  public allProductTypes = PRODUCT_TYPE;
  public priority: Observable<string> = undefined;
  public size: Observable<string> = undefined;
  public tags$: Observable<Array<ConfigurationHasTagKeyDTO>>;

  public disabled: boolean;

  constructor(
    private _requestService: IRequestService,
    private _logger: ILogger,
    private _tagService: ITagService,
    public customerService: ICustomerService,
    public createRequestModalStore: CreateRequestModalStore,
  ) {
    FashionImageResolutionTypes.forEach((v) =>
      this.renderSizes.push({ id: v.text, data: v.text }),
    );
  }

  ngOnInit(): void {
    this.inputValid = false;
    this._onDestroy$ = new Subject<void>();
    this.createRequestModalStore.createRequestAction$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((create) => {
        this.disabled = create;
      });
    this.createRequestModalStore.createRequestActionAndAnotherOne$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((createAndAnotherOne) => {
        this.disabled = createAndAnotherOne;
      });
    this.tags$ = this.createRequestModalStore.tags$.pipe(
      takeUntil(this._onDestroy$),
      map((t) => {
        return t.map((tag) => {
          return {
            tagKeyId: tag.id,
            tagKeyName: tag.name,
            tagKeyColor: tag.color,
            tagKeyDescription: tag.description,
            tagKeyDisplayTemplate: tag.displayTemplate,
            tagKeyJsonSchema: tag.jsonSchema,
            value: '',
            configurationId: '',
          } as ConfigurationHasTagKeyDTO;
        });
      }),
    );

    this.size = this.createRequestModalStore.requestCommonDataSize$.pipe(
      map((s) => {
        this._logger.debug(`Common input setting size`, s);
        if (s) {
          return `${s.text}`;
        }
        return null;
      }),
    );
    this.priority =
      this.createRequestModalStore.requestCommonDataPriority$.pipe(
        map((s) => s?.id),
      );

    this._requestService.allPriorities$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe({
        next: this.onPrioritiesChanged,
        error: this.onPrioritiesChangedHasError,
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._onDestroy$.unsubscribe();
    this._onDestroy$ = undefined;
  }

  onPrioritiesChanged = (data: Array<IPriority>) => {
    this._allPriorities = data;
    this.prioritiesDDValues = this._allPriorities.map((v) => {
      return { id: v.id, data: v.name };
    });
  };

  onPrioritiesChangedHasError = (error: Error) => {
    console.error('Was not able to fetch priorites.', error);
  };

  onNameChanged(entry: { value: string; valid: boolean }) {
    this.createRequestModalStore.changeCreateRequestCommonData({
      name: entry.value,
    });
  }
  onSkuChanged(entry: { value: string; valid: boolean }) {
    this.createRequestModalStore.changeCreateRequestCommonData({
      sku: entry.value,
    });
  }
  onDueChanged(entry: Date) {
    this.createRequestModalStore.changeCreateRequestCommonData({
      dueDate: entry,
    });
  }
  onPriorityChanged(entry: DropDownEntry) {
    this.createRequestModalStore.changeCreateRequestCommonData({
      priority: {
        id: `${entry.id}`,
        name: entry.data,
      },
    });
  }
  onSizeChanged(entry: DropDownEntry) {
    this.createRequestModalStore.changeCreateRequestCommonData({
      size: FashionImageResolutionTypes.find((i) => i.text === entry.id),
    });
  }
  nameValidation = (value: string): boolean => {
    return value.length > 0;
  };

  addExistingTagToSelectionEvent(event: TagKeyDTO) {
    this.tags.push(event);
    // TODO:
    //this.tagsToAdd.emit(this.tags);
    this.createRequestModalStore.addTagEntry(event);
  }
  async addToSelectionEvent(event: TagKeyDTO) {
    this._logger.info('addToSelectionEvent', event);
    await this._tagService.createOrUpdateTag(event.id, event);
    this.tags.push(event);
    // TODO:
    //this.tagsToAdd.emit(this.tags);
    this.createRequestModalStore.addTagEntry(event);
  }
  async deleteTagEvent(event: TagKeyDTO) {
    this._logger.info('deleteTagEvent', event);
    await this._tagService.deleteTag(event.id);
    this.tags = this.tags.filter((o) => o.id !== event.id);
    // TODO:
    //this.tagsToAdd.emit(this.tags);
    this.createRequestModalStore.removeTagEntry(event);
  }
  async removeFromSelectionEvent(event: TagKeyDTO) {
    this._logger.info('removeFromSelectionEvent', event);
    this.tags = this.tags.filter((o) => o.id !== event.id);
    // TODO:
    //this.tagsToAdd.emit(this.tags);
    this.createRequestModalStore.removeTagEntry(event);
  }
  async updateOrCreateSelectionEvent(event: TagKeyDTO) {
    this._logger.info('updateOrCreateSelectionEvent', event);
    await this._tagService.createOrUpdateTag(event.id, event);
  }
}
