import { Injectable, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RRModalService {
  private _modalIds: (string | number)[];

  public isShown: Subject<boolean>;
  public idIsShown: Subject<string>;

  constructor(private _modalService: BsModalService) {
    this._modalIds = [];
    this.isShown = new Subject<boolean>();
    _modalService.onShown.subscribe(() => this.isShown.next(true));
    this.idIsShown = new Subject<string>();
    _modalService.onShown.subscribe((data) => this.idIsShown.next(data.id));
  }

  public showModal(
    template: TemplateRef<any>,
    config: ModalOptions,
  ): BsModalRef {
    this._modalIds.push(config.id);
    return this._modalService.show(template, config);
  }

  public closeModal(id: number | string): void {
    this._modalService.hide(id);
    this._modalIds = this._modalIds.filter((i: number | string) => id !== i);
  }

  public closeAllModals(): void {
    if (Array.isArray(this._modalIds)) {
      this._modalIds.forEach((id: number | string) => {
        this._modalService.hide(id);
      });

      this._modalIds = [];
    }
  }
}
