<app-input-text
  id="create-request-name-txt"
  class="mb-5"
  placeholder="Name"
  (valueChange)="onNameChanged($event)"
  [value]="(createRequestModalStore.requestCommonData$ | async).name"
  [required]="true"
  [validationFunction]="nameValidation"
  [disabled]="disabled"
></app-input-text>
<app-input-text
  id="create-request-sku-txt"
  class="mb-5"
  placeholder="SKU"
  (valueChange)="onSkuChanged($event)"
  [value]="(createRequestModalStore.requestCommonData$ | async).sku"
  [disabled]="disabled"
>
</app-input-text>
<app-input-date
  id="create-request-dueat-txt"
  *ngIf="createRequestModalStore.supportDueDate$ | async"
  class="mb-5"
  placeholder="Due at"
  (valueChange)="onDueChanged($event)"
  [date]="(createRequestModalStore.requestCommonData$ | async).dueDate"
  [disabled]="disabled"
></app-input-date>
<app-input-dropdown
  *ngIf="createRequestModalStore.supportPriority$ | async"
  class="mb-5"
  [entries]="prioritiesDDValues"
  placeholder="Priority"
  [value]="priority | async"
  (valueChange)="onPriorityChanged($event)"
  [disabled]="disabled"
></app-input-dropdown>
<app-input-dropdown
  *ngIf="createRequestModalStore.supportSize$ | async"
  class="mb-5"
  [entries]="renderSizes"
  placeholder="Size"
  [value]="size | async"
  (valueChange)="onSizeChanged($event)"
  [disabled]="disabled"
></app-input-dropdown>
<app-tag-selection
  [cutstomerId]="(customerService.currentCustomer$ | async).id"
  [configurationId]="undefined"
  [configurationTags]="tags$ | async"
  [disabled]="disabled"
  (addExistingTagToSelectionEvent)="addExistingTagToSelectionEvent($event)"
  (addToSelectionEvent)="addToSelectionEvent($event)"
  (deleteTagEvent)="deleteTagEvent($event)"
  (removeFromSelectionEvent)="removeFromSelectionEvent($event)"
  (updateOrCreateSelectionEvent)="updateOrCreateSelectionEvent($event)"
>
</app-tag-selection>
