import { NgModule } from '@angular/core';
import { RRBootstrapModule } from '../bootstrap/bootstrap.module';
import { RRIconsModule } from '../icons/icons.module';
import { RRSharedModule } from '../shared-module/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { RRLayoutBuilderModule } from '../layout-builder/layout-builder.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    RRSharedModule,
    RRBootstrapModule,
    RRIconsModule,
    RRLayoutBuilderModule,
  ],
  exports: [HeaderComponent],
})
export class RRHeaderModule {}
