<article id="app-add-scene-modal-article">
  <div class="d-flex mt-3 ml-3 mb-1 justify-content-between align-self-center">
    <h2 class="mb-auto mt-auto mr-5">Scenes</h2>

    <app-filter-selection
      class="mb-auto mt-auto ml-auto"
      [filters]="filterConfiguration"
    ></app-filter-selection>

    <span class="d-flex align-items-center">
      <span
        class="magGlass oi oi-magnifying-glass"
        (click)="input.focus()"
      ></span>
      <input
        #input
        type="text"
        placeholder="Search"
        (input)="searchPatternChanged($event)"
        [value]="searchPattern ? searchPattern : ''"
      />
      <span
        [hidden]="searchPattern === ''"
        class="clear rr-selection-search-delete"
        (click)="clearSearchInput()"
      ></span>
    </span>
  </div>
  <ngx-skeleton-loader
    *ngIf="spinnerStatus.show"
    class="loader"
    [count]="pageSize"
    appearance="line"
    [theme]="{
      'border-radius': '8px',
      'background-color': '#f5f0eb'
    }"
  ></ngx-skeleton-loader>
  <div
    class="modal-body mb-3 scene-modal"
    *ngIf="!spinnerStatus.show"
    id="app-add-scene-modal-list"
  >
    <ng-container *ngFor="let scene of allScenes">
      <div
        id="app-add-scene-modal-row-{{ scene.id }}"
        class="scene-entry d-flex flex-row align-items-center pt-2 pb-2"
        (click)="cb_selected.click()"
        [ngClass]="!scene.enabled ? 'disabled' : ''"
      >
        <span class="tttext" *ngIf="!scene.archived"
          >This scene is not valid.</span
        >
        <span class="tttext" *ngIf="scene.archived"
          >This scene has been archived.</span
        >
        <input
          #cb_selected
          class="ml-3"
          type="checkbox"
          (change)="onCheckboxChanged($event, scene)"
          name="cb"
          [checked]="selScene?.id === scene?.id"
          [disabled]="!scene.enabled"
        />

        <div class="thumbnail-hover">
          <img
            class="thumbnail ml-3"
            src="{{
              (!scene.archived ? scene?.previewUrl : undefined)
                | sceneThumbnailAvailable
            }}"
          />
          <span>
            <img
              class="ml-3"
              src="{{
                (!scene.archived ? scene?.previewUrl : undefined)
                  | sceneThumbnailAvailable
              }}"
            />
          </span>
        </div>

        <div class="d-flex flex-column">
          <span class="ml-3 mr-1">{{ scene?.name }}</span>
          <span class="sku ml-3 mr-1">{{ scene?.sku }}</span>
        </div>
        <span class="ml-auto mr-1">{{
          scene?.createdAt ? (scene.createdAt | prettyProductDate) : ''
        }}</span>
      </div>
    </ng-container>
    <rr-request-list-pagination></rr-request-list-pagination>
  </div>
  <div class="button-container">
    <div class="row mt-auto d-flex flex-row justify-content-end mb-3 mr-3">
      <button
        (click)="closeModal()"
        type="button"
        class="btn btn-primary mr-3"
        id="app-add-scene-modal-cancel-button"
      >
        Cancel
      </button>
      <button
        id="app-add-scene-modal-select-button"
        (click)="selectSceneClicked()"
        type="button"
        class="btn btn-primary"
        [disabled]="selScene === undefined"
      >
        Select
      </button>
    </div>
  </div>
</article>
