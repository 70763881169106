import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FilterOutput } from 'projects/web-ui-component-library/src/lib/filter-elements/interfaces/filter.interface';

import { RRSelectFilterModule } from 'projects/web-ui-component-library/src/lib/filter-elements/select-filter.module';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  map,
  takeUntil,
} from 'rxjs';
import { ILogger } from '../../logging';
import { SelectionModalStore } from '../../request-overview/components/create-request-modal/store/selection-modal-store';
import { IFilterConfiguration } from './types/filter-selection.types';

import { ViewContainerRef } from '@angular/core';
import {
  DateInputElementComponent,
  RRLayoutModule,
  SelectInputElementComponent,
  TextInputElementComponent,
} from 'projects/web-ui-component-library/src';

@Component({
  selector: 'app-filter-selection',
  templateUrl: './filter-selection.component.html',
  styleUrls: ['./filter-selection.component.scss'],
  standalone: true,
  imports: [CommonModule, RRSelectFilterModule, RRLayoutModule],
})
export class FilterSelectionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  showFilterList: boolean = false;

  @Input()
  public filters: IFilterConfiguration<
    | TextInputElementComponent
    | SelectInputElementComponent
    | DateInputElementComponent
  >[];

  @ViewChild('filterContainer', { read: ViewContainerRef })
  container: ViewContainerRef;

  public filterCount = 0;

  private _clearFilterObservable: BehaviorSubject<string> = new BehaviorSubject(
    '',
  );
  private _onDestroy$: Subject<void>;

  constructor(private _logger: ILogger, public store: SelectionModalStore) {
    this._onDestroy$ = new Subject<void>();
  }
  ngAfterViewInit(): void {
    this.filters.forEach((filterConfig) => {
      const { id, type, data, placeholder } = filterConfig;

      // handle filter changes happening in the filter component.
      const onChange = (value) => {
        this._logger.debug('Changed value:', id, value);
        this.onFilterChanged({
          id,
          value,
        });
      };

      // hide the current open filter when teh back button in the component is clicked.
      const onBackClicked = () => {
        this.store.updateVisibleFilter('');
      };

      // build the component...
      const component = this.container.createComponent(type);

      // configure it...
      component.instance.inputData$ = combineLatest([
        data ?? new Observable((sub) => sub.next({})),
        this.store.visibleFilter$,
      ]).pipe(
        map(([data, visibleFilter]) => ({
          id,
          placeholder,
          data,
          onChange,
          onBackClicked,
          hidden: visibleFilter !== filterConfig.id,
        })),
      );

      component.instance.reset$ = this._clearFilterObservable;
    });
    this.store.countFilters$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((count) => (this.filterCount = count));
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._onDestroy$.unsubscribe();
    this._onDestroy$ = undefined;
  }

  onFilterChanged(output: FilterOutput) {
    this.store.updateFilters(output);
  }

  clearAllFilters() {
    this.store.clearFilters();
    this._clearFilterObservable.next('');
  }

  clearFilter($event, id: string) {
    this.store.clearFilter(id);
    this._clearFilterObservable.next(id);
    $event.stopPropagation();
  }
}
