import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationHasTagKeyDTO } from '@reactivereality/cs-api-sdk';
import { ICustomerService } from 'projects/content-service-cms/src/app/customer';
import {
  IFileService,
  IFileUploadService,
  IGarmentService,
  IProductService,
  IRequestDetail,
  IRequestService,
} from 'projects/content-service-cms/src/app/data';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { firstValueFrom } from 'rxjs';
import {
  CreateRequestCommonData,
  CreateRequestInputData,
} from '../create-request-input.interface';
import { CreateRequestModalStore } from '../create-request-modal.state';
import { CreateRequestBaseService } from './create-request-base';
import { ITagService } from 'projects/content-service-cms/src/app/data/api';
import { Outfit3DManifest } from 'projects/content-service-cms/src/app/data/models/outfit3d/outfit3d.types';

@Injectable({
  providedIn: 'root',
})
export class CreateOutfit3DService extends CreateRequestBaseService {
  private readonly OUTFIT_3D_FILENAME = 'outfit3d-manifest.json';
  private readonly OUTFIT_3D_PREVIEW_FILENAME = 'outfit3d-preview.png';

  constructor(
    private _http: HttpClient,
    private _fileService: IFileService<any>,
    requestService: IRequestService,
    private garmentService: IGarmentService,
    private _logger: ILogger,
    private customerService: ICustomerService,
    productService: IProductService,
    private _fileUploadService: IFileUploadService,
    tagService: ITagService,
  ) {
    super(requestService, productService, tagService);
  }

  public readOutfitManifest = async (
    request: IRequestDetail,
  ): Promise<Outfit3DManifest | null> => {
    const files = await this._fileService.getRequestFiles(
      request.id,
      JSON.stringify({
        name: this.OUTFIT_3D_FILENAME,
      }),
    );
    const inputFile = files.results.length > 0 ? files.results[0] : undefined;
    if (inputFile) {
      return await firstValueFrom(
        this._http.get<Outfit3DManifest>(inputFile.url, {
          responseType: 'json',
        }),
      );
    }
  };

  public buildOutfitManifest = async (
    store: CreateRequestModalStore,
    mustBeValid: boolean,
  ): Promise<Outfit3DManifest | null> => {
    const current = store.current();
    let order = -1;
    current.dressingRoom.garments.forEach((g) => {
      const mobileResult = g.results.find((r) => r.format === 'mobile');
      if (!mobileResult) {
        throw new Error(
          `Failed to handle configuration ${g.id}, no mobile result`,
        );
      }
      if (mobileResult.files.length < 1) {
        throw new Error(
          `Failed to handle configuration ${g.id}, invalid mobile result, is empty`,
        );
      }
    });

    const garmentInputs = current.dressingRoom.garments.map((garment) => {
      const mobileResult = garment.results.find((r) => r.format === 'mobile');
      order = order + 1;
      return {
        order,
        stylingTemplates: garment.stylingOptions ?? {},
        source: {
          configurationId: garment.id,
          resultId: mobileResult.id,
          fileName:
            mobileResult.files?.length === 1 ? mobileResult.files[0].name : '',
        },
      };
    });

    const avatarMobileResult = current.dressingRoom.avatar.results.find(
      (r) => r.format === 'mobile',
    );
    if (!avatarMobileResult && mustBeValid) {
      throw new Error(
        `Failed to handle configuration ${current.dressingRoom.avatar.id}, no mobile result`,
      );
    }

    const avatar = avatarMobileResult
      ? {
          configurationId: current.dressingRoom.avatar.id,
          resultId: avatarMobileResult.id,
          fileName:
            avatarMobileResult.files?.length === 1
              ? avatarMobileResult.files[0].name
              : '',
          isPublic: current.dressingRoom.avatar.isPublic,
        }
      : null;

    return {
      outfit: {
        avatar: {
          source: avatar,
        },
        garments: garmentInputs,
      },
    };
  };

  prepareCreateNew = async (
    inputData: CreateRequestInputData,
    store: CreateRequestModalStore,
  ): Promise<void> => {
    store.clearCreateRequestCommonData();
    store.clearExistingRequest();
    store.setProductType(inputData.productType);
    store.setFeatures({
      supportDraft: true,
      supportDueDate: false,
      supportPriority: false,
      supportSize: false,
    });
  };

  prepareDraftEdit = async (
    request: IRequestDetail,
    store: CreateRequestModalStore,
  ): Promise<void> => {
    const priorities = this._requestService.allPriorities$.value;
    const manifest = await this.readOutfitManifest(request);

    this._logger.debug(`3D Outfit manifest loaded`, manifest);

    const requestCommonData: CreateRequestCommonData = {
      name: request.name,
      sku: request.sku,
      priority: request.priority
        ? priorities.find((p) => p.id === request.priority)
        : undefined,
      dueDate: request.dueAt ? new Date(request.dueAt * 1000) : undefined,
      dataValid: true,
    };

    store.changeCreateRequestCommonData(requestCommonData);
    store.setTags(
      request.tags.map((v: ConfigurationHasTagKeyDTO) => {
        return {
          id: v.tagKeyId,
          name: v.tagKeyName,
          color: v.tagKeyColor,
          description: v.tagKeyDescription,
          displayTemplate: v.tagKeyDisplayTemplate,
          jsonSchema: v.tagKeyJsonSchema,
        };
      }),
    );

    store.setExistingRequest(request);
    store.setProductType(request.productTypeId);
    store.setFeatures({
      supportDraft: true,
      supportDueDate: false,
      supportPriority: false,
      supportSize: false,
    });

    const avatarID = manifest?.outfit.avatar.source.configurationId;
    if (avatarID) {
      const avatarRequest = await this._requestService.getRequestByID(avatarID);
      store.setAvatar({
        ...avatarRequest,
        isPublic: false,
      });
    }

    const garments = manifest?.outfit.garments;
    if (garments) {
      this._logger.debug(`Garments: `, garments);
      const resolved = (
        await Promise.all(
          garments
            .map((g) => ({
              dto: this._requestService.getRequestByID(
                g.source.configurationId,
              ),
              garment: g,
              stylingTemplates: g.stylingTemplates,
            }))
            .map(
              async ({
                dto,
                garment: { stylingTemplates: stylingOptions },
              }) => {
                try {
                  return {
                    ...(await this.garmentService.mapRequestsToGarments(
                      await dto,
                      {
                        dressed: true,
                        isPublic: false,
                      },
                    )),
                    stylingOptions,
                  };
                } catch (e) {
                  this._logger.warn(`Failed to load garment!`, e);
                  return undefined;
                }
              },
            ),
        )
      ).filter((v) => v !== undefined);
      this._logger.debug(`Resolved Garments: `, resolved);
      store.setSelectedGarments(resolved);
      store.setGarments(resolved);
      store.refreshRoomNow(true);
    }
  };

  createRequest = async (
    store: CreateRequestModalStore,
    previewImage: string,
    asDraft: boolean,
  ): Promise<void> => {
    this._logger.debug(`Creating/Updating Outfit 3D request ...`);
    const customer = await this.customerService.currentCustomer();
    const state = store.current();
    const manifest = await this.buildOutfitManifest(store, !asDraft);

    let productId = '';

    if (!state.existingRequest) {
      const product = await this.createProduct(
        customer,
        state.requestCommonData,
        state.productType,
      );
      productId = product.id;
    } else {
      productId = state.existingRequest.productId;
    }

    const request = this.getRequestUpdate(state.requestCommonData, productId);

    if (state.existingRequest) {
      request.id = state.existingRequest.id;
    }

    const front = await this.updateRequest(request);

    await this.updateTags(front, state.tags);

    if (state.existingRequest) {
      const files = await this._fileService.getRequestFiles(request.id);
      const outfitFile = files.results.find(
        (o) => o.name === this.OUTFIT_3D_FILENAME,
      );
      const previewFile = files.results.find(
        (o) => o.name === this.OUTFIT_3D_PREVIEW_FILENAME,
      );
      if (outfitFile) {
        await this._fileService.deleteFile(outfitFile);
      }
      if (previewFile) {
        await this._fileService.deleteFile(previewFile);
      }
    }

    await this._fileUploadService.uploadJsonFile(
      {
        id: '',
        configurationId: request.id,
        fileName: this.OUTFIT_3D_FILENAME,
        typeId: 0,
        mimeType: 'application/json',
      },
      manifest,
    );

    await this._fileUploadService.uploadBase64File(
      {
        id: '',
        configurationId: request.id,
        fileName: this.OUTFIT_3D_PREVIEW_FILENAME,
        typeId: 36,
        mimeType: 'image/png',
      },
      previewImage,
    );

    if (!asDraft) {
      await this._requestService.setReady(request.id);
    }
  };
}
