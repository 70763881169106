import { Injectable } from '@angular/core';
import {
  RRAlertModel,
  RR_ALERT_TYPE,
} from 'projects/web-ui-component-library/src';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  public subscribeAlert: Subject<RRAlertModel>;

  constructor() {
    this.subscribeAlert = new Subject<RRAlertModel>();
  }

  public showAlert(alert: RRAlertModel): void {
    this.subscribeAlert.next(alert);
  }

  public showErrorAlert(message: string): void {
    this.showAlert({
      type: RR_ALERT_TYPE.DANGER,
      message,
    });
  }

  public showSuccessAlert(message: string): void {
    this.showAlert({
      type: RR_ALERT_TYPE.SUCCESS,
      message,
    });
  }
}
