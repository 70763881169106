import { NgModule } from '@angular/core';
import { TextFieldComponent } from './components/text-field/text-field.component';
import { DropdownFieldComponent } from './components/dropdown-field/dropdown-field.component';
import { RadioButtonFieldComponent } from './components/radio-button-field/radio-button-field.component';
import { CheckboxFieldComponent } from './components/checkbox-field/checkbox-field.component';
import { DateFieldComponent } from './components/date-field/date-field.component';
import { TimeFieldComponent } from './components/time-field/time-field.component';
import { DateTimeFieldComponent } from './components/date-time-field/date-time-field.component';
import { DateRangeFieldComponent } from './components/date-range-field/date-range-field.component';
import { TextareaFieldComponent } from './components/textarea-field/textarea-field.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MultiSelectFieldComponent } from './components/multi-select-field/multi-select-field.component';
import { ColorPickerFieldComponent } from './components/color-picker-field/color-picker-field.component';
import { RRSharedModule } from '../shared-module/shared.module';
import { RRBootstrapModule } from '../bootstrap/bootstrap.module';
import { RRIconsModule } from '../icons/icons.module';
import { AutofocusDirective } from './directives/autofocus.directive';

@NgModule({
  declarations: [
    TextFieldComponent,
    DropdownFieldComponent,
    RadioButtonFieldComponent,
    CheckboxFieldComponent,
    DateFieldComponent,
    TimeFieldComponent,
    DateTimeFieldComponent,
    DateRangeFieldComponent,
    TextareaFieldComponent,
    MultiSelectFieldComponent,
    ColorPickerFieldComponent,
    AutofocusDirective,
  ],
  imports: [RRSharedModule, RRBootstrapModule, NgSelectModule, RRIconsModule],
  exports: [
    TextFieldComponent,
    DropdownFieldComponent,
    RadioButtonFieldComponent,
    CheckboxFieldComponent,
    DateFieldComponent,
    TimeFieldComponent,
    DateTimeFieldComponent,
    DateRangeFieldComponent,
    TextareaFieldComponent,
    MultiSelectFieldComponent,
    ColorPickerFieldComponent,
    AutofocusDirective,
  ],
})
export class RRFormInputsModule {}
