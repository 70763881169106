import { NgModule } from '@angular/core';
import { RRBootstrapModule } from '../bootstrap/bootstrap.module';
import { RRSharedModule } from '../shared-module/shared.module';
import { ListBuilderComponent } from './components/list-builder/list-builder.component';

@NgModule({
  declarations: [ListBuilderComponent],
  imports: [RRSharedModule, RRBootstrapModule],
  exports: [ListBuilderComponent],
})
export class RRLayoutBuilderModule {}
