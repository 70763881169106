import { Component } from '@angular/core';
import { GarmentReference } from '../../collection-screen.state';
import { InputTextComponent } from '../../../components/inputs/input-text/input-text.component';
import { CommonModule } from '@angular/common';
import { BaseListRowComponent } from '../base-list-row/base-list-row.component';
import { RowButtonsComponent } from '../row-buttons/row-buttons.component';
import { CdkDragHandle } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-garment-list-row',
  standalone: true,
  imports: [
    InputTextComponent,
    CommonModule,
    RowButtonsComponent,
    CdkDragHandle,
  ],
  templateUrl: './garment-list-row.component.html',
  styleUrls: [
    '../base-list-row/base-list-row.scss',
    './garment-list-row.component.scss',
  ],
})
export class GarmentListRowComponent extends BaseListRowComponent<GarmentReference> {
  onGarmentLinkChanged($event: { value: string; valid: boolean }) {
    this.updateReference.emit({
      ...this.reference,
      garmentLink: $event.value,
    });
  }
  onCurrencyChanged($event: { value: string; valid: boolean }) {
    this.updateReference.emit({
      ...this.reference,
      price: { ...this.reference.price, currency: $event.value },
    });
  }
  onPriceChanged($event: { value: string; valid: boolean }) {
    this.updateReference.emit({
      ...this.reference,
      price: {
        ...this.reference.price,
        price: Number.parseFloat($event.value) ?? 0,
      },
    });
  }
  onNameChanged($event: { value: string; valid: boolean }) {
    this.updateReference.emit({
      ...this.reference,
      name: $event.value,
    });
  }
  constructor() {
    super();
    this.reference;
  }
}
