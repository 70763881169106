import { Injectable } from '@angular/core';
import {
  ProductDTO,
  ProductTypeDTO,
  ProductsApi,
} from '@reactivereality/cs-api-sdk';
import { AxiosResponse } from 'axios';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import {
  IApiSdkService,
  ICsApiSdkConfiguration,
  IProduct,
  IProductService as IProductService,
  IProductType,
} from '../api';

@Injectable({
  providedIn: 'root',
})
export class ProductService implements IProductService {
  private _productApi: ProductsApi;
  private _apiVersion: string;

  constructor(
    private _apiSdkService: IApiSdkService,
    private _logger: ILogger,
  ) {
    this._logger.info('Creating product service ...');

    this._apiSdkService.sdkConfig$.subscribe({
      next: this.initProductsService,
      error: this.initProductsServiceHasError,
    });
  }

  public async fetchProductByID(
    productId: string,
  ): Promise<IProduct | undefined> {
    if (this._productApi) {
      return this._productApi
        .productLink(productId, this._apiVersion)
        .then((response: AxiosResponse<ProductDTO>) => {
          if (response && response.data) {
            return this.mapProductDTOToIProduct(response.data);
          }

          return undefined;
        })
        .catch((reason: any) => {
          this._logger.error(
            `Error while fetching the product ${productId}`,
            reason,
          );
          return null;
        });
    }

    return Promise.reject('There is no Products API.');
  }

  public async updateProduct(product: IProduct): Promise<IProduct | undefined> {
    if (this._productApi) {
      return this._productApi
        .vversionProductsIdPut(product.id, this._apiVersion, product)
        .then((response: AxiosResponse<ProductDTO>) => {
          if (response && response.data) {
            return this.mapProductDTOToIProduct(response.data);
          }

          return undefined;
        })
        .catch((reason: any) => {
          this._logger.error(
            `Error while creating/updating the product ${product.id}`,
            reason,
          );
          return null;
        });
    }

    return Promise.reject('There is no Products API.');
  }

  public async fetchProductTypes(): Promise<Array<IProductType> | undefined> {
    return this._productApi
      .vversionProductsTypesGet(this._apiVersion)
      .then((response: AxiosResponse<ProductTypeDTO[]>) => {
        if (response && Array.isArray(response.data)) {
          return response.data.map(this.mapProductTypeDTOToIProductType);
        }

        return undefined;
      })
      .catch((reason: any) => {
        this._logger.error('Error while fetching product types.', reason);
        return undefined;
      });
  }

  private initProductsService = (sdkConfig: ICsApiSdkConfiguration) => {
    if (sdkConfig !== undefined && sdkConfig.productApiSdk) {
      this._apiVersion = sdkConfig.apiVersion;
      this._productApi = sdkConfig.productApiSdk;
    } else {
      this.initProductsServiceHasError(
        new Error('There is no Products API Sdk Configuration or API version.'),
      );
    }
  };

  private initProductsServiceHasError = (error: Error) => {
    this._logger.error('Cannot initate product API.', error);
  };

  private mapProductDTOToIProduct = (prodcut: ProductDTO): IProduct => ({
    id: prodcut.id,
    name: prodcut.name,
    customerId: prodcut.customerId,
    customerName: prodcut.customerName,
    createdAt: prodcut.createdAt,
    typeId: prodcut.typeId,
    sku: prodcut.sku,
    metadata: prodcut.metadata,
    dueAt: prodcut.dueAt,
    modifiedAt: prodcut.modifiedAt,
    stateId: prodcut.stateId,
  });

  private mapProductTypeDTOToIProductType = (
    type: ProductTypeDTO,
  ): IProductType => ({
    id: type.id,
    name: type.name,
    reviewEnabled: type.reviewEnabled,
  });
}
