import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { StaticAsset } from '@reactivereality/pictofit-web-sdk';
import { IAppState } from 'projects/content-service-cms/src/app/app.state';

import { IAuthService } from 'projects/content-service-cms/src/app/auth';
import { AddAvatarModalComponent } from 'projects/content-service-cms/src/app/components/avatar-selection-entry/add-avatar-modal/add-avatar-modal.component';
import { AvatarSelectionEntryComponent } from 'projects/content-service-cms/src/app/components/avatar-selection-entry/avatar-selection-entry.component';
import { AvatarSelectionService } from 'projects/content-service-cms/src/app/components/avatar-selection-entry/avatar-selection.service';
import { FileUploadFullComponent } from 'projects/content-service-cms/src/app/components/file-upload-full/file-upload-full.component';
import { AddGarmentModalComponent } from 'projects/content-service-cms/src/app/components/garment-selection/add-garment-modal/add-garment-modal.component';
import { GarmentSelectionComponent } from 'projects/content-service-cms/src/app/components/garment-selection/garment-selection.component';
import { AlertService } from 'projects/content-service-cms/src/app/core/services/alert.service';
import { NgDiscoveryService } from 'projects/content-service-cms/src/app/core/services/ng-discovery.service';
import { ICustomerService } from 'projects/content-service-cms/src/app/customer';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { ViewerMode } from 'projects/content-service-cms/src/app/virtual-dressing-room/data/api';
import { Avatar3DAsset } from 'projects/content-service-cms/src/app/virtual-dressing-room/data/model/AvatarAssets';
import { Garment3DAsset } from 'projects/content-service-cms/src/app/virtual-dressing-room/data/model/GarmentAssets';
import { IIDAsset } from 'projects/content-service-cms/src/app/virtual-dressing-room/interfaces/assets';
import { RRIDressingRoomSettings } from 'projects/content-service-cms/src/app/virtual-dressing-room/interfaces/dressing-room-settings';
import {
  IVDRDisplay,
  IVDRStatus,
} from 'projects/content-service-cms/src/app/virtual-dressing-room/interfaces/vdressing-room';
import { VirtualDressingRoomModule } from 'projects/content-service-cms/src/app/virtual-dressing-room/virtual-dressing-room.module';
import { RRSpinnerModule } from 'projects/web-ui-component-library/src';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import { createNewRequestAction } from '../../../store/requests-overview.actions';
import { ActionButtonsComponent } from '../action-buttons/action-buttons.component';
import { CommonInputsComponent } from '../common-inputs/common-inputs.component';
import { CreateRequestModalStore } from '../create-request-modal.state';
import { CreateOutfit3DService } from '../services/create-outfit-3d.service';
import { VirtualDressingRoomComponent } from 'projects/content-service-cms/src/app/virtual-dressing-room/virtual-dressing-room/virtual-dressing-room.component';
import { IScreenShotService } from 'projects/content-service-cms/src/app/core/interfaces/i-screenshot';

@Component({
  selector: 'app-create-outfit-3d',
  templateUrl: './create-outfit-3d.component.html',
  styleUrls: ['./create-outfit-3d.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AddAvatarModalComponent,
    AddGarmentModalComponent,
    RRSpinnerModule,
    VirtualDressingRoomModule,
    FileUploadFullComponent,
    CommonInputsComponent,
    AvatarSelectionEntryComponent,
    GarmentSelectionComponent,
    ActionButtonsComponent,
  ],
})
export class CreateOutfit3DComponent
  implements OnInit, OnDestroy, IScreenShotService
{
  public dressingRoomSettings: RRIDressingRoomSettings;
  public vdrUpdate$: BehaviorSubject<IVDRDisplay>;
  public vdrStatus$: BehaviorSubject<IVDRStatus>;
  public vdrReset$: Subject<void>;
  private _onDestroy$: Subject<void>;

  public token = this._authService.getToken();
  public viewerReloadRequired: boolean;

  @ViewChild('MainVDR') vdr: VirtualDressingRoomComponent;

  constructor(
    public createRequestModalStore: CreateRequestModalStore,
    public customerService: ICustomerService,
    private _logger: ILogger,
    private _authService: IAuthService,
    private _discoveryService: NgDiscoveryService,
    private _http: HttpClient,
    private _outfit3DService: CreateOutfit3DService,
    private alertService: AlertService,
    private avatarSelectionService: AvatarSelectionService,
    private _store: Store<IAppState>,
  ) {
    this.vdrStatus$ = new BehaviorSubject<IVDRStatus>(undefined);
    this.vdrUpdate$ = new BehaviorSubject<IVDRDisplay>(undefined);
    this.vdrReset$ = new Subject<void>();
    this.dressingRoomSettings = {
      type: '3D',
      computeServerUrl:
        this._discoveryService.config.apiConfiguration.computeServerUrl,
      height: '100%',
      width: '100%',
      disableAvatarSelection: true,
      disableFullscreenMode: true,
    };
  }

  async takeScreenshot(height?: number, width?: number): Promise<string> {
    return await this.vdr.takeScreenshot(height, width);
  }

  ngOnInit(): void {
    this._onDestroy$ = new Subject<void>();

    this.vdrStatus$.subscribe((status) => {
      this._logger.error(`Received status from VDR!!`, status);
      if (status && status.error) {
        this._logger.error(`Received error status from VDR!!`);
        this.alertService.showErrorAlert(`Failed to perform 3D tryon.`);
        this.viewerReloadRequired = true;
      }
    });
    this.createRequestModalStore.requestCommonDataName$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
        this.isInputValid();
      });
    this.createRequestModalStore.garments$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((_) => {
        this.viewerReloadRequired = true;
      });

    this.createRequestModalStore.refreshRoom$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((refresh) => {
        if (refresh) {
          this.applyChangedState();
          this.createRequestModalStore.refreshRoomNow(false);
        }
      });

    this.createRequestModalStore.avatar$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((avatar) => {
        this._logger.debug(`Updated avatar!`, avatar);
        this.updateViewer();
      });

    this.createRequestModalStore.createRequestAction$
      .pipe(
        takeUntil(this._onDestroy$),
        filter((c) => c),
      )
      .subscribe(async () => {
        const image = await this.vdr.takeScreenshot();
        await this._outfit3DService.createRequest(
          this.createRequestModalStore,
          image,
          false,
        );
        this.createRequestModalStore.cancelAction();
      });
    this.createRequestModalStore.createRequestActionAndAnotherOne$
      .pipe(
        takeUntil(this._onDestroy$),
        filter((c) => c),
      )
      .subscribe(async () => {
        const image = await this.vdr.takeScreenshot();
        await this._outfit3DService.createRequest(
          this.createRequestModalStore,
          image,
          false,
        );
        const state = this.createRequestModalStore.current();
        this._store.dispatch(
          createNewRequestAction({
            productType: state.productType,
          }),
        );
        this.vdrReset$.next();
      });
    this.createRequestModalStore.saveDraftAction$
      .pipe(
        takeUntil(this._onDestroy$),
        filter((c) => c),
      )
      .subscribe(async () => {
        const image = await this.vdr.takeScreenshot();
        await this._outfit3DService.createRequest(
          this.createRequestModalStore,
          image,
          true,
        );
        this.createRequestModalStore.cancelAction();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._onDestroy$.unsubscribe();
    this._onDestroy$ = undefined;
  }

  public addAvatarClicked() {
    this.avatarSelectionService.selectAvatar();
  }

  private updateViewer = () => {
    const currentAvatar = this.createRequestModalStore.currentAvatar();
    if (!currentAvatar) {
      this._logger.debug('not ready to show');
      return;
    }

    const viewerMode: ViewerMode = ViewerMode.MODE_3D;
    const sceneAsset: IIDAsset = {
      asset: new StaticAsset('/assets/viewer/scenes/3D/standard'),
      id: 'test',
    };
    const avatarWebResult = currentAvatar.results.find(
      (r) => r.format === 'web',
    );

    if (!avatarWebResult) {
      this._logger.error(`Failed to load web result of avatar!`);
      throw new Error(`Failed to load web result of avatar!`);
    }

    const avatar: any = new Avatar3DAsset(
      avatarWebResult.files.map((f) => ({
        filename: f.name,
        mimetype: f.mimeType,
        url: f.url,
      })),
      this._http,
      this._logger,
    );

    const currentGarments = this.createRequestModalStore
      .current()
      .dressingRoom.garments.filter(
        (c) => c.results.find((r) => r.format === 'web') !== undefined,
      )
      .map((r) => {
        const result = r.results.find((r) => r.format === 'web');
        const stylingOptions = r.stylingOptions;

        const asset = new Garment3DAsset(
          result.files.map((f) => ({
            filename: f.name,
            url: f.url,
          })),
          this._http,
          this._logger,
        );

        return {
          asset,
          id: r.id,
          stylingOptions,
        };
      });

    this.vdrUpdate$.next({
      garments: currentGarments,
      scene: sceneAsset,
      avatar: {
        id: currentAvatar.id,
        asset: avatar,
      },
      focusedGarment: null,
      sizeRecomendation: null,
      mode: viewerMode,
    });
    this.viewerReloadRequired = false;
    this.isInputValid();
  };

  applyChangedState() {
    this.updateViewer();
    this.viewerReloadRequired = false;
  }
  private isInputValid() {
    const currentState = this.createRequestModalStore.current();

    if (
      currentState.requestCommonData.name &&
      currentState.dressingRoom?.avatar &&
      currentState.dressingRoom?.garments?.length > 0
    ) {
      this.createRequestModalStore.changeCreateRequestCommonData({
        dataValid: true,
      });
    } else {
      this.createRequestModalStore.changeCreateRequestCommonData({
        dataValid: false,
      });
    }
  }
}
