<tr id="apikey-list-row-{{ record.id }}">
  <td>
    <div>{{ record.name }}</div>
  </td>
  <td>
    <div>{{ record.createdAt | prettyProductDate }}</div>
  </td>
  <td>
    <span (click)="deleteKey(record)" class="oi oi-trash"></span>
  </td>
</tr>
