import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, filter, map, skip } from 'rxjs';
import { ILogger } from '../../logging';
import { NgDiscoveryService } from './ng-discovery.service';

interface Dictionary {
  [key: string]: any;
}

interface StorageLayout {
  profile: string;
  storages: {
    local: Dictionary;
    session: Dictionary;
  };
}

const notEmpty = () => filter((v) => Object.getOwnPropertyNames(v).length > 0);

@Injectable({
  providedIn: 'root',
})
export class NgStorageManagerService {
  private currentState: StorageLayout;
  private store: ComponentStore<StorageLayout>;

  private readonly updateLocation: (update: {
    values: Dictionary;
    mode: 'append' | 'overwrite';
    location: keyof StorageLayout['storages'];
  }) => void;
  private readonly updateProfile: (prefix: string) => void;

  public readonly local$: Observable<StorageLayout['storages']['local']>;
  public readonly session$: Observable<StorageLayout['storages']['session']>;

  constructor(
    private discoveryService: NgDiscoveryService,
    private logger: ILogger,
  ) {
    // initialise the store as empty...
    this.currentState = {
      profile: '',
      storages: {
        local: {},
        session: {},
      },
    };
    this.store = new ComponentStore(this.currentState);

    // update the current state on each change...
    this.store
      .select((state) => state)
      .subscribe((state) => {
        this.currentState = state;
      });

    // updater for prefix...
    this.updateProfile = this.store.updater((state, profile: string) => ({
      ...state,
      profile,
    }));

    // updater for setting individual store values...
    this.updateLocation = this.store.updater(
      (
        state,
        update: {
          values: Dictionary;
          mode: 'append' | 'overwrite';
          location: keyof StorageLayout['storages'];
        },
      ) => {
        const { values, mode, location } = update;
        const existing = mode === 'append' ? state.storages[location] : {};
        return {
          ...state,
          storages: {
            ...state.storages,
            [location]: {
              ...existing,
              ...values,
            },
          },
        };
      },
    );

    // selector for accessing the storages...
    this.local$ = this.store.select((state) => state.storages.local);
    this.session$ = this.store.select((state) => state.storages.session);

    // save changes as they happen...
    this.local$
      .pipe(skip(1), notEmpty())
      .subscribe((values) =>
        this.saveToStorage(values, this.currentState.profile, localStorage),
      );
    this.session$
      .pipe(skip(1), notEmpty())
      .subscribe((values) =>
        this.saveToStorage(values, this.currentState.profile, sessionStorage),
      );

    // get the discovery information...
    const environmentName =
      this.discoveryService.config.apiConfiguration.baseApiUrl;
    const profile = ['CMS', environmentName].join('_');

    // store the prefix...
    this.updateProfile(profile);

    // load the stored values...
    this.loadFromStorage('local', localStorage, profile);
    this.loadFromStorage('session', sessionStorage, profile);
  }

  private loadFromStorage(
    location: keyof StorageLayout['storages'],
    storage: Storage,
    profile: string,
  ): void {
    // load from storage...
    let values: Dictionary = {};
    for (let i = 0; i < storage.length; i++) {
      const key = storage.key(i);
      if (key === profile) {
        values = JSON.parse(storage.getItem(key)) as Dictionary;
      }
    }

    // update the store...
    this.updateLocation({
      values,
      mode: 'overwrite',
      location,
    });
  }

  private saveToStorage(
    values: Dictionary,
    profile: string,
    storage: Storage,
  ): void {
    storage.setItem(profile, JSON.stringify(values));
  }

  public getItem<T extends any = any>(
    key: string,
    location: keyof StorageLayout['storages'],
  ): T {
    return this.currentState.storages[location][key];
  }

  public getItem$<T extends any = any>(
    key: string,
    location: keyof StorageLayout['storages'],
  ): Observable<T> {
    return this.store
      .select((state) => state.storages[location])
      .pipe(map((p) => p[key]));
  }

  public setItem(
    key: string,
    value: any,
    location: keyof StorageLayout['storages'],
  ) {
    this.updateLocation({
      values: {
        [key]: value,
      },
      mode: 'append',
      location,
    });
  }

  public clearLocation(location: keyof StorageLayout['storages']) {
    this.updateLocation({
      values: {},
      mode: 'overwrite',
      location,
    });
  }
}
