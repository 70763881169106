import { Injectable } from '@angular/core';
import {
  IProductType,
  PRODUCT_STATE,
  PRODUCT_TYPE,
} from 'projects/content-service-cms/src/app/data';

@Injectable({
  providedIn: 'root',
})
export class ProductHelper {
  static get ProductTypes_CMS(): IProductType[] {
    return [
      {
        id: PRODUCT_TYPE.GARMENT_2D,
        name: 'GARMENT_2D',
      },
      {
        id: PRODUCT_TYPE.GARMENT_3D,
        name: 'GARMENT_3D',
      },
      {
        id: PRODUCT_TYPE.ACCESSORY_3D,
        name: 'ACCESSORY_3D',
      },
      {
        id: PRODUCT_TYPE.OUTFIT_2D,
        name: 'OUTFIT_2D',
      },
      {
        id: PRODUCT_TYPE.OUTFIT_3D,
        name: 'OUTFIT_3D',
      },
    ];
  }

  static getProductStatusLabel(stateId: PRODUCT_STATE) {
    switch (stateId) {
      case PRODUCT_STATE.NOT_READY_FOR_PROCESSING:
      default:
        return 'Not Ready';
      case PRODUCT_STATE.READY_FOR_PROCESSING:
        return 'Ready';
      case PRODUCT_STATE.IN_PROGRESS:
        return 'In Progress';
      case PRODUCT_STATE.COMPLETED:
        return 'Completed';
      case PRODUCT_STATE.REJECTED:
        return 'Rejected';
      case PRODUCT_STATE.CANCELED:
        return 'Canceled';
    }
  }

  static getProductStatusScssClass(stateId: PRODUCT_STATE) {
    switch (stateId) {
      case PRODUCT_STATE.NOT_READY_FOR_PROCESSING:
      default:
        return 'bg-state-gray';
      case PRODUCT_STATE.READY_FOR_PROCESSING:
        return 'bg-state-yellow';
      case PRODUCT_STATE.IN_PROGRESS:
        return 'bg-state-lightblue';
      case PRODUCT_STATE.COMPLETED:
        return 'bg-state-blue';
      case PRODUCT_STATE.REJECTED:
        return 'bg-state-red';
      case PRODUCT_STATE.CANCELED:
        return 'bg-state-purple';
    }
  }

  static mapProductTypeFromStringToEnum(type: string): PRODUCT_TYPE {
    return parseInt(type, 10);
  }

  static mapProductTypeFromEnumToStringName(type: PRODUCT_TYPE): string {
    switch (type) {
      case PRODUCT_TYPE.GARMENT_2D:
        return 'Garment 2D';
      case PRODUCT_TYPE.GARMENT_3D:
        return 'Garment 3D';
      case PRODUCT_TYPE.GARMENT_3D_CAD:
        return 'Garment 3D CAD';
      case PRODUCT_TYPE.ACCESSORY_3D:
        return 'Accessory 3D';
      case PRODUCT_TYPE.OUTFIT_2D:
        return 'Outfit 2D';
      case PRODUCT_TYPE.OUTFIT_3D:
        return 'Outfit 3D';
      case PRODUCT_TYPE.AVATAR_2D:
        return 'Avatar 2D';
      case PRODUCT_TYPE.AVATAR_3D:
        return 'Avatar 3D';
      case PRODUCT_TYPE.OUTFIT_2D_PARALLAX:
        return 'Outfit 2D Parallax';
      case PRODUCT_TYPE.SCENE:
        return 'Scene';
      case PRODUCT_TYPE.UNDEFINED:
        return 'Undefined';
      default:
        return '';
    }
  }

  static getProductType(type: PRODUCT_TYPE): IProductType {
    switch (type) {
      case PRODUCT_TYPE.GARMENT_2D:
        return {
          id: PRODUCT_TYPE.GARMENT_2D,
          name: 'GARMENT_2D',
        };
      case PRODUCT_TYPE.GARMENT_3D:
        return {
          id: PRODUCT_TYPE.GARMENT_3D,
          name: 'GARMENT_3D',
        };
      case PRODUCT_TYPE.ACCESSORY_3D:
        return {
          id: PRODUCT_TYPE.ACCESSORY_3D,
          name: 'ACCESSORY_3D',
        };
      case PRODUCT_TYPE.OUTFIT_2D:
        return {
          id: PRODUCT_TYPE.OUTFIT_2D,
          name: 'OUTFIT_2D',
        };
      case PRODUCT_TYPE.OUTFIT_3D:
        return {
          id: PRODUCT_TYPE.OUTFIT_3D,
          name: 'OUTFIT_3D',
        };
      case PRODUCT_TYPE.GARMENT_3D_CAD:
        return {
          id: PRODUCT_TYPE.GARMENT_3D_CAD,
          name: 'GARMENT_3D_CAD',
        };
      case PRODUCT_TYPE.OUTFIT_3D:
        return {
          id: PRODUCT_TYPE.OUTFIT_3D,
          name: 'OUTFIT_3D',
        };
      default:
        return null;
    }
  }
}
