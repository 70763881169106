<span>Filter by:</span>

<rr-filter-bar-element
  *ngFor="let config of filters"
  [config]="config"
  [filterService]="filterService"
  [id]="config.id"
></rr-filter-bar-element>
<div class="clear-all-filters d-flex flex-row" (click)="clearFilter()">
  <span class="rr-close"></span>
</div>
<div class="spacer"></div>
