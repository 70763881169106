import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { PRODUCT_TYPE } from '../../../data';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../app.state';
import { createNewRequestAction } from '../../store/requests-overview.actions';
import { CommonModule } from '@angular/common';
import { Observable, Subject, takeUntil } from 'rxjs';
import { PRODUCT_TYPE_GROUP } from '../../../data/enums/product-type-group.enum';

@Component({
  selector: 'app-create-request-select-type-modal',
  templateUrl: './create-request-select-type-modal.component.html',
  styleUrls: ['./create-request-select-type-modal.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class CreateRequestSelectTypeModalComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public readonly REQUESTS_OPTIONS = [
    {
      order: 0,
      type: PRODUCT_TYPE.GARMENT_2D,
      title: 'Garment 2D',
      description: 'Create a new 2D garment.',
      imageIconUrl: '../../../../assets/img/garment-2d.png',
      group: 0,
      additionalInfo: {},
    },
    {
      order: 0,
      type: PRODUCT_TYPE.OUTFIT_2D_PARALLAX,
      title: 'Fashion Image',
      description: 'Create a Fashion Image',
      imageIconUrl: '../../../../assets/img/outfit-2d.png',
      group: 0,
      additionalInfo: {},
    },
    {
      order: 0,
      type: PRODUCT_TYPE.OUTFIT_2D_PARALLAX,
      title: 'Collection Outfit',
      description: 'Create an outfit to be used in a collection.',
      imageIconUrl: '../../../../assets/img/collection.png',
      group: 0,
      additionalInfo: { isCollectionOutfit: true },
    },
    // {
    //   order: 1,
    //   type: PRODUCT_TYPE.GARMENT_3D_CAD,
    //   title: 'Garment 3D CAD',
    //   description: 'Create a new 3D garment, based on a CAD file.',
    //   imageIconUrl: '../../../../assets/img/garment-3d.png',
    //   group: 0,
    // },
    // {
    //   order: 1,
    //   type: PRODUCT_TYPE.OUTFIT_3D,
    //   title: 'Outfit 3D',
    //   description: 'Create a new 3D outfit.',
    //   imageIconUrl: '../../../../assets/img/outfit-3d.svg',
    //   group: 1,
    // },
  ];
  public readonly MODAL_ID = 'request_type';
  public requests = [];
  private _onDestroy$: Subject<void> = new Subject();
  @Input() selectedModalGroup$: Observable<PRODUCT_TYPE_GROUP>;

  constructor(private _store: Store<IAppState>) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._onDestroy$.unsubscribe();
    this._onDestroy$ = undefined;
  }

  ngOnInit(): void {
    this.selectedModalGroup$
      ?.pipe(takeUntil(this._onDestroy$))
      .subscribe((type) => {
        this.requests = this.REQUESTS_OPTIONS.filter((o) => o.group === type);
      });
  }

  ngAfterViewInit(): void {}

  public goToCreateRequestPage(type: PRODUCT_TYPE, additionalInfo: any): void {
    this._store.dispatch(
      createNewRequestAction({
        productType: type,
        additionalInfo: additionalInfo,
      }),
    );
  }
}
