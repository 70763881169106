<div
  id="spinner-overlay-{{ spinnerStatus.id }}"
  class="spinner-overlay spinner-component"
  *ngIf="spinnerStatus && spinnerStatus.show"
  [style]="
    spinnerStatus.background
      ? 'background:' + spinnerStatus.background + ';'
      : ''
  "
>
  <div class="col-12 h-100 d-flex justify-content-center align-items-center">
    <div
      [ngClass]="{
        'spinner-inner': true,
        'no-loading-text': !spinnerStatus.text
      }"
    >
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="loading-text" *ngIf="spinnerStatus.text">
        {{ spinnerStatus.text }}<span>.</span><span>.</span><span>.</span>
      </div>
    </div>
  </div>
</div>
