import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'defaultValue' })
export class RRDefaultValuePipe<T> implements PipeTransform {
  transform(value: T | undefined, defaultValue: T): T {
    if (value === undefined) return defaultValue;

    return value;
  }
}
