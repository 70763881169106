import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ProductHelper } from 'projects/content-service-cms/src/app/data';
import { RequestRecord } from '../../request-list.page.component';

@Pipe({
  name: 'prettyProductType',
})
export class AssetPrettyProductTypePipe implements PipeTransform {
  transform(record: RequestRecord) {
    const output: string[] = [];
    output.push(
      ProductHelper.mapProductTypeFromEnumToStringName(record.productType),
    );

    if (record.linked && record.linked.length > 0) {
      output.push('•');
      output.push(`${record.linked.length} views`);
    }

    return output.join(' ');
  }
}

@Component({
  selector: 'rr-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss'],
})
export class RequestListAssetComponent implements OnInit {
  @Input() record: RequestRecord;
  @Input() expanded: boolean;

  constructor() {}

  ngOnInit(): void {}
}
