import { Injectable } from '@angular/core';
import {
  AccessoryReference,
  Collection,
  PictofitBuilder,
  VirtualStylingApi,
  VirtualStylingBuilder,
} from '@pictofit/public-types';
import { ICollection } from '../collection.interface';

@Injectable({
  providedIn: 'root',
})
export class PreviewService {
  constructor() {}
  private _api: VirtualStylingApi = undefined;
  private _builder: VirtualStylingBuilder = undefined;

  public show(
    org: string,
    ref: string,
    collection: { id: string; collection: ICollection },
  ) {
    window.onPictofitReady = (builder: PictofitBuilder) => {
      if (!this._api) {
        console.warn('Pictofit ready');
        const pictofitApi = builder
          // We do setup a default organization id, which is an alias for the customer id
          .forOrganisation(org);

        const virtualStylingBuilder = pictofitApi
          // We want to use the virtual styling component
          .asVirtualStyling();
        this._builder = virtualStylingBuilder;

        //const collection = virtualStylingBuilder.getCollection(ref, org);

        const mappedCollection = this.mapCollection(collection);

        if (collection !== null) {
          if (!this._api) {
            virtualStylingBuilder.build().then(({ api }) => {
              this._api = api;
              this._api.show(Promise.resolve(mappedCollection), {
                reference: ref,
                organizationId: org,
              });
            });
          }
        }
      } else {
        const mappedCollection = this.mapCollection(collection);
        this._api.show(Promise.resolve(mappedCollection), {
          reference: ref,
          organizationId: org,
        });
      }
    };
  }

  private mapCollection(collection: {
    id: string;
    collection: ICollection;
  }): Collection {
    const outfits = collection.collection.outfits;
    const mappedOutfits = outfits.map((outfit) => {
      return {
        ...outfit,
        accessories: outfit.accessories?.map((accessory) => {
          return {
            position: accessory.position,
            organizationId: accessory.organizationId,
            reference: accessory.reference,
          } as AccessoryReference;
        }),
      };
    });

    return {
      outfits: mappedOutfits,
      avatars: collection.collection.avatars,
      garments: collection.collection.garments,
      info: collection.collection.info,
      scenes: collection.collection.scenes,
    };
  }
}
