<ng-template #selectAvatar>
  <app-add-avatar-modal
    (selectedAvatar)="onAvatarSelected($event)"
    [productType]="avatarType"
  ></app-add-avatar-modal>
</ng-template>

<app-add-item
  *ngIf="selectedAvatar === undefined || selectedAvatar === null"
  title="Avatar"
  info="Select an Avatar"
  (addItemClicked)="avatarSelectionService.selectAvatar()"
>
</app-add-item>
<div
  *ngIf="selectedAvatar !== undefined && selectedAvatar !== null"
  (click)="
    modalService.show({ title: 'Select Avatar', template: selectAvatar })
  "
  class="d-flex flex-row row entry align-items-center"
>
  <div class="p2 ml-4 mt-3 mb-3">
    <img
      src="{{ selectedAvatar.previewUrl | avatarThumbnailAvailable }}"
      class="thumbnail"
    />
  </div>
  <div class="d-flex flex-column mt-3 mb-3 ml-3">
    <span class="ml-3 mr-1">{{ selectedAvatar?.name }}</span>
    <span class="type ml-3 mr-1">{{
      selectedAvatar?.productTypeId | productType
    }}</span>
  </div>
  <div class="p2 ml-auto mr-2 delete">
    <span class="rr-pen"></span>
  </div>
</div>
