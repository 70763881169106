import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CreateRequestModalStore } from '../create-request-modal.state';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ActionButtonsComponent implements OnInit, OnDestroy {
  public mouseOver: boolean;
  public showDropDown: boolean;
  @ViewChild('#dropdown') dropDown: ElementRef;

  constructor(
    public createRequestModalStore: CreateRequestModalStore,
    private eRef: ElementRef,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  cancelClicked = () => {
    this.createRequestModalStore.cancelAction();
  };

  createRequestClicked = () => {
    this.createRequestModalStore.createRequestAction();
    this.showDropDown = false;
  };
  saveDraftRequestClicked = () => {
    this.createRequestModalStore.saveDraftAction();
    this.showDropDown = false;
  };
  onDropDownClicked() {
    this.showDropDown = !this.showDropDown;
  }
  clickOutside(event: MouseEvent) {
    this.showDropDown = false;
  }
  saveRequestAndAnotherOneClicked() {
    this.createRequestModalStore.createRequestActionAnAnotherOne();
    this.showDropDown = false;
  }
}
