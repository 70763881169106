import { Injectable } from '@angular/core';
import { RRIBaseModel } from '../../common/interfaces/base-model.interface';
import { RRISort } from '../../common/interfaces/base-sort.interface';
import { RRClientSideDataModel } from '../models/client-side-data.model';

@Injectable({
  providedIn: 'root',
})
export class RRClientSideDataService<T extends RRIBaseModel> {
  private cache: RRClientSideDataModel<T>;

  constructor() {
    this.cache = this.getEmtpyClientSideData();
  }

  public getCachedData(): RRClientSideDataModel<T> {
    return this.cache;
  }

  public setCachedData(data: RRClientSideDataModel<T>): void {
    this.cache = data;
  }

  public onPageChange(newPage: number): Promise<T[]> {
    this.cache = {
      ...this.cache,
      currentPage: newPage,
    };
    return this.getTableRows();
  }

  public onPageSizeChange(newPageSize: number): Promise<T[]> {
    this.cache = {
      ...this.cache,
      pageSize: newPageSize,
    };
    return this.getTableRows();
  }

  public onSortChange(sortBy: RRISort<T>): Promise<T[]> {
    this.cache.sortBy = sortBy;
    return this.getTableRows();
  }

  public deleteEntry(entry: T): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.cache.results.some((e: T) => e.id === entry.id)) {
        this.cache.results = this.cache.results.filter(
          (e) => e.id !== entry.id,
        );
        this.cache.recordCount = this.cache.results.length;
        resolve(true);
      } else {
        reject(false);
      }
    });
  }

  public getEmtpyClientSideData(): RRClientSideDataModel<T> {
    return {
      results: [],
      sortBy: {
        fieldName: 'id',
        desc: true,
      },
      recordCount: 0,
      pageSize: 1,
      currentPage: 1,
      pageCount: 1,
    };
  }

  getTableRows(): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      if (!this.cache || !this.cache.results || !this.cache.results.length) {
        reject(this.getEmtpyClientSideData());
      } else {
        const returnModel = this.filterTableContainer();
        if (returnModel === null) {
          resolve([]);
        } else {
          resolve(returnModel);
        }
      }
    });
  }

  public filterTableContainer(): T[] {
    const filterData = { ...this.cache };
    let newClienSideDataModel = new RRClientSideDataModel<T>();
    let newResults: T[] = [];
    if (
      typeof filterData.results !== 'undefined' &&
      filterData.results.length
    ) {
      newClienSideDataModel.results = this.sortTableViewContainer(
        filterData.results,
        filterData.sortBy,
      );
      newClienSideDataModel.recordCount = filterData.results.length;
      newClienSideDataModel.pageSize = filterData.pageSize;
      newClienSideDataModel.currentPage = filterData.currentPage;
      newClienSideDataModel.pageCount = Math.ceil(
        filterData.results.length / filterData.pageSize,
      );

      const firstItemIndex =
        filterData.currentPage * filterData.pageSize - filterData.pageSize;
      const lastItemIndex = firstItemIndex + filterData.pageSize;

      newResults = filterData.results.slice(firstItemIndex, lastItemIndex);
    } else {
      newClienSideDataModel = this.getEmtpyClientSideData();
    }

    this.setCachedData(newClienSideDataModel);
    return newResults;
  }

  private sortTableViewContainer(data: T[], sortBy: RRISort<T>): Array<any> {
    if (data.length && sortBy && sortBy.fieldName) {
      const fieldToSort = `${String(sortBy.fieldName)}`;
      data.sort(this.sortList(fieldToSort, sortBy.desc));
    }

    return data;
  }

  private sortList(property: string, descOrder: boolean = true) {
    const sortOrder = descOrder ? -1 : 1;

    return (a, b) =>
      sortOrder == -1
        ? String(b[property]).localeCompare(a[property])
        : String(a[property]).localeCompare(b[property]);
  }
}
