import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  JSON: { input: any; output: any };
};

/**
 * The asset that holds files, for a specific virtual dressing platform
 *
 */
export type Asset = {
  __typename?: 'Asset';
  /** The files that belong to this asset */
  files: Array<AssetFile>;
  /** The format of this asset */
  format: ResultFormat;
  /** The id of this asset */
  id: Scalars['String']['output'];
  /** The content of the metadata.json file */
  metadata?: Maybe<Scalars['JSON']['output']>;
};

/**
 * An asset file
 *
 */
export type AssetFile = {
  __typename?: 'AssetFile';
  /** The name of this asset file */
  fileName: Scalars['String']['output'];
  /** The url to call with a GET method to receive the content of this file */
  getLocation: Scalars['String']['output'];
  /** The url to call with a HEAD method to receive meta information of this file */
  headLocation: Scalars['String']['output'];
  /** The id of this asset file */
  id: Scalars['String']['output'];
};

/**
 * The setting for configuring the managed MDRC
 *
 */
export type Mdrcsetting = {
  __typename?: 'Mdrcsetting';
  /** The id of this mdrc setting */
  id: Scalars['String']['output'];
  /** The content of the mdrc settings */
  setting: Scalars['JSON']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Create a new VDR session
   *
   * ## Arguments
   *
   * * `forOrganization` - The organization slug (or id)
   * * `forBrowser` - The browser id
   *
   * ## Response
   *
   * * `organization` - The organization id
   * * `browser` - The browser id
   * * `session` - The session id
   *
   */
  createSession: Session;
};

export type MutationCreateSessionArgs = {
  forBrowser?: InputMaybe<Scalars['String']['input']>;
  forOrganization: Scalars['String']['input'];
};

export enum ProductType {
  Accessory_3D = 'ACCESSORY_3D',
  Animation = 'ANIMATION',
  Avatar_2D = 'AVATAR_2D',
  Avatar_3D = 'AVATAR_3D',
  Avatar_3DLegacy = 'AVATAR_3D_LEGACY',
  AvatarCreationData = 'AVATAR_CREATION_DATA',
  AvatarHead_2D = 'AVATAR_HEAD_2D',
  AvatarHead_3D = 'AVATAR_HEAD_3D',
  AvatarHeadLegacy = 'AVATAR_HEAD_LEGACY',
  Garment_2D = 'GARMENT_2D',
  Garment_3D = 'GARMENT_3D',
  Garment_3DCad = 'GARMENT_3D_CAD',
  GhostGarment_3D = 'GHOST_GARMENT_3D',
  Mannequin_3D = 'MANNEQUIN_3D',
  Material = 'MATERIAL',
  Outfit_2D = 'OUTFIT_2D',
  Outfit_2DParallax = 'OUTFIT_2D_PARALLAX',
  Outfit_3D = 'OUTFIT_3D',
  Scene = 'SCENE',
  SizingSettings = 'SIZING_SETTINGS',
  Undefined = 'UNDEFINED',
}

export type Query = {
  __typename?: 'Query';
  /**
   * Find public metadata for an asset
   *
   * ## Arguments
   *
   * * `forOrganization` - The organization slug (or id) that holds the asset (min. length of 3)
   * * `forReferences` - A list of references that should be fetched (max. 100 entries)
   * * `forFormat` - A list of result formats mostly WEB or MOBILE that should be fetched
   * * `forKind` - The list of request kinds mostly GARMENT_2D, GARMENT_3D or GARMENT_3D_CAD
   *
   * ## Response
   *
   * * `references` - A list of registered external references of this object
   * * `requests` - A list of requests that are associated with this metadata
   * Each request will have a different kind, so it can be used for
   * different dressing room modes, like 2D, 3D.
   *
   *
   */
  findMetadata: Array<Request>;
  /**
   * Find mdrc setting
   *
   * ## Arguments
   *
   * * `forOrganization` - The organization id that holds the asset (min. length of 3)
   * * `forReference` - An optional reference that should be fetched
   *
   * ## Response
   *
   * Optional setting response, might be null if none was found.
   *
   * * `id` - The id of the setting that was fetched
   * * `setting` - The json value of the setting.
   *
   *
   */
  findSetting?: Maybe<Mdrcsetting>;
  /**
   * Get public metadata for an asset by ids
   *
   * ## Arguments
   *
   * * `forIds` - A list of ids for assets (max. 100 entries)
   * * `forFormat` - A list of result formats mostly WEB or MOBILE that should be fetched
   * * `forKind` - The list of request kinds mostly GARMENT_2D, GARMENT_3D or GARMENT_3D_CAD
   *
   * ## Response
   *
   * * `references` - A list of registered external references of this object
   * * `requests` - A list of requests that are associated with this metadata
   * Each request will have a different kind, so it can be used for
   * different dressing room modes, like 2D, 3D.
   *
   *
   */
  getMetadata: Array<Request>;
  /**
   * Get stock metadata resoures
   *
   * ## Arguments
   *
   * * `forFormat` - A list of result formats mostly WEB or MOBILE that should be fetched
   * * `forKind` - The list of request kinds mostly GARMENT_2D, GARMENT_3D or GARMENT_3D_CAD
   *
   * ## Response
   *
   * * `references` - A list of registered external references of this object
   * * `requests` - A list of requests that are associated with this metadata
   * Each request will have a different kind, so it can be used for
   * different dressing room modes, like 2D, 3D.
   *
   *
   */
  getStockResources: Array<Request>;
};

export type QueryFindMetadataArgs = {
  forFormat?: InputMaybe<Array<ResultFormat>>;
  forKind?: InputMaybe<Array<ProductType>>;
  forOrganization: Scalars['String']['input'];
  forReferences: Array<Scalars['String']['input']>;
};

export type QueryFindSettingArgs = {
  forOrganization: Scalars['String']['input'];
  forReference?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetMetadataArgs = {
  forFormat?: InputMaybe<Array<ResultFormat>>;
  forIds: Array<Scalars['String']['input']>;
  forKind?: InputMaybe<Array<ProductType>>;
};

export type QueryGetStockResourcesArgs = {
  forFormat: Array<ResultFormat>;
  forKind: Array<ProductType>;
};

/**
 * Each request will have a different kind, so it can be used for
 * different dressing room modes, like 2D, 3D
 *
 */
export type Request = {
  __typename?: 'Request';
  /**
   * The file assets to be used in the dressing room, there are different
   * formats in the different assets like WEB or MOBILE.
   * Select the one that matches your platform.
   */
  assets: Array<Asset>;
  /** The id of this request */
  id: Scalars['String']['output'];
  /** The kind of the request. */
  kind: ProductType;
  /** The request name */
  name?: Maybe<Scalars['String']['output']>;
  /** The organization this product belongs to */
  organization: Scalars['String']['output'];
  /**
   * A list of registered external references of this object
   *
   */
  references: Array<Scalars['String']['output']>;
  /** The tags that belong to this request */
  tags: Array<Tag>;
  /** The url to call with a GET method to receive the thumbnail for this request */
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export enum ResultFormat {
  /** This should be used when working with a mobile SDK */
  Mobile = 'MOBILE',
  /** This is the raw result, other formats are derived from this */
  Raw = 'RAW',
  /** This should not happen */
  Undefined = 'UNDEFINED',
  /** This should be used when working with the web SDK */
  Web = 'WEB',
  /** This is the same as WEB but packaged into a zip file */
  WebZip = 'WEB_ZIP',
}

/** A session information object */
export type Session = {
  __typename?: 'Session';
  /** The browser identifier */
  browser: Scalars['String']['output'];
  /** The organization id of this session */
  organization: Scalars['String']['output'];
  /** The session identifier */
  session: Scalars['String']['output'];
};

/**
 * The tag that add additional information to an object
 *
 */
export type Tag = {
  __typename?: 'Tag';
  /** The id of this tag */
  id: Scalars['String']['output'];
  /** The key of this tag */
  key: Scalars['String']['output'];
  /** The value of this tag */
  value: Scalars['String']['output'];
};

export type FindSettingQueryVariables = Exact<{
  org: Scalars['String']['input'];
  reference: Scalars['String']['input'];
}>;

export type FindSettingQuery = {
  __typename?: 'Query';
  findSetting?: { __typename?: 'Mdrcsetting'; id: string; setting: any } | null;
};

export const FindSettingDocument = gql`
  query findSetting($org: String!, $reference: String!) {
    findSetting(forOrganization: $org, forReference: $reference) {
      id
      setting
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
  _variables,
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    findSetting(
      variables: FindSettingQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<FindSettingQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FindSettingQuery>(FindSettingDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'findSetting',
        'query',
        variables,
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
