import { NgModule } from '@angular/core';
import { StatusBarComponent } from './components/status-bar/status-bar.component';
import { RRSharedModule } from '../shared-module/shared.module';
import { RRBootstrapModule } from '../bootstrap/bootstrap.module';

@NgModule({
  declarations: [StatusBarComponent],
  imports: [RRSharedModule, RRBootstrapModule],
  exports: [StatusBarComponent],
})
export class RRStatusBarModule {}
