<tr (click)="openRequest(record)" id="overview-list-row-{{ record.id }}">
  <td>
    <span
      (click)="toggleExpanded()"
      class="oi"
      [class]="expanded ? 'oi-caret-bottom' : 'oi-caret-right'"
      *ngIf="record.linked"
    ></span>
  </td>

  <td>
    <rr-asset [record]="record"></rr-asset>
  </td>

  <td>
    <div>
      <rr-state [record]="record"></rr-state>
    </div>
  </td>

  <td>
    <rr-product-type [record]="record"></rr-product-type>
  </td>

  <td>
    <div>{{ record.createdAt | prettyProductDate }}</div>
  </td>

  <td>
    <div>
      <rr-tag *ngFor="let tag of record.tags" [tag]="tag"></rr-tag>
    </div>
  </td>
</tr>
<tr
  *ngFor="let linked of record.linked"
  class="linked"
  [class]="expanded ? 'expanded' : ''"
  (click)="openRequest(linked)"
>
  <td></td>

  <td>
    <rr-asset [record]="linked"></rr-asset>
  </td>

  <td>
    <div>
      <rr-state [record]="linked"></rr-state>
    </div>
  </td>

  <td>
    <div>{{ linked.createdAt | prettyProductDate }}</div>
  </td>

  <td>
    <div>
      <rr-tag *ngFor="let tag of linked.tags" [tag]="tag"></rr-tag>
    </div>
  </td>

  <td>
    <span (click)="openRequest(linked)" class="oi oi-ellipses"></span>
  </td>
</tr>
