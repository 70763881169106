import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-row-buttons',
  standalone: true,
  imports: [],
  templateUrl: './row-buttons.component.html',
  styleUrls: ['./row-buttons.component.scss'],
})
export class RowButtonsComponent<T> {
  @Input() reference: T;
  @Output() moveRowUp: EventEmitter<T> = new EventEmitter();
  @Output() moveRowDown: EventEmitter<T> = new EventEmitter();
  @Output() removeRow: EventEmitter<T> = new EventEmitter();
}
