<ng-content
  select="[emptyState]"
  *ngIf="!showLoader && displayedRecords.length === 0"
>
</ng-content>
<div class="table-container">
  <table>
    <thead *ngIf="showLoader || (!showLoader && displayedRecords.length > 0)">
      <tr>
        <th
          *ngFor="let col of configuration.columns"
          [ngClass]="{ sortable: !col.hideSort }"
          (click)="sort(col)"
        >
          {{ col.title }}
          <img
            *ngIf="!col.hideSort && !col.sort"
            src="../../../../assets/icons/sort.svg"
          />
          <img
            *ngIf="!col.hideSort && col.sort === 'ASC'"
            src="../../../../assets/icons/sort-asc.svg"
          />
          <img
            *ngIf="!col.hideSort && col.sort === 'DESC'"
            src="../../../../assets/icons/sort-desc.svg"
          />
        </th>
      </tr>
    </thead>
    <ng-container>
      <ng-container
        rrTableRow
        *ngFor="let record of displayedRecords"
        [record]="record"
        [component]="configuration.rowComponent"
        (loadingDone)="loadingDone()"
      ></ng-container>
    </ng-container>
  </table>
  <ngx-skeleton-loader
    *ngIf="showLoader"
    class="loader"
    count="10"
    appearance="line"
    [theme]="{
      'border-radius': '8px',
      'background-color': '#f5f0eb'
    }"
  ></ngx-skeleton-loader>
</div>
