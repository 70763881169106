import { v4 as uuid } from 'uuid';
import { RRIBaseModel } from 'projects/web-ui-component-library/src';

export class File implements RRIBaseModel {
  id: uuid;
  configurationId: uuid;
  name = '';
  typeId = 0;
  typeName = '';
  mimeType = '';
  mimeTypeId: number = 0;
  currentRevisionId: number = 0;
  url = '';
  size: number;
  createdAt: number;
  modifiedAt: number;
  text: string;
  previewUrl?: string;

  toDataFormat(): { [key: string]: string } {
    return {
      id: String(this.id),
      configurationId: String(this.configurationId),
      name: String(this.name),
      typeId: String(this.typeId),
    };
  }
}
