import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  PRIMARY_OUTLET,
  Router,
} from '@angular/router';
import {
  RRAlertModel,
  RRBreadcrumbModel,
} from 'projects/web-ui-component-library/src';
import { filter, map } from 'rxjs/operators';
import { AlertService } from './core/services/alert.service';
import { BreadcrumbService } from './core/services/breadcrumb.service';
import { NgStorageManagerService } from './core/services/ng-storage-manager.service';
import { IAuthService } from './auth/api';
import { BehaviorSubject } from 'rxjs';
import { CreateRequestInputData } from './request-overview/components/create-request-modal/create-request-input.interface';
import { CreateRequestModalStore } from './request-overview/components/create-request-modal/create-request-modal.state';
import { ModalService } from './modal/modal.service';
import { PRODUCT_TYPE_GROUP } from './data/enums/product-type-group.enum';
import { CollectionScreenStore } from './collection-screen/collection-screen.state';

@Component({
  selector: 'rr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CreateRequestModalStore, CollectionScreenStore],
})
export class AppComponent implements OnInit {
  public isAuthenticated: boolean;
  public alerts: RRAlertModel;
  public showModal: boolean;
  public createInput$: BehaviorSubject<CreateRequestInputData> =
    new BehaviorSubject(undefined);
  public selectedModalGroup$ = new BehaviorSubject<PRODUCT_TYPE_GROUP>(
    PRODUCT_TYPE_GROUP.PRODUCTS,
  );
  @ViewChild('createNewRequest') createNewRequest: TemplateRef<any>;
  @ViewChild('createNewRequestHeader') createNewRequestHeader: TemplateRef<any>;
  @ViewChild('logoutPrompt') logoutPrompt: TemplateRef<any>;
  @ViewChild('createNewCollection') createNewCollection: TemplateRef<any>;
  constructor(
    private _authService: IAuthService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _breadcrumbService: BreadcrumbService,
    private _alertService: AlertService,
    private _storageService: NgStorageManagerService,
    private createRequestModalStore: CreateRequestModalStore,
    private modalService: ModalService,
  ) {
    this.isAuthenticated = false;
    this.createRequestModalStore.startRequestAction$
      .pipe(filter((show) => show))
      .subscribe(() =>
        this.modalService.show({
          title: 'Create New Request',
          headerTemplate: this.createNewRequestHeader,
          template: this.createNewRequest,
          onClose: () => {
            this.createRequestModalStore.resetStartRequestAction();
          },
        }),
      );
  }

  ngOnInit() {
    this._alertService.subscribeAlert.subscribe((data: RRAlertModel) => {
      this.alerts = data;
    });

    this._authService.loginChanged$.subscribe((res: boolean) => {
      this.isAuthenticated = res;
    });

    this._authService.logOutClicked$.subscribe(() => {
      this.logout();
    });

    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this._activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
      )
      .pipe(filter((route) => route.outlet === PRIMARY_OUTLET))
      .subscribe((route) => {
        this._breadcrumbService.resetBreadcrumbs();

        const thisPath = route.snapshot.routeConfig.path;
        const activeRouteIndex = this._router.config.indexOf(
          this._router.config.find((x) => x.path == thisPath),
        );
        if (activeRouteIndex > -1 && thisPath != '') {
          this._breadcrumbService.getBreadcrumb(activeRouteIndex, route);
        }
      });
  }

  public getBreadcrumbs(): RRBreadcrumbModel[] {
    return this._breadcrumbService.breadcrumbs;
  }

  public login(): void {
    this._storageService.clearLocation('session');
    this._storageService.clearLocation('local');
    this._authService.login();
  }

  public logout(): void {
    this.modalService.show({
      title: '',
      template: this.logoutPrompt,
    });
  }
  public showHelpModal() {
    this.showModal = true;
  }
  public goToDocumentation() {
    window.open('https://docs.pictofit.com', '_blank');
  }

  cancelClicked() {
    this.modalService.close();
  }
  logoutClicked() {
    this._authService.logout();
  }
}
