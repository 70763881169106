import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VirtualDressingRoomComponent } from './virtual-dressing-room/virtual-dressing-room.component';
import { OverlaySpinnerComponent } from './components/overlay-spinner/overlay-spinner.component';
import { DataModule } from './data';

@NgModule({
  declarations: [VirtualDressingRoomComponent, OverlaySpinnerComponent],
  exports: [VirtualDressingRoomComponent],
  imports: [CommonModule, DataModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class VirtualDressingRoomModule {}
