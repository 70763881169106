import { Injectable } from '@angular/core';
import {
  RRIStackedBarData,
  SelectedFilters,
} from 'projects/web-ui-component-library/src';
import { Observable, from, take } from 'rxjs';
import { NgStorageManagerService } from '../../core/services/ng-storage-manager.service';
import { IResquestStateCount, RequestStateHelper } from '../../data';
import { RequestFilters } from '../components/request-list-action-bar/request-list-action-bar.component';

@Injectable({
  providedIn: 'root',
})
export class RequestOverviewService {
  constructor(private _storageService: NgStorageManagerService) {}

  public buildRequestsOverviewChartData(
    data: IResquestStateCount[],
  ): RRIStackedBarData[] {
    if (!Array.isArray(data)) return [];

    let total = 0;
    const chartData = data.reduce((accum, currentValue) => {
      const customerStateId =
        RequestStateHelper.mapInternatStateToCustomerState(
          currentValue.stateId,
        );
      const stateName =
        RequestStateHelper.getCustomerStateLabel(customerStateId);
      const state = accum
        ? accum.find((a) => a.data === customerStateId)
        : undefined;
      total = total + currentValue.count;
      if (state !== undefined) {
        state.count += currentValue.count;
      } else {
        if (accum === undefined) accum = [];
        accum.push({
          data: customerStateId,
          count: currentValue.count,
          label: stateName,
          value: currentValue.count,
          percentage: 0,
          color: RequestStateHelper.getCustomerStateColor(customerStateId),
        });
      }
      return accum;
    }, []);

    chartData.forEach((state) => {
      if (total !== 0) {
        state.percentage = state.count / total;
      } else {
        state.percentage = 1 / chartData.length;
      }
    });

    return chartData;
  }

  public setFiltersFromStorage(): SelectedFilters<RequestFilters> {
    const storedFilters: SelectedFilters<RequestFilters> = JSON.parse(
      this._storageService.getItem('filters', 'local'),
    );
    if (!storedFilters) return {};
    return storedFilters;
  }

  public loadFilters(): Observable<SelectedFilters<RequestFilters>> {
    return this._storageService.getItem$('filters', 'local').pipe(take(1));
  }

  public storeFilters(
    filters: SelectedFilters<RequestFilters>,
  ): Observable<void> {
    return from(
      new Promise<void>((resolve) => {
        this._storageService.setItem('filters', filters, 'local');
        resolve();
      }),
    );
  }
}
