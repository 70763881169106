import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RRBreadcrumbModel } from 'projects/web-ui-component-library/src';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { IRequestDetail, IRequestService } from '../../data';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public breadcrumbs: RRBreadcrumbModel[];

  constructor(
    private router: Router,
    private _logger: ILogger,
    private _newRequestService: IRequestService,
  ) {
    this.breadcrumbs = [];
  }

  public resetBreadcrumbs() {
    this.breadcrumbs = [];
  }

  public getBreadcrumb(
    index: number,
    route: ActivatedRoute,
    currentId?: string,
    routeType?: string,
  ): void {
    if (
      !currentId &&
      typeof route.snapshot.params !== 'undefined' &&
      typeof route.snapshot.params.id !== 'undefined'
    ) {
      currentId = route.snapshot.params.id;
    }
    if (
      !routeType &&
      typeof route.snapshot.data !== 'undefined' &&
      typeof route.snapshot.data.type !== 'undefined'
    ) {
      routeType = route.snapshot.data.type;
    }

    let label = this.router.config[index].path;
    if (
      typeof this.router.config[index].data !== 'undefined' &&
      typeof this.router.config[index].data['breadcrumb'] !== 'undefined'
    ) {
      label = this.router.config[index].data['breadcrumb'];
    }

    const pathParameters = this.router.config[index].path.split('/');
    pathParameters.forEach((path, index) => {
      if (path.includes(':')) {
        pathParameters[index] = routeType
          ? currentId
          : route.snapshot.params[path.replace(':', '').trim()];
      }
    });
    if (routeType && currentId) {
      if (routeType == 'results') {
        this._newRequestService
          .getResult(currentId)
          .then((data) => {
            this._newRequestService
              .getRequestByID(data.configurationId)
              .then((data) => {
                this.addBreadcrumb(
                  pathParameters,
                  label,
                  index,
                  route,
                  data,
                  data.id,
                  'configurations',
                );
              })
              .catch((error) => {
                this._logger.error('Error', error);
              });
          })
          .catch((error) => {
            this._logger.error('Error', error);
          });
      } else {
        this.addBreadcrumb(pathParameters, label, index, route);
      }
    } else {
      this.addBreadcrumb(pathParameters, label, index, route);
    }
  }

  private addBreadcrumb(
    pathParameters: string[],
    label: string,
    index: number,
    route: ActivatedRoute,
    dropdown?: IRequestDetail,
    parentId?: string,
    routeType?: string,
  ): void {
    const tooltip = this.getBreadcrumbTooltip(dropdown);
    this.breadcrumbs.unshift({
      url: pathParameters.join('/'),
      label: label,
      tooltip,
    });

    if (
      typeof this.router.config[index].data !== 'undefined' &&
      typeof this.router.config[index].data['breadcrumbParentPath'] !==
        'undefined'
    ) {
      const parentRouteIndex = this.router.config.indexOf(
        this.router.config.find(
          (x) =>
            x.path == this.router.config[index].data['breadcrumbParentPath'],
        ),
      );
      if (
        parentRouteIndex > -1 &&
        this.router.config[parentRouteIndex].path != ''
      ) {
        this.getBreadcrumb(parentRouteIndex, route, parentId, routeType);
      }
    }
  }

  private getBreadcrumbTooltip(dropdown?: any): string {
    let tooltip: string = null;
    if (dropdown) {
      tooltip = '';
      if (dropdown.id) {
        tooltip += `
            <div>
              <span class="bc-label"> Id: </span>
              <span class="bc-value">
                ${dropdown.id}
              </span>
            </div>
          `;
      }

      if (dropdown.name) {
        tooltip += `
            <div>
              <span class="bc-label"> Name: </span>
              <span class="bc-value">
                ${dropdown.name}
              </span>
            </div>
          `;
      }

      if (dropdown.sku) {
        tooltip += `
            <div>
              <span class="bc-label"> SKU: </span>
              <span class="bc-value">
                ${dropdown.sku}
              </span>
            </div>
          `;
      }

      if (dropdown.customerName) {
        tooltip += `
            <div>
              <span class="bc-label"> Customer Name: </span>
              <span class="bc-value">
                ${dropdown.customerName}
              </span>
            </div>
          `;
      }

      if (dropdown.createdAt) {
        tooltip += `
            <div>
              <span class="bc-label"> Created At: </span>
              <span class="bc-value">
                ${new Date(dropdown.createdAt * 1000).toLocaleString()}
              </span>
            </div>
          `;
      }

      if (dropdown.dueAt) {
        tooltip += `
            <div>
              <span class="bc-label"> Due At: </span>
              <span class="bc-value">
                ${new Date(dropdown.dueAt * 1000).toLocaleString()}
              </span>
            </div>
          `;
      }
    }

    return tooltip;
  }
}
