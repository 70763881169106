import { Injectable } from '@angular/core';
import { ComputeServer } from '@reactivereality/pictofit-web-sdk';
import { IAuthService } from '../../auth';
import { NgDiscoveryService } from '../../core/services/ng-discovery.service';

@Injectable({
  providedIn: 'root',
})
export class WebSDKService {
  public computeServer: ComputeServer;

  constructor(
    private authService: IAuthService,
    private discovery: NgDiscoveryService,
  ) {
    this.computeServer = new ComputeServer(
      this.discovery.config.apiConfiguration.computeServerUrl,
      {
        Authorization: `Bearer ${this.authService.getToken()}`,
      },
    );
  }
}
