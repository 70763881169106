<div class="month" *ngIf="displayed">
  <span (click)="skipMonth(-1)">&#10094;</span>
  <span>
    {{ displayed.month }}<br /><span style="font-size: 18px">{{
      displayed.year
    }}</span>
  </span>
  <span (click)="skipMonth(1)">&#10095;</span>
</div>

<div class="weekdays">
  <span *ngFor="let day of days()">{{ day }}</span>
</div>

<div class="days" *ngIf="displayed">
  <div
    *ngFor="let day of displayed.days"
    class="
      {{ day.month !== displayed.date.getMonth() ? 'empty' : '' }}
      {{ day.date.getTime() === selected?.from?.getTime() ? 'selected' : '' }}
      {{
      day.date.getTime() <= selected?.to?.getTime() &&
      day.date.getTime() >= selected?.from?.getTime()
        ? 'selected'
        : ''
    }}
      {{
      day.date.getTime() > selected?.from?.getTime() &&
      day.date.getTime() <= hovered?.getTime()
        ? 'hovered'
        : ''
    }}
    "
    (click)="onSelectDate(day.year, day.month, day.day, $event)"
    (mouseenter)="onHoverDate(day.year, day.month, day.day)"
  >
    {{ day.day }}
  </div>
</div>
