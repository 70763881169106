import { NgModule, inject } from '@angular/core';
import { CanMatchFn, RouterModule, Routes } from '@angular/router';
import { AuthRoutes, IAuthService } from './auth';
import { NoPermissionPageComponent } from './auth/pages/no-permission-page/no-permission-page.component';
import { PatientPageComponent } from './auth/pages/patient/patient.page.component';
import { ProfilePageComponent } from './profile/components/profile-page/profile-page.component';
import { CollectionScreenComponent } from './collection-screen/collection-screen.component';
import { filter } from 'rxjs';

const canMatchCollectionCreator: CanMatchFn = () => {
  const r = inject(IAuthService).isCollectionCreator$.pipe(
    filter((isCollectionCreator) => isCollectionCreator !== undefined),
  );
  return r;
};

const routes: Routes = [
  {
    path: 'patient',
    component: PatientPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'no-permission',
    component: NoPermissionPageComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: '/requests',
    pathMatch: 'full',
  },
  {
    path: 'requests',
    loadChildren: () =>
      import('./request-overview/request-overview.module').then(
        (m) => m.RequestOverviewModule,
      ),
  },
  {
    path: 'requests/:id',
    loadChildren: () =>
      import('./request-detail/request-detail.module').then(
        (m) => m.RequestDetailModule,
      ),
  },
  {
    path: 'profile',
    component: ProfilePageComponent,
    pathMatch: 'full',
  },
  {
    path: 'collections',
    canMatch: [canMatchCollectionCreator],
    loadChildren: () =>
      import('./collection-overview/collection-overview.module').then(
        (m) => m.CollectionOverviewModule,
      ),
  },
  {
    path: 'collections/:id',
    canMatch: [canMatchCollectionCreator],
    component: CollectionScreenComponent,
  },
  {
    path: 'collections/create',
    canMatch: [canMatchCollectionCreator],
    component: CollectionScreenComponent,
  },

  ...AuthRoutes,
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
