import {
  AfterViewInit,
  Component,
  Input,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {
  REQUEST_STATE_CMS,
  RequestStateHelper,
} from 'projects/content-service-cms/src/app/data';
import { RequestRecord } from '../../request-list.page.component';

@Pipe({
  name: 'prettyProductState',
})
export class PrettyProductStatePipe implements PipeTransform {
  transform(record: RequestRecord) {
    if (record.linked && record.linked.length > 0) {
      return 'Multiple';
    }

    return RequestStateHelper.getCustomerStateLabel(record.state);
  }
}

@Component({
  selector: 'rr-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss'],
})
export class StateComponent implements AfterViewInit {
  @Input() record: RequestRecord;

  labelProductState: string = '';
  circleProductState: string = '';

  constructor() {}

  ngAfterViewInit(): void {
    if (!this.record) return;

    this.labelProductState = `label-${this.classForState(this.record.state)}`;
    this.circleProductState = `circle-${this.classForState(this.record.state)}`;
  }

  classForState(state: REQUEST_STATE_CMS) {
    if (this.record.linked && this.record.linked.length > 0) {
      return 'state-multiple';
    }

    switch (state) {
      case REQUEST_STATE_CMS.IN_PROGRESS:
        return 'state-in-progress';
      case REQUEST_STATE_CMS.CREATED:
        return 'state-created';
      case REQUEST_STATE_CMS.FINISHED:
        return 'state-finished';
      case REQUEST_STATE_CMS.REJECTED:
        return 'state-rejected';
      case REQUEST_STATE_CMS.CANCELED:
        return 'state-canceled';
      case REQUEST_STATE_CMS.APPROVED:
        return 'state-approved';
      default:
        return 'state-gray';
    }
  }
}
