import { Injectable } from '@angular/core';
import {
  RRIBatchOption,
  RRIDownloadBatchOptionSettings,
  RR_ALERT_TYPE,
} from 'projects/web-ui-component-library/src';
import { Observable, Subject, forkJoin } from 'rxjs';
import { ProgressBarService } from 'projects/content-service-cms/src/app/core/services/progress-bar.service';
import { AlertService } from 'projects/content-service-cms/src/app/core/services/alert.service';
import { map } from 'rxjs/operators';
import { DataService } from 'projects/content-service-cms/src/app/core/services/data.service';
import { HttpClient } from '@angular/common/http';
import * as streamSaver from 'streamsaver';
import { WritableStream } from 'web-streams-polyfill';
import { FileRevision } from 'projects/content-service-cms/src/app/core/models/files/file-revision.model';
import { File } from 'projects/content-service-cms/src/app/core/models/files/file.model';
import { ZIP } from '../../../assets/lib/zip-stream/zip-stream';
import {
  IApiSdkService,
  ICsApiSdkConfiguration,
  IRequestResultFile,
} from 'projects/content-service-cms/src/app/data';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { IFileDownloadService } from '../api';
import { ConfigurationResultDTO } from '@reactivereality/cs-api-sdk';
import { saveAs } from 'file-saver';
import { NgDiscoveryService } from '../../core/services/ng-discovery.service';
//@ts-ignore
import JSZip from 'jszip';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService<T>
  extends DataService<File, any>
  implements IFileDownloadService<T>
{
  static endpointName = 'files';
  static standardUrl: string;
  public isLoading: Subject<any>;

  constructor(
    protected _http: HttpClient,
    protected _discoveryService: NgDiscoveryService,
    protected _logger: ILogger,
    protected _apiSdkService: IApiSdkService,
    protected _alertService: AlertService,
    protected _progressBarOverlayService: ProgressBarService,
  ) {
    super('files', _http, _logger, _apiSdkService, _discoveryService);
    FileDownloadService.standardUrl = `${this.BASE_API_URL}/${FileDownloadService.endpointName}`;
    this.isLoading = this._progressBarOverlayService.isLoading;
    this.fixFirefoxStreamDownload();

    this.fetchFile = this.fetchFile.bind(this);
    this.downloadOneFile = this.downloadOneFile.bind(this);
    this.downloadListOfFiles = this.downloadListOfFiles.bind(this);
  }

  public getFile(fileId: string): Observable<File> {
    return this._http.get<File>(`${FileDownloadService.standardUrl}/${fileId}`);
  }

  public getFileExtension(
    file: File | IRequestResultFile | FileRevision,
  ): string {
    let extension = file.url;
    if (extension.indexOf('?') > -1) {
      extension = extension.substring(0, extension.indexOf('?'));
    }
    extension = extension.substr(extension.lastIndexOf('.'), extension.length);
    return extension;
  }

  public fileDownload(
    entities: any[],
    visibleBatchOptions: RRIBatchOption<RRIDownloadBatchOptionSettings<T, any>>,
    singleDownload: boolean = false,
  ): void {
    this._progressBarOverlayService.show(entities.length);
    if (entities.find((x) => !x.url)) {
      this.getFileListWithUrls(entities).subscribe(
        (files) => {
          if (singleDownload) {
            files.forEach((element) => {
              this.downloadFile(element).subscribe();
            });
          } else {
            this.streamDownload(visibleBatchOptions, files);
          }
        },
        (error) => this._logger.error('error', error),
      );
    } else {
      if (singleDownload) {
        entities.forEach((element) => {
          this.downloadFile(element).subscribe();
        });
      } else {
        this.streamDownload(visibleBatchOptions, entities);
      }
    }
  }

  public downloadFile(file: File): Observable<any> {
    return new Observable((subscribe) => {
      fetch(file.url)
        .then((response) => response.blob())
        .then((blob) => {
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blobURL;
          a.setAttribute(
            'download',
            this.getFileName(file, this.getFileExtension),
          );

          document.body.appendChild(a);
          a.click();
          this._progressBarOverlayService.hide();

          subscribe.next({
            type: 'success',
            msg: 'File downloaded successfully',
          });
          subscribe.complete();
        })
        .catch(() => {
          this._alertService.showAlert({
            type: RR_ALERT_TYPE.DANGER,
            message: 'Error downloading the file',
          });
          this._progressBarOverlayService.error('error downloading file');

          subscribe.next({ type: 'danger', msg: 'Error downloading the file' });
          subscribe.complete();
        });
    });
  }
  public downloadResultFile(file: IRequestResultFile): Observable<any> {
    return new Observable((subscribe) => {
      fetch(file.url)
        .then((response) => response.blob())
        .then((blob) => {
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blobURL;
          a.setAttribute(
            'download',
            this.getFileName(file, this.getFileExtension),
          );

          document.body.appendChild(a);
          a.click();
          this._progressBarOverlayService.hide();

          subscribe.next({
            type: 'success',
            msg: 'File downloaded successfully',
          });
          subscribe.complete();
        })
        .catch(() => {
          this._alertService.showAlert({
            type: RR_ALERT_TYPE.DANGER,
            message: 'Error downloading the file',
          });
          this._progressBarOverlayService.error('error downloading file');

          subscribe.next({ type: 'danger', msg: 'Error downloading the file' });
          subscribe.complete();
        });
    });
  }

  private async fetchFile(
    url: string,
    fileName: string,
    ctrl: any,
    resolve: () => void,
    reject: () => void,
  ) {
    await fetch(url)
      .then((res) => {
        const stream = () => res.body;
        ctrl.enqueue({ name: fileName, stream });
        resolve();
      })
      .catch((reason) => {
        this._logger.error('error downloading file', reason);
        this._alertService.showErrorAlert(
          `Error downloading the file: ${reason}`,
        );
        reject();
      });
  }
  public async downloadZippedFiles(
    results: ConfigurationResultDTO[],
    name: string,
  ): Promise<void> {
    const zip = new JSZip();
    for (const result of results) {
      for (const file of result.files) {
        if (results.length === 1) {
          zip.file(file.name, await this.fetchFileByUrl(file.url));
        } else {
          zip.file(
            result.format + '/' + file.name,
            await this.fetchFileByUrl(file.url),
          );
        }
      }
    }
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, name + '.zip');
    });
  }
  public async downloadFiles(results: ConfigurationResultDTO[]): Promise<void> {
    for (const result of results) {
      for (const file of result.files) {
        const fileData = await this.fetchFileByUrl(file.url);
        saveAs(fileData, file.name);
      }
    }
  }
  public async fetchFileByUrl(url): Promise<Blob> {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  }
  private streamDownload(
    visibleBatchOptions: RRIBatchOption<RRIDownloadBatchOptionSettings<T, any>>,
    entities: any[],
    startProgress: boolean = false,
  ): void {
    if (startProgress) this._progressBarOverlayService.show(entities.length);

    const progressBarService = this._progressBarOverlayService;
    const download = visibleBatchOptions;
    const fileStream = streamSaver.createWriteStream(
      download.settings.downloadFileName,
    );
    const selectedEntities = entities;
    const getFileExtension = this.getFileExtension;
    let resolveStartPromise: () => void;
    const startPromise = new Promise<void>((resolve) => {
      resolveStartPromise = resolve;
    });
    const allPromises = [startPromise];
    let isThereSomethingToDownload: boolean = false;
    let stream;
    const downloadListOfFiles = this.downloadListOfFiles;
    const downloadOneFile = this.downloadOneFile;

    const readableZipStream = new ZIP({
      async start(ctrl) {
        stream = ctrl;
        allPromises.push(
          new Promise(async (resolve, reject) => {
            for (let index = 0; index < selectedEntities.length; index++) {
              const entity = selectedEntities[index];
              const files = await download.settings.getFiles(entity);
              if (Array.isArray(files) && files.length > 0) {
                isThereSomethingToDownload = await downloadListOfFiles(
                  files,
                  getFileExtension,
                  ctrl,
                  download,
                  resolve,
                  reject,
                );
              } else if (!Array.isArray(files) && files) {
                isThereSomethingToDownload = await downloadOneFile(
                  selectedEntities,
                  index,
                  files,
                  getFileExtension,
                  ctrl,
                  download,
                  resolve,
                  reject,
                );
              } else {
                resolve();
              }
              progressBarService.increaseProgressBarValue();

              if (index + 1 === selectedEntities.length) {
                resolveStartPromise();
              }
            }
          }),
        );
      },
    });

    Promise.all(allPromises)
      .then(() =>
        this.successStreamDownload(
          stream,
          isThereSomethingToDownload,
          readableZipStream,
          fileStream,
        ),
      )
      .catch(() => this.finishStreamDownload(stream));
  }

  private async downloadOneFile(
    selectedEntities: any[],
    index: number,
    files: any,
    getFileExtension: (
      file: File | IRequestResultFile | FileRevision,
    ) => string,
    ctrl,
    download: RRIBatchOption<RRIDownloadBatchOptionSettings<T, any>>,
    resolve: (value: void | PromiseLike<void>) => void,
    reject: (reason?: any) => void,
  ): Promise<boolean> {
    const fileName = this.getFileName(
      files,
      getFileExtension,
      selectedEntities,
      index,
    );
    const filePath = download.settings.generateFilePath(fileName, files);
    return await this.fetchFile(
      files.url,
      filePath,
      ctrl,
      resolve,
      reject,
    ).then(() => true);
  }
  private async downloadListOfFiles(
    files: any[],
    getFileExtension: (
      file: File | IRequestResultFile | FileRevision,
    ) => string,
    ctrl,
    download: RRIBatchOption<RRIDownloadBatchOptionSettings<T, any>>,
    resolve: (value: void | PromiseLike<void>) => void,
    reject: (reason?: any) => void,
  ): Promise<boolean> {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileName = this.getFileName(file, getFileExtension, files, i);
      const filePath = download.settings.generateFilePath(fileName, file);
      return await this.fetchFile(file.url, filePath, ctrl, resolve, reject)
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    }
  }

  private successStreamDownload(
    stream,
    isThereSomethingToDownload: boolean,
    readableZipStream: ZIP,
    fileStream: WritableStream,
  ): void {
    this.finishStreamDownload(stream);
    if (isThereSomethingToDownload) {
      if (window.WritableStream && readableZipStream.pipeTo) {
        return readableZipStream.pipeTo(fileStream);
      }

      const writer = fileStream.getWriter();
      const reader = readableZipStream.getReader();
      const pump = () =>
        reader
          .read()
          .then((res) =>
            res.done ? writer.close() : writer.write(res.value).then(pump),
          );

      pump();
    } else {
      this._progressBarOverlayService.error(
        'There were no files to download. Check if your requests have results to download.',
      );
      this._alertService.showErrorAlert(
        'There were no files to download. Check if your requests have results to download.',
      );
    }
  }

  private finishStreamDownload(stream): void {
    stream.close();
    this._progressBarOverlayService.hide();
  }

  private getFileListWithUrls(entities: any): Observable<any> {
    if (entities.length <= 10) {
      const fileRequests: any[] = [];
      entities.forEach((selectedFile) => {
        fileRequests.push(this.getFile(selectedFile.id));
      });

      return forkJoin(fileRequests);
    } else {
      return this.getData({
        pagination: {
          pageSize: -1,
          currentPage: 1,
          recordCount: 0,
          pageCount: 0,
        },
      }).pipe(
        map(({ results }) =>
          results.filter((x) => entities.find((y) => y.id == x.id)),
        ),
      );
    }
  }

  private getFileName(
    file: IRequestResultFile | File | FileRevision,
    getFileExtension: any,
    fileList?: IRequestResultFile[] | File[] | FileRevision[],
    index?: number,
  ): string {
    const extension = getFileExtension(file);

    /**
     * We had a problem that files from the endpoint configurations/ID/results had the fileextension in the filename.
     * The result was that a file gets the fileextension twice, once frome the name of the file and once from the extension.
     * The result was "filename.garment.garment" which is now transformed to "filename.garment"
     */
    if (
      typeof file['configurationResultId'] !== 'undefined' &&
      file.name.lastIndexOf(extension) + extension.length == file.name.length
    ) {
      file.name = file.name.substr(0, file.name.lastIndexOf(extension));
    }

    let duplicateNumber: number = 0;
    if (fileList && fileList.length) {
      fileList.some((previousFile, i) => {
        if (i < index) {
          const otherExtension = getFileExtension(previousFile);
          if (file.name === previousFile.name && otherExtension === extension) {
            duplicateNumber++;
          }
        } else {
          return true;
        }
      });
    }
    let duplicateSuffix: string = '';
    if (duplicateNumber) {
      duplicateSuffix = '(' + duplicateNumber.toString() + ')';
    }

    return `${file.name}${duplicateSuffix}${extension}`;
  }

  private fixFirefoxStreamDownload() {
    if (!window.WritableStream) {
      streamSaver.WritableStream = WritableStream;
    }
  }

  protected initService(sdkConfig: ICsApiSdkConfiguration) {}
}
