<ng-container *ngIf="!dataset || dataset.length === 0">
  <div
    class="bar-container"
    [ngStyle]="{
      'flex-grow': 1
    }"
  >
    <div class="bar-legend">
      <span class="bar-name color-state-created"
        ><span class="oi oi-warning">&nbsp;</span> No Requests Found
      </span>
    </div>
  </div>
</ng-container>

<ng-container *ngFor="let entry of dataset">
  <div
    class="bar-container"
    [ngStyle]="{
      'flex-grow': entry.percentage * 100
    }"
  >
    <div class="bar-legend">
      <span class="bar-name color-{{ entry.color }}">
        {{ entry.label }}
      </span>
      <span class="bar-count"> {{ entry.count }} </span>
    </div>
    <div class="bar bg-color-{{ entry.color }}"></div>
  </div>
</ng-container>
