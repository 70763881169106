<ng-container
  *ngIf="
    (modalService.modalStackDepth$ | async) > 0 && modalService.modalStack$
      | async as stack
  "
  [ngTemplateOutlet]="modal"
  [ngTemplateOutletContext]="{
    index: 0
  }"
></ng-container>

<ng-template #modal let-index="index">
  <div
    *ngIf="modalService.modalStack$ | async as stack"
    class="rr-modal-backdrop active modal-{{ index }}"
    (click)="closeAfterConfirm(stack[index])"
  >
    <div class="rr-modal" (click)="$event.stopPropagation()">
      <header>
        <ng-container
          *ngIf="stack[index].headerTemplate"
          [ngTemplateOutlet]="stack[index].headerTemplate"
          role="header"
        >
        </ng-container>
        <h2>{{ !stack[index].headerTemplate ? stack[index].title : '' }}</h2>
        <span class="controls">
          <span
            *ngIf="stack[index].showClose ?? true"
            class="oi oi-x"
            (click)="this.modalService.close()"
          ></span>
        </span>
      </header>
      <ng-container [ngTemplateOutlet]="stack[index].template" role="dialog">
      </ng-container>
      <!-- THIS IS THE NESTED MODAL CONTAINER -->
      <ng-container
        *ngIf="(modalService.modalStackDepth$ | async) > index + 1"
        [ngTemplateOutlet]="modal"
        [ngTemplateOutletContext]="{ index: index + 1 }"
      ></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #confirmDiscard>
  <article class="discard">
    <button
      class="btn btn-danger"
      (click)="this.modalService.close(); this.modalService.close()"
    >
      Discard
    </button>
    <button class="btn btn-outline" (click)="this.modalService.close()">
      Back
    </button>
  </article>
</ng-template>
