import { NgModule } from '@angular/core';
import { RRLayoutBuilderModule } from '../layout-builder/layout-builder.module';
import { RRSharedModule } from '../shared-module/shared.module';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [RRSharedModule, RRLayoutBuilderModule],
  exports: [FooterComponent],
})
export class RRFooterModule {}
