import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalContainerComponent } from './modal-container.component';
import { ModalService } from './modal.service';

@NgModule({
  declarations: [ModalContainerComponent],
  providers: [ModalService],
  imports: [CommonModule],
  exports: [ModalContainerComponent],
})
export class ModalModule {}
