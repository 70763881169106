import { Component, Input } from '@angular/core';
import { RRSpinnerModel } from '../../models/spinner.model';

@Component({
  selector: 'rr-overlay-spinner',
  templateUrl: './overlay-spinner.component.html',
  styleUrls: ['./overlay-spinner.component.scss'],
})
export class OverlaySpinnerComponent {
  @Input() spinnerStatus: RRSpinnerModel;

  constructor() {}
}
