/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { ICustomerService } from 'projects/content-service-cms/src/app/customer';
import {
  IFileUploadService,
  IGarmentService,
  IProductService,
  IRequestDetail,
  IRequestService,
} from 'projects/content-service-cms/src/app/data';
import { ILogger } from 'projects/content-service-cms/src/app/logging';

import {
  CreateRequestCommonData,
  CreateRequestInputData,
} from '../create-request-input.interface';
import {
  CreateRequestModalState,
  CreateRequestModalStore,
} from '../create-request-modal.state';
import { CreateRequestBaseService } from './create-request-base';
import {
  IFileService,
  ITagService,
} from 'projects/content-service-cms/src/app/data/api';
import { Store } from '@ngrx/store';
import { IAppState } from 'projects/content-service-cms/src/app/app.state';
import { v4 as uuid } from 'uuid';
import { ConfigurationHasTagKeyDTO } from '@reactivereality/cs-api-sdk';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  ACCESSORY_SLOT,
  SelectedAccessories,
} from 'projects/content-service-cms/src/app/components/accessory-selection/accessory-selection.component';
export interface ParallaxConfig {
  scene?: uuid;
  avatar?: uuid;
  garments?: uuid[];
  render_resolutions?: {
    name: string;
    width: number;
    height: number;
  }[];
  accessories?:
    | []
    | {
        id: uuid;
        position: 'TOP' | 'BOTTOM' | 'MIDDLE';
      }[];
  mode?: 'DEFAULT' | 'IMAGE_ONLY';
  skip_manual_step?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CreateOutfit2DParallaxService extends CreateRequestBaseService {
  private readonly OUTFIT_2D_PARALLAX_FILENAME = 'config.json';
  private readonly OUTFIT_2D_PARALLAX_PREVIEW_FILENAME =
    '__configuration_thumbnail.png';

  private readonly DEFAULT_RESOLUTIONS = [
    {
      name: '4K',
      width: 2160,
      height: 3840,
    },
  ];
  constructor(
    requestService: IRequestService,
    private garmentService: IGarmentService,
    private _logger: ILogger,
    private customerService: ICustomerService,
    productService: IProductService,
    private _fileUploadService: IFileUploadService,
    tagService: ITagService,
    private appState: Store<IAppState>,
    private _fileService: IFileService<any>,
    private _http: HttpClient,
  ) {
    super(requestService, productService, tagService);
  }

  prepareCreateNew = async (
    inputData: CreateRequestInputData,
    store: CreateRequestModalStore,
  ): Promise<void> => {
    store.clearCreateRequestCommonData();
    store.clearExistingRequest();
    store.setAvatar(null);
    store.setProductType(inputData.productType);
    store.setFeatures({
      supportDraft: false,
      supportDueDate: false,
      supportPriority: false,
      supportSize: true,
    });
  };

  public readConfigFile = async (
    request: IRequestDetail,
  ): Promise<ParallaxConfig | null> => {
    const files = await this._fileService.getRequestFiles(
      request.id,
      JSON.stringify({
        name: this.OUTFIT_2D_PARALLAX_FILENAME,
      }),
    );
    const inputFile = files.results.length > 0 ? files.results[0] : undefined;
    if (inputFile) {
      return await firstValueFrom(
        this._http.get<ParallaxConfig>(inputFile.url, {
          responseType: 'json',
        }),
      );
    }
  };

  prepareDraftEdit = async (
    request: IRequestDetail,
    store: CreateRequestModalStore,
  ): Promise<void> => {
    const priorities = this._requestService.allPriorities$.value;
    const outfitConfiguration = await this.readConfigFile(request);

    this._logger.debug(`Outfit configuration loaded`, outfitConfiguration);

    const requestCommonData: CreateRequestCommonData = {
      name: request.name,
      sku: request.sku,
      priority: request.priority
        ? priorities.find((p) => p.id === request.priority)
        : undefined,
      dueDate: request.dueAt ? new Date(request.dueAt * 1000) : undefined,
      dataValid: true,
      size: null,
    };

    store.changeCreateRequestCommonData(requestCommonData);
    store.setTags(
      request.tags.map((v: ConfigurationHasTagKeyDTO) => {
        return {
          id: v.tagKeyId,
          name: v.tagKeyName,
          color: v.tagKeyColor,
          description: v.tagKeyDescription,
          displayTemplate: v.tagKeyDisplayTemplate,
          jsonSchema: v.tagKeyJsonSchema,
        };
      }),
    );

    if (outfitConfiguration.skip_manual_step === true) {
      store.changeSkipManualStep(true);
    } else {
      store.changeSkipManualStep(false);
    }

    if (outfitConfiguration.mode === 'DEFAULT') {
      store.changeIsCollectionOutfit(true);
    } else {
      store.changeIsCollectionOutfit(false);
    }

    store.setExistingRequest(request);
    store.setProductType(request.productTypeId);
    store.setFeatures({
      supportDraft: true,
      supportDueDate: false,
      supportPriority: false,
      supportSize: true,
    });

    const avatarID = outfitConfiguration?.avatar;
    if (avatarID) {
      this._logger.info('Setting avatar:', avatarID);
      const avatarRequest = await this._requestService.getRequestByID(avatarID);
      store.setAvatar({
        ...avatarRequest,
        isPublic: true,
      });
    }

    const sceneID = outfitConfiguration?.scene;
    if (sceneID) {
      this._logger.info('Setting scene:', sceneID);
      const sceneRequest = await this._requestService.getRequestByID(sceneID);
      store.setScene({
        ...sceneRequest,
        isPublic: true,
      });
    }

    const garments = outfitConfiguration?.garments;
    if (garments) {
      this._logger.debug(`Garments: `, garments);
      const resolved = (
        await Promise.all(
          garments
            .map((g) => ({
              dto: this._requestService.getRequestByID(g),
              garment: g,
            }))
            .map(async ({ dto, garment }) => {
              try {
                const d = await dto;
                return await this.garmentService.mapRequestsToGarments(d, {
                  dressed: true,
                  isPublic: false,
                  tuckedIn: true,
                });
              } catch (e) {
                this._logger.warn(`Failed to load garment!`, e);
                return undefined;
              }
            }),
        )
      ).filter((v) => v !== undefined);
      this._logger.debug(`Resolved Garments: `, resolved);
      const tuckedInItem = resolved.find((o) => o.tuckedIn === true);
      store.setTuckedInGarment(tuckedInItem);
      store.setSelectedGarments(resolved);
      store.setGarments(resolved);

      const accessories = outfitConfiguration?.accessories;
      if (accessories) {
        this._logger.debug(`Accessories: `, accessories);
        const resolved = (
          await Promise.all(
            accessories
              .map((g) => ({
                dto: this._requestService.getRequestByID(g),
                garment: g,
              }))
              .map(async ({ dto, garment }) => {
                try {
                  const d = await dto;
                  const mapped =
                    await this.garmentService.mapRequestsToGarments(d, {
                      dressed: true,
                      isPublic: false,
                    });
                  return { dto: mapped, position: garment.position };
                } catch (e) {
                  this._logger.warn(`Failed to load Accessory!`, e);
                  return undefined;
                }
              }),
          )
        ).filter((v) => v !== undefined);
        this._logger.debug(`Resolved Accessories: `, resolved);
        const accs: SelectedAccessories = {
          TOP: undefined,
          MIDDLE: undefined,
          BOTTOM: undefined,
        };
        resolved.forEach((r) => {
          accs[r.position] = r.dto;
        });
        store.setAccessories(accs);
      }
      store.refreshRoomNow(true);
    }
  };

  build2DParallaxConfig = async (state: CreateRequestModalState) => {
    let res = this.DEFAULT_RESOLUTIONS;
    const size = state.requestCommonData?.size;
    if (size) {
      res = [
        {
          name: size.slug,
          width: size.width,
          height: size.height,
        },
      ];
    }

    const config: ParallaxConfig = {
      scene: state.dressingRoom.scene.id,
      avatar: state.dressingRoom.avatar.id,
      garments: state.dressingRoom.garments.map((g) => g.id),
      accessories: mapAccessories(state.dressingRoom.accessories),
      render_resolutions: res,
      mode: state.isCollectionOutfit ? 'DEFAULT' : 'IMAGE_ONLY',
      skip_manual_step: state.skipManualStep,
    };

    if (state.isCollectionOutfit) {
      delete config.render_resolutions;
    }
    return config;
  };

  createRequest = async (
    store: CreateRequestModalStore,
    previewImage: string,
    asDraft: boolean,
  ): Promise<void> => {
    this._logger.debug(`Creating/Updating Outfit 2D parallax request ...`);
    const customer = await this.customerService.currentCustomer();
    const state = store.current();

    const config = await this.build2DParallaxConfig(state);
    let productId = '';

    if (!state.existingRequest) {
      const product = await this.createProduct(
        customer,
        state.requestCommonData,
        state.productType,
      );
      productId = product.id;
    } else {
      productId = state.existingRequest.productId;
    }

    const request = this.getRequestUpdate(state.requestCommonData, productId);

    if (state.existingRequest) {
      request.id = state.existingRequest.id;
    }

    const front = await this.updateRequest(request);

    await this.updateTags(front, state.tags);

    if (state.existingRequest) {
      const files = await this._fileService.getRequestFiles(request.id);
      const outfitFile = files.results.find(
        (o) => o.name === this.OUTFIT_2D_PARALLAX_FILENAME,
      );
      const previewFile = files.results.find(
        (o) => o.name === this.OUTFIT_2D_PARALLAX_PREVIEW_FILENAME,
      );
      if (outfitFile) {
        await this._fileService.deleteFile(outfitFile);
      }
      if (previewFile) {
        await this._fileService.deleteFile(previewFile);
      }
    }

    await this._fileUploadService.uploadJsonFile(
      {
        id: '',
        configurationId: request.id,
        fileName: this.OUTFIT_2D_PARALLAX_FILENAME,
        typeId: 0,
        mimeType: 'application/json',
      },
      config,
    );

    await this._fileUploadService.uploadBase64File(
      {
        id: '',
        configurationId: request.id,
        fileName: this.OUTFIT_2D_PARALLAX_PREVIEW_FILENAME,
        typeId: 36,
        mimeType: 'image/png',
      },
      previewImage,
    );

    if (!asDraft) {
      await this._requestService.setReady(request.id);
    }
  };
}
function mapAccessories(accessories: SelectedAccessories): {
  id: uuid;
  position: 'TOP' | 'BOTTOM' | 'MIDDLE';
}[] {
  if (accessories) {
    const result = [];
    for (const p of Object.values(ACCESSORY_SLOT)) {
      if (accessories[p] !== undefined) {
        result.push({
          id: accessories[p].id,
          position: p,
        });
      }
    }
    return result;
  }
}
