import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { InputTextComponent } from '../components/inputs/input-text/input-text.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { ProfileUserInformationComponent } from './components/profile-user-information/profile-user-information.component';
import { ProfileApiKeysComponent } from './components/profile-api-keys/profile-api-keys.component';
import { ProfileSecurityComponent } from './components/profile-security/profile-security.component';
import { AsyncButtonComponent } from '../components/async-button/async-button.component';
import {
  RRBootstrapModule,
  RRTableModule,
} from 'projects/web-ui-component-library/src';
import { PrettyProductDatePipe } from '../request-overview/components/request-list-page/request-list-row/request-list-row.component';
import { ApiKeyRowComponent } from './components/profile-api-keys/components/api-key-row.component';

@NgModule({
  declarations: [
    ProfilePageComponent,
    ProfileMenuComponent,
    ProfileUserInformationComponent,
    ProfileApiKeysComponent,
    ProfileSecurityComponent,
    ApiKeyRowComponent,
  ],
  exports: [ProfilePageComponent],
  imports: [
    CommonModule,
    RRBootstrapModule,
    PrettyProductDatePipe,
    RRTableModule,
    InputTextComponent,
    AsyncButtonComponent,
  ],
})
export class ProfileModule {}
