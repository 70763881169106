import { createReducer, on } from '@ngrx/store';
import {
  navigateToAction,
  navigateToCompleteAction,
} from './navigation.actions';
import { INavigationState } from './navigation.state';

const initialState: INavigationState = {
  current: {
    route: [],
  },
};

const navigateToReducer = (
  state: INavigationState,
  { route }: { route: string[] },
): INavigationState => {
  return {
    ...state,
    target: {
      route,
    },
  };
};

const navigateToCompleteReducer = (
  state: INavigationState,
): INavigationState => {
  return {
    ...state,
    target: undefined,
    current: state.target ?? initialState.current,
  };
};

export const navigationReducer = createReducer<INavigationState>(
  initialState,
  on(navigateToAction, navigateToReducer),
  on(navigateToCompleteAction, navigateToCompleteReducer),
);
