import { ComponentStore } from '@ngrx/component-store';
import { Configuration, UsersApi } from '@reactivereality/cs-api-sdk';
import { User } from 'oidc-client';
import { IDiscoveryConfiguration } from '../../data';

interface IAuthState {
  currentUser?: User;
  features?: {
    canApprove: boolean;
    canReject: boolean;
    isCustomer: boolean;
    isEmployee: boolean;
    isCollectionCreator: boolean;
  };
}

const mapFeatures = (features: string[]): IAuthState['features'] => {
  return {
    canApprove: features.includes('PICTOFITCMS_APPROVE_REQUEST'),
    canReject: features.includes('PICTOFITCMS_REJECT_REQUEST'),
    isCustomer: features.includes('RR_CUSTOMER'),
    isEmployee: features.includes('RR_EMPLOYEE'),
    isCollectionCreator: features.includes('RR_COLLECTION_CREATOR'),
  };
};

export class AuthState extends ComponentStore<IAuthState> {
  private cfg: IDiscoveryConfiguration = undefined;

  constructor() {
    super({});

    this.select((s) => s.currentUser).subscribe((u) => {
      if (!u) {
        // in case we remove the current user, we remove all features
        this.setFeatures(undefined);
      } else {
        // we need to get the user
        const config = new Configuration({
          basePath: this.cfg.apiConfiguration.baseApiUrl.replace(
            `/${this.cfg.appConfiguration.apiVersion}`,
            '',
          ),
          apiKey: `Bearer ${u.access_token}`,
        });

        const usersApiSdk = new UsersApi(config);

        usersApiSdk.vversionUsersMeGet('1.5').then((resp) => {
          this.setFeatures(mapFeatures(resp.data.featureFlags));
        });
      }
    });
  }

  public setDiscoveryConfiguration(cfg: IDiscoveryConfiguration) {
    this.cfg = cfg;
  }

  private readonly setFeatures = this.updater(
    (state, features: IAuthState['features'] | undefined) => {
      return {
        ...state,
        features,
      };
    },
  );

  public readonly access_token = (): string | undefined => {
    return this.get().currentUser?.access_token ?? undefined;
  };

  public readonly access_token$ = this.select(
    (s) => s.currentUser?.access_token,
  );

  public readonly user = (): User | undefined => {
    return this.get().currentUser ?? undefined;
  };

  public readonly user$ = this.select((s) => s.currentUser);

  public readonly setUser = this.updater(
    (state, currentUser: User | undefined) => {
      return {
        ...state,
        currentUser,
      };
    },
  );
}
