import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CollectionListPageComponent } from './components/collection-list.page/collection-list.page.component';
import { AuthGuard } from '../auth';

const routes: Routes = [
  {
    path: '',
    component: CollectionListPageComponent,
    canActivate: [AuthGuard],
    data: {
      type: 'collections',
      breadcrumb: 'Collections',
      breadcrumbParentPath: 'collections',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CollectionOverviewRoutingModule {}
