export enum REQUEST_STATE_INTERNAL_STRING {
  READY_FOR_PROCESSING = 'READY_FOR_PROCESSING',
  FETCHED = 'FETCHED',
  PROCESSED = 'PROCESSED',
  REPORTED = 'REPORTED',
  APPROVED = 'APPROVED',
  FETCHED_FOR_REVIEW = 'FETCHED_FOR_REVIEW',
  REJECTED_BY_CUSTOMER = 'REJECTED_BY_CUSTOMER',
  REJECTED_BY_SERVICE = 'REJECTED_BY_SERVICE',
  LOCKED = 'LOCKED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_READY_FOR_PROCESSING = 'NOT_READY_FOR_PROCESSING',
  REJECTED_BY_REVIEWER = 'REJECTED_BY_REVIEWER',
  APPROVED_BY_CUSTOMER = 'APPROVED_BY_CUSTOMER',
  CANCELED_BY_CUSTOMER = 'CANCELED_BY_CUSTOMER',
  RESET_EVENT = 'RESET_EVENT',
  EXPORTING = 'EXPORTING',
}
