import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth';
import { RequestListPageComponent } from './components/request-list-page/request-list.page.component';

const routes: Routes = [
  {
    path: '',
    component: RequestListPageComponent,
    canActivate: [AuthGuard],
    data: {
      type: 'requests',
      breadcrumb: 'Request Detail',
      breadcrumbParentPath: 'requests',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RequestOverviewRoutingModule {}
