import { IRequestFormConfig } from '../interfaces/request/request-form-config.interface';
import { FILE_FORMAT } from '../enums/file/file-format.enum';

export const ACCESSORY_3D_CONFIG: IRequestFormConfig = {
  canOnlyBeDrafted: true,
  files: [
    {
      type: 'REFERENCE_ADDITIONAL',
      fileFormats: [FILE_FORMAT.JPEG, FILE_FORMAT.PNG],
      multiplicity: [0, Number.MAX_SAFE_INTEGER],
    },
  ],
};
