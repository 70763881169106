import { createSelector } from '@ngrx/store';
import { IAppState } from '../../app.state';
import { IRequestsOverviewState } from './requests-overview.state';

export const selectRequestOverview = (state: IAppState) =>
  state.requestOverview;

export const selectRequestsOverviewShowLoader = createSelector(
  selectRequestOverview,
  (state: IRequestsOverviewState) => state.showLoader,
);

export const selectRequestsOverviewPagination = createSelector(
  selectRequestOverview,
  (state: IRequestsOverviewState) => state.pagination,
);

export const selectLastUpdated = createSelector(
  selectRequestOverview,
  (state: IRequestsOverviewState) => state.requests_loaded,
);

export const selectRequestsOverviewSort = createSelector(
  selectRequestOverview,
  (state: IRequestsOverviewState) => state.sort,
);

export const selectRequestsOverviewFilters = createSelector(
  selectRequestOverview,
  (state: IRequestsOverviewState) => state.filters,
);
export const selectRequestsOverviewCurrentCustomerId = createSelector(
  selectRequestOverview,
  (state: IRequestsOverviewState) => state.currentCustomerID,
);

export const selectRequestsOverview = createSelector(
  selectRequestOverview,
  (state: IRequestsOverviewState) => ({
    requests: state.requests,
    recordCount: state.recordCount,
  }),
);

export const selectRequestsOverviewAggregate = createSelector(
  selectRequestOverview,
  (state: IRequestsOverviewState) => state.requestAggregates,
);

export const selectRequestsErrorOverview = createSelector(
  selectRequestOverview,
  (state: IRequestsOverviewState) => state.requestsError,
);

export const selectRequestsOverviewAggregateError = createSelector(
  selectRequestOverview,
  (state: IRequestsOverviewState) => state.requestAggregatesError,
);

export const selectRequestsFilter = createSelector(
  selectRequestOverview,
  (state: IRequestsOverviewState) => state.filters,
);

export const selectRequestsModalFilter = createSelector(
  selectRequestOverview,
  (state: IRequestsOverviewState) => state.createRequestModal,
);
