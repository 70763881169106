<div
  id="garment-selection-{{ garment.id }}"
  class="d-flex flex-row row configuration-entry align-items-center"
>
  <app-configuration-display [garment]="garment"></app-configuration-display>
  <div class="p2 ml-auto buttons d-flex flex-row align-items-center">
    <ng-template #stylingOptionsModal>
      <app-styling-options
        [garment]="garment"
        [schema]="stylingOptions"
      ></app-styling-options>
    </ng-template>
    <span
      *ngIf="stylingOptions"
      tooltip="Styling options"
      containerClass="garment-options-tooltip"
      class="rr-styling-options mr-3"
      (click)="
        modalService.show({
          title: 'Styling Options',
          template: stylingOptionsModal
        })
      "
    ></span>
    <span
      id="garment-selection-{{ garment.id }}-tuck-in-btn"
      *ngIf="
        garment.groupId === 4 && garment.dressed && garment.tuckedIn === false
      "
      tooltip="Tuck in"
      containerClass="garment-options-tooltip"
      class="rr-tuck-in mr-3"
      (click)="tuckGarment($event, 'in')"
    ></span>
    <span
      id="garment-selection-{{ garment.id }}-tuck-out-btn"
      *ngIf="
        garment.dressed && garment.groupId === 4 && garment.tuckedIn === true
      "
      tooltip="Tuck out"
      containerClass="garment-options-tooltip"
      class="rr-tuck-out mr-3"
      (click)="tuckGarment($event, 'out')"
    ></span>
    <span
      id="garment-selection-{{ garment.id }}-undress-btn"
      *ngIf="garment.dressed === undefined || garment.dressed === false"
      tooltip="Dress garment"
      containerClass="garment-options-tooltip"
      class="rr-un-view mr-3"
      (click)="dressGarment($event)"
    ></span>
    <span
      id="garment-selection-{{ garment.id }}-dress-btn"
      *ngIf="garment.dressed === true"
      tooltip="Undress garment"
      containerClass="garment-options-tooltip"
      class="rr-view mr-3"
      (click)="dressGarment($event)"
    ></span>
    <span
      class="rr-delete mr-3"
      tooltip="Remove garment"
      id="garment-selection-{{ garment.id }}-remove-btn"
      containerClass="garment-options-tooltip"
      (click)="deleteEntry($event)"
    ></span>
  </div>
</div>
