import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ILogger } from '../../../logging';

import { ISceneService, ViewerMode, IScene as WebshopScene } from '../api';
import { IAsset, StaticAsset } from '@reactivereality/pictofit-web-sdk';

import { HttpClient } from '@angular/common/http';
import { createSceneAsset } from '../model/WebshopAssets';
import { PRODUCT_TYPE } from '../../../data';
import { IRequestService, PagingData, SceneDTO } from '../../../data/api';
import { mapRequestsToAssets } from './common';

@Injectable({
  providedIn: 'root',
})
export class SceneService implements ISceneService {
  // All brands loading
  public readonly allScenes_loading$: BehaviorSubject<boolean>;
  public readonly allScenes$: BehaviorSubject<Array<WebshopScene>>;

  private allScenes_query_counter: number = 0;
  private readonly internal_allScenes$: BehaviorSubject<
    Array<WebshopScene> | undefined
  >;

  constructor(
    private httpClient: HttpClient,
    private logger: ILogger,
    private _requestService: IRequestService,
  ) {
    // User Avatars
    this.internal_allScenes$ = new BehaviorSubject<Array<WebshopScene>>([]);
    this.allScenes_loading$ = new BehaviorSubject<boolean>(false);

    this.allScenes$ = new BehaviorSubject<Array<WebshopScene>>([]);

    this.internal_allScenes$.subscribe((n) => this.allScenes$.next(n));

    //load default scene
    const sceneAsset = new StaticAsset(
      'https://rrwebsites.blob.core.windows.net/sdks/sample-assets-17/scenes/2D-parallax/street/',
    );
  }
  public async getScenesForCustomer(
    requestFilter: string,
    productType: PRODUCT_TYPE,
    customerId: string,
    page: number,
    pageSize: number,
    tags: string,
    signal: AbortSignal,
    inlcudeArchived: boolean = true,
  ): Promise<{ scenes: SceneDTO[]; paging: PagingData }> {
    const customerRequests = await this._requestService.getAllCustomerRequests(
      customerId,
      requestFilter,
      page,
      pageSize,
      tags,
      inlcudeArchived,
      signal,
    );
    return {
      scenes: await mapRequestsToAssets(
        PRODUCT_TYPE.SCENE,
        customerRequests.configurations,
      ),
      paging: customerRequests.paging,
    };
  }

  private toBlob = async (url: string): Promise<Blob> => {
    const response = await this.httpClient
      .get(url, { observe: 'response', responseType: 'blob' })
      .toPromise();

    if (response.status !== 200) {
      const responseText =
        response.body !== null
          ? await response.body.text()
          : 'Unkown response text';
      throw new Error(
        `Failed to fetch url '${url}' for Avatar ${responseText}`,
      );
    }

    if (response.body === null) {
      throw new Error(`Key '${url}' for Avatar resulted in null blob content`);
    }
    return response.body;
  };

  public toSdkAsset = async (
    scene: WebshopScene,
    mode: ViewerMode,
  ): Promise<IAsset> => {
    switch (mode) {
      case ViewerMode.MODE_2D_PARALLAX:
        if (!scene.supports_2d) {
          throw new Error(`Unsupported scene mode!`);
        }
      case ViewerMode.MODE_3D:
        if (!scene.supports_3d) {
          throw new Error(`Unsupported scene mode!`);
        }
    }
    this.logger.debug(`Scene ${scene.name} to asset => `, scene);
    return createSceneAsset(scene.asset, this.httpClient, this.logger);
  };
}
