import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-permission-page',
  templateUrl: './no-permission-page.component.html',
  styleUrls: ['./no-permission-page.component.scss'],
})
export class NoPermissionPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
