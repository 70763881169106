import { NgModule } from '@angular/core';
import { IAvatarService, IConfigurationService, ISceneService } from './api';
import { ConfigurationService } from './services/configuration.service';
import { AvatarService } from './services/avatar.service';
import { SceneService } from './services/scene.service';

@NgModule({
  imports: [],
  providers: [
    {
      provide: IConfigurationService,
      useClass: ConfigurationService,
    },
    {
      provide: IAvatarService,
      useClass: AvatarService,
    },
    {
      provide: ISceneService,
      useClass: SceneService,
    },
  ],
  declarations: [],
  exports: [],
  bootstrap: [],
})
export class DataModule {}

export {
  IGarmentService,
  IConfigurationService,
  IGarmentDetails,
  PagedGarmentResponse,
  IAvatarService,
  ISceneService,
} from './api';
