import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IAppState } from '../../app.state';
import { ILogger } from '../../logging';
import { changePageAction } from '../store/requests-overview.actions';
import { IPaginationDataProviderInterface } from './pagination-data-provider.interface';

@Injectable({
  providedIn: 'root',
})
export class GlobalStatePaginationDataProvider
  implements IPaginationDataProviderInterface
{
  public readonly pageCount$: Observable<number>;
  public readonly currentPage$: Observable<number>;
  public readonly totalRecords$: Observable<number>;
  public readonly pageSize$: Observable<number>;

  constructor(private store: Store<IAppState>, private logger: ILogger) {
    this.currentPage$ = this.store.select(
      (state) => state.requestOverview.pagination.currentPage,
    );
    this.pageCount$ = this.store.select((state) => {
      // The state doesn't hold an accurate record
      // of the pageCount. So we calculate it.
      return Math.ceil(
        state.requestOverview.recordCount /
          state.requestOverview.pagination.pageSize,
      );
    });

    this.totalRecords$ = this.store.select((state) => {
      return state.requestOverview.recordCount;
    });
    this.pageSize$ = this.store.select((state) => {
      return state.requestOverview.pagination.pageSize;
    });
  }

  public setCurrentPage(page: number) {
    this.store.dispatch(changePageAction({ page }));
  }

  public setPageSize(page: number) {
    this.store.dispatch(changePageAction({ page }));
  }
}
