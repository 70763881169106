import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-input-dropdown',
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.scss'],
  imports: [CommonModule, FormsModule],
  standalone: true,
})
export class InputDropdownComponent implements OnInit {
  @Input() value: string | number;
  @Input() entries: DropDownEntry[];
  @Input() disabled?: boolean;
  @Output() valueChange = new EventEmitter<DropDownEntry>();

  @Input() placeholder: string;

  public showList: boolean;

  constructor() {}

  ngOnInit(): void {
    this.showList = false;
  }

  itemSelect(entry: DropDownEntry) {
    this.showList = false;
    this.value = entry.id;
    this.valueChange.emit(entry);
  }
}

export interface DropDownEntry {
  id: string | number;
  data: string;
}
