import { Injectable } from '@angular/core';
import {
  AddApiKeyResponseDto,
  ApiKeyDto,
  UserDTO,
  UsersApi,
} from '@reactivereality/cs-api-sdk';
import { AxiosResponse } from 'axios';
import { BehaviorSubject, Observable, first, lastValueFrom } from 'rxjs';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import {
  IApiSdkService,
  ICsApiSdkConfiguration,
  IUser,
  IUserService,
} from '../api';
import { USER_FEATURE_FLAGS } from '../enums/user-feature-flags.enum';

@Injectable({
  providedIn: 'root',
})
export class UserService implements IUserService {
  public readonly me: Observable<IUser>;

  private _apiVersion: string;
  private _usersApi: UsersApi;

  private _me: BehaviorSubject<IUser | undefined>;

  constructor(
    private _logger: ILogger,
    private _apiSdkService: IApiSdkService,
  ) {
    this._logger.info('Creating user service ...');

    this._me = new BehaviorSubject<IUser | undefined>(undefined);

    this.me = this._me.asObservable();

    this._apiSdkService.sdkConfig$.subscribe({
      next: this.initUserService,
      error: this.initUserServiceHasError,
    });
  }

  public updateMe(me: Partial<IUser>): Promise<void> {
    if (!me.id) throw new Error('Missing ID when updating user profile.');

    return this._usersApi
      .vversionUsersIdPut(me.id, this._apiVersion, {
        email: me.email,
        firstName: me.firstName,
        lastName: me.lastName,
      })
      .then(() => {
        this.loadMe();
      });
  }

  public async listApiKeys(): Promise<Array<ApiKeyDto>> {
    const response = await this._usersApi.vversionUsersApikeyGet(
      this._apiVersion,
    );
    return response.data;
  }
  public async newApiKey(name?: string): Promise<AddApiKeyResponseDto> {
    const response = await this._usersApi.vversionUsersApikeyPut(
      this._apiVersion,
      {
        name,
      },
    );
    return response.data;
  }
  public async revokeApiKey(id: string): Promise<void> {
    await this._usersApi.vversionUsersApikeyIdDelete(id, this._apiVersion);
  }

  public async canUserApproveRequest(): Promise<boolean> {
    return lastValueFrom(this.me).then((user: IUser) => {
      if (user && Array.isArray(user.featureFlags)) {
        return user.featureFlags.includes(
          USER_FEATURE_FLAGS.PICTOFITCMS_REJECT_REQUEST,
        );
      }

      return false;
    });
  }

  public async canUserRejectRequest(): Promise<boolean> {
    return lastValueFrom(this.me).then((user: IUser) => {
      if (user && Array.isArray(user.featureFlags)) {
        return user.featureFlags.includes(
          USER_FEATURE_FLAGS.PICTOFITCMS_REJECT_REQUEST,
        );
      }

      return false;
    });
  }

  public refreshMeUser(): void {
    this._apiSdkService.sdkConfig$.pipe(first()).subscribe({
      next: this.loadMe,
      error: this.errorOnFetchingMeUser,
    });
  }

  private loadMe(): void {
    this._usersApi
      .vversionUsersMeGet(this._apiVersion)
      .then((response: AxiosResponse<UserDTO>) => {
        if (response && response.data) {
          this._me.next(this.mapUserDTOToIUser(response.data));
        }
      })
      .catch(this.errorOnFetchingMeUser);
  }

  private initUserService = (apiSdkConfig: ICsApiSdkConfiguration) => {
    if (apiSdkConfig && apiSdkConfig.usersApiSdk && apiSdkConfig.apiVersion) {
      this._apiVersion = apiSdkConfig.apiVersion;
      this._usersApi = apiSdkConfig.usersApiSdk;

      this.loadMe();
    }
  };

  private initUserServiceHasError = (error: Error) => {
    this._logger.error('Cannot initate User API.', error);
  };

  private errorOnFetchingMeUser = (error: Error) => {
    this._logger.error('Error when fetching me user.', error);
  };

  private mapUserDTOToIUser = (user: UserDTO): IUser => {
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      createdAt: user.createdAt,
      modifiedAt: user.modifiedAt,
      name: `${user.firstName} ${user.lastName}`,
      featureFlags: Array.isArray(user.featureFlags) ? user.featureFlags : [],
    };
  };
}
