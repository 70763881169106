<div class="row rowcontent create-content">
  <div class="col-6 pl-5 pr-5 d-flex flex-column">
    <rr-virtual-dressing-room
      #MainVDR
      [settings]="dressingRoomSettings"
      [vdrChange$]="vdrUpdate$"
      [vdrStatus$]="vdrStatus$"
      [vdrReset$]="vdrReset$"
      [token]="token"
    >
      <div
        *ngIf="
          viewerReloadRequired &&
          (createRequestModalStore.avatar$ | async) !== null
        "
        class="col-12 h-100 d-flex justify-content-center align-items-center reload-overlay flex-column"
      >
        <span class="m-2 overlay-info"
          >The outfit has changed. Please click<br />
          reload when you’re finised editing.</span
        >
        <button
          class="m-2 pr-5 pl-5 pt-3 pb-3 btn btn-primary"
          (click)="applyChangedState()"
        >
          Reload preview
        </button>
      </div>
      <div
        *ngIf="(createRequestModalStore.avatar$ | async) === null"
        class="col-12 h-100 d-flex justify-content-center align-items-center avatar-overlay flex-column"
      >
        <span class="m-2">Please select an avatar</span>
        <button
          class="m-2 pr-5 pl-5 pt-3 pb-3 btn btn-primary"
          (click)="addAvatarClicked()"
        >
          Select Avatar
        </button>
      </div>
    </rr-virtual-dressing-room>
  </div>
  <div class="col-6 d-flex flex-column scroll-here">
    <div class="col-11 details-area">
      <app-common-inputs class="d-flex flex-column"></app-common-inputs>
      <!--Select Avatars-->
      <div class="mt-5">
        <h2>Avatar</h2>
        <div class="addAvatarContainer">
          <app-avatar-selection-entry
            [selectedAvatar]="createRequestModalStore.avatar$ | async"
            (changeClicked)="addAvatarClicked()"
          >
          </app-avatar-selection-entry>
        </div>
      </div>
      <!--Select Garments-->
      <app-garment-selection [productType]="5"></app-garment-selection>
      <!--Buttons-->
      <app-action-buttons></app-action-buttons>
    </div>
  </div>
</div>
