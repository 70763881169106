import { ConfigurationPublicOnlyDTO } from '@reactivereality/cs-api-sdk';
import { PRODUCT_TYPE } from '../../../data';
import { AvatarDTO, SceneDTO } from '../../../data/api';

export async function mapRequestsToAssets(
  productType: PRODUCT_TYPE,
  requests: ConfigurationPublicOnlyDTO[],
): Promise<AvatarDTO[] | SceneDTO[]> {
  const assets: AvatarDTO[] | SceneDTO[] = [];
  for (const request of requests) {
    if (request.storageInTransit != false || request.storageLocation != null) {
      //configuration is archived or a archiving/restoring process is ongoing
      this._logger.debug('Asset is archived - id: ', request.id);
      const asset = {
        ...request,
        archived: true,
        enabled: false,
        isPublic: false,
      };
      assets.push(asset);
      continue;
    }
    let enabled = true;

    if (productType === PRODUCT_TYPE.AVATAR_3D) {
      enabled = ['mobile', 'web'].every((value) => {
        return request.results.find((r) => r.format === value);
      });
    }
    if (
      productType === PRODUCT_TYPE.AVATAR_2D ||
      productType === PRODUCT_TYPE.SCENE
    ) {
      enabled = ['web'].every((value) => {
        return request.results.find((r) => r.format === value);
      });
    }

    const asset = {
      ...request,
      isPublic: false,
      enabled: enabled,
      archived:
        request.storageInTransit != false || request.storageLocation != null,
    };
    assets.push(asset);
  }
  return assets;
}
