<div class="input-container" [ngClass]="isValid ? '' : 'input-error'">
  <input
    type="text"
    [ngModel]="value"
    (ngModelChange)="valueChanged($event)"
    placeholder=" "
    [disabled]="disabled"
  />
  <span class="input-label">{{ placeholder }}</span>
  <span *ngIf="required" class="input-required">Required</span>
</div>
