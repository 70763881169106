import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FILE_UPLOAD_STATE,
  UploadData,
} from 'projects/content-service-cms/src/app/core/interfaces/upload-data.interface';
import { ICustomerService } from 'projects/content-service-cms/src/app/customer';
import {
  IFileService,
  IFileUploadService,
  IProductService,
  IRequestDetail,
  IRequestService,
} from 'projects/content-service-cms/src/app/data';
import { ITagService } from 'projects/content-service-cms/src/app/data/api';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { CreateRequestInputData } from '../create-request-input.interface';
import { CreateRequestModalStore } from '../create-request-modal.state';
import { CreateRequestBaseService } from './create-request-base';

@Injectable({
  providedIn: 'root',
})
export class CreateGarment3DCADService extends CreateRequestBaseService {
  constructor(
    private _http: HttpClient,
    private _fileService: IFileService<any>,
    _requestService: IRequestService,
    private _logger: ILogger,
    private customerService: ICustomerService,
    _productService: IProductService,
    private _fileUploadService: IFileUploadService,
    tagService: ITagService,
  ) {
    super(_requestService, _productService, tagService);
  }

  prepareCreateNew = async (
    inputData: CreateRequestInputData,
    store: CreateRequestModalStore,
  ): Promise<void> => {
    store.clearCreateRequestCommonData();
    store.clearExistingRequest();
    store.setProductType(inputData.productType);
    store.setFeatures({
      supportDraft: false,
      supportDueDate: false,
      supportPriority: false,
      supportSize: false,
    });
  };

  uploadFiles = async (
    filesToUpload: Array<UploadData>,
    request: IRequestDetail,
  ): Promise<void> => {
    const promises = filesToUpload.map(async (file) => {
      file.fileinformation.configurationId = request.id;
      file.uploadState.next(FILE_UPLOAD_STATE.UPLOADING);
      return this._fileUploadService.uploadFile(
        file.fileinformation,
        file.file,
      );
    });

    promises.map((p, index) => {
      p.then((_) => {
        filesToUpload[index].uploadState.next(FILE_UPLOAD_STATE.DONE);
      });
    });

    await Promise.all(promises);
  };

  createRequest = async (
    store: CreateRequestModalStore,
    files: {
      cad: UploadData;
      reference: Array<UploadData>;
    },
  ): Promise<void> => {
    this._logger.debug(`Creating Garment 3D CAD request ...`);
    const customer = await this.customerService.currentCustomer();
    const state = store.current();
    const product = await this.createProduct(
      customer,
      state.requestCommonData,
      state.productType,
    );

    const frontRequest = this.getRequestUpdate(
      state.requestCommonData,
      product.id,
    );
    const front = await this.updateRequest(frontRequest);
    await this.updateTags(front, state.tags);
    const refs =
      files.reference === undefined || files.reference === null
        ? []
        : files.reference;
    await this.uploadFiles([files.cad, ...refs], front);
    await this._requestService.setReady(front.id);
  };
}
