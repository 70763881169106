import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { InputTextComponent } from 'projects/content-service-cms/src/app/components/inputs/input-text/input-text.component';
import { CollectionScreenStore } from '../../collection-screen.state';

@Component({
  selector: 'app-collection-info',
  standalone: true,
  imports: [InputTextComponent, CommonModule],
  templateUrl: './collection-info.component.html',
  styleUrl: './collection-info.component.scss',
})
export class CollectionInfoComponent {
  constructor(public createCollectionStore: CollectionScreenStore) {}
}
