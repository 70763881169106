import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { IUserService } from 'projects/content-service-cms/src/app/data';
import { firstValueFrom } from 'rxjs';
import { NgStorageManagerService } from '../../core/services/ng-storage-manager.service';
import { UserDataPermissionService } from '../../core/services/userdata-permission.service';
import { ILogger } from '../../logging';
import { IAuthService } from '../api';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  public userAuthenticated: boolean = false;

  private userGrantedPermission: boolean = false;

  constructor(
    private _userService: IUserService,
    private _authService: IAuthService,
    private _storageService: NgStorageManagerService,
    private router: Router,
    private logger: ILogger,
    private _userdata: UserDataPermissionService,
  ) {
    this._userdata.granted$.subscribe((granted) => {
      this.userGrantedPermission = granted.includes('Core');
    });
  }

  canActivate(): Promise<boolean> {
    return this.checkIsUserAuthenticated();
  }

  private async checkIsUserAuthenticated(): Promise<boolean> {
    if (!this.userGrantedPermission) return false;

    const res = await this._authService.isAuthenticated();
    this._userService.me.subscribe((current) => {
      if (current != undefined)
        // this._storageService.setCurrentUserID(current.id.toString());
        this._storageService.setItem(
          'currentUserId',
          current.id ?? '',
          'local',
        );
    });

    if (!res) {
      return this.redirectToUnauthorized();
    }

    const isCustomer = await firstValueFrom(this._authService.isCustomer$);
    const isEmployee = await firstValueFrom(this._authService.isEmployee$);

    this.logger.info(`Features`, {
      isCustomer,
      isEmployee,
    });

    if (isCustomer || isEmployee) {
      return true;
    }
    this.router.navigate(['/patient']);
    return false;
  }

  private redirectToUnauthorized(): boolean {
    this._authService.login();
    return false;
  }
}
