<rr-card>
  <div>
    <span class="oi rr-back-button" (click)="onBackClicked()"></span>
    <div (click)="clearRange()">All</div>
    <div *ngFor="let preset of presets" (click)="selectPreset(preset.range)">
      <span>{{ preset.text }}</span>
    </div>
  </div>

  <rr-calendar #calendar (selectedChanged)="onChange($event)"></rr-calendar>
</rr-card>
