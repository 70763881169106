import { createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { SelectedFilters } from 'projects/web-ui-component-library/src';
import { RequestFilters } from '../components/request-list-action-bar/request-list-action-bar.component';
import {
  changeCustomerAction,
  changePageAction,
  changePageSizeAction,
  clearFilters,
  clearRequestModalAction,
  cloneRequestAction,
  createNewRequestAction,
  filtersChange,
  hideLoaderAction,
  loadFiltersCompleteAction,
  loadTagsCompleteAction,
  paginationChange,
  retrievedRequests,
  retrievedRequestsAggregate,
  retrievedRequestsAggregateWithError,
  retrievedRequestsWithError,
  showLoaderAction,
  sortChange,
  updateDraftRequestAction,
} from './requests-overview.actions';
import { IRequestsOverviewState } from './requests-overview.state';

const initialState: IRequestsOverviewState = {
  recordCount: null,
  pagination: {
    currentPage: 1,
    pageSize: 10,
    pageCount: null,
    recordCount: null,
  },
  sort: {
    fieldName: 'createdAt',
    desc: true,
  },
  filters: {},
  tags: [],
  requests: [],
  requestAggregates: null,
  requestsError: null,
  requestAggregatesError: null,
  currentCustomerID: null,
  requests_loaded: null,
  showLoader: false,
  createRequestModal: null,
};

const paginationChangedReducer = (
  state: IRequestsOverviewState,
  { pagination },
): IRequestsOverviewState => ({
  ...state,
  pagination: {
    ...state.pagination,
    ...pagination,
  },
  showLoader: true,
});

const sortChangedReducer = (
  state: IRequestsOverviewState,
  { sort },
): IRequestsOverviewState => ({ ...state, sort, showLoader: true });

const filtersChangedReducer = (
  state: IRequestsOverviewState,
  { filters },
): IRequestsOverviewState => {
  // only change the page if the filters are NOT equal
  const currentPage = _.isEqual(state.filters, filters)
    ? state.pagination.currentPage
    : 1;

  return {
    ...state,
    filters,
    pagination: {
      ...state.pagination,
      currentPage,
    },
    showLoader: true,
  };
};
const clearFiltersReducer = (
  state: IRequestsOverviewState,
): IRequestsOverviewState => ({
  ...state,
  filters: {
    customerId: state.filters.customerId,
    createdAt: null,
    dueAt: null,
    name: null,
    productTypeId: null,
    sku: null,
    stateId: null,
    tags: null,
  },
  pagination: {
    ...state.pagination,
    currentPage: 1,
  },
  showLoader: true,
});
const requestsChangedReducer = (
  state: IRequestsOverviewState,
  { requests, recordCount },
): IRequestsOverviewState => ({
  ...state,
  requests,
  recordCount,
  requestsError: null,
  requests_loaded: new Date(),
});

const showLoaderReducer = (
  state: IRequestsOverviewState,
): IRequestsOverviewState => ({
  ...state,
  showLoader: true,
});
const hideLoaderReducer = (
  state: IRequestsOverviewState,
): IRequestsOverviewState => ({
  ...state,
  showLoader: false,
});
const requestsErrorChangedReducer = (
  state: IRequestsOverviewState,
): IRequestsOverviewState => ({
  ...state,
  requestsError: 'Error while fetching requests.',
  showLoader: false,
});

const requestsAggregateChangedReducer = (
  state: IRequestsOverviewState,
  { requestAggregates },
): IRequestsOverviewState => ({
  ...state,
  requestAggregates,
  requestAggregatesError: null,
  showLoader: false,
});

const requestsAggregateErrorChangedReducer = (
  state: IRequestsOverviewState,
): IRequestsOverviewState => ({
  ...state,
  requestAggregatesError: 'Error while fetching requests aggregates.',
  showLoader: false,
});

const changePageReducer = (
  state: IRequestsOverviewState,
  { page }: { page: number },
): IRequestsOverviewState => ({
  ...state,
  pagination: Object.assign({}, state.pagination, {
    currentPage: page,
  }),
  showLoader: true,
});

const changePageSizeReducer = (
  state: IRequestsOverviewState,
  { pageSize }: { pageSize: number },
): IRequestsOverviewState => ({
  ...state,
  pagination: Object.assign({}, state.pagination, {
    pageSize: pageSize,
  }),
  showLoader: true,
});

const loadFiltersReducer = (
  state: IRequestsOverviewState,
  { filters }: { filters: SelectedFilters<RequestFilters> },
): IRequestsOverviewState => {
  return {
    ...state,
    filters,
  };
};

const loadTagsReducer = (
  state: IRequestsOverviewState,
  { tags }: { tags: { name: string; uuid: string }[] },
): IRequestsOverviewState => ({
  ...state,
  tags,
});

const changeRequestModalStateReducer = (
  state: IRequestsOverviewState,
  createRequestModal: IRequestsOverviewState['createRequestModal'],
): IRequestsOverviewState => ({
  ...state,
  createRequestModal,
});

const changeCustomerReducer = (
  state: IRequestsOverviewState,
  { customer }: { customer: string },
): IRequestsOverviewState => {
  return {
    ...initialState,
    currentCustomerID: customer,
  };
};

export const requestOverviewReducer = createReducer<IRequestsOverviewState>(
  initialState,
  on(retrievedRequests, requestsChangedReducer),
  on(paginationChange, paginationChangedReducer),
  on(changePageAction, changePageReducer),
  on(changePageSizeAction, changePageSizeReducer),
  on(sortChange, sortChangedReducer),
  on(filtersChange, filtersChangedReducer),
  on(retrievedRequestsWithError, requestsErrorChangedReducer),
  on(retrievedRequestsAggregate, requestsAggregateChangedReducer),
  on(retrievedRequestsAggregateWithError, requestsAggregateErrorChangedReducer),
  on(loadFiltersCompleteAction, loadFiltersReducer),
  on(loadTagsCompleteAction, loadTagsReducer),
  on(changeCustomerAction, changeCustomerReducer),
  on(showLoaderAction, showLoaderReducer),
  on(hideLoaderAction, hideLoaderReducer),
  on(createNewRequestAction, changeRequestModalStateReducer),
  on(cloneRequestAction, changeRequestModalStateReducer),
  on(updateDraftRequestAction, changeRequestModalStateReducer),
  on(clearFilters, clearFiltersReducer),
  on(clearRequestModalAction, (state) =>
    changeRequestModalStateReducer(state, null),
  ),
);
