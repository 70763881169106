import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import {
  FILE_UPLOAD_STATE,
  UploadData,
} from '../../core/interfaces/upload-data.interface';

@Component({
  selector: 'app-file-upload-entry',
  templateUrl: './file-upload-entry.component.html',
  styleUrls: ['./file-upload-entry.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class FileUploadEntryComponent implements OnInit {
  @Input() public file: UploadData;
  @Input() public uploadState: Subject<FILE_UPLOAD_STATE>;
  @Output() public deleteClicked = new EventEmitter<UploadData>();

  public imageDataUrl;
  public state: FILE_UPLOAD_STATE = FILE_UPLOAD_STATE.SELECTED;

  constructor() {}

  async ngOnInit(): Promise<void> {
    this.generateUploadThumbnail();
    this.uploadState.subscribe((state) => {
      this.state = state;
    });
  }

  public calculateFileSize(sizeInBytes: number): string {
    if (sizeInBytes === 0) {
      return '';
    }
    let val = sizeInBytes / 1000;
    let unit = ' kB';
    if (val >= 1000) {
      val = val / 1000;
      unit = ' MB';
    }
    return `${val.toFixed(1)} ${unit}`;
  }

  private blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  public deleteEntry($event: MouseEvent) {
    this.deleteClicked.emit(this.file);
  }

  public async generateUploadThumbnail() {
    const img: HTMLImageElement = document.createElement('img');
    const temp: any = await this.blobToBase64(this.file.file);
    img.src = temp;
    const canvas = document.createElement('canvas');
    img.onload = () => {
      if (img.width > img.height) {
        canvas.height = 64;
        canvas.width = img.width / (img.height / 64);
      } else {
        canvas.width = 64;
        canvas.height = img.height / (img.width / 64);
      }
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      this.imageDataUrl = canvas.toDataURL();
    };
  }
}
