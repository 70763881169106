import { NgModule } from '@angular/core';
import { RRSharedModule } from '../shared-module/shared.module';
import { RRToDateFormatPipe } from './pipes/to-date-format.pipe';
import { RRKeysPipe } from './pipes/keys.pipe';
import { RRTruncatePipe } from './pipes/truncate.pipe';
import { RRDefaultValuePipe } from './pipes/default-value.pipe';
import { RRTakePipe } from './pipes/take.pipe';

@NgModule({
  declarations: [
    RRToDateFormatPipe,
    RRKeysPipe,
    RRTruncatePipe,
    RRDefaultValuePipe,
    RRTakePipe,
  ],
  imports: [RRSharedModule],
  exports: [
    RRToDateFormatPipe,
    RRKeysPipe,
    RRTruncatePipe,
    RRDefaultValuePipe,
    RRTakePipe,
  ],
})
export class RRPipesModule {}
