<ul *ngIf="profileStore.currentPage$ | async as currentPage">
  <li
    [class.active]="currentPage === 'UserInfo'"
    (click)="profileStore.setCurrentPage('UserInfo')"
  >
    <span class="oi oi-person"></span>User Information
  </li>
  <li
    [class.active]="currentPage === 'Security'"
    (click)="profileStore.setCurrentPage('Security')"
  >
    <span class="oi oi-lock-locked"></span>Security
  </li>
  <li
    [class.active]="currentPage === 'ApiKeys'"
    (click)="profileStore.setCurrentPage('ApiKeys')"
  >
    <span class="oi oi-key"></span>API Keys
  </li>
</ul>
