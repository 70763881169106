<div class="row rowcontent">
  <div class="col-6 pl-5 pr-5 d-flex flex-column">
    <h2>CAD file</h2>
    <app-file-upload-full
      class="mb-5"
      title="CAD (required)"
      [initialFiles]="initialCadFiles"
      [multipleFiles]="false"
      (filesSelected)="onSelectedFilesChanged('cad', $event)"
    ></app-file-upload-full>
    <app-file-upload-full
      class="mb-5"
      [initialFiles]="initialReferenceFiles"
      title="Reference images"
      (filesSelected)="onSelectedFilesChanged('ref', $event)"
    ></app-file-upload-full>
  </div>
  <div class="col-6 d-flex flex-column scroll-here">
    <div class="col-11">
      <app-common-inputs class="d-flex flex-column"></app-common-inputs>
      <!--Buttons-->
      <app-action-buttons></app-action-buttons>
    </div>
  </div>
</div>
