<ng-template #selectScene>
  <app-add-scene-modal
    (selectedScene)="onSceneSelected($event)"
  ></app-add-scene-modal>
</ng-template>

<app-add-item
  *ngIf="selectedScene === undefined || selectedScene === null"
  title="Scene"
  info="Select a Scene"
  (addItemClicked)="sceneSelectionService.selectScene()"
>
</app-add-item>
<div
  *ngIf="selectedScene !== undefined && selectedScene !== null"
  (click)="modalService.show({ title: 'Select Scene', template: selectScene })"
  class="d-flex flex-row row entry align-items-center"
>
  <div class="p2 ml-4 mt-3 mb-3">
    <img
      src="{{ selectedScene.previewUrl | sceneThumbnailAvailable }}"
      class="thumbnail"
    />
  </div>
  <div class="d-flex flex-column mt-3 mb-3 ml-3">
    <span class="ml-3 mr-1">{{ selectedScene?.name }}</span>
    <span class="type ml-3 mr-1">{{
      selectedScene?.productTypeId | productType
    }}</span>
  </div>
  <div class="p2 ml-auto mr-2 delete">
    <span class="rr-pen"></span>
  </div>
</div>
