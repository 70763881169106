import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RRCalendarModule } from 'projects/web-ui-component-library/src';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  imports: [CommonModule, FormsModule, RRCalendarModule],
  standalone: true,
})
export class InputDateComponent implements OnInit, OnChanges {
  @Input() date: Date;
  @Input() disabled?: boolean;
  @Input() placeholder: string;
  @Output() valueChange = new EventEmitter<Date>();

  public show: boolean;
  public formatedDate: string;
  public showBackdrop: boolean = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['date'] !== undefined) {
      this.formatedDate = changes['date'].currentValue
        ? Intl.DateTimeFormat('en', {
            dateStyle: 'short',
            timeStyle: 'short',
          }).format(changes['date'].currentValue)
        : '';
    }
  }
  ngOnInit(): void {
    this.show = false;
  }

  onDateSelected(dates: { from: Date; to: Date }) {
    this.show = false;
    const date: Date = new Date();
    const fromDate: Date = dates.from;
    date.setDate(fromDate.getDate());
    this.date = date;

    this.valueChange.emit(date);
  }

  backdropListClicked() {
    this.show = false;
  }
}
