import { createAction, props } from '@ngrx/store';
import { CustomerDTO } from '@reactivereality/cs-api-sdk';

export const updateCustomerListAction = createAction(
  '[Customer] Update customer list',
  props<{ myCustomerList: Array<CustomerDTO> }>(),
);

export const setCurrentCustomerAction = createAction(
  '[Customer] Set current customer',
  props<{ currentCustomer?: CustomerDTO }>(),
);

export const setCurrentCustomerIDAction = createAction(
  '[Customer] Set current customer ID',
  props<{ customerID?: string }>(),
);

export const loadCurrentCustomerAction = createAction(
  '[Customer] Loading current customer...',
);

export const loadCurrentCustomerCompleteAction = createAction(
  '[Customer] Loaded current customer',
  props<{ currentCustomer?: string }>(),
);
