<tr
  class="collection-list-row"
  id="overview-list-row-{{ record.id }}"
  (click)="editCollection(record)"
>
  <td><img src="{{ record.imageUrl }}" class="collection-thumbnail" /></td>
  <td>{{ record.name }}</td>
  <td>{{ record.id }}</td>
  <td>{{ record.references }}</td>
  <td>
    <span
      title="Preview Collection"
      class="oi oi-eye"
      (click)="onPreviewCollection($event, record)"
    ></span>
  </td>
  <td>
    <span
      title="Delete Collection"
      class="oi oi-trash"
      (click)="notImplemnted($event)"
    ></span>
  </td>
</tr>
