<div class="request-overview d-flex flex-column">
  <div class="overview-scroll d-flex flex-column">
    <rr-stacked-bar-chart
      [dataset]="statesDataset"
      [barWidthMultiplier]="1000"
      [barMinWidth]="24"
    ></rr-stacked-bar-chart>
    <rr-card id="request-list">
      <rr-overlay-spinner [spinnerStatus]="spinnerStatus"></rr-overlay-spinner>
      <div class="inner">
        <h2>Requests</h2>
        <div class="d-flex justify-content-between create-request-button">
          <button
            id="request-overview-new-request"
            class="btn btn-primary"
            (click)="onCreateNewClicked()"
          >
            <span class="oi oi-plus"></span>
            <span> New Request </span>
          </button>
          <div class="refresh-info-text">
            <span
              class="margin-right"
              [tooltip]="updated_date | async | prettyProductDate"
            >
              {{ updated_text | async }}
            </span>
            <span class="oi oi-reload" (click)="reloadData()"></span>
          </div>
        </div>

        <rr-table
          [configuration]="requestTableConfiguration"
          [datasource]="requestDatasource"
          [showSkeletonLoader]="showSkeletonLoader"
          (sortColumnClicked)="onSortColumn($event)"
        >
          <div
            class="empty-table-state d-flex flex-column justify-content-center align-items-center"
            emptyState
          >
            <span class="rr-empty-hanger"></span>
            <span class="empty-first-line">We couldn’t find any requests</span>
            <span class="empty-second-line">Try adjusting your filters.</span>
            <span class="btn-underline" (click)="clearFilter()"
              >Clear all filters</span
            >
          </div>
        </rr-table>
      </div>
    </rr-card>
  </div>
  <!--div class="body-background"-->
  <rr-request-list-pagination
    class="list-pagination"
    [AdvancedControls]="true"
  ></rr-request-list-pagination>
  <!--/div-->
</div>
<ng-template #statusOptionTemplate let-item="item">
  <span
    class="status-field"
    [ngClass]="
      RequestStateIdHelper.getCustomerStateColorClass(
        RequestStateIdHelper.mapInternatStateToCustomerState(item.id)
      )
    "
    >{{ item.value }}</span
  >
</ng-template>


