import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { IAuthService } from '../../api';

@Component({
  selector: 'rr-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
  public errorCode: string;

  constructor(
    private _authService: IAuthService,
    private route: ActivatedRoute,
    private location: Location,
  ) {}

  ngOnInit() {
    this.route.fragment.subscribe((fragment: string) => {
      this.errorCode = fragment;
    });
  }

  public login(): void {
    this._authService.login();
  }

  public clickBack(): void {
    this.location.back();
  }
}
