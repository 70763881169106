import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class AvatarSelectionService {
  public onSelect: EventEmitter<void> = new EventEmitter();

  constructor() {}

  public selectAvatar(): void {
    this.onSelect.emit();
  }
}
