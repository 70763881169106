<div
  class="input-container d-flex flex-row"
  [ngClass]="{ disabled: disabled === true }"
  (click)="show = !show"
>
  <input type="text" [ngModel]="formatedDate" placeholder=" " disabled />
  <span class="input-label">{{ placeholder }}</span>
  <span class="rr-dropdown ml-auto mr-2"></span>
</div>
<div class="d-flex flex-column" *ngIf="show && disabled !== true">
  <rr-calendar
    (selectedChanged)="onDateSelected($event)"
    [singleSelect]="true"
    class="calendar"
  ></rr-calendar>
</div>
<div
  class="backdrop-selection"
  *ngIf="this.show"
  (click)="backdropListClicked()"
></div>
