<rr-card>
  <app-menu></app-menu>

  <span id="title">
    <span class="parent" *ngIf="parentLink">
      <a [routerLink]="[parentLink.url]">{{ parentLink.title }}</a>
      &nbsp;/&nbsp;
    </span>
    {{ titleText }}
  </span>

  <svg
    id="logo"
    viewBox="0 0 90 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 13.2941V0.587696H4.64765C5.37506 0.587696 5.95502 0.623684 6.38726 0.692618C7.39946 0.869768 8.18569 1.31404 8.74595 2.0239C9.30994 2.73504 9.59021 3.64816 9.59021 4.76403C9.59021 6.08011 9.2104 7.08548 8.45025 7.7776C7.69197 8.47252 6.59007 8.81922 5.1459 8.81922H3.40815V13.2941H0ZM3.40815 3.19654V6.2091H4.35089C4.90609 6.2091 5.34525 6.07149 5.66464 5.79575C5.98669 5.52027 6.14559 5.1419 6.14559 4.66012C6.14559 3.68465 5.56031 3.19654 4.38921 3.19654H3.40815Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2783 0.588081H16.9671V13.2944H13.2783V0.588081Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.7215 2.21615L30.0707 4.59442C29.3177 4.02074 28.4992 3.73532 27.6151 3.73532C26.6223 3.73532 25.78 4.07603 25.0855 4.75384C24.3926 5.43346 24.0468 6.2815 24.0468 7.29359C24.0468 8.34374 24.3831 9.20772 25.0585 9.88683C25.7333 10.5667 26.5862 10.9079 27.6151 10.9079C28.1655 10.9079 28.6493 10.8115 29.067 10.6189C29.3293 10.5124 29.6651 10.299 30.0707 9.9758L31.6681 12.3541C31.0816 12.8308 30.5501 13.1779 30.0707 13.3924C29.1269 13.7969 28.1531 14 27.1491 14C25.2836 14 23.7342 13.3741 22.5033 12.1221C21.2719 10.8691 20.6558 9.2733 20.6558 7.33036C20.6558 5.39772 21.2773 3.79215 22.5209 2.5098C23.7649 1.22977 25.3249 0.587696 27.2017 0.587696C28.1345 0.587696 29.0147 0.749435 29.8379 1.07111C30.4127 1.28531 31.0404 1.66613 31.7215 2.21615Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34.6726 3.47824V0.588081H43.5245V3.47824H40.7261V13.2944H37.4892V3.47824H34.6726Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M46.4756 7.29436C46.4756 5.38048 47.1382 3.78357 48.4652 2.50594C49.7912 1.22728 51.4704 0.587696 53.5039 0.587696C55.5229 0.587696 57.1942 1.22446 58.5139 2.49722C59.833 3.7687 60.4921 5.36843 60.4921 7.29436C60.4921 9.22055 59.8262 10.82 58.4948 12.091C57.1618 13.364 55.4987 14 53.5039 14C51.4821 14 49.8065 13.3668 48.4748 12.1C47.1417 10.8344 46.4756 9.23209 46.4756 7.29436ZM53.5039 3.7628C52.5442 3.7628 51.7223 4.10926 51.0409 4.7968C50.3601 5.48691 50.0186 6.31934 50.0186 7.29436C50.0186 8.29272 50.3601 9.13362 51.0409 9.81834C51.7223 10.5007 52.5442 10.8434 53.5039 10.8434C54.4505 10.8434 55.2609 10.4974 55.9363 9.80936C56.6117 9.119 56.9504 8.28144 56.9504 7.29436C56.9504 6.33293 56.6141 5.50204 55.9456 4.80526C55.2759 4.11208 54.463 3.7628 53.5039 3.7628Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M64.1806 13.2944V0.588081H72.2953V3.1422H67.8139V5.4344H71.7219V7.97287H67.8139V13.2944H64.1806Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M74.5087 5.52912H78.1974V13.2944H74.5087V5.52912Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M81.1481 3.47824V0.588081H90V3.47824H87.2041V13.2944H83.9638V3.47824H81.1481Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M78.1971 1.7649C78.1971 0.790337 77.3718 0 76.3531 0C75.3338 0 74.5085 0.790337 74.5085 1.7649C74.5085 2.73995 75.3338 3.52955 76.3531 3.52955C77.3718 3.52955 78.1971 2.73995 78.1971 1.7649Z"
      fill="black"
    />
  </svg>

  <button (click)="doLogout()">
    <span class="oi oi-lock-unlocked"></span>
  </button>
</rr-card>

<rr-card *ngIf="actionBarComponent" class="action-bar">
  <ng-container #actionBar></ng-container>
</rr-card>
