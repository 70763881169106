/* eslint-disable no-console */
import { InjectionToken } from '@angular/core';
import {
  IApiConfiguration,
  IAppConfiguration,
  IDiscoveryConfiguration,
} from '../../data/api';
import { ConsoleLoggerService } from '../../logging/services/console-logger.service';

export const DiscoToken = new InjectionToken<NgDiscoveryService>('DiscoToken');

export abstract class NgDiscoveryService {
  public readonly config: IDiscoveryConfiguration;
}

export class DiscoveryConfig extends NgDiscoveryService {
  constructor(public readonly config: IDiscoveryConfiguration) {
    super();
  }
}

export const getDiscovery: Promise<IDiscoveryConfiguration> = new Promise(
  (resolve) => {
    console.time('getDiscovery');
    const logger = new ConsoleLoggerService();
    logger.info('getDiscovery', 'starting...');

    fetch('/.well-known/app-configuration')
      .then((res) => res.json())
      .then((appConfiguration: IAppConfiguration) => {
        logger.info('getDiscovery', 'acquired App configuration...');

        return fetch(appConfiguration.config)
          .then((res) => res.json())
          .then((apiConfiguration: IApiConfiguration) => {
            logger.info('getDiscovery', 'acquired API configuration...');

            // TODO: 🔥🔥🔥 Burn this with nuclear fire 🔥🔥🔥
            apiConfiguration = {
              ...apiConfiguration,
              computeServerUrl: apiConfiguration.computeServerUrl,
              baseUrl: window.location.origin,
              baseApiUrl: apiConfiguration.baseApiUrl.includes(
                appConfiguration.apiVersion,
              )
                ? apiConfiguration.baseApiUrl
                : `${apiConfiguration.baseApiUrl}/${appConfiguration.apiVersion}`,
              signInCallback: `${window.location.origin}/signin-callback`,
              signOutCallback: `${window.location.origin}/signout-callback`,
              platformApiBaseUrl: (apiConfiguration.platformApiBaseUrl !== undefined && apiConfiguration.platformApiBaseUrl !== ''?
                apiConfiguration.platformApiBaseUrl: apiConfiguration.baseApiUrl.includes('cs-dev')
                  ?'https://api.platform-staging.pictofit.com/'
                  : 'https://api.platform.pictofit.com/'

              ),
            };

            return {
              appConfiguration,
              apiConfiguration,
            };
          });
      })
      .then((cfg) => {
        logger.info('getDiscovery', 'done!', cfg);

        console.timeEnd('getDiscovery');
        resolve(cfg);
      });
  },
);
