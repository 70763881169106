import { NgModule } from '@angular/core';
import { TableComponent } from './components/table/table.component';
import { TableContainerComponent } from './components/table-container/table-container.component';
import { TablePaginationComponent } from './components/table-pagination/table-pagination.component';
import { TableButtonsComponent } from './components/table-buttons/table-buttons.component';
import { TableBatchOptionsComponent } from './components/table-batch-options/table-batch-options.component';
import { RRClientSideDataService } from './services/client-side-data.service';
import { ShowTooltipDirective } from './components/directives/show-tooltip.directive';
import { RRClickEventsModule } from '../click-events/click-events.module';
import { RRTagsModule } from '../tags/tags.module';
import { TableCustomBatchOptionsComponent } from './components/table-custom-batch-options/table-custom-batch-options.component';
import { RRSharedModule } from '../shared-module/shared.module';
import { RRBootstrapModule } from '../bootstrap/bootstrap.module';
import { RRSpinnerModule } from '../spinner/spinner.module';
import { RRCopyToClipboardModule } from '../copy-to-clipboard/copy-to-clipboard.module';
import { RRModalModule } from '../modal/modal.module';

@NgModule({
  declarations: [
    TableComponent,
    TableContainerComponent,
    TablePaginationComponent,
    TableButtonsComponent,
    TableBatchOptionsComponent,
    ShowTooltipDirective,
    TableCustomBatchOptionsComponent,
  ],
  imports: [
    RRSharedModule,
    RRBootstrapModule,
    RRSpinnerModule,
    RRCopyToClipboardModule,
    RRModalModule,
    RRClickEventsModule,
    RRTagsModule,
  ],
  exports: [
    TableComponent,
    TableContainerComponent,
    TablePaginationComponent,
    TableButtonsComponent,
    TableBatchOptionsComponent,
    TableCustomBatchOptionsComponent,
  ],
  providers: [RRClientSideDataService],
})
export class RRTableContainerModule {}
