import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomerDTO } from '@reactivereality/cs-api-sdk';
import { Observable, filter, firstValueFrom } from 'rxjs';
import { ICustomerService } from '..';
import { IApiSdkService } from '../../data';
import {
  setCurrentCustomerIDAction,
  updateCustomerListAction,
} from '../store/customer.actions';
import {
  selectCurrentCustomer,
  selectMyCustomers,
} from '../store/customer.selectors';
import { ICustomerState } from '../store/customer.state';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends ICustomerService {
  constructor(
    private sdkService: IApiSdkService,
    private store: Store<ICustomerState>,
  ) {
    super();

    (window as any).customer = this;

    this.sdkService.sdkConfig$
      .pipe(filter((cfg) => cfg !== undefined))
      .subscribe(async (sdk) => {
        const customers = await sdk.customersApiSdk.vversionCustomersMyGet(
          '1.5',
        );

        // sort alphabetically...
        const myCustomerList = [...customers.data].sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        this.store.dispatch(updateCustomerListAction({ myCustomerList }));
      });
  }

  readonly currentCustomer = async (): Promise<CustomerDTO> => {
    return firstValueFrom(this.store.select(selectCurrentCustomer));
  };

  readonly setCurrentCustomer = (id: string) => {
    this.store.dispatch(setCurrentCustomerIDAction({ customerID: id }));
  };

  readonly currentCustomer$: Observable<CustomerDTO | undefined> =
    this.store.select(selectCurrentCustomer);
  readonly myCustomers$: Observable<Array<CustomerDTO>> =
    this.store.select(selectMyCustomers);
}
