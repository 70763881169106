import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  PageContextService,
  RRAlertsModule,
  RRBootstrapModule,
  RRBreadcrumbModule,
  RRFilterModule,
  RRFooterModule,
  RRHeaderModule,
  RRLayoutModule,
  RRModalModule,
  RRPipesModule,
  RRSpinnerModule,
} from 'projects/web-ui-component-library/src';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { CoreModule } from './core/core.module';
import { startupFactory } from './core/factories/start-up.factory';
import { AuthComponents, AuthProviders } from './auth';
import { DataProviders } from './data';
import { ILogger, LoggerProviders } from './logging';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { VirtualDressingRoomModule } from './virtual-dressing-room/virtual-dressing-room.module';

import * as Sentry from '@sentry/angular';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'projects/web-ui-component-tester/src/environments/environment';
import { CustomerModule } from './customer/customer.module';
import { MenuComponent } from './components/menu/menu.component';
import { RRTopAppBarComponent } from './components/top-app-bar/top-app-bar.component';
import { CreateRequestModalComponent } from './request-overview/components/create-request-modal/create-request-modal.component';
import { CreateRequestSelectTypeModalComponent } from './request-overview/components/create-request-select-type-modal/create-request-select-type-modal.component';
import { GeneralComponents, GeneralProviders } from './components';
import { RequestOverviewModule } from './request-overview/request-overview.module';
import { RRSelectFilterModule } from 'projects/web-ui-component-library/src/lib/filter-elements/select-filter.module';
import { ModalModule } from './modal/modal.module';
import {
  DiscoToken,
  NgDiscoveryService,
} from './core/services/ng-discovery.service';
import { UserDataPermissionService } from './core/services/userdata-permission.service';
import { UserDataPermissionComponent } from './components/user-data-permission/user-data-permission/user-data-permission.component';
import { NavigationModule } from './navigation/navigation.module';
import { ProfileModule } from './profile/profile.module';

import { CollectionOverviewModule } from './collection-overview/collection-overview.module';
import { CollectionScreenComponent } from './collection-screen/collection-screen.component';

@NgModule({
  declarations: [
    AppComponent,
    ...AuthComponents,
    ...GeneralComponents,
    MenuComponent,
    RRTopAppBarComponent,
    UserDataPermissionComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgJsonEditorModule,
    BrowserAnimationsModule,
    // PROJECT MODULES
    CoreModule,
    //WEB UI COMPONENT LIBRARY
    RRBootstrapModule,
    RRHeaderModule,
    RRBreadcrumbModule,
    RRFooterModule,
    RRPipesModule,
    RRAlertsModule,
    RRModalModule,
    RRSpinnerModule,
    VirtualDressingRoomModule,
    RRLayoutModule,
    RRFilterModule,
    RequestOverviewModule,
    CollectionOverviewModule,
    RRSelectFilterModule,

    //Stores
    StoreModule.forRoot([]),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    CustomerModule,
    CreateRequestSelectTypeModalComponent,
    CreateRequestModalComponent,

    CollectionScreenComponent,

    ModalModule,
    NavigationModule,
    ProfileModule,
  ],
  providers: [
    {
      provide: NgDiscoveryService,
      useExisting: DiscoToken,
    },
    HttpClient,
    {
      provide: APP_INITIALIZER,
      useFactory: startupFactory,
      deps: [ILogger, NgDiscoveryService, UserDataPermissionService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    ...AuthProviders,
    ...DataProviders,
    ...LoggerProviders,
    ...GeneralProviders,
    PageContextService,
    UserDataPermissionService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
