import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

export interface ProfileState {
  currentPage: 'UserInfo' | 'ApiKeys' | 'Security';
}

export interface IProfileStore {
  readonly currentPage$: Observable<ProfileState['currentPage']>;

  readonly setCurrentPage: (currentPage: ProfileState['currentPage']) => void;
}

export class ProfileStore implements IProfileStore {
  private store: ComponentStore<ProfileState>;

  public readonly currentPage$: Observable<ProfileState['currentPage']>;
  public readonly setCurrentPage: (
    currentPage: ProfileState['currentPage'],
  ) => void;

  constructor() {
    this.store = new ComponentStore<ProfileState>({
      currentPage: 'UserInfo',
    });

    this.currentPage$ = this.store.select((state) => state.currentPage);

    this.setCurrentPage = this.store.updater(
      (state, currentPage: ProfileState['currentPage']) => ({
        ...state,
        currentPage,
      }),
    );
  }
}
