import { NgModule } from '@angular/core';
import { OverlaySpinnerComponent } from './components/overlay-spinner/overlay-spinner.component';
import { RRSharedModule } from '../shared-module/shared.module';

@NgModule({
  declarations: [OverlaySpinnerComponent],
  imports: [RRSharedModule],
  exports: [OverlaySpinnerComponent],
})
export class RRSpinnerModule {}
