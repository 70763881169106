import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

import {
  DiscoToken,
  DiscoveryConfig,
  getDiscovery,
} from './app/core/services/ng-discovery.service';

(() => {
  getDiscovery.then((config) => {
    enableProdMode();
    platformBrowserDynamic([
      {
        provide: DiscoToken,
        useValue: new DiscoveryConfig(config),
      },
    ])
      .bootstrapModule(AppModule)
      .catch((err) => {
        console.error('Failed to bootstrap AppModule:', err);
      });
  });
})();
