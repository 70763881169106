import {
  AfterViewInit,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UserDataPermissionService } from '../../../core/services/userdata-permission.service';
import { ModalService } from '../../../modal/modal.service';

@Component({
  selector: 'app-user-data-permission',
  templateUrl: './user-data-permission.component.html',
  styleUrls: ['./user-data-permission.component.scss'],
})
export class UserDataPermissionComponent implements AfterViewInit {
  @ViewChild('acceptUserData') acceptUserData: TemplateRef<any>;

  constructor(
    public userDataPermission: UserDataPermissionService,
    public modalService: ModalService,
  ) {}

  ngAfterViewInit(): void {
    this.userDataPermission.granted$.subscribe((granted) => {
      if (granted.length === 0) {
        this.modalService.show({
          template: this.acceptUserData,
          title: 'User Data',
          dismissable: false,
          showClose: false,
        });
      } else {
        this.modalService.close();
      }
    });
  }

  acceptAll(): void {
    this.userDataPermission.grant('Core', 'UserExperience');
    window.location.reload();
  }

  acceptCore(): void {
    this.userDataPermission.grant('Core');
    window.location.reload();
  }
}
