import { AfterViewInit, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserService } from '../../../data';
import {
  MutableUserInfo,
  UserInfo,
} from '../profile-page/profile-page.component';
import { AlertService } from '../../../core/services/alert.service';

@Component({
  selector: 'app-profile-user-information',
  templateUrl: './profile-user-information.component.html',
  styleUrls: ['./profile-user-information.component.scss'],
})
export class ProfileUserInformationComponent implements AfterViewInit {
  @Input() user$: Observable<UserInfo>;

  public updatedUser: UserInfo;

  constructor(
    private userService: IUserService,
    private alerts: AlertService,
  ) {}

  ngAfterViewInit(): void {
    this.user$.subscribe((user) => {
      this.updateUser('email', { value: user.email });
      this.updateUser('givenName', { value: user.givenName });
      this.updateUser('familyName', { value: user.familyName });
    });
  }

  updateUser<T extends keyof MutableUserInfo>(
    key: T,
    { value }: { value: string },
  ) {
    this.updatedUser = {
      ...this.updatedUser,
      [key]: value,
    };
  }

  updateUserInfo(id: string) {
    return () =>
      this.userService.updateMe({
        id,
        email: this.updatedUser.email,
        firstName: this.updatedUser.givenName,
        lastName: this.updatedUser.familyName,
      });
  }

  handleDone([err]: [any, null]) {
    if (!err) return;

    console.error('handleDone:', err.response?.data);
    this.alerts.showErrorAlert(err.response?.data?.title ?? 'Unknown error');
  }
}
