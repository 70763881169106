import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Filters } from '../../../..';
import {
  FilterBarElement,
  FilterBarInputElement,
} from '../filter-bar-element.component';

interface Dictionary<T> {
  [key: string]: T;
}

type SelectedItems<R> = Dictionary<{ id: string; selected?: boolean; data: R }>;

@Component({
  selector: 'rr-text-input-element',
  templateUrl: './text-input-element.component.html',
  styleUrls: ['./text-input-element.component.scss'],
})
export class TextInputElementComponent<
  F extends Filters,
  K extends keyof F = keyof F,
> implements FilterBarInputElement<string>, AfterViewInit
{
  @Input() element: FilterBarElement<F, K>;

  public label: string;

  @Output() valueChanged: EventEmitter<string> = new EventEmitter();

  @ViewChild('inputText') inputText: ElementRef<HTMLInputElement>;
  @Input() filterValueSub: BehaviorSubject<string>;

  filterValue: string = '';

  constructor() {}

  setValue(value: string) {
    console.log('TextInputElementComponent', 'setValue:', value);
    this.filterValue = value;
    this.updateBadge();
  }

  ngAfterViewInit(): void {
    this.filterValueSub.subscribe((value) => this.setValue(value));
  }

  updateBadge() {
    this.element.badge = this.filterValue && this.filterValue.length > 0;
  }

  init(element: FilterBarElement<F, K>) {
    this.element = element as any;
    if ('label' in this.element.config) {
      this.label = this.element.config.label;
    }
  }

  getValue() {
    return this.filterValue;
  }

  detectChanges(event: Event | KeyboardEvent) {
    if ('key' in event && event.key === 'Escape') {
      console.log((event as KeyboardEvent).key);
      this.inputText.nativeElement.value = '';
    }

    if (
      this.inputText &&
      this.filterValue !== this.inputText.nativeElement.value
    ) {
      this.filterValue = this.inputText?.nativeElement.value;

      // TODO: Emit something here?
      this.valueChanged.emit(this.filterValue);
    }
  }
}
