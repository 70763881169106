/* eslint-disable max-lines-per-function */
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  AccessoryMetadata,
  AccessoryPositionInfo,
  ComputeServerWasm,
  IAccessory,
  IAvatar,
  IGarment,
  IScene,
  IVirtualDressingRoom,
  PictofitWebHTMLElement,
  VirtualDressingRoom2D,
  VirtualDressingRoom2DParallax,
  VirtualDressingRoom3D,
  VirtualDressingRoomFactory,
  VirtualTryOn3DDebugInformation,
  WebViewer,
} from '@reactivereality/pictofit-web-sdk';
import { IVDRDisplay, IVDRStatus } from '../interfaces/vdressing-room';
import { ILogger } from '../../logging';
import { ViewerMode } from '../data/api';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Mutex } from '../utils/mutex';
import { bufferTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ISpinnerModel } from '../interfaces/spinner.model';
import * as _ from 'lodash';
import { AvatarService } from '../data/services/avatar.service';
import { RRIDressingRoomSettings } from '../interfaces/dressing-room-settings';
import { IScreenShotService } from '../../core/interfaces/i-screenshot';
import { sleep } from '../utils/utilities';

import wasmInfo from '@reactivereality-public/pictofit-core-wasm/package.json';
import { IAccessoryAsset } from '../interfaces/assets';

customElements.define('pictofit-web', PictofitWebHTMLElement);

@Component({
  selector: 'rr-virtual-dressing-room',
  templateUrl: './virtual-dressing-room.component.html',
  styleUrls: ['./virtual-dressing-room.component.scss'],
})
export class VirtualDressingRoomComponent
  implements AfterViewInit, OnDestroy, IScreenShotService
{
  // Needed components
  private viewer: WebViewer | undefined = undefined;
  private computeServer: ComputeServerWasm | undefined = undefined;
  private virtualDressingRoom: IVirtualDressingRoom | undefined = undefined;
  private changeSubscription: Subscription | undefined = undefined;
  private resetSubscription: Subscription | undefined = undefined;
  private currentTargetState: IVDRDisplay | undefined = undefined;
  private currentState: IVDRDisplay | undefined = undefined;
  private running: boolean = false;
  private reconcileInProgress: boolean = false;
  public showSizeRecommendation: boolean = false;

  private reconcileMutex: Mutex = new Mutex();
  private _onDestroy$: Subject<void> = new Subject();

  @Input() vdrReset$: Subject<void>;
  @Input() vdrChange$: Subject<IVDRDisplay | undefined>;
  @Input() vdrStatus$: BehaviorSubject<IVDRStatus | undefined>;
  @Input() settings: RRIDressingRoomSettings;
  @Input() token: string = undefined;
  @Input() warningMessage: string;

  private mode: ViewerMode = undefined;
  public spinner: ISpinnerModel = {
    show: false,
    text: 'Loading preview',
    id: 'vdr',
  };
  private _spinnerCounter = 0;

  @ViewChild('PictofitWeb') pictofitWeb: ElementRef<PictofitWebHTMLElement>;

  @HostListener('window:resize')
  onResize() {
    if (this.viewer) {
      this.viewer.resize();
    }
    this.virtualDressingRoom?.refresh();
  }
  constructor(private logger: ILogger, public avatarService: AvatarService) {}

  async takeScreenshot(height?: number, width?: number): Promise<string> {
    const origWidth = this.viewer.canvas.width;
    const origHeigth = this.viewer.canvas.height;
    try {
      this.viewer.doRender = false;
      this.viewer.scene.useConstantAnimationDeltaTime = true;

      // Temporary hack because takeScreenshot always returns blurry image
      this.viewer.canvas.width = width ?? 1024;
      this.viewer.canvas.height = height ?? 1024;

      const canvasType = 'image/png';

      // render twice to make sure everything is rendered
      for (let i = 0; i < 2; i++) {
        this.viewer.scene.render();
      }

      const image = this.viewer.canvas.toDataURL(canvasType);

      return image;
    } catch (e) {
    } finally {
      this.viewer.doRender = true;
      this.viewer.canvas.width = origWidth;
      this.viewer.canvas.height = origHeigth;
    }
    // this.viewer.performanceMonitor.stopMonitoring();
    // const data = await this.viewer.takeScreenshot({
    //   height: height ?? 1024,
    //   width: width ?? 1024,
    // });

    // const screenshot = await (await fetch(data)).blob();
    // this.saveBlob(screenshot, "screenshot.jpg");

    // return data;
  }

  saveBlob(blob: Blob, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.cssText = 'display: none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  async ngAfterViewInit(): Promise<void> {
    this.viewer = new WebViewer(this.pictofitWeb.nativeElement);
    this.viewer.performanceMonitor.stopMonitoring();
    this.viewer.resize();
    // await this.viewer.scene.debugLayer.show({ embedMode: true })
    const computeServerUrl = this.settings.computeServerUrl;
    if (computeServerUrl === undefined) {
      throw new Error(`Failed to initial webviewer, missing computeServerUrl!`);
    }
    this.computeServer = new ComputeServerWasm(
      computeServerUrl,
      {},
      `https://cdn.pictofit.com/pictofit-core-wasm/v${wasmInfo.version}/rr_pictofit_core_wrapper.wasm`,
    );
    this.computeServer.setProviderCreationCallbacks(
      async () => {
        return fetch('https://cdn.pictofit.com/bm3d/default_female.bin').then(
          (r) => r.blob(),
        );
      },
      async () => {
        return fetch('https://cdn.pictofit.com/bm3d/default_male.bin').then(
          (r) => r.blob(),
        );
      },
    );

    if (this.vdrReset$) {
      this.resetSubscription = this.vdrReset$.subscribe(async () => {
        if (this.virtualDressingRoom) {
          if (this.virtualDressingRoom instanceof VirtualDressingRoom3D) {
            this.virtualDressingRoom.avatar = null;
            this.virtualDressingRoom.garments = [];
            await this.virtualDressingRoom.refresh();
          } else {
            await this.virtualDressingRoom.cleanup();
            await this.virtualDressingRoom.refresh();
          }
        }
      });
    }

    this.changeSubscription = this.vdrChange$
      .pipe(bufferTime(250))
      .pipe(filter((v) => v.length > 0))
      .pipe(map((array) => array[array.length - 1]))
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return _.isEqual(prev, curr);
        }),
      )
      .subscribe((state) => {
        this.currentTargetState = state;
        this.applyState();
      });

    // /*
    // this.mainCanvas.nativeElement.onresize = (e) => {
    //   const dispHeight = this.mainCanvas.nativeElement.clientHeight;
    //   const dispWidth = this.mainCanvas.nativeElement.clientWidth;
    //   const vpHeight = this.mainCanvas.nativeElement.height;
    //   const vpWidth = this.mainCanvas.nativeElement.width;
    //   this.logger.debug(
    //     `Main canvas resize: Display: ${dispWidth}x${dispHeight} vs ${vpWidth}x${vpHeight}`,
    //   );

    //   if (dispHeight !== vpHeight || dispWidth !== vpWidth) {
    //     this.mainCanvas.nativeElement.height = dispHeight;
    //     this.mainCanvas.nativeElement.width = dispWidth;
    //   }
    // };
    // */

    this.running = true;
    // this.reconcile();
    this.applyState();
  }

  reconcile = async () => {
    try {
      while (this.running) {
        if (!this.reconcileInProgress) {
          await this.applyState();
        }
        await sleep(1000);
      }
    } catch (e) {
      this.logger.error(`Reconcile error`, e);
    }
  };

  applyState = async (): Promise<boolean> => {
    const exec = Math.floor(Math.random() * 100);
    return await this.reconcileMutex.exec(async () => {
      const targetRequest = this.currentTargetState;
      if (!targetRequest?.mode) return;
      if (this.virtualDressingRoom) {
        this.virtualDressingRoom.webViewer.doRender = true;
      }
      this.logger.debug(`VDR (${exec}) refresh `, targetRequest);
      let currentMode: ViewerMode;
      if (this.virtualDressingRoom instanceof VirtualDressingRoom3D) {
        currentMode = ViewerMode.MODE_3D;
      } else if (
        this.virtualDressingRoom instanceof VirtualDressingRoom2DParallax
      ) {
        currentMode = ViewerMode.MODE_2D_PARALLAX;
      } else if (this.virtualDressingRoom instanceof VirtualDressingRoom2D) {
        currentMode = ViewerMode.MODE_2D;
      }
      if (currentMode !== targetRequest.mode) {
        switch (targetRequest.mode) {
          case ViewerMode.MODE_2D:
            // assertion to keep typings happy below...
            if (this.settings.type !== '2D') break;

            this.mode = ViewerMode.MODE_2D;
            this.virtualDressingRoom =
              VirtualDressingRoomFactory.createDressingRoom2D(
                this.viewer,
                this.computeServer,
              );
            (
              this.virtualDressingRoom as VirtualDressingRoom2D
            ).backgroundHidden = false;

            // set the render padding to 20%...
            (this.virtualDressingRoom as VirtualDressingRoom2D).padding =
              this.settings.padding ?? 0;
            break;
          case ViewerMode.MODE_2D_PARALLAX: {
            this.mode = ViewerMode.MODE_2D_PARALLAX;
            this.virtualDressingRoom =
              VirtualDressingRoomFactory.createDressingRoom2DParallax(
                this.viewer,
                this.computeServer,
              );
            break;
          }
          default:
          case ViewerMode.MODE_3D:
            this.mode = ViewerMode.MODE_3D;
            this.virtualDressingRoom =
              VirtualDressingRoomFactory.createDressingRoom3D(
                this.viewer,
                this.computeServer,
              );
            break;
        }
        if (this.virtualDressingRoom) {
          await this.virtualDressingRoom.cleanup();
        }
      }

      if (this.virtualDressingRoom === undefined) {
        return false;
      }
      let error = undefined;
      // we need to change something
      try {
        this.vdrStatus$.next({
          ...this.vdrStatus$.value,
          loading: true,
        });
        this.manageSpinner(true);

        if (targetRequest !== undefined) {
          // Setting up avatar
          await this.setupAvatar(targetRequest);

          // Setting up scene
          if (this.mode !== ViewerMode.MODE_2D) {
            await this.setupScene(targetRequest);
          }

          // Setting up garments
          await this.setupGarments(targetRequest);

          // Setting up accessories
          await this.setupAccessories(targetRequest);

          // Enable physics
          if (this.mode === ViewerMode.MODE_3D) {
            this.setupPhysics();
          }

          try {
            this.logger.debug(
              `[VDR] (${exec}) enter`,
              this.virtualDressingRoom,
            );
            await this.virtualDressingRoom?.refresh();
          } finally {
            if (targetRequest.mode !== ViewerMode.MODE_2D) {
              this.viewer.postProcessingEnabled = true;
            }
            this.logger.debug(`[VDR] (${exec}) exit`);
          }
        }
        // we sucessfully mirated to the new state
        this.currentState = targetRequest;
        this.running = false;
        return true;
      } catch (e) {
        if (e instanceof Error) {
          if (
            e.message.startsWith(
              'Operation not permitted due to async function being currently processed.',
            )
          ) {
            // retry ...
            this.logger.warn(
              `VDR is still processing a request, retrying in 100 MS`,
              e,
            );
          }
        }
        this.logger.error(`Failed to apply virtual dressing room settings!`, e);
        this.logger.error(e.stack);
        error = `Failed to apply virtual dressing room settings!`;
      } finally {
        this.vdrStatus$.next({
          ...this.vdrStatus$.value,
          error,
          loading: false,
        });
        this.manageSpinner(false);
      }
    });
  };

  private async setupAvatar(targetRequest: IVDRDisplay) {
    let avatar: IAvatar | null = null;
    if (targetRequest.avatar !== null) {
      avatar = await this.virtualDressingRoom.createAvatar(
        targetRequest.avatar.asset,
      );
    }
    this.virtualDressingRoom.avatar = avatar;
  }

  private async setupScene(targetRequest: IVDRDisplay): Promise<void> {
    let scene: IScene | null = null;
    if (targetRequest.scene !== null) {
      scene = await this.virtualDressingRoom.createScene(
        targetRequest.scene.asset,
      );
    }
    this.virtualDressingRoom.scene = scene;
  }

  private async setupAccessories(targetRequest: IVDRDisplay) {
    let accessories: Array<[any, IAccessory]> = [];
    if (!targetRequest.accessories) return;
    accessories = [];
    for (const pos of Object.values(AccessoryPositionInfo)) {
      const slot = pos.toUpperCase();
      const accessory: IAccessoryAsset = targetRequest.accessories?.[slot];
      if (accessory) {
        const meta: AccessoryMetadata = new AccessoryMetadata(pos);
        const accessoryInput: IAccessory =
          await this.virtualDressingRoom.createAccessory(
            targetRequest.accessories[slot].asset,
            meta,
          );
        accessories.push([accessory.id, accessoryInput]);
      }
    }
    const t = accessories.map(([_, accessory]) => accessory);
    this.virtualDressingRoom.accessories = t;
  }

  private async setupGarments(
    targetRequest: IVDRDisplay,
  ): Promise<Array<[string, IGarment]>> {
    let garments: Array<[string, IGarment]> = [];

    if (targetRequest.garments !== null) {
      garments = [];
      for (const garment of targetRequest.garments) {
        const garmentInput = await this.virtualDressingRoom.createGarment(
          garment.asset,
        );

        if (targetRequest.mode === ViewerMode.MODE_3D) {
          garmentInput.stylingTemplateParametersJSON = JSON.stringify(
            garment.stylingOptions ?? {},
          );
        }

        garments.push([garment.id, garmentInput]);
      }
    }

    this.virtualDressingRoom.garments = garments.map(([_, garment]) => garment);
    if (this.mode != ViewerMode.MODE_2D) {
      this.virtualDressingRoom.focusGarment = null;
      if (targetRequest.focusedGarment !== null) {
        const garment = garments.find(
          ([id, _]) => id === targetRequest.focusedGarment,
        );
        if (garment) {
          //TODO: Reactivate when focusing is fixed.
          //this.virtualDressingRoom.focusGarment = garment[1];
        } else {
          this.logger.warn(
            `Can't find garment for requested focus!!`,
            targetRequest.focusedGarment,
          );
        }
      }
    }
    return garments;
  }

  setupPhysics() {
    if (this.virtualDressingRoom instanceof VirtualDressingRoom3D) {
      this.virtualDressingRoom.enablePhysicsSimulation = true;
      this.virtualDressingRoom.emitTryOnDebugEvents = true;
      this.virtualDressingRoom.addEventListener(
        'tryOnDebugInformation',
        (info) => {
          this.logger.debug(
            `========================================================`,
          );
          this.logger.debug(`TryOn debug information`, info);
          this.logger.debug(
            (info as VirtualTryOn3DDebugInformation).sceneDescription,
          );
          this.logger.debug(
            `========================================================`,
          );
        },
      );
    }
  }

  async ngOnDestroy(): Promise<void> {
    this.logger.debug(`Destroying VDR`);
    this.running = false;
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._onDestroy$ = undefined;
    if (this.changeSubscription !== undefined) {
      this.changeSubscription.unsubscribe();
      this.changeSubscription = undefined;
    }

    if (this.resetSubscription !== undefined) {
      this.resetSubscription.unsubscribe();
      this.resetSubscription = undefined;
    }

    if (this.virtualDressingRoom !== undefined) {
      this.virtualDressingRoom.cleanup();
      this.virtualDressingRoom = null;
    }
    this.computeServer = undefined;
    this.viewer = undefined;
  }

  public manageSpinner(isLoading) {
    if (isLoading === false) {
      this._spinnerCounter--;
    }
    if (isLoading === true) {
      this._spinnerCounter++;
    }
    if (this._spinnerCounter > 0) {
      this.spinner.show = true;
    }
    if (this._spinnerCounter === 0) {
      this.spinner.show = false;
    }
  }
}
