import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

export type ActionComplete = [any, null] | null;

@Component({
  selector: 'rr-button',
  templateUrl: './async-button.component.html',
  styleUrls: ['./async-button.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class AsyncButtonComponent implements OnInit {
  busy: boolean;

  @Input() action: () => Promise<any>;
  @Output() done: EventEmitter<ActionComplete> = new EventEmitter();

  @HostListener('click', ['$event']) onClick($event) {
    if (this.busy) return;

    this.busy = true;
    this.action()
      .then(() => {
        this.done.emit(null);
      })
      .catch((err) => {
        this.done.emit([err, null]);
      })
      .finally(() => {
        this.busy = false;
      });
  }

  constructor() {}

  ngOnInit(): void {}
}
