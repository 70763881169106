import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomerDTO } from '@reactivereality/cs-api-sdk';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  takeUntil,
} from 'rxjs';
import { setCurrentCustomerAction } from '../store/customer.actions';
import {
  selectCurrentCustomer,
  selectMyCustomers,
} from '../store/customer.selectors';
import { ICustomerState } from '../store/customer.state';

@Component({
  selector: 'app-customer-selector',
  templateUrl: './customer-selector.component.html',
  styleUrls: ['./customer-selector.component.scss'],
})
export class CustomerSelectorComponent implements OnInit {
  public currentCustomer$: Observable<CustomerDTO>;
  public myCustomers$: Observable<Array<CustomerDTO>>;
  public customersToDisplay$: BehaviorSubject<Array<CustomerDTO>> =
    new BehaviorSubject([]);
  public showSelection: boolean = false;
  private _onDestroy: Subject<void> = new Subject();
  constructor(private store: Store<ICustomerState>) {
    this.currentCustomer$ = this.store.select(selectCurrentCustomer);
    this.myCustomers$ = this.store.select(selectMyCustomers);
    combineLatest([this.currentCustomer$, this.myCustomers$])
      .pipe(takeUntil(this._onDestroy))
      .subscribe(([currentCustomer, myCustomers]) => {
        if (currentCustomer && myCustomers) {
          this.customersToDisplay$.next([
            currentCustomer,
            ...myCustomers.filter((o) => o.id !== currentCustomer.id),
          ]);
        }
      });
  }

  ngOnInit(): void {
    this._onDestroy = new Subject();
  }
  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onDestroy = undefined;
  }
  switchCustomer(customer: CustomerDTO): void {
    this.showSelection = false;
    this.store.dispatch(
      setCurrentCustomerAction({
        currentCustomer: customer,
      }),
    );
  }
}
