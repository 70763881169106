import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, filter, map } from 'rxjs';
import { CustomerStorageService } from '../service/customer-storage.service';
import { CustomerService } from '../service/customer.service';
import {
  loadCurrentCustomerAction,
  loadCurrentCustomerCompleteAction,
  setCurrentCustomerAction,
  updateCustomerListAction,
} from './customer.actions';

@Injectable()
export class CustomerEffects {
  constructor(
    private actions$: Actions,
    private customerStore: CustomerService,
    private customerStorage: CustomerStorageService,
  ) {}

  loadCurrentCustomer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadCurrentCustomerAction),
      map(() => {
        const currentCustomer = this.customerStorage.getSelectedCustomer();

        return loadCurrentCustomerCompleteAction({
          currentCustomer,
        });
      }),
    );
  });

  initialiseCurrentCustomer$ = createEffect(() => {
    return combineLatest([
      this.customerStore.currentCustomer$,
      this.actions$.pipe(ofType(updateCustomerListAction)),
    ]).pipe(
      filter(([currentCustomer]) => !currentCustomer),
      map(() => {
        return loadCurrentCustomerAction();
      }),
    );
  });

  /*
    If the customer list is being updated, and we DON'T have a
    current customer set in the Ngrx store, then we pick the
    first one and set it as our current customer.
  */
  updateCustomerList$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(updateCustomerListAction)),
      this.customerStore.currentCustomer$,
      this.actions$.pipe(ofType(loadCurrentCustomerCompleteAction)),
    ]).pipe(
      filter(([_, currentCustomer]) => !currentCustomer),
      map(([{ myCustomerList }]) => {
        // set the current customer...
        return setCurrentCustomerAction({
          currentCustomer:
            myCustomerList.length > 0 ? myCustomerList[0] : undefined,
        });
      }),
    ),
  );
}
