export enum PRODUCT_TYPE {
  UNDEFINED = 0,
  GARMENT_2D = 1,
  GARMENT_3D = 3,
  OUTFIT_3D = 5,
  AVATAR_2D = 6,
  ACCESSORY_3D = 9,
  OUTFIT_2D = 12,
  SCENE = 14,
  AVATAR_3D = 15,
  GARMENT_3D_CAD = 16,
  OUTFIT_2D_PARALLAX = 21,
}
