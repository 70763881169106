import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { NgDiscoveryService } from '../../../core/services/ng-discovery.service';
import { AlertService } from '../../../core/services/alert.service';
import { IAuthService } from '../../../auth';
import { ILogger } from '../../../logging';

@Component({
  selector: 'app-profile-security',
  templateUrl: './profile-security.component.html',
  styleUrls: ['./profile-security.component.scss'],
})
export class ProfileSecurityComponent implements OnInit {
  public busy: boolean;

  constructor(
    private httpClient: HttpClient,
    private disco: NgDiscoveryService,
    private authService: IAuthService,
    private alerts: AlertService,
    private logger: ILogger,
  ) {}

  ngOnInit(): void {}

  done(res: [any, null] | null) {
    if (res === null) {
      this.alerts.showSuccessAlert('Password reset email sent!');
      return;
    }

    const err = res[0];
    this.logger.error(`Failed to request password reset link`, err);
    this.alerts.showErrorAlert('Unable to request password reset');
  }

  triggerReset() {
    return async () => {
      const me = await firstValueFrom(this.authService.loggedUser$);

      const connection = me.profile['auth_connection'] ?? 'LocalUsers';

      return await lastValueFrom(
        this.httpClient.post(
          new URL(
            '/dbconnections/change_password',
            this.disco.config.apiConfiguration.baseAuthServiceUrl,
          ).toString(),
          {
            client_id: this.disco.config.appConfiguration.clientId,
            email: me.profile.email,
            connection,
          },
          {
            observe: 'response',
            headers: {
              authorization: '',
            },
            responseType: 'text',
          },
        ),
      );
    };
  }
}
