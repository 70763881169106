/* eslint-disable max-lines-per-function */
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  DressingRoomLogicFactory,
  IDressingRoomLogicGarmentOptions,
} from '@reactivereality/pictofit-web-sdk';
import { Subject, takeUntil } from 'rxjs';
import { ICustomerService } from '../../customer';
import { PRODUCT_TYPE } from '../../data';
import { GarmentDTO, IRequestResult, IRequestService } from '../../data/api';
import { ILogger } from '../../logging';
import { AddItemComponent } from '../add-item/add-item.component';
import { GarmentSelectionEntryComponent } from './garment-selection-entry/garment-selection-entry.component';
import { IGarmentSelectionService } from './services/i-garment-selection.service';
import { ConfigurationDisplayComponent } from './configuration-display/configuration-display.component';
import * as _ from 'lodash';
import { RRSpinnerModel } from 'projects/web-ui-component-library/src';
import { RRSpinnerModule } from '../../../../../web-ui-component-library/src/lib/spinner/spinner.module';
import { CustomerDTO } from '@reactivereality/cs-api-sdk';
import { NgStorageManagerService } from '../../core/services/ng-storage-manager.service';

const LOCAL_STORAGE_KEY = 'garmentSelection';
const RECENTLY_USED_GARMENTS_KEY = 'recentlyUsedGarments';
import { AddGarmentModalComponent } from './add-garment-modal/add-garment-modal.component';
import { CreateRequestModalStore } from '../../request-overview/components/create-request-modal/create-request-modal.state';
import { ModalService } from '../../modal/modal.service';

@Component({
  selector: 'app-garment-selection',
  templateUrl: './garment-selection.component.html',
  styleUrls: ['./garment-selection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AddGarmentModalComponent,
    AddItemComponent,
    GarmentSelectionEntryComponent,
    ConfigurationDisplayComponent,
    RRSpinnerModule,
  ],
})
export class GarmentSelectionComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() public productType: PRODUCT_TYPE;
  private _onDestroy$: Subject<void>;
  public selectedGarments: GarmentDTO[] = [];
  public recenltyUsedGarments: GarmentDTO[] = [];
  public garmentToDisplay: GarmentDTO[] = [];
  public showRecenltyUsedDialog: boolean;
  public spinnerStatusRecenltyUsed: RRSpinnerModel = {
    text: 'Loading recenlty used',
    show: false,
  };
  public recenltyUsedMaxHeight: number;
  public showRecenltyUsedGarmentOverlayLeft: boolean;
  private _currentCustomer: CustomerDTO;

  @ViewChild('addGarments') addGarments: TemplateRef<any>;

  constructor(
    public garmentSelectionService: IGarmentSelectionService,
    private logger: ILogger,
    private _requestService: IRequestService,
    private _customerService: ICustomerService,
    private _localStorageService: NgStorageManagerService,
    private _elementRef: ElementRef,
    public createRequestModalStore: CreateRequestModalStore,
    public modalService: ModalService,
  ) {
    this._onDestroy$ = new Subject<void>();
    this.logger.debug(`Initialized store:`, this.garmentSelectionService);
    this.garmentSelectionService.tuckedInGarment$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe({
        next: this.onGarmentTuckedIn,
        error: console.error,
      });
    this.garmentSelectionService.selectedGarments$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe({
        next: this.onGarmentsSelected,
        error: console.error,
      });
    this._customerService.currentCustomer$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((customer) => {
        this._currentCustomer = customer;
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._onDestroy$.unsubscribe();
    this._onDestroy$ = undefined;
  }

  async ngOnInit(): Promise<void> {
    await this.getRequests();
  }
  ngAfterViewInit(): void {
    this.calcRecentlyUsedMayHeigh();
  }
  private calcRecentlyUsedMayHeigh() {
    const parent = document.getElementsByClassName('details-area');
    this.recenltyUsedMaxHeight = (parent[0] as HTMLElement).clientHeight - 300;
  }

  private onGarmentTuckedIn = (garment: GarmentDTO) => {
    if (garment) {
      const item = this.selectedGarments.find((o) => o.id === garment.id);
      item.tuckedIn = true;
      this.garmentSelectionService.setDressedGarments(
        Object.assign(
          [],
          this.calculateGarments(
            this.selectedGarments.filter((g) => g.dressed === true),
          ),
        ),
      );
    } else {
      this.selectedGarments.map((o) => (o.tuckedIn = false));
      this.garmentSelectionService.setDressedGarments(
        Object.assign(
          [],
          this.calculateGarments(
            this.selectedGarments.filter((g) => g.dressed === true),
          ),
        ),
      );
    }
  };

  private onGarmentsSelected = (garments: GarmentDTO[]) => {
    if (garments) {
      this.selectedGarments = garments;
      if (garments.length > 0) {
        let recenltyUsed: string[] = this._localStorageService.getItem(
          [
            LOCAL_STORAGE_KEY,
            RECENTLY_USED_GARMENTS_KEY,
            garments[0].productTypeId,
            this._currentCustomer.id,
          ].join('.'),
          'local',
        );
        if (!recenltyUsed) {
          recenltyUsed = [];
        }
        const itemsToStore = _.uniq([
          ...garments.reverse().map((o) => o.id),
          ...recenltyUsed,
        ]);
        if (itemsToStore.length > 6) {
          itemsToStore.splice(6, itemsToStore.length - 1);
        }
        this._localStorageService.setItem(
          [
            LOCAL_STORAGE_KEY,
            RECENTLY_USED_GARMENTS_KEY,
            garments[0].productTypeId,
            this._currentCustomer.id,
          ].join('.'),
          itemsToStore,
          'local',
        );
        // Wait till RXJS store has stored the changes in the localstorage
        setTimeout(() => {
          this.getRequests();
        });
      }
    }
  };

  public addGarmentsClicked() {
    this.modalService.show({
      title: 'Select Garments',
      template: this.addGarments,
    });
    this.showRecenltyUsedDialog = false;
  }
  public showRecentlyUsed() {
    this.showRecenltyUsedDialog = true;
  }

  deleteGarmentEntry(config: GarmentDTO) {
    this.garmentSelectionService.setSelectedGarments(
      this.selectedGarments.filter((c) => {
        return c.id !== config.id;
      }),
    );
    this.garmentSelectionService.setDressedGarments(
      this.garmentSelectionService.currentDressed().filter((c) => {
        return c.id !== config.id;
      }),
    );
  }

  deleteAllGarments() {
    this.garmentSelectionService.setSelectedGarments([]);
    this.garmentSelectionService.setDressedGarments([]);
  }
  recentlyUsedGarmentClicked(garment: GarmentDTO) {
    const arr = [...this.selectedGarments, garment];
    const uniqueEntries = _.unionBy(arr, 'id');
    this.garmentSelectionService.setSelectedGarments(uniqueEntries);
    this.showRecenltyUsedDialog = false;
  }
  public tuckGarment(config: GarmentDTO, action: string) {
    this.garmentSelectionService.currentDressed().map((g) => {
      if (g.id === config.id) {
        if (action === 'in') {
          this.garmentSelectionService.setTuckedInGarment({
            ...g,
            tuckedIn: true,
          });
        } else {
          this.garmentSelectionService.setTuckedInGarment(null);
        }
      }
      return g;
    });
  }

  public dressGarment(garment: GarmentDTO) {
    let toBeDressed: GarmentDTO[] = [];
    const currentDressed = this.garmentSelectionService.currentDressed();
    if (currentDressed.find((g) => g.id === garment.id)) {
      toBeDressed = currentDressed.filter((g) => g.id !== garment.id);
    } else {
      toBeDressed = [...currentDressed, garment];
    }
    this.garmentSelectionService.setDressedGarments(
      this.calculateGarments(toBeDressed),
    );
    this.updateSelectedGarments();
  }

  private calculateGarments(garmentsToBeDressed: GarmentDTO[]) {
    const dressingRoomLogic =
      DressingRoomLogicFactory.createDressingRoomLogicWithoutVirtualDressingRoom();
    garmentsToBeDressed.forEach((garment) => {
      dressingRoomLogic.addGarment({
        garment: undefined,
        garmentGroupId: garment.groupId,
        tuckedIn: garment.tuckedIn,
        garmentId: garment.id,
      });
    });
    return this.mapGarments(dressingRoomLogic.getOrderedGarments());
  }

  private updateSelectedGarments() {
    this.selectedGarments.forEach((g) => (g.dressed = false));
    this.selectedGarments.forEach((selected) => {
      if (
        this.garmentSelectionService
          .currentDressed()
          .find((dressed) => dressed.id === selected.id)
      ) {
        selected.dressed = true;
      }
    });
  }

  private mapGarments(
    garments: IDressingRoomLogicGarmentOptions[],
  ): GarmentDTO[] {
    const orderedGarments: GarmentDTO[] = [];
    garments.forEach((garment) => {
      const toBeDressed = this.selectedGarments.find((toBeDressed) => {
        return garment.garmentId === toBeDressed.id;
      });
      if (toBeDressed !== undefined) {
        toBeDressed.dressed = true;
        orderedGarments.push(toBeDressed);
      }
    });
    return orderedGarments;
  }

  private async getRequests(): Promise<void> {
    let type: PRODUCT_TYPE = this.productType;

    this.spinnerStatusRecenltyUsed.show = true;
    if (
      this.productType === PRODUCT_TYPE.OUTFIT_2D ||
      this.productType === PRODUCT_TYPE.OUTFIT_2D_PARALLAX
    ) {
      type = PRODUCT_TYPE.GARMENT_2D;
    }
    if (this.productType === PRODUCT_TYPE.OUTFIT_3D) {
      type = PRODUCT_TYPE.GARMENT_3D;
    }
    const requestFilter = {
      productTypeId: type,
      stateId: [4, 13],
    };

    const recentlyUsedGarments = [];

    let storedGarments: string[] = this._localStorageService.getItem(
      [
        LOCAL_STORAGE_KEY,
        RECENTLY_USED_GARMENTS_KEY,
        type,
        this._currentCustomer.id,
      ].join('.'),
      'local',
    );

    if (!storedGarments) {
      storedGarments = [];
    }
    for (const garment of storedGarments) {
      try {
        recentlyUsedGarments.push(
          await this._requestService.getRequestByID(garment),
        );
      } catch (error) {
        this.logger.warn('Error during fetching the garment', error);
      }
    }
    if (recentlyUsedGarments.length < 6) {
      const allGarments = await this._requestService.getAllCustomerRequests(
        this._currentCustomer.id,
        JSON.stringify(requestFilter),
        1,
        6,
        undefined,
      );
      recentlyUsedGarments.push(...allGarments.configurations);
    }

    this.garmentToDisplay = [];
    let garmentList = [];
    for (const garment of recentlyUsedGarments) {
      const webresults = garment.results?.filter((c) => {
        return c.format === 'web';
      });
      if (!webresults || webresults.length === 0) {
        continue;
      }
      const metadata = await this.getMetadata(webresults[0]);
      if (
        metadata &&
        metadata.garmentGroup &&
        (this.productType === PRODUCT_TYPE.OUTFIT_2D ||
          this.productType === PRODUCT_TYPE.OUTFIT_2D_PARALLAX)
      ) {
        garmentList.push({
          ...garment,
          isPublic: false,
          selected: false,
          groupId: metadata?.garmentGroup ? metadata.garmentGroup : 0,
          tuckedIn: false,
          dressed: false,
          tryonable: true,
        });
      }
      if (
        this.productType === PRODUCT_TYPE.OUTFIT_3D &&
        metadata.tryonable === true
      ) {
        garmentList.push({
          ...garment,
          isPublic: false,
          selected: false,
          groupId: metadata?.garmentGroup ? metadata.garmentGroup : 0,
          tuckedIn: false,
          dressed: false,
          tryonable: true,
        });
      }
    }
    garmentList = _.uniqBy(garmentList, 'id');
    if (garmentList.length > 6) {
      garmentList.splice(6, garmentList.length - 1);
    }
    this.garmentToDisplay = Object.assign([], garmentList);
    this.spinnerStatusRecenltyUsed.show = false;
  }
  private async getMetadata(webResult: IRequestResult) {
    if (!webResult) {
      return;
    }
    const metadataFile = webResult.files.find(
      (o) => o.name === 'metadata.json',
    );
    if (!metadataFile) {
      return;
    }
    const metadataResponse = await fetch(metadataFile.url);
    if (!metadataResponse.ok) {
      return;
    }
    return await metadataResponse.json();
  }
  getBottomPosition(): string {
    const item =
      this.selectedGarments.length === 0
        ? this._elementRef.nativeElement.querySelector('#addGarmentContainer')
        : this._elementRef.nativeElement.querySelector('#recentlyUsedLeft');
    if (!item) {
      return '0';
    }
    const rect = item.getBoundingClientRect();
    return document.body.clientHeight - rect.bottom + 'px';
  }
  getRightPosition(): any {
    if (this.selectedGarments.length > 0) {
      const item = this._elementRef.nativeElement.querySelector(
        '#addGarmentContainer',
      );
      const rect = item.getBoundingClientRect();
      return rect.width - rect.width * 0.1 + 'px';
    }
  }
}
