import { CommonModule, DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ConfigurationPublicOnlyDTO } from '@reactivereality/cs-api-sdk';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import {
  RRSpinnerModel,
  RRSpinnerModule,
} from 'projects/web-ui-component-library/src';
import { CustomerStorageService } from '../../../customer/service/customer-storage.service';
import { PRODUCT_TYPE } from '../../../data';
import {
  GarmentDTO,
  IRequestResult,
  IRequestService,
  PagingRequestDTO,
} from '../../../data/api';
import { ILogger } from '../../../logging';
import { RequestListPaginationComponent } from '../../../request-overview/components/request-list-page/request-list-pagination/request-list-pagination.component';
import { ComponentPaginationDataProvider } from '../../../request-overview/services/component-pagination-data-provider';
import { IPaginationDataProviderInterface } from '../../../request-overview/services/pagination-data-provider.interface';
import {
  GarmentThumbnailPipe,
  ProductTypePipe,
} from '../../../utils/CommonPipes';
import { SelectionModalStore } from '../../../request-overview/components/create-request-modal/store/selection-modal-store';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  from,
  switchMap,
} from 'rxjs';
import { FilterSelectionComponent } from '../../filter-selection/filter-selection.component';
import { ModalService } from '../../../modal/modal.service';
import { IFilterConfiguration } from '../../filter-selection/types/filter-selection.types';
import { DateInputElementComponent } from 'projects/web-ui-component-library/src/lib/filter-elements/date-input-element/date-input-element.component';
import { SelectInputElementComponent } from 'projects/web-ui-component-library/src/lib/filter-elements/select-input-element/select-input-element.component';
import { IAppState } from '../../../app.state';
import { Store } from '@ngrx/store';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PrettyProductDatePipe } from '../../../request-overview/components/request-list-page/request-list-row/request-list-row.component';
import { ConfigurationDisplayComponent } from '../configuration-display/configuration-display.component';

@Component({
  selector: 'app-add-garment-modal',
  templateUrl: './add-garment-modal.component.html',
  styleUrls: ['./add-garment-modal.component.scss'],
  standalone: true,
  providers: [
    [
      SelectionModalStore,
      {
        provide: IPaginationDataProviderInterface,
        useClass: ComponentPaginationDataProvider,
      },
    ],
  ],
  imports: [
    DatePipe,
    ProductTypePipe,
    RRSpinnerModule,
    CommonModule,
    PaginationModule,
    GarmentThumbnailPipe,
    RequestListPaginationComponent,
    NgxSkeletonLoaderModule,
    FilterSelectionComponent,
    PrettyProductDatePipe,
    ConfigurationDisplayComponent,
  ],
})
export class AddGarmentModalComponent implements OnInit, AfterViewInit {
  @Input() public productType: PRODUCT_TYPE;
  @Input() public currentSelectedGarments: GarmentDTO[];
  @Input() private singleSelect: boolean = false;
  @Output() public setSelectedGarment = new EventEmitter<GarmentDTO[]>();

  @ViewChild('selectGarments') modal;

  public allGarments: GarmentDTO[];
  public spinnerStatus: RRSpinnerModel;
  public searchPattern: string;
  public filterConfiguration: IFilterConfiguration<any>[];

  private _selGarments: GarmentDTO[] = [];
  private _currentPage: number = 1;
  private _filter = {};
  private _allTags$ = new BehaviorSubject<{ value: string; text: string }[]>(
    undefined,
  );

  private _garmentLookup$: Subject<void> = new Subject();
  private _abortController: AbortController;
  constructor(
    private _requestService: IRequestService,
    private _logger: ILogger,
    private _customerStorageService: CustomerStorageService,
    private _store: SelectionModalStore,
    private _modalService: ModalService,
    private _appStore: Store<IAppState>,
  ) {
    this.spinnerStatus = {
      show: false,
      text: 'Loading...',
    };

    this.filterConfiguration = [
      {
        id: 'tags',
        text: 'Tags',
        placeholder: 'Find Tags...',
        type: SelectInputElementComponent,
        data: this._allTags$,
      },
      {
        id: 'createdAt',
        text: 'Created at',
        placeholder: 'Select date',
        type: DateInputElementComponent,
      },
    ];
  }

  async ngAfterViewInit() {
    combineLatest([
      this._store.currentPage$,
      this._store.searchPattern$,
      this._store.filters$,
    ])
      .pipe(debounceTime(100))
      .subscribe(([currentPage, searchPattern, filter]) => {
        if (currentPage && searchPattern != undefined && filter != undefined) {
          this._currentPage = currentPage;
          this.searchPattern = searchPattern;
          this._filter = filter;
          this._garmentLookup$.next();
        }
      });

    //request the garments and enable aborting the request with a new request
    this._garmentLookup$
      .pipe(
        debounceTime(100),
        switchMap(() => {
          this.spinnerStatus.show = true;
          if (this._abortController !== undefined) {
            this._abortController.abort();
          }
          return this.getGarments();
        }),
      )
      .subscribe(async (result) => {
        this.allGarments = await this.mapRequestsToGarments(
          result.configurations,
        );
        this._store.updateCurrentPage(this._currentPage);
        this._store.updatePageCount(result.paging.pageCount);
        this.spinnerStatus.show = false;
      });

    //read all tags from app store
    this._appStore
      .select((state) => state.requestOverview.tags)
      .subscribe((value) => {
        const data = value.map((v) => {
          return { value: v.uuid, text: v.name };
        });
        this._allTags$.next(data);
      });

    this.reset();
  }

  ngOnInit() {}

  onCheckboxChanged($event: any, garment: GarmentDTO) {
    const selElement = $event.target.checked;
    if (this.singleSelect) {
      const cbs = document.getElementsByName('cb');
      cbs.forEach((data: HTMLInputElement) => {
        data.checked = false;
      });
      this._selGarments = [];
    }
    if (selElement) {
      if (garment) {
        if (
          this._selGarments.length === 0 ||
          this._selGarments.some((g) => g.id !== garment.id)
        ) {
          this._selGarments.push(garment);
          $event.target.checked = true;
        }
      }
    } else {
      if (garment) {
        $event.target.checked = false;
        this._selGarments = this._selGarments.filter((g) => {
          return g.id !== garment.id;
        });
      }
    }
    $event.stopPropagation();
  }
  public closeModal() {
    this._modalService.close();
  }

  public onSelectedGarments() {
    this.setSelectedGarment.emit(this._selGarments);
    this.closeModal();
  }

  public getSelected(id: string): boolean {
    if (this._selGarments.length > 0) {
      return this._selGarments.some((g) => g.id === id);
    }
    return false;
  }

  private async getMetadata(webResult: IRequestResult) {
    if (!webResult) {
      return;
    }
    const metadataFile = webResult.files.find(
      (o) => o.name === 'metadata.json',
    );
    if (!metadataFile) {
      return;
    }
    const metadataResponse = await fetch(metadataFile.url);
    if (!metadataResponse.ok) {
      return;
    }
    return await metadataResponse.json();
  }

  private reset() {
    this.allGarments = [];
    this._selGarments = [...this.currentSelectedGarments];
    this._store.updateSearchPattern('');
  }

  private getGarments(): Observable<PagingRequestDTO> {
    this._logger.debug(`Loading garments for`, this.productType);
    if (
      this.productType === PRODUCT_TYPE.OUTFIT_2D ||
      this.productType === PRODUCT_TYPE.OUTFIT_2D_PARALLAX
    ) {
      return this.getRequests([PRODUCT_TYPE.GARMENT_2D]);
    }
    if (this.productType === PRODUCT_TYPE.OUTFIT_3D) {
      return this.getRequests([
        PRODUCT_TYPE.GARMENT_3D,
        PRODUCT_TYPE.GARMENT_3D_CAD,
      ]);
    }
  }

  private getRequests(type: PRODUCT_TYPE[]): Observable<PagingRequestDTO> {
    let requestFilter = {
      productTypeId: type,
      stateId: [4, 13],
    };

    //extract the tags from the filters
    let tags = this._filter['tags'];
    const filters = JSON.parse(JSON.stringify(this._filter));
    if (tags !== undefined) {
      tags = this.convertToTagFormat(tags);
      delete filters['tags'];
    }

    requestFilter = { ...requestFilter, ...filters };

    if (this.searchPattern !== undefined && this.searchPattern !== '') {
      requestFilter = {
        ...requestFilter,
        ...{ 'name,sku': this.searchPattern },
      };
    }

    this._abortController = new AbortController();
    return from(
      this._requestService.getAllCustomerRequests(
        this._customerStorageService.getSelectedCustomer(),
        JSON.stringify(requestFilter),
        this._currentPage,
        10,
        tags,
        true,
        this._abortController.signal,
      ),
    );
  }

  private async mapRequestsToGarments(
    requests: ConfigurationPublicOnlyDTO[],
  ): Promise<GarmentDTO[]> {
    const garments: GarmentDTO[] = [];
    for (const request of requests) {
      if (
        request.storageInTransit != false ||
        request.storageLocation != null
      ) {
        //configuration is archived or a archiving/restoring process is ongoing
        this._logger.debug('Garment is archived - id: ', request.id);
        const garment = {
          ...request,
          isPublic: false,
          selected: false,
          groupId: 0,
          tuckedIn: false,
          dressed: false,
          tryonable: false,
          archived: true,
        };
        garments.push(garment);
        continue;
      }
      const webresults = request.results.filter((c) => {
        return c.format === 'web';
      });

      const metadata = await this.getMetadata(webresults[0]);
      let enabled = true;
      if (metadata === undefined || metadata.garmentGroup === undefined) {
        this._logger.debug(
          'Garmentgroup is not defined for config id :',
          request.id,
        );
        enabled = false;
      }
      //garment 2ds are always tryonable if they have a webresult

      if (this.productType === PRODUCT_TYPE.OUTFIT_3D) {
        const mobileResult = request.results.filter((c) => {
          return c.format === 'mobile';
        });
        if (
          metadata?.tryonable === false ||
          mobileResult === undefined ||
          mobileResult.length === 0
        ) {
          this._logger.debug(
            'Garment is not tryonable for requestId',
            request.id,
          );
          enabled = false;
        }
      }
      const garment = {
        ...request,
        isPublic: false,
        selected: this.getSelected(request.id),
        groupId: metadata?.garmentGroup ? metadata.garmentGroup : 0,
        tuckedIn: false,
        dressed: false,
        tryonable: enabled,
      };
      garments.push(garment);
    }
    return garments;
  }

  public async searchPatternChanged(event: any) {
    if (event.target?.value === '' || event.target?.value?.length > 0) {
      this.allGarments = [];
      this._store.updateSearchPattern(event.target.value);
    }
  }

  public clearSearchInput() {
    this.allGarments = [];
    this._store.updateSearchPattern('');
  }

  private convertToTagFormat(tags: string): string {
    const values = JSON.parse(tags) as {
      value: string;
      id: string;
    }[];
    const tagId = values.map((v) => {
      return v.id;
    });
    let v = {};
    for (const id of tagId) {
      v = { ...v, [id]: ['*'] };
    }
    return JSON.stringify(v);
  }
}
