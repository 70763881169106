<div
  class="input-container d-flex flex-row"
  [ngClass]="{ disabled: disabled === true }"
>
  <input type="text" [ngModel]="value" placeholder=" " disabled />
  <span class="input-label">{{ placeholder }}</span>
  <div class="clickable-area" (click)="showList = !showList"></div>
  <span class="rr-dropdown ml-auto mr-2"></span>
</div>
<div
  class="d-flex flex-column dropdown-list"
  *ngIf="showList && disabled !== true"
>
  <div *ngFor="let entry of entries" (click)="itemSelect(entry)" class="item">
    {{ entry.data }}
  </div>
</div>
<div
  class="backdrop-selection"
  *ngIf="this.showList"
  (click)="showList = false"
></div>
