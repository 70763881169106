import {
  AfterViewInit,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CalendarComponent } from 'projects/web-ui-component-library/src/public-api';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FilterInput } from '../interfaces/filter.interface';

type DateRange = { from: Date; to: Date };

@Component({
  selector: 'rr-date-filter-input-element',
  templateUrl: './date-input-element.component.html',
  styleUrls: ['./date-input-element.component.scss'],
})
export class DateInputElementComponent implements OnInit, AfterViewInit {
  @ViewChild('calendar') calendar: CalendarComponent;
  @Input() inputData$: Observable<FilterInput>;
  @Input() reset$: Observable<string>;

  @HostBinding('hidden')
  public isHidden: boolean = true;
  public onDataChanged: (value: number[]) => void = (_) => {};
  public onBackClicked: () => void = () => {};

  private _onDestroy: Subject<void> = new Subject();
  private _id: string = '';

  constructor() {}

  ngAfterViewInit(): void {
    this.reset$.pipe(takeUntil(this._onDestroy)).subscribe((filterId) => {
      if (this._id === filterId || filterId === '') {
        this.clearRange();
      }
    });
    this.inputData$.pipe(takeUntil(this._onDestroy)).subscribe((data) => {
      this.onDataChanged = data.onChange;
      this.onBackClicked = data.onBackClicked;
      this.isHidden = data.hidden;
      this._id = data.id;
    });
  }

  ngOnInit(): void {
    this._onDestroy = new Subject();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onDestroy = undefined;
  }

  private today = new Date();
  presets = [
    {
      text: 'Today',
      range: () => ({
        from: new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate(),
          0,
          0,
          0,
        ),
        to: new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate(),
          23,
          59,
          59,
        ),
      }),
    },
    {
      text: 'Yesterday',
      range: () => ({
        from: new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate() - 1,
          0,
          0,
          0,
        ),
        to: new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate() - 1,
          23,
          59,
          59,
        ),
      }),
    },
    {
      text: 'This Week',
      range: () => {
        const from = this.getMondayForDate(this.today);
        const to = new Date(from);
        to.setDate(to.getDate() + 7);
        to.setSeconds(-1);

        return {
          from,
          to,
        };
      },
    },
    {
      text: 'Last Week',
      range: () => {
        const from = this.getMondayForDate(this.today);
        from.setDate(from.getDate() - 7);
        const to = new Date(from);
        to.setDate(to.getDate() + 7);
        to.setSeconds(-1);

        return {
          from,
          to,
        };
      },
    },
    {
      text: 'This Month',
      range: () => {
        const from = new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          1,
        );
        const to = new Date(from);
        to.setMonth(to.getMonth() + 1);
        to.setSeconds(-1);

        return {
          from,
          to,
        };
      },
    },
    {
      text: 'Last Month',
      range: () => {
        const from = new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          1,
        );
        from.setMonth(from.getMonth() - 1);
        const to = new Date(from);
        to.setMonth(to.getMonth() + 1);
        to.setSeconds(-1);

        return {
          from,
          to,
        };
      },
    },
  ];

  getMondayForDate(date: Date) {
    const monday = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    );

    monday.setDate(monday.getDate() - monday.getDay());
    monday.setDate(monday.getDate() + 1);

    return monday;
  }

  selectPreset(preset?: () => DateRange) {
    const { from, to } = preset();

    this.calendar.selected = { from, to };
    this.updateFilter({ from, to });
  }

  clearRange() {
    this.calendar.selected = null;
    this.updateFilter(undefined);
  }

  getValue() {
    return this.calendar.selected;
  }

  onChange(date: DateRange) {
    this.updateFilter(date);
  }

  private updateFilter(date: DateRange | undefined) {
    if (date === undefined) {
      this.onDataChanged(undefined);
      return;
    }
    this.onDataChanged([date.from.getTime() / 1000, date.to.getTime() / 1000]);
  }
}
