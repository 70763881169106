import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RRCopyToClipboardModule } from 'projects/web-ui-component-library/src';
import { AlertService } from '../../../core/services/alert.service';

@Component({
  selector: 'app-copy-to-clipboard',
  standalone: true,
  imports: [RRCopyToClipboardModule, CommonModule],
  templateUrl: './copy-to-clipboard.component.html',
  styleUrl: './copy-to-clipboard.component.scss',
})
export class CopyToClipboardComponent {
  @Input() data: string;

  constructor(private _alertService: AlertService) {}

  public showInfo() {
    this._alertService.showSuccessAlert(`Coppied to clipboard!`);
  }
}
