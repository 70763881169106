import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
} from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';

export interface TableRowComponent<R> {
  record: R;
  loaded: Subject<void>;
}

export interface DataSource<R> {
  fetch: (opts?: Partial<{ offset: number; count: number }>) => Observable<{
    data: Array<R>;
    page?: {
      offset: number;
      count: number;
      total?: number;
    };
  }>;
}

export interface TableConfiguration<R> {
  columns?: Array<{
    title: string;
    hideSort?: boolean;
    sort?: 'ASC' | 'DESC' | '';
    sortable?: (a: R, b: R) => number;
  }>;
  rowComponent: Type<TableRowComponent<R>>;
}

@Component({
  selector: 'rr-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class RRTableComponent<R> implements OnInit, AfterViewInit, OnDestroy {
  @Input() configuration: TableConfiguration<R>;
  @Input() datasource: DataSource<R>;
  @Input() showSkeletonLoader: Observable<boolean>;
  @Output() sortColumnClicked: EventEmitter<{
    title: string;
    hideSort?: boolean;
    sort?: 'ASC' | 'DESC' | '';
    sortable?: (a: R, b: R) => number;
  }> = new EventEmitter();
  public showLoader: boolean;
  displayedRecords: Array<R> = [];
  private _onDestroy: Subject<void> = new Subject();
  constructor() {}

  ngOnInit(): void {
    this._onDestroy = new Subject();
  }

  ngAfterViewInit(): void {
    if (!this.configuration) return;
    if (!this.datasource) return;
    const tableContainer = document.getElementsByClassName(
      'table-container',
    )[0] as any;
    this.datasource
      .fetch()
      .pipe(takeUntil(this._onDestroy))
      .subscribe(({ data }) => {
        this.displayedRecords = data;
        if (data.length === 0) {
          this.showLoader = false;
          tableContainer.style.height = 'unset';
        }
      });
    this.showSkeletonLoader
      ?.pipe(takeUntil(this._onDestroy))
      .subscribe((show) => {
        if (!show) {
          //This timeout is that the UI is refreshed before the skeleton loader was removed.
          setTimeout(() => {
            this.showLoader = show;
          }, 100);
          return;
        }
        this.showLoader = show;
        tableContainer.style.height = '686px';
      });
  }
  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onDestroy = undefined;
  }

  loadingDone() {
    this.showLoader = false;
    (
      document.getElementsByClassName('table-container')[0] as any
    ).style.height = 'unset';
  }
  sort(col: {
    title: string;
    hideSort?: boolean;
    sort?: 'ASC' | 'DESC' | '';
    sortable?: (a: R, b: R) => number;
  }) {
    if (col.hideSort) {
      return;
    }
    const otherCols = this.configuration.columns.filter(
      (o) => o.title !== col.title,
    );
    otherCols.map((o) => (o.sort = ''));
    switch (col.sort) {
      case 'ASC':
        col.sort = 'DESC';
        break;
      case 'DESC':
        col.sort = 'ASC';
        break;
      default:
        col.sort = 'DESC';
    }
    this.sortColumnClicked.emit(col);
  }
}
