import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISimpleFileService } from '../../data/api';
import { Injectable } from '@angular/core';
import { OutfitReference } from '../collection-screen.state';

@Injectable({
  providedIn: 'root',
})
export class SimpleFileService implements ISimpleFileService {
  constructor(private _http: HttpClient) {}
  public readJsonFile = (url: string): Observable<OutfitReference> => {
    return this._http.get<OutfitReference>(url, { responseType: 'json' });
  };
}
