const JointMap = {
  rShldrBend: 'below shoulder - right',
  rShldrTwist: 'above elbow - right',
  rForearmBend: 'below elbow - right',
  rForearmTwist: 'above wrist - right',
  lShldrBend: 'below shoulder - left',
  lShldrTwist: 'above elbow - left',
  lForearmBend: 'below elbow - left',
  lForearmTwist: 'above wrist - left',
  rThighBend: 'upper thigh - right',
  rThighTwist: 'above knee - right',
  rShin: 'shin - right',
  lThighBend: 'upper thigh - left',
  lThighTwist: 'above knee - left',
  lShin: 'shin - left',
};

export function fromOriginalJointToMapped(v: string) {
  return JointMap[v];
}

export function fromMappedJointToOriginal(v: string) {
  for (const key in JointMap) {
    if (Object.prototype.hasOwnProperty.call(JointMap, key)) {
      const element = JointMap[key];
      if (element === v) {
        return key;
      }
    }
  }
}

export function mapStylingObject(obj: any) {
  if (typeof obj === 'object') {
    const result = {
      ...obj,
    };
    if (result.cinchUp && Array.isArray(result.cinchUp)) {
      for (const cinch of result.cinchUp) {
        if (typeof cinch.rootJointName === 'string') {
          cinch.rootJointName = fromMappedJointToOriginal(cinch.rootJointName);
        }
      }
    }
    return result;
  }

  return obj;
}

export function mapStylingObjectToUI(obj: any) {
  if (typeof obj === 'object') {
    const result = {
      ...obj,
    };
    if (result.cinchUp && Array.isArray(result.cinchUp)) {
      for (const cinch of result.cinchUp) {
        if (typeof cinch.rootJointName === 'string') {
          cinch.rootJointName = fromOriginalJointToMapped(cinch.rootJointName);
        }
      }
    }
    return result;
  }

  return obj;
}
