<div
  id="accessory-selection-{{ accessory.id }}"
  class="d-flex flex-row row configuration-entry align-items-center"
>
  <app-configuration-display [garment]="accessory"></app-configuration-display>
  <div class="p2 ml-auto buttons d-flex flex-row align-items-center">
    <span
      class="rr-delete mr-3"
      tooltip="Remove Accessory"
      id="accessory-selection-{{ accessory.id }}-remove-btn"
      containerClass="accessory-options-tooltip"
      (click)="deleteEntry($event)"
    ></span>
  </div>
</div>
