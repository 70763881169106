import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, first } from 'rxjs';
import { ILogger } from '../../logging';
import { NgStorageManagerService } from './ng-storage-manager.service';

export type Permission = 'Core' | 'UserExperience';

@Injectable({
  providedIn: 'root',
})
export class UserDataPermissionService {
  public readonly granted$: Observable<Permission[]>;

  private readonly store: ComponentStore<Permission[]> = new ComponentStore([]);
  private readonly _grant: (permission: Permission) => void;

  constructor(
    private logger: ILogger,
    private storage: NgStorageManagerService,
  ) {
    this._grant = this.store.updater((state, permission: Permission) => {
      this.logger.info(
        'UserDataPermissionService',
        'Granting permission:',
        permission,
        state,
      );
      if (state.includes(permission)) return;

      const updated = [...state, permission];

      // only update if we have permission to store data...
      if (updated.includes('Core')) {
        this.logger.debug('UserDataPermissionService', 'saving changes...');
        this.storage.setItem('UserDataPermissions', updated, 'local');
      }

      this.logger.info(
        'UserDataPermissionService',
        'Updated permission:',
        updated,
      );
      return updated;
    });

    this.granted$ = this.store.select((state) => state);
    this.restore();
  }

  public grant(...permissions: Permission[]): void {
    permissions.forEach((permission) => this._grant(permission));
  }

  private restore(): void {
    this.storage
      .getItem$<Permission[]>('UserDataPermissions', 'local')
      .pipe(first())
      .subscribe((fromStorage) => {
        this.logger.debug(
          'UserDataPermissionService',
          'loading existing grants...',
          fromStorage,
        );
        fromStorage?.forEach((permission) => this._grant(permission));
      });
  }
}
