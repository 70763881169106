import { OUTFIT_2D_CONFIG } from 'projects/content-service-cms/src/app/core/configs/outfit-2d.config';
import { FileType } from 'projects/content-service-cms/src/app/core/models/files/file-type.model';
import { PRODUCT_TYPE } from 'projects/content-service-cms/src/app/data';
import { ACCESSORY_3D_CONFIG } from '../../configs/accessory-3d.config';
import { GARMENT_2D_CONFIG } from '../../configs/garment-2d.config';
import { GARMENT_3D_CONFIG } from '../../configs/garment-3d.config';
import { IRequestFormConfig } from '../../interfaces/request/request-form-config.interface';

export class RequestFilesHelper {
  static get ReferenceImageFileType(): FileType {
    return {
      id: 3,
      name: 'REFERENCE_ADDITIONAL',
    };
  }

  static get MannequinShotFileType(): FileType {
    return {
      id: 1,
      name: 'FRONTAL',
    };
  }

  static get MannequinShotBackFileType(): FileType {
    return {
      id: 4,
      name: 'BACK',
    };
  }

  static get RejectedFileType(): FileType {
    return {
      id: 16,
      name: 'REJECTION_ATTACHMENT',
    };
  }

  static get CadFileType(): FileType {
    return {
      id: 42,
      name: 'CAD_FILE',
    };
  }

  static getRequestFilesConfig(type: PRODUCT_TYPE): IRequestFormConfig {
    switch (type) {
      case PRODUCT_TYPE.GARMENT_2D:
        return GARMENT_2D_CONFIG;
      case PRODUCT_TYPE.GARMENT_3D:
        return GARMENT_3D_CONFIG;
      case PRODUCT_TYPE.ACCESSORY_3D:
        return ACCESSORY_3D_CONFIG;
      case PRODUCT_TYPE.OUTFIT_2D:
        return OUTFIT_2D_CONFIG;
      default:
        return null;
    }
  }

  static containsFileType(
    config: IRequestFormConfig,
    fileType: string,
  ): boolean {
    return config.files.find((f) => f.type === fileType) !== undefined;
  }

  static maxUploadsForFileType(
    config: IRequestFormConfig,
    fileType: string,
  ): number {
    const fileConfig = config.files.find((f) => f.type === fileType);
    return fileConfig !== undefined ? fileConfig.multiplicity[1] : 0;
  }

  static minUploadsForFileType(
    config: IRequestFormConfig,
    fileType: string,
  ): number {
    const fileConfig = config.files.find((f) => f.type === fileType);
    return fileConfig !== undefined ? fileConfig.multiplicity[0] : 0;
  }

  static getSupportedFileExtensionsPerFileType(
    config: IRequestFormConfig,
    fileType: string,
  ): string[] {
    const file = config.files.find((f) => f.type === fileType);
    return file ? file.fileFormats : [];
  }
}
