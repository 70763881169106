import { Provider } from '@angular/core';
import { ILogger } from './api';
import { ConsoleLoggerService } from './services/console-logger.service';

export const LoggerProviders: Provider[] = [
  {
    provide: ILogger,
    useExisting: ConsoleLoggerService,
  },
  ConsoleLoggerService,
];

export { ILogger } from './api';
