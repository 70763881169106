<ng-template #acceptUserData>
  <article id="user-data-permission-article">
    <p>
      We store necessary user data to make our site work. We'd also like to set
      analytics cookies that help us make improvements by measuring how you use
      the site. These will be set only if you accept.
    </p>
    <p>
      For more detailed information about the data we store in your browser, see
      <a
        href="https://www.reactivereality.com/privacy"
        target="_blank"
        id="user-data-permission-privacy-policy-link"
        >here</a
      >
    </p>
  </article>
  <footer>
    <button
      class="btn btn-success"
      (click)="acceptAll()"
      id="user-data-permission-accept"
    >
      Accept
    </button>
    <button
      class="btn"
      (click)="acceptCore()"
      id="user-data-permission-necessary"
    >
      Necessary Only
    </button>
  </footer>
</ng-template>
