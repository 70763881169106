import { HttpClient } from '@angular/common/http';
import { AssetType, IAsset } from '@reactivereality/pictofit-web-sdk';
import { ILogger } from '../../../logging';
import { AssetFiles } from '../api';
import { BaseAsset, toAssetFiles } from './common';

export { Garment2DAsset, Garment3DAsset } from './GarmentAssets';
export { Avatar3DAsset, Generated3DAvatar } from './AvatarAssets';

const createBaseAsset = (
  data: AssetFiles,
  assetType: AssetType,
  httpClient: HttpClient,
  logger: ILogger,
): IAsset => {
  return new BaseAsset(toAssetFiles(data), httpClient, logger, assetType);
};

export const createSceneAsset = (
  data: AssetFiles,
  httpClient: HttpClient,
  logger: ILogger,
): IAsset => {
  return createBaseAsset(data, AssetType.UNDEFINED, httpClient, logger);
};

export const createGarment2DAsset = (
  data: AssetFiles,
  httpClient: HttpClient,
  logger: ILogger,
): IAsset => {
  return createBaseAsset(data, AssetType.GARMENT_2D, httpClient, logger);
};

export const createGarment3DAsset = (
  data: AssetFiles,
  httpClient: HttpClient,
  logger: ILogger,
): IAsset => {
  return createBaseAsset(data, AssetType.GARMENT_3D, httpClient, logger);
};
