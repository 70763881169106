<div class="selection-wrapper">
  <rr-overlay-spinner
    [spinnerStatus]="spinnerSelectionBox"
  ></rr-overlay-spinner>
  <div
    class="backdrop-selection"
    *ngIf="listOpacity"
    (click)="backdropListClicked()"
  ></div>
  <div
    id="select-input"
    class="selection d-flex flex-row flex-wrap p-1 pl-2 w-100"
    (click)="focusInput()"
  >
    <div class="selected-items d-flex flex-row flex-wrap mr-1 w-100">
      <ng-container *ngFor="let selectedItem of selected">
        <div
          class="item d-flex flex-row justify-content-center align-items-center m-1"
          [ngStyle]="{ 'background-color': selectedItem.color }"
        >
          <span class="item-text mr-1">{{ selectedItem.name }}</span>
          <span class="rr-close8x8" (click)="removeItem(selectedItem)"></span>
        </div>
      </ng-container>
      <div
        class="input ml-1 d-flex justify-content-center align-items-center justify-content-center"
      >
        <span class="placeholder" *ngIf="!listOpacity"> Tags </span>
        <span
          class="input"
          role="textbox"
          (keypress)="addToSelection($event)"
          contenteditable
          #inputSpan
        ></span>
      </div>
    </div>
  </div>
  <div
    class="item-list d-flex flex-column justify-content-start"
    *ngIf="listOpacity"
  >
    <div class="tag-list-text p-2 m-2" *ngIf="filteredTags?.length > 0">
      Select a tag or type to create one
    </div>
    <div class="tag-list-text p-2 m-2" *ngIf="filteredTags?.length === 0">
      Press enter to create "{{ inputText }}"
    </div>

    <div
      class="tag-item p-1 ml-2 d-flex align-items-center justify-content-between"
      *ngFor="let item of filteredTags"
    >
      <span
        class="item item-text"
        [ngStyle]="{ 'background-color': item.color }"
        (click)="addTagToSelected(item)"
        >{{ item.name }}</span
      >
      <span class="rr-more-horiz" (click)="editTag(item, $event)"></span>
    </div>
  </div>
  <div class="backdrop" *ngIf="editItem" (click)="backdropClicked()"></div>
  <div
    class="edit-overlay p-2"
    *ngIf="editItem"
    [ngStyle]="{ left: editLeft + 'px', top: editTop + 'px' }"
  >
    <rr-overlay-spinner [spinnerStatus]="spinnerTextBox"></rr-overlay-spinner>
    <div class="edit-item d-flex flex-column justify-content-start p-2 m2">
      <input
        class="edit-input p-2"
        [(ngModel)]="editItem.name"
        (ngModelChange)="updateTag()"
      />
      <div
        class="delete-button d-flex justify-content-start align-items-center align-content-center mt-2 mb-2 p-2 hover"
        (click)="deleteTag(editItem)"
      >
        <span class="rr-delete mr-2"></span> Delete
      </div>
      <span class="mb-2 p-2">Color</span>
      <ng-container *ngFor="let color of colorList">
        <div
          class="color d-flex flex-row justify-content-start align-items-center p-2 hover"
          (click)="changeColor(color)"
        >
          <div
            class="color-dot"
            [ngStyle]="{ 'background-color': color.color }"
          ></div>
          <div class="text ml-2">
            {{ color.name }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
