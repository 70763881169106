import { Injectable } from '@angular/core';
import { ILogger } from '../api';

@Injectable({
  providedIn: 'root',
})
export class ConsoleLoggerService implements ILogger {
  private warnedOfConsole: boolean = false;
  constructor() {
    // eslint-disable-next-line no-console
    console.log = (...args: any[]) => {
      if (!this.warnedOfConsole) {
        this.emitMessage('Warn', "Instances of 'console.log' still in use.");
        this.warnedOfConsole = true;
      }

      this.emitMessage('Debug', ...args);
    };

    this.info('Creating console logger');
  }

  private emitMessage(
    level: 'Debug' | 'Info' | 'Warn' | 'Error',
    ...args: any[]
  ) {
    // eslint-disable-next-line no-console
    console[level.toLowerCase()](`[${level}]`, ...args);
  }

  public debug(msg: string, ...details: any[]) {
    this.emitMessage('Debug', msg, ...details);
  }
  public info(msg: string, ...details: any[]) {
    this.emitMessage('Info', msg, ...details);
  }
  public warn(msg: string, ...details: any[]) {
    this.emitMessage('Warn', msg, ...details);
  }
  public error(msg: string, ...details: any[]) {
    this.emitMessage('Error', msg, ...details);
  }
}
