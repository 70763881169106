import { Injectable } from '@angular/core';
import { createReducer, on } from '@ngrx/store';
import { CustomerDTO } from '@reactivereality/cs-api-sdk';
import {
  loadCurrentCustomerCompleteAction,
  setCurrentCustomerAction,
  setCurrentCustomerIDAction,
  updateCustomerListAction,
} from '../store/customer.actions';
import { ICustomerState } from '../store/customer.state';
import { CustomerStorageService } from './customer-storage.service';

const initialState: ICustomerState = {
  myCustomerList: [],
};

@Injectable({
  providedIn: 'root',
})
export class CustomerReducerService {
  constructor(private customerStorageService: CustomerStorageService) {}

  getReducers() {
    const updateMyCustomerListReducer = (
      state: ICustomerState,
      { myCustomerList }: { myCustomerList: Array<CustomerDTO> },
    ): ICustomerState => ({ ...state, myCustomerList });

    const setCurrentCustomerReducer = (
      state: ICustomerState,
      { currentCustomer }: { currentCustomer?: CustomerDTO },
    ): ICustomerState => {
      if (currentCustomer) {
        this.customerStorageService.setSelectedCustomer(currentCustomer.id);
      } else {
        this.customerStorageService.clearSelectedCustomer();
      }

      return { ...state, currentCustomer };
    };

    const setCurrentCustomerIDReducer = (
      state: ICustomerState,
      { customerID }: { customerID?: string },
    ): ICustomerState => {
      const newCustomer = state.myCustomerList.find((v) => v.id === customerID);
      if (newCustomer) {
        return {
          ...state,
          currentCustomer: newCustomer,
        };
      }
      return {
        ...state,
      };
    };

    const loadCurrentCustomerCompleteReducer = (
      state: ICustomerState,
      { currentCustomer }: { currentCustomer?: string },
    ): ICustomerState => {
      return {
        ...state,
        currentCustomer: state.myCustomerList.find(
          (c) => c.id === currentCustomer,
        ),
      };
    };

    return createReducer<ICustomerState>(
      initialState,
      on(updateCustomerListAction, updateMyCustomerListReducer),
      on(setCurrentCustomerAction, setCurrentCustomerReducer),
      on(setCurrentCustomerIDAction, setCurrentCustomerIDReducer),
      on(loadCurrentCustomerCompleteAction, loadCurrentCustomerCompleteReducer),
    );
  }
}
