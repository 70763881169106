/* eslint-disable @angular-eslint/component-selector */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Pipe,
  PipeTransform,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from 'projects/content-service-cms/src/app/app.state';
import { REQUEST_STATE_CMS } from 'projects/content-service-cms/src/app/data';
import { TableRowComponent } from 'projects/web-ui-component-library/src';
import { Subject } from 'rxjs';
import { updateDraftRequestAction } from '../../../store/requests-overview.actions';
import { CreateRequestService } from '../../create-request-modal/services/create-request.service';
import { RequestRecord } from '../request-list.page.component';

@Pipe({
  standalone: true,
  name: 'prettyProductDate',
})
export class PrettyProductDatePipe implements PipeTransform {
  transform(time: number) {
    const date = new Date(time * 1000);
    const locale =
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language ?? 'en';

    return date.toLocaleTimeString(locale, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }
}

@Component({
  selector: 'tbody.app-request-list-row',
  templateUrl: './request-list-row.component.html',
  styleUrls: ['./request-list-row.component.scss'],
})
export class RequestListRowComponent
  implements AfterViewInit, TableRowComponent<RequestRecord>, OnChanges
{
  @Input() record: RequestRecord;
  @Input() loaded: Subject<void>;
  expanded: boolean = false;
  constructor(
    private element: ElementRef<HTMLDivElement>,
    private router: Router,
    private store: Store<IAppState>,
    private createRequestService: CreateRequestService,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.loaded) {
      const tableImages = document.getElementsByClassName('table-thumbnail');
      if (tableImages.length > 0) {
        tableImages[tableImages.length - 1].addEventListener('load', () => {
          this.loaded.next();
        });
      } else {
        this.loaded.next();
      }
    }
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

  openRequest(record: RequestRecord) {
    if (
      record.state === REQUEST_STATE_CMS.CREATED &&
      this.createRequestService.supportsDraft(record.productType)
    ) {
      // start creation modal ...
      this.store.dispatch(
        updateDraftRequestAction({
          id: record.id,
        }),
      );
    } else {
      this.router.navigate(['requests', record.id]);
    }
  }

  ngAfterViewInit() {
    this.element.nativeElement.classList.toggle('collapsed', !this.expanded);
    if (this.loaded) {
      const tableImages = document.getElementsByClassName('table-thumbnail');
      if (tableImages?.length > 0) {
        tableImages[tableImages.length - 1].addEventListener('load', () => {
          this.loaded.next();
        });
      }
    }
  }
}
