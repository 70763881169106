import { v4 as uuid } from 'uuid';

export class RRFileContainerModel {
  id: uuid;
  customerId: uuid;
  name: string;
  configurationId: uuid;
  configurationResultId: uuid;
  typeId: string;
  metadata?: {};

  toDataFormat(): { [key: string]: string } {
    return {
      id: String(this.id),
      configurationResultId: String(this.configurationResultId),
      name: String(this.name),
      metadata: String(this.metadata),
    };
  }

  toInputFormat(model: RRFileContainerModel): { [key: string]: string } {
    return {
      id: model.id,
      customerId: model.customerId,
      configurationId: model.configurationId,
      name: model.name,
      typeId: model.typeId,
    };
  }
}
