<div class="row rowcontent create-content">
  <div class="col-6 pl-5 pr-5 d-flex flex-column">
    <rr-virtual-dressing-room
      #MainVDR
      [settings]="dressingRoomSettings"
      [vdrChange$]="vdrUpdate$"
      [vdrStatus$]="vdrStatus$"
      [vdrReset$]="vdrReset$"
      [token]="token"
    >
    </rr-virtual-dressing-room>
  </div>
  <div class="col-6 pl-5 pr-5 pb-5 d-flex flex-column scroll-here rightColumn">
    <div class="col-11 details-area">
      <app-common-inputs class="d-flex flex-column"></app-common-inputs>
      <!-- <div class="mt-5 ml-auto mr-auto">
        <input
          class="mr-3"
          type="checkbox"
          id="performQc"
          name="performQc"
          [disabled]="createRequestModalStore.existingRequest$ | async"
          [checked]="
            (createRequestModalStore.skipManualStep$ | async) === false
          "
          (change)="createRequestModalStore.switchSkipManualStep()"
        />
        <span>Manual quality control</span>
      </div> -->
      <div *ngIf="authService.isCollectionCreator$ | async">
        <app-accessory-selection
          *ngIf="createRequestModalStore.isCollectionOutfit$ | async"
        ></app-accessory-selection>
      </div>
      <!--Select Garments-->
      <app-garment-selection [productType]="21"></app-garment-selection>
      <!--Select Avatars-->
      <div class="mt-5">
        <h2>Avatar</h2>
        <div class="addAvatarContainer">
          <app-avatar-selection-entry
            [selectedAvatar]="createRequestModalStore.avatar$ | async"
            (changeClicked)="addAvatarClicked()"
          >
          </app-avatar-selection-entry>
        </div>
      </div>
      <!--Select Scenes-->
      <div class="mt-5">
        <h2>Scene</h2>
        <div class="addSceneContainer">
          <app-scene-selection-entry
            [selectedScene]="createRequestModalStore.scene$ | async"
          >
          </app-scene-selection-entry>
        </div>
      </div>
      <!--Buttons-->
      <app-action-buttons></app-action-buttons>
    </div>
  </div>
</div>
