import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IPaginationDataProviderInterface {
  public readonly currentPage$: Observable<number>;
  public setCurrentPage: (page: number) => void;

  public readonly pageCount$: Observable<number>;
  public readonly totalRecords$: Observable<number>;
  public readonly pageSize$: Observable<number>;
  public setPageSize: (page: number) => void;
}
