import { Injectable } from '@angular/core';
import { NgStorageManagerService } from '../../core/services/ng-storage-manager.service';

const CURRENT_CUSTOMER_ID = 'customer.current_id';

@Injectable({
  providedIn: 'root',
})
export class CustomerStorageService {
  constructor(private storageManager: NgStorageManagerService) {}

  public readonly setSelectedCustomer = (customerId: string) => {
    if (customerId) {
      this.storageManager.setItem(CURRENT_CUSTOMER_ID, customerId, 'local');
    }
  };

  public readonly clearSelectedCustomer = () => {
    this.storageManager.setItem(CURRENT_CUSTOMER_ID, undefined, 'local');
  };

  public readonly getSelectedCustomer = (): string => {
    return this.storageManager.getItem(CURRENT_CUSTOMER_ID, 'local');
  };
}
