import { HttpClient } from '@angular/common/http';
import { AssetType, IAsset } from '@reactivereality/pictofit-web-sdk';
import { ILogger } from '../../../logging';
import { AssetFiles } from '../api';

export class Avatar2DAsset implements IAsset {
  constructor(
    protected avatar: AssetFiles,
    protected httpClient: HttpClient,
    protected logger: ILogger,
  ) {}
  preloadComponents(): Promise<void> {
    this.logger.warn('[Avatar2DAsset] preloadComponents not implemented');
    return;
  }
  preloadComponent(key: string): Promise<void> {
    this.logger.warn('[Avatar2DAsset] preloadComponent not implemented');
    return;
  }

  private findFile(key: string) {
    this.logger.debug(`[Avatar2DAsset] Loading key: ${key}`);
    return this.avatar.find((file) => file.filename === key);
  }

  protected getLocation = async (key: string): Promise<string | null> => {
    const fileInQuestion = this.findFile(key);

    if (!fileInQuestion) {
      return null;
    }

    return fileInQuestion.url;
  };

  public getComponent = async (key: string): Promise<Blob> => {
    const location = await this.getLocation(key);

    if (location == null) {
      throw new Error(`Key '${key}' is not available in Avatar2DAsset`);
    }

    const response = await this.httpClient
      .get(location, { observe: 'response', responseType: 'blob' })
      .toPromise();

    if (response.status !== 200) {
      const responseText =
        response.body !== null
          ? await response.body.text()
          : 'Unkown response text';
      throw new Error(
        `Failed to fetch key '${key}' for Avatar2DAsset ${responseText}`,
      );
    }

    if (response.body === null) {
      throw new Error(`Key '${key}' for product resulted in null blob content`);
    }

    return response.body;
  };

  /**
   * Get the location of a component of this asset associated with the specified key.
   * @param key
   * @returns The location of the component or null. Does not mean that the component behind the url is available.
   */
  public getComponentLocation = async (key: string): Promise<string | null> => {
    const location = await this.getLocation(key);

    return location;
  };

  /**
   * Get all keys of this asset.
   */
  public getKeys = async (): Promise<string[]> => {
    return this.avatar.map((f) => f.filename);
  };

  /**
   * The type of an asset.
   */
  public getType = async (): Promise<AssetType> => {
    return AssetType.AVATAR_2D;
  };
}
