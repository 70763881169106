import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'projects/content-service-cms/src/app/app.state';
import { AddAvatarModalComponent } from 'projects/content-service-cms/src/app/components/avatar-selection-entry/add-avatar-modal/add-avatar-modal.component';
import { AvatarSelectionEntryComponent } from 'projects/content-service-cms/src/app/components/avatar-selection-entry/avatar-selection-entry.component';
import { FileUploadFullComponent } from 'projects/content-service-cms/src/app/components/file-upload-full/file-upload-full.component';
import { AddGarmentModalComponent } from 'projects/content-service-cms/src/app/components/garment-selection/add-garment-modal/add-garment-modal.component';
import { GarmentSelectionComponent } from 'projects/content-service-cms/src/app/components/garment-selection/garment-selection.component';
import { RequestFilesHelper } from 'projects/content-service-cms/src/app/core/helpers/request/request-files.helper';
import { UploadData } from 'projects/content-service-cms/src/app/core/interfaces/upload-data.interface';
import { ICustomerService } from 'projects/content-service-cms/src/app/customer';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { VirtualDressingRoomModule } from 'projects/content-service-cms/src/app/virtual-dressing-room/virtual-dressing-room.module';
import { RRSpinnerModule } from 'projects/web-ui-component-library/src';
import { Subject, filter, takeUntil } from 'rxjs';
import { createNewRequestAction } from '../../../store/requests-overview.actions';
import { ActionButtonsComponent } from '../action-buttons/action-buttons.component';
import { CommonInputsComponent } from '../common-inputs/common-inputs.component';
import { CreateRequestModalStore } from '../create-request-modal.state';
import { CreateGarment3DCADService } from '../services/create-garment-3d-cad.service';

@Component({
  selector: 'app-create-garment-3d-cad',
  templateUrl: './create-garment-3d-cad.component.html',
  styleUrls: ['./create-garment-3d-cad.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AddAvatarModalComponent,
    AddGarmentModalComponent,
    RRSpinnerModule,
    VirtualDressingRoomModule,
    FileUploadFullComponent,
    CommonInputsComponent,
    AvatarSelectionEntryComponent,
    GarmentSelectionComponent,
    ActionButtonsComponent,
  ],
})
export class CreateGarment3DCADComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<void>;

  private _cadFile: UploadData;
  private _referencefiles: UploadData[];

  public initialCadFiles = new Subject<UploadData[]>();
  public initialReferenceFiles = new Subject<UploadData[]>();

  constructor(
    private _logger: ILogger,
    public customerService: ICustomerService,
    public createRequestModalStore: CreateRequestModalStore,
    private createGarment3DCADService: CreateGarment3DCADService,
    private _store: Store<IAppState>,
  ) {}

  ngOnInit(): void {
    this._onDestroy$ = new Subject<void>();
    this.createRequestModalStore.requestCommonDataName$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((name) => {
        this.isInputValid();
      });

    this.createRequestModalStore.createRequestAction$
      .pipe(
        takeUntil(this._onDestroy$),
        filter((c) => c),
      )
      .subscribe(async () => {
        await this.createGarment3DCADService.createRequest(
          this.createRequestModalStore,
          {
            cad: this._cadFile,
            reference: this._referencefiles,
          },
        );
        this.createRequestModalStore.cancelAction();
      });
    this.createRequestModalStore.createRequestActionAndAnotherOne$
      .pipe(
        takeUntil(this._onDestroy$),
        filter((c) => c),
      )
      .subscribe(async () => {
        await this.createGarment3DCADService.createRequest(
          this.createRequestModalStore,
          {
            cad: this._cadFile,
            reference: this._referencefiles,
          },
        );
        const state = this.createRequestModalStore.current();
        this._store.dispatch(
          createNewRequestAction({
            productType: state.productType,
          }),
        );
        this.initialCadFiles.next([]);
        this.initialReferenceFiles.next([]);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._onDestroy$.unsubscribe();
    this._onDestroy$ = undefined;
  }
  private isInputValid() {
    const commonData = this.createRequestModalStore.currentCommonData();
    if (commonData.name && this._cadFile) {
      this.createRequestModalStore.changeCreateRequestCommonData({
        dataValid: true,
      });
    } else {
      this.createRequestModalStore.changeCreateRequestCommonData({
        dataValid: false,
      });
    }
  }
  public onSelectedFilesChanged(type: 'cad' | 'ref', files: UploadData[]) {
    this._logger.debug(type, files);
    switch (type) {
      case 'cad':
        if (files?.length === 0) {
          this._cadFile = undefined;
          break;
        }
        this._cadFile = files[0];
        this._cadFile.fileinformation.typeId =
          RequestFilesHelper.CadFileType.id;
        break;
      case 'ref':
        if (files?.length === 0) {
          this._referencefiles = [];
          break;
        }
        this._referencefiles = files;
        this._referencefiles.forEach((a) => {
          a.fileinformation.typeId =
            RequestFilesHelper.ReferenceImageFileType.id;
        });
        break;
    }
    this.isInputValid();
  }
}
