import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Filters } from '../../../..';
import {
  FilterBarElement,
  FilterBarInputElement,
} from '../filter-bar-element.component';

type Arrayable<R> = R extends Array<infer U> ? Array<U> : Array<R>;
type Item<R> = R extends Array<infer U> ? U : never;
type SelectableItem<R> = { text: string; value: Item<R>; selected?: boolean };

export abstract class SelectInputElementOptions<R> {
  public abstract formatItem: (item: R) => {
    text: string;
    item;
  };
}

export interface ElementOptions<R> {
  formatItem: (item: Item<R>) => { text: string };
  compareItem?: (a: Item<R>, b: Item<R>) => boolean;
  selector?: Observable<Arrayable<R>>;
}

@Component({
  selector: 'rr-select-input-element',
  templateUrl: './select-input-element.component.html',
  styleUrls: ['./select-input-element.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SelectInputElementComponent<
  F extends Filters,
  K extends keyof F = keyof F,
  R extends Arrayable<F[K]> = Arrayable<F[K]>,
> implements FilterBarInputElement<R>, AfterViewInit
{
  @Input() element: FilterBarElement<R>;
  @Input() filterValueSub: BehaviorSubject<R>;
  @Output() valueChanged: EventEmitter<R> = new EventEmitter();
  @Input() options: ElementOptions<R>;

  @ViewChild('inputText') searchInput: ElementRef<HTMLInputElement>;
  searchInputValue: string = '';

  items: SelectableItem<R>[] = [];
  filtered: Item<R>[] = [];

  constructor() {}

  get filteredItems() {
    return this.searchInputValue.length
      ? this.items.filter((i) =>
          i.text.toLowerCase().includes(this.searchInputValue.toLowerCase()),
        )
      : this.items;
  }

  setValue(value) {
    this.filtered = value;

    if (!this.items || !this.filtered) return;
    this.updateSelectedItems();
  }

  updateSelectedItems() {
    console.log(
      'SelectInputElement',
      'updateSelectedItems:',
      this.filtered,
      this.items,
    );

    if (!this.filtered) return;

    this.items = this.items.map((item) => {
      return {
        ...item,
        selected: this.filtered.some(
          (v) => this.options.compareItem?.(v, item.value) ?? v === item.value,
        ),
      };
    });

    console.log(
      'SelectInputElement',
      'selectedItems:',
      this.items.filter((i) => i.selected),
    );

    this.sortItems();
    this.updateBadge();
  }

  ngAfterViewInit(): void {
    // get our currently selected options from the filter...
    this.filterValueSub.subscribe((selected) => {
      if (!selected) {
        this.setValue([]);
      } else {
        this.setValue(selected);
      }
    });
  }

  updateTagFilter() {
    this.searchInputValue = this.searchInput.nativeElement.value;
  }

  updateBadge() {
    this.element.badge = this.items
      .map((r) => r.selected)
      .filter((r) => r).length;
  }

  public label: string;

  init(element: FilterBarElement<F, K>) {
    // console.log('SelectInputElementComponent', 'init', element);
    this.element = element as any;

    if ('label' in this.element.config) {
      this.label = this.element.config.label;
    }

    // get all our options that can be selected...
    if (this.options.selector) {
      this.options.selector?.subscribe((newSelectableOptions) => {
        this.items = [];
        newSelectableOptions.forEach((value) =>
          this.items.push({
            ...this.options.formatItem(value),
            value,
          }),
        );

        this.updateSelectedItems();
      });
    }
  }

  sortItems() {
    if (!this.items) return;

    this.items = this.items.sort((a, b) => {
      if (a.selected && !b.selected) return -1;
      if (!a.selected && b.selected) return 1;

      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;

      return 0;
    });
  }

  getValue() {
    return (this.items ?? [])
      .filter((item) => item.selected)
      .map((item) => item.value) as R; // shouldn't need this hack
  }

  toggleResultCheck(item: SelectableItem<Item<R>>, event: Event | MouseEvent) {
    event.stopPropagation();

    this.items = this.items.map((i) => {
      if (
        this.options.compareItem &&
        !this.options.compareItem(i.value, item.value)
      )
        return i;
      if (i.value !== item.value) return i;

      return {
        ...i,
        selected: i.selected !== undefined ? !i.selected : true,
      };
    });

    // this.sortItems();
    this.valueChanged.emit(this.getValue());
  }
}
