import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[rrDragAndDrop]',
  standalone: true,
})
export class DragAndDropDirective {
  @Output() filesDropped = new EventEmitter<any>();
  @HostBinding('style.background') private background = '#ffffff';
  @HostListener('dragover', ['$event']) dragOver($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    this.background = 'beige';
  }

  @HostListener('drop', ['$event']) drop($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    this.filesDropped.emit($event.dataTransfer.files);
    this.background = '#ffffff';
  }

  @HostListener('dragleave', ['$event']) dragLeave($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    this.background = '#ffffff';
  }
}
