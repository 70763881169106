import { createAction, props } from '@ngrx/store';
import {
  RRIPagination,
  RRISort,
  SelectedFilters,
} from 'projects/web-ui-component-library/src';
import { IRequest, IRequestsAggregate } from '../../data/api';
import {
  CloneRequestInputData,
  CreateRequestInputData,
  UpdateRequestInputData,
} from '../components/create-request-modal/create-request-input.interface';
import { RequestFilters } from '../components/request-list-action-bar/request-list-action-bar.component';

export const paginationChange = createAction(
  '[Request Overview Pagination] Change Pagination',
  props<{ pagination: Partial<RRIPagination> }>(),
);

export const changePageAction = createAction(
  '[Request Overview Pagination] Change Page',
  props<{ page: number }>(),
);

export const changePageSizeAction = createAction(
  '[Request Overview Pagination] Change Page Size',
  props<{ pageSize: number }>(),
);

export const sortChange = createAction(
  '[Request Overview Table] Change Sort',
  props<{ sort: RRISort<IRequest> }>(),
);

export const clearFilters = createAction(
  '[Request Overview Filters] Clear Filters',
);

export const filtersChange = createAction(
  '[Request Overview Filters] Change Filters',
  props<{ filters: SelectedFilters<RequestFilters> }>(),
);

export const filtersLoad = createAction(
  '[Request Overview Filters] Loading Filters',
  props<{ filters: SelectedFilters<RequestFilters> }>(),
);

export const retrievedRequests = createAction(
  '[Request Overview API] Retrieved Requests Success',
  props<{ recordCount: number; pageCount: number; requests: IRequest[] }>(),
);

export const retrievedRequestsWithError = createAction(
  '[Request Overview API] Retrieved Requests Error',
);

export const retrievedRequestsAggregate = createAction(
  '[Request Overview API] Retrieved Requests Aggregate Success',
  props<{ requestAggregates: IRequestsAggregate }>(),
);

export const retrievedRequestsAggregateWithError = createAction(
  '[Request Overview API] Retrieved Requests Aggregate Error',
);

export const changeCustomerAction = createAction(
  '[Request Overview Page] Change Customer',
  props<{
    customer: string;
  }>(),
);

export const loadRequests = createAction(
  '[Request Overview Page] Load Requests',
  props<{
    sort: RRISort<any>;
    pagination: RRIPagination;
    filters: SelectedFilters<RequestFilters>;
  }>(),
);

export const loadRequestsAggregate = createAction(
  '[Request Overview Page] Load Requests Aggregate',
  props<{ filters: SelectedFilters<RequestFilters> }>(),
);

export const storeFiltersAction = createAction(
  '[Request Overview Filters] Store Filters',
  props<{ filters: SelectedFilters<RequestFilters> }>(),
);

export const storeFiltersCompleteAction = createAction(
  '[Request Overview Filters] Store Filters complete',
);

export const loadFiltersAction = createAction(
  '[Request Overview Filters] Load Filters',
);

export const loadFiltersCompleteAction = createAction(
  '[Request Overview Filters] Load Filters complete',
  props<{ filters: SelectedFilters<RequestFilters> }>(),
);

export const loadTagsAction = createAction(
  '[Request Overview Filters] Load tags',
  props<{ customerId?: string }>(),
);

export const showLoaderAction = createAction('[Request Overview] Show loader');

export const hideLoaderAction = createAction('[Request Overview ] Hide loader');

export const loadTagsCompleteAction = createAction(
  '[Request Overview Filters] Load tags complete',
  props<{ tags: { uuid: string; name: string }[] }>(),
);

export const createNewRequestAction = createAction(
  '[Request Overview Page] Create new request',
  props<CreateRequestInputData>(),
);

export const cloneRequestAction = createAction(
  '[Request Overview Page] Clone request',
  props<CloneRequestInputData>(),
);

export const updateDraftRequestAction = createAction(
  '[Request Overview Page] Edit a draft request',
  props<UpdateRequestInputData>(),
);

export const clearRequestModalAction = createAction(
  '[Request Overview Page] Clear request modal',
);

export const reloadRequestsAction = createAction(
  '[Request Overview Page] Reload Requests',
);
