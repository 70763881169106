import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AccessoryDTO, GarmentDTO } from '../../../data/api';
import {
  GarmentThumbnailPipe,
  ProductTypePipe,
} from '../../../utils/CommonPipes';

@Component({
  selector: 'app-configuration-display',
  templateUrl: './configuration-display.component.html',
  styleUrls: ['./configuration-display.component.scss'],
  standalone: true,
  imports: [CommonModule, ProductTypePipe, GarmentThumbnailPipe],
})
export class ConfigurationDisplayComponent implements OnInit {
  @Input() public garment: GarmentDTO | AccessoryDTO;

  public garmentToDisplay: any[];

  constructor() {}

  ngOnInit(): void {}
}
