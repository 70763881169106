import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageContextService } from 'projects/web-ui-component-library/src';
import {
  PRODUCT_TYPE,
  REQUEST_STATE_CMS,
} from 'projects/content-service-cms/src/app/data';

export interface RequestRecord {
  id: string;
  name: string;
  sku: string;
  createdAt: number;
  state: REQUEST_STATE_CMS;
  productType: PRODUCT_TYPE;
  tags: { colour: string; name: string; value: string | null }[];
  thumbnail?: string;
  linked?: RequestRecord[];
}
import { ILogger } from '../../../logging';

@Component({
  selector: 'rr-patient',
  templateUrl: './patient.page.component.html',
  styleUrls: ['./patient.page.component.scss'],
})
export class PatientPageComponent implements OnInit, OnDestroy {
  constructor(
    private _pageContextService: PageContextService,
    private logger: ILogger,
  ) {
    this.logger.info(`Loading patients page`);
    this._pageContextService.setPageParent().setPageTitle('');
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
