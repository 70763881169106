export enum REQUEST_STATE_INTERNAL_ID {
  READY_FOR_PROCESSING = 0,
  FETCHED = 1,
  PROCESSED = 2,
  REPORTED = 3,
  APPROVED = 4,
  FETCHED_FOR_REVIEW = 5,
  REJECTED_BY_CUSTOMER = 7,
  REJECTED_BY_SERVICE = 8,
  LOCKED = 9,
  IN_PROGRESS = 10,
  NOT_READY_FOR_PROCESSING = 11,
  REJECTED_BY_REVIEWER = 12,
  APPROVED_BY_CUSTOMER = 13,
  CANCELED_BY_CUSTOMER = 14,
  RESET_EVENT = 15,
  EXPORTING = 16,
}
