import { BehaviorSubject, Observable } from 'rxjs';
import { GarmentDTO } from '../../../data/api';
import {
  DressableGarment,
  IGarmentSelectionService,
} from './i-garment-selection.service';

export class GarmentSelectionService extends IGarmentSelectionService {
  public tuckedInGarment$: Observable<DressableGarment>;
  private _selectedGarments$: BehaviorSubject<GarmentDTO[]> =
    new BehaviorSubject<GarmentDTO[]>([]);
  private _dressed$: BehaviorSubject<GarmentDTO[]> = new BehaviorSubject<
    GarmentDTO[]
  >([]);

  constructor() {
    super();
  }

  public selectedGarments$: Observable<GarmentDTO[]> =
    this._selectedGarments$.asObservable();

  public dressed$: Observable<GarmentDTO[]> = this._dressed$.asObservable();

  public currentSelectedGarments = (): GarmentDTO[] => {
    return this._selectedGarments$.value;
  };

  public currentDressed = (): GarmentDTO[] => {
    return this._dressed$.value;
  };

  public setSelectedGarments = (garments: GarmentDTO[]) => {
    this._selectedGarments$.next([...garments]);
  };

  public setDressedGarments = (garments: GarmentDTO[]) => {
    this._dressed$.next([...garments]);
  };
  public setTuckedInGarment: (garment: DressableGarment) => {};

  public setGarmentStylingOptions: (
    garment: GarmentDTO,
    options: any,
  ) => {
    // TODO: This whole class need removing and then generic store mechanism used instead
  };
}
