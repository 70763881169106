<h3>Create a new API key</h3>
<alert
  class="alert-box"
  type="success"
  [dismissible]="true"
  *ngIf="(store.token$ | async) !== null"
>
  Your new API key: {{ store.token$ | async }}
</alert>
<app-input-text
  id="apiKeyName"
  class="mb-5"
  placeholder="Key name"
  [value]="keyName"
  (valueChange)="updateKeyName($event.value)"
>
</app-input-text>
<rr-button [action]="addKey()" class="btn btn-primary">Add Key</rr-button>

<hr />

<h3>Available api keys</h3>
<div class="refresh-info-text">
  <span
    class="margin-right"
    [tooltip]="updated_date | async | prettyProductDate"
  >
    {{ updated_text | async }}
  </span>
  <span class="oi oi-reload" (click)="store.refreshKeys()"></span>
</div>
<rr-table
  [configuration]="requestTableConfiguration"
  [datasource]="requestDatasource"
  [showSkeletonLoader]="store.isLoading$"
>
  <div
    class="empty-table-state d-flex flex-column justify-content-center align-items-center"
    emptyState
  >
    <span class="empty-first-line">We couldn’t find any api keys</span>
  </div>
</rr-table>
