<div class="d-flex flex-row justify-content-center m-3 p-3">
  <rr-card class="card pt-5 pb-5">
    <h2>No permission</h2>
    <span>You have no permission for this request.</span>
    <span
      ><a routerLink="/requests">Here</a> navigate to to overview page.</span
    >
    <hr />
    If you have any questions, please feel free to reach out to
    <a href="mailto:support@reactivereality.com" target="_blank"
      >support&#64;reactivereality.com</a
    >
  </rr-card>
</div>
