import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TableRowComponent } from '../public-api';

@Directive({
  selector: '[rrTableRow]',
  // template: '<ng-container #container></ng-container>',
})
export class RRTableRowDirective<R> implements AfterViewInit {
  @Input() record: R;
  @Input() component: Type<TableRowComponent<R>>;
  @Output() loadingDone: EventEmitter<void> = new EventEmitter();
  @ViewChild('container', { read: ViewContainerRef })
  container: ViewContainerRef;
  private _loadingDone$: Subject<void>;
  private _onDestroy$: Subject<void>;
  constructor(
    private hostContainer: ViewContainerRef,
    private el: ElementRef<HTMLElement>,
  ) {}

  ngAfterViewInit() {
    if (!this.component) return;
    this._onDestroy$ = new Subject();
    this.el.nativeElement;

    const component = this.component;
    this._loadingDone$ = new Subject<void>();
    const ref =
      this.hostContainer.createComponent<TableRowComponent<R>>(component);
    ref.instance.record = this.record;
    ref.instance.loaded = this._loadingDone$;
    this._loadingDone$.pipe(takeUntil(this._onDestroy$)).subscribe((done) => {
      this.loadingDone.emit();
    });
  }
  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
