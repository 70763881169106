import { Component, Input } from '@angular/core';
import { RRIStackedBarData } from '../../public-api';

@Component({
  selector: 'rr-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss'],
})
export class StackedBarChartComponent {
  @Input() dataset: RRIStackedBarData[];
  @Input() barMinWidth: number;
  @Input() barWidthMultiplier: number;

  constructor() {}
}
