import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IAuthService } from '../../auth';
import { IUser, IUserService } from '../../data';
import { ModalService } from '../../modal/modal.service';
import { CreateRequestModalStore } from '../../request-overview/components/create-request-modal/create-request-modal.state';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public currentUser$: Observable<IUser>;
  public loggedIn$: Observable<boolean>;
  public showMenu: boolean = false;

  @ViewChild('profileModal') profileModal: TemplateRef<any>;

  constructor(
    public auth: IAuthService,
    public userService: IUserService,
    private createRequestModalStore: CreateRequestModalStore,
    public modalService: ModalService,
  ) {
    this.currentUser$ = userService.me.pipe(
      map((me) => {
        const { firstName, lastName, name } = me;
        const displayName = [firstName, lastName].join(' ').trim();

        me.name =
          name && name.trim().length > 0
            ? name
            : displayName.length > 0
            ? displayName
            : 'My Profile';
        return me;
      }),
    );
    this.loggedIn$ = auth.loggedUser$.pipe(
      map((u) => u !== undefined && u !== null),
    );
  }

  ngOnInit(): void {}

  logout(): void {
    this.auth.logOutClicked();
  }
  createNewRequest(): void {
    this.createRequestModalStore.startRequestAction();
    this.showMenu = false;
  }

  navigateToProfile(): void {
    // window.location.href = 'https://accounts.reactivereality.com/ui/profile';
    this.modalService.show({
      template: this.profileModal,
      title: 'Manage Profile',
      showClose: false,
    });
  }
}
