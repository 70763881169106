import { User } from 'oidc-client';
import { Observable } from 'rxjs';

export abstract class IAuthService {
  abstract loginChanged$: Observable<boolean>;
  abstract loggedUser$: Observable<User>;
  abstract logOutClicked$: Observable<void>;

  abstract getToken(): string | undefined;
  abstract getToken$(): Observable<string | undefined>;
  abstract isAuthenticated(): Promise<boolean>;
  abstract login(): Promise<void>;
  abstract logout(): Promise<void>;
  abstract logOutClicked(): void;
  abstract signinRedirectCallback(): Promise<void>;
  abstract finishLogout(): Promise<void>;

  // User Features
  abstract canApprove$: Observable<boolean>;
  abstract canReject$: Observable<boolean>;
  abstract isCustomer$: Observable<boolean>;
  abstract isEmployee$: Observable<boolean>;
  abstract isCollectionCreator$: Observable<boolean>;
}
