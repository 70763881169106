import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { GarmentDTO } from 'projects/content-service-cms/src/app/data/api';
import { CmsFormlyModule } from '../../../formly/formly.module';
import {
  DressableGarment,
  IGarmentSelectionService,
} from '../../services/i-garment-selection.service';
import { mapStylingObject, mapStylingObjectToUI } from '../jointMap';
import { ModalService } from 'projects/content-service-cms/src/app/modal/modal.service';

@Component({
  selector: 'app-styling-options',
  templateUrl: './styling-options.component.html',
  styleUrls: ['./styling-options.component.scss'],
  standalone: true,
  imports: [CmsFormlyModule, CommonModule, FormsModule, ReactiveFormsModule],
  providers: [CmsFormlyModule],
})
export class StylingOptionsComponent implements AfterViewInit {
  fields: FormlyFieldConfig[];
  form: FormGroup;
  options: FormlyFormOptions;
  stylingOptions: any = {};

  @Input() garment: GarmentDTO;
  @Input() schema: object = {};

  constructor(
    private formlyJsonschema: FormlyJsonschema,
    private garmentSelectionService: IGarmentSelectionService,
    protected modalService: ModalService,
  ) {}

  ngAfterViewInit(): void {
    this.show();
  }

  closeModal() {
    this.modalService.close();
  }

  setOptions() {
    // emit the options...
    // this.stylingOptionsChanged.emit(this.stylingOptions);
    this.garmentSelectionService.setGarmentStylingOptions(
      this.garment,
      mapStylingObject(this.stylingOptions),
    );

    // close the modal
    this.closeModal();
  }

  private isGarment(garment: DressableGarment): garment is DressableGarment {
    return garment !== undefined;
  }

  show() {
    const [updated] = this.garmentSelectionService
      .currentSelectedGarments()
      .filter((garment) => garment.id === this.garment.id)
      .filter(this.isGarment);

    if (!updated) return;

    const stylingOptions = JSON.parse(
      JSON.stringify(updated.stylingOptions ?? {}),
    );
    this.stylingOptions = mapStylingObjectToUI(
      Object.assign({}, stylingOptions),
    );

    this.form = new FormGroup({});
    this.options = {};

    // const validSchema = JSON.parse(VALID_SCHEMA)
    const config = this.formlyJsonschema.toFieldConfig(this.schema);
    this.fields = [config];
  }
}
