<div>
  <div class="menu-container">
    <div
      class="menu-item d-flex flex-row justify-content-between"
      (click)="showSelection = !showSelection"
    >
      <div class="text-column d-flex flex-column">
        <span class="main-text">{{ (currentCustomer$ | async)?.name }}</span>
        <span class="info-text">Change customer</span>
      </div>
      <span class="rr-arrow-right"></span>
    </div>
    <div *ngIf="showSelection" class="selection-menu">
      <div
        class="menu-item d-flex flex-row justify-content-between"
        *ngFor="let customer of customersToDisplay$ | async"
        (click)="switchCustomer(customer)"
      >
        <div class="text-column d-flex flex-column">
          <span
            class="main-text text-truncate"
            style="max-width: 8vw; display: inline-block"
            >{{ customer.name }}</span
          >
          <span
            class="info-text text-truncate"
            style="max-width: 8vw; display: inline-block"
            *ngIf="(currentCustomer$ | async).id === customer.id"
            >Current customer</span
          >
        </div>
        <span
          *ngIf="(currentCustomer$ | async).id === customer.id"
          class="rr-check-black"
        ></span>
      </div>
    </div>
  </div>
</div>
<div
  class="backdrop-selection"
  *ngIf="this.showSelection"
  (click)="showSelection = false"
></div>
