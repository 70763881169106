import { NgModule } from '@angular/core';
import { RRCalendarModule } from '../calendar/calendar.module';
import { RRLayoutModule } from '../layout/layout.module';
import { RRPipesModule } from '../pipes/pipes.module';
import { RRSharedModule } from '../shared-module/shared.module';
import { SelectInputElementComponent } from './select-input-element/select-input-element.component';
import { TextInputElementComponent } from './text-input-element/text-input-element.component';
import { DateInputElementComponent } from './date-input-element/date-input-element.component';

@NgModule({
  declarations: [
    SelectInputElementComponent,
    TextInputElementComponent,
    DateInputElementComponent,
  ],
  imports: [RRSharedModule, RRPipesModule, RRLayoutModule, RRCalendarModule],
  exports: [
    SelectInputElementComponent,
    TextInputElementComponent,
    DateInputElementComponent,
  ],
})
export class RRSelectFilterModule {}
