import { NgModule } from '@angular/core';
import { RRSharedModule } from '../shared-module/shared.module';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard.directive';

@NgModule({
  declarations: [CopyToClipboardDirective],
  imports: [RRSharedModule],
  exports: [CopyToClipboardDirective],
})
export class RRCopyToClipboardModule {}
