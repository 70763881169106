<span *ngIf="AdvancedControls" class="info-text mr-auto"
  >Showing {{ (currentItems$ | async)[0] }} -
  {{ (currentItems$ | async)[1] }} of
  {{ paginationDataProvider.totalRecords$ | async }}</span
>

<button
  id="prev"
  (click)="showPrevPage()"
  [disabled]="currentPage === 1"
  [hidden]="pageCount === 0"
>
  <i class="rr-page-navigation"></i>
</button>

<button
  class="pager"
  (click)="showPage(1)"
  [hidden]="currentPage <= WindowSize"
>
  1
</button>

<button
  (click)="showPage(prevPageBlockAt(currentPage, WindowSize))"
  class="window"
  [ngClass]="currentPage > WindowSize ? 'visible' : ''"
>
  ...
</button>

<button
  *ngFor="
    let page of pageCount.toCollection().chunk(currentPage - 1, WindowSize)
  "
  class="pager"
  [disabled]="currentPage === page"
  (click)="showPage(page)"
>
  {{ page }}
</button>

<button
  (click)="showPage(nextPageBlockAt(currentPage, WindowSize))"
  class="window"
  [ngClass]="
    nextPageBlockAt(currentPage, WindowSize) <= pageCount ? 'visible' : ''
  "
>
  ...
</button>

<button
  class="pager"
  [disabled]="currentPage === pageCount"
  (click)="showPage(pageCount)"
  [hidden]="nextPageBlockAt(currentPage, WindowSize) > pageCount"
>
  {{ pageCount }}
</button>

<button
  id="next"
  (click)="showNextPage()"
  [disabled]="currentPage === pageCount"
  [hidden]="pageCount === 0"
>
  <i class="rr-page-navigation"></i>
</button>

<span *ngIf="AdvancedControls" class="info-text ml-auto">
  {{ paginationDataProvider.pageSize$ | async }} items per page</span
>
