import { createReducer, on } from '@ngrx/store';
import {
  clearFilters,
  filtersChange,
  loadCollectionsError,
  loadCollectionsSuccess,
  sortChange,
} from './collections-overview.actions';
import { ICollectionsOverviewState } from './collections-overview.state';

const initialState: ICollectionsOverviewState = {
  collections: [],
  recordCount: 0,
  showLoader: true,
  filters: {},
  pagination: undefined,
  tags: [],
  sort: undefined,
  error: '',
};

const collectionsChangedReducer = (
  state: ICollectionsOverviewState,
  { collections, recordCount },
): ICollectionsOverviewState => ({
  ...state,
  collections,
  recordCount,
});

const filtersClearedReducer = (
  state: ICollectionsOverviewState,
): ICollectionsOverviewState => ({
  ...state,
  filters: {},
});

const filtersChangedReducer = (
  state: ICollectionsOverviewState,
  { filters },
): ICollectionsOverviewState => ({
  ...state,
  filters,
});

const sortChangedReducer = (
  state: ICollectionsOverviewState,
  { sort },
): ICollectionsOverviewState => ({ ...state, sort, showLoader: true });

const collectionLoadErrorReducer = (
  state: ICollectionsOverviewState,
): ICollectionsOverviewState => ({
  ...state,
  error: 'Error while fetching collections.',
  showLoader: false,
});

export const collectionOverviewReducer =
  createReducer<ICollectionsOverviewState>(
    initialState,
    on(loadCollectionsSuccess, collectionsChangedReducer),
    on(clearFilters, filtersClearedReducer),
    on(filtersChange, filtersChangedReducer),
    on(sortChange, sortChangedReducer),
    on(loadCollectionsError, collectionLoadErrorReducer),
  );
