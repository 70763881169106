import { Component, Input, OnInit } from '@angular/core';
import { ProfileStore } from '../../profile-state';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnInit {
  @Input() profileStore: ProfileStore;

  constructor() {}

  ngOnInit(): void {}
}
