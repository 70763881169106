import { AssetType, IAsset } from '@reactivereality/pictofit-web-sdk';

import { HttpClient } from '@angular/common/http';
import { ILogger } from '../../../logging/api';
import { GarmentAssetFiles } from '../api';

abstract class GarmentAsset implements IAsset {
  constructor(
    protected asset: GarmentAssetFiles,
    protected httpClient: HttpClient,
    protected logger: ILogger,
  ) {}

  preloadComponents(): Promise<void> {
    this.logger.warn('[GarmentAsset] preloadComponents not implemented');
    return;
  }
  preloadComponent(key: string): Promise<void> {
    this.logger.warn('[GarmentAsset] preloadComponent not implemented');
    return;
  }

  private findFile(key: string) {
    this.logger.debug(`Asset searching for file: ${key}`);
    return this.asset.find((file) => file.filename === key);
  }

  /**
   * Get a component of this asset associated with the specified key.
   * Returns a blob incase the component is available, otherwise it will throw an error.
   * This is the entrypoint from which components are requested for local purposes. (aka. when no compute server is involved.)
   * @param key The key that the component is associated with.
   * @returns A promise that resolves to the components blob.
   */
  public getComponent = async (key: string): Promise<Blob> => {
    const location = await this.getComponentLocation(key);

    if (location == null) {
      throw new Error(`Key '${key}' is not available in WebshopAsset`);
    }

    const response = await this.httpClient
      .get(location, { observe: 'response', responseType: 'blob' })
      .toPromise();

    if (response.status !== 200) {
      const responseText =
        response.body !== null
          ? await response.body.text()
          : 'Unkown response text';
      throw new Error(
        `Failed to fetch key '${key}' for WebshopAsset ${responseText}`,
      );
    }

    if (response.body === null) {
      throw new Error(
        `Key '${key}' for WebshopAsset resulted in null blob content`,
      );
    }

    return response.body;
  };

  /**
   * Get the location of a component of this asset associated with the specified key.
   * Will return null incase an location could not be resolved. This could sometimes be the case when a component is only available locally but has no public location.
   * @param key
   * @returns The location of the component or null. Does not mean that the component behind the url is available.
   */
  public getComponentLocation = async (key: string): Promise<string | null> => {
    const fileInQuestion = this.findFile(key);

    if (!fileInQuestion) {
      return null;
    }

    return fileInQuestion.url;
  };

  /**
   * Get all keys of this asset.
   */
  public getKeys = async (): Promise<string[]> => {
    return this.asset.map((f) => f.filename);
  };

  abstract getType(): Promise<AssetType>;
}

export class Garment2DAsset extends GarmentAsset {
  constructor(
    protected asset: GarmentAssetFiles,
    protected httpClient: HttpClient,
    logger: ILogger,
  ) {
    super(asset, httpClient, logger);
  }

  /**
   * The type of an asset.
   */
  public getType = async (): Promise<AssetType> => {
    return AssetType.GARMENT_2D;
  };
}

export class Garment3DAsset extends GarmentAsset {
  constructor(
    protected asset: GarmentAssetFiles,
    protected httpClient: HttpClient,
    logger: ILogger,
  ) {
    super(asset, httpClient, logger);
  }

  /**
   * The type of an asset.
   */
  public getType = async (): Promise<AssetType> => {
    return AssetType.GARMENT_3D;
  };
}
