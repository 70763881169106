import {
  ConfigurationHasTagKeyDTO,
  CustomerDTO,
  TagKeyDTO,
} from '@reactivereality/cs-api-sdk';
import {
  IProduct,
  IProductService,
  IRequestDetail,
  IRequestService,
  PRODUCT_STATE,
  PRODUCT_TYPE,
} from 'projects/content-service-cms/src/app/data';
import {
  IRequestUpdate,
  ITagService,
} from 'projects/content-service-cms/src/app/data/api';

import { v4 as uuid } from 'uuid';
import { CreateRequestCommonData } from '../create-request-input.interface';

export class CreateRequestBaseService {
  constructor(
    protected _requestService: IRequestService,
    protected _productService: IProductService,
    protected _tagService: ITagService,
  ) {}

  protected updateTags = async (
    req: IRequestDetail,
    tags: Array<TagKeyDTO>,
  ) => {
    await Promise.all(
      req.tags?.map((t: ConfigurationHasTagKeyDTO) => {
        if (!tags.some((v) => v.id === t.tagKeyId)) {
          return this._tagService.deleteTagFromConfiguration(
            t.tagKeyId,
            req.id,
          );
        }
      }),
    );
    await Promise.all(
      tags.map((t) => {
        return this._tagService.addTagToConfiguration(t.id, req.id);
      }),
    );
  };

  protected createProduct = async (
    customer: CustomerDTO,
    requestCommonData: CreateRequestCommonData,
    productType: PRODUCT_TYPE,
  ): Promise<IProduct> => {
    const productId = uuid();
    const product = {
      createdAt: Date.now(),
      customerId: customer.id,
      customerName: customer.name,
      dueAt: null,
      id: productId,
      metadata: {},
      modifiedAt: Date.now(),
      name: requestCommonData.name,
      sku: requestCommonData.sku ? requestCommonData.sku : uuid(),
      stateId: PRODUCT_STATE.READY_FOR_PROCESSING,
      typeId: productType,
    };
    return await this._productService.updateProduct(product);
  };

  protected getRequestUpdate = (
    requestCommonData: CreateRequestCommonData,
    productId: string,
  ): IRequestUpdate => {
    return {
      id: uuid(),
      productId: productId,
      name: requestCommonData.name,
      sku: requestCommonData.sku,
      dueAt: requestCommonData.dueDate
        ? requestCommonData.dueDate?.getTime() / 1000
        : null,
      priority: requestCommonData.priority
        ? requestCommonData.priority.id
        : null,
      metadata: {},
      annotations: null,
    };
  };

  protected updateRequest = async (
    request: IRequestUpdate,
  ): Promise<IRequestDetail> => {
    return await this._requestService.update(request);
  };
}
