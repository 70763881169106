import { NgModule } from '@angular/core';
import { RRBootstrapModule } from '../bootstrap/bootstrap.module';
import { InfoIconComponent } from './info-icon/info-icon.component';
import { QuestionmarkCircelIconComponent } from './questionmark-circel-icon/questionmark-circel-icon.component';
import { CheckCircleFillComponent } from './check-circle-fill/check-circle-fill.component';
import { GenderFemaleComponent } from './gender-female/gender-female.component';
import { GenderMaleComponent } from './gender-male/gender-male.component';
import { RRSharedModule } from '../shared-module/shared.module';

@NgModule({
  declarations: [
    InfoIconComponent,
    QuestionmarkCircelIconComponent,
    CheckCircleFillComponent,
    GenderFemaleComponent,
    GenderMaleComponent,
  ],
  imports: [RRSharedModule, RRBootstrapModule],
  exports: [
    InfoIconComponent,
    QuestionmarkCircelIconComponent,
    CheckCircleFillComponent,
    GenderFemaleComponent,
    GenderMaleComponent,
  ],
})
export class RRIconsModule {}
