import { Injectable, InjectionToken, TemplateRef } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { ILogger } from '../logging';

export const DEPTH_TOKEN = new InjectionToken<number>('Depth of service', {
  factory: () => 0,
});

export interface ModalProps {
  template: TemplateRef<any>;
  headerTemplate?: TemplateRef<any>;
  title?: string;
  confirmDiscard?: boolean;
  showClose?: boolean;
  dismissable?: boolean;
  onClose?: () => any;
}

export interface IModalService {
  show(modal: ModalProps): void;
  close(): void;
}

class ModalStore extends ComponentStore<{
  modals: ModalProps[];
}> {
  constructor() {
    super({
      modals: [],
    });
  }

  public switch = this.updater((s, newModal: ModalProps) => {
    if (s.modals.length <= 0)
      return {
        ...s,
        modals: [newModal],
      };

    const outgoing = s.modals.slice(-1)[0];
    if (outgoing.onClose) outgoing.onClose();

    return {
      ...s,
      modals: [...s.modals.slice(0, -1), newModal],
    };
  });
  public show = this.updater((s, newModal: ModalProps) => ({
    ...s,
    modals: [...s.modals, newModal],
  }));

  public close = this.updater((s) => {
    if (s.modals.length <= 0) return s;

    const outgoing = s.modals.slice(-1)[0];
    const newStack = s.modals.slice(0, -1);
    if (outgoing.onClose) outgoing.onClose();

    return {
      ...s,
      modals: newStack,
    };
  });
}

@Injectable({
  providedIn: 'root',
})
export class ModalService implements IModalService {
  private modalStore: ModalStore;

  public readonly modalStack$: Observable<ModalProps[]>;
  public readonly modalStackDepth$: Observable<number>;

  public show(modal: ModalProps) {
    return this.modalStore.show(modal);
  }

  public switch(modal: ModalProps) {
    return this.modalStore.switch(modal);
  }
  public close() {
    return this.modalStore.close();
  }

  constructor(private logger: ILogger) {
    this.logger.debug('[ModalService]', 'New ModalService');
    this.modalStore = new ModalStore();

    this.modalStack$ = this.modalStore.select((s) => s.modals);
    this.modalStackDepth$ = this.modalStore.select((s) => s.modals.length);

    // debug
    this.modalStack$.subscribe((stack) =>
      this.logger.debug('[ModalService]', 'Stack:', stack),
    );
  }
}
