import { createAction, props } from '@ngrx/store';
import {
  RRIPagination,
  RRISort,
  SelectedFilters,
} from 'projects/web-ui-component-library/src';
import { ExtendedCollection } from '../collection.interface';
import { CollectionFilters } from '../../collection-screen/components/reference-search/components/collection-search-filter-bar/collection-search-filter-bar.component';
import { CollectionRecord } from '../components/collection-list.page/collection-list.page.component';

const prefix = '[Collection Overview Page]';

export const loadCollections = createAction(
  `${prefix} Load Collections`,
  props<{
    sort: RRISort<any>;
    pagination: RRIPagination;
  }>(),
);

export const loadCollectionsSuccess = createAction(
  `${prefix} Load Collections Success`,
  props<{
    collections: ExtendedCollection[];
    recordCount: number;
  }>(),
);

export const loadCollectionsError = createAction(
  `${prefix} Load Collections Error`,
  props<{
    error: string;
  }>(),
);

// export const createOrUpdateCollectionAction = createAction(
//   `${prefix} Create new collection`,
// );

export const clearFilters = createAction(
  '[Collection Overview Filters] Clear Filters',
);

export const filtersChange = createAction(
  '[Collection Overview Filters] Change Filters',
  props<{ filters: SelectedFilters<CollectionFilters> }>(),
);

export const sortChange = createAction(
  '[Collection Overview Table] Change Sort',
  props<{ sort: RRISort<CollectionRecord> }>(),
);
