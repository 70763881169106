<div class="d-flex flex-row row entry align-items-center">
  <div class="p2 ml-4 mt-3 mb-3" *ngIf="imageDataUrl">
    <img src="{{ imageDataUrl }}" class="thumbnail" />
  </div>
  <div class="d-flex flex-column mt-3 mb-3" *ngIf="state === 0 || state === 2">
    <div class="p2 ml-3 imagename">{{ file.fileinformation.fileName }}</div>
    <div class="p2 ml-3 imageinfo">
      {{ file.fileinformation.mimeType }} ({{
        calculateFileSize(file.file.size)
      }})
    </div>
  </div>
  <div class="d-flex flex-column" *ngIf="state === 1">
    <div class="p2 ml-3 imageinfo">Uploading...</div>
  </div>
  <div class="p2 ml-auto mr-2 delete">
    <span class="rr-delete" (click)="deleteEntry($event)"></span>
  </div>
</div>
