import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  RRIBaseModel,
  RRIBaseService,
  RRIFilterOutput,
  RRIQueryParams,
  RRISort,
  RRITableData,
} from 'projects/web-ui-component-library/src';
import { Observable, Subscriber, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiTable } from 'projects/content-service-cms/src/app/core/models/api/table.model';
import {
  IApiSdkService,
  ICsApiSdkConfiguration,
} from 'projects/content-service-cms/src/app/data';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { NgDiscoveryService } from './ng-discovery.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export abstract class DataService<T extends RRIBaseModel, U>
  implements RRIBaseService<T, U>
{
  public BASE_API_URL: string | undefined;
  protected standardUrl: string | undefined;

  constructor(
    public endpointName: string,
    protected _http: HttpClient,
    protected _logger: ILogger,
    protected _apiSdkService: IApiSdkService,
    private discoveryService: NgDiscoveryService,
  ) {
    this._logger.info(`Creating service ${this.constructor['name']}`);

    this.BASE_API_URL =
      this.discoveryService.config.apiConfiguration.baseApiUrl;
    this.standardUrl = `${this.BASE_API_URL}/${endpointName ?? ''}`;
  }

  public getListURL(): string | undefined {
    return this.standardUrl ? `${this.standardUrl}` : undefined;
  }

  public getDeleteURL(entry: T): string | undefined {
    return this.standardUrl ? `${this.standardUrl}/${entry.id}` : undefined;
  }

  public getUpdateURL(entry: T): string | undefined {
    return this.standardUrl ? `${this.standardUrl}/${entry.id}` : undefined;
  }

  public isDeleteable(): boolean {
    return true;
  }

  public isUpdateable(): boolean {
    return true;
  }

  public getData(params?: RRIQueryParams<T, U>): Observable<RRITableData<T>> {
    const url = this.getListURL();
    if (url === undefined)
      return new Observable(this.emitErrorNoEndpointURLTable);

    let urlParams = new HttpParams();

    if (params) {
      if (params.pagination) {
        if (params.pagination.currentPage) {
          urlParams = urlParams.set(
            'currentPage',
            params.pagination.currentPage.toString(),
          );
        }

        if (params.pagination.pageSize) {
          urlParams = urlParams.set(
            'pageSize',
            params.pagination.pageSize.toString(),
          );
        }
      }

      if (params.sort) {
        urlParams = urlParams.append('sort', this.formatSortBy(params.sort));
      }

      if (params.filters) {
        urlParams = urlParams.append(
          'filters',
          this.formatFilters(params.filters),
        );
      }
      if (params.includeResult) {
        urlParams = urlParams.append('includeResults', 'true');
      }
    }

    return this.convertToTableData(
      this._http.get<ApiTable<T>>(url, { params: urlParams }),
    );
  }

  public deleteEntry(entry: T): Observable<T> {
    if (!this.isDeleteable()) {
      const errorMessage: string = 'Delete is not available.';
      return throwError(() => errorMessage);
    }
    const url = this.getDeleteURL(entry);
    if (url === undefined) return new Observable(this.emitErrorNoEndpointURL);

    return this._http.delete<T>(url, httpOptions);
  }

  public updateEntry(entry: T, httpRequestType: string = 'put'): Observable<T> {
    if (!this.isUpdateable()) {
      const errorMessage: string = 'Create/Update is not available.';
      return throwError(() => errorMessage);
    }
    const url = this.getUpdateURL(entry);
    if (url === undefined) return new Observable(this.emitErrorNoEndpointURL);

    if (httpRequestType == 'put') {
      return this._http.put<T>(url, entry, httpOptions);
    } else if (httpRequestType == 'post') {
      return this._http.post<T>(url, entry, httpOptions);
    } else {
      throw new Error('No httpRequestType defined');
    }
  }

  public deleteMultipleEntries?(): Observable<T[]> {
    return new Observable();
  }

  public convertToTableData(
    data: Observable<ApiTable<T> | T[]>,
  ): Observable<RRITableData<T>> {
    return data.pipe(
      map((res) => {
        if (!Array.isArray(res) && res.results) {
          return {
            results: res.results,
            pagination: {
              currentPage: res.currentPage,
              pageCount: res.pageSize,
              pageSize: res.pageSize,
              recordCount: res.recordCount,
              recordCountHidden: res.recordCountHidden,
            },
          };
        } else {
          return {
            results: res as T[],
            pagination: null,
          };
        }
      }),
    );
  }

  public formatFilters(filters: RRIFilterOutput<any>[]): string {
    const filterBy = {};
    filters.forEach((filter: RRIFilterOutput<any>) => {
      if (filter.filterBy.value && Array.isArray(filter.filterBy.value)) {
        const key = filter.filterBy.fieldName;
        const values = [];
        filter.filterBy.value.forEach((option: any) => {
          if (option?.value && typeof option.value === 'object') {
            values.push(option.value.value);
          } else if (option?.value) {
            values.push(option.value);
          } else if (option) {
            values.push(option);
          }
        });
        if (values && values.length) {
          filterBy[key] = values;
        }
      } else if (
        filter.filterBy.value &&
        typeof filter.filterBy.value === 'object'
      ) {
        let value = filter.filterBy.value.id;
        if (value === '') {
          return;
        }
        if (filter.filterBy.fieldName === 'stateId' && value) {
          value = JSON.parse(value);
        }
        filterBy[filter.filterBy.fieldName] = value;
      } else if (filter.filterBy.value) {
        filterBy[filter.filterBy.fieldName] = filter.filterBy.value;
      }
    });
    return JSON.stringify(filterBy);
  }

  public formatSortBy(sort: RRISort<T>): string {
    return `${sort.fieldName.toString()}${sort.desc ? '_desc' : ''}`;
  }

  public findFilterByFieldName(
    fieldName: string,
    filters: RRIFilterOutput<any>[],
  ): RRIFilterOutput<any> {
    return filters
      ? filters.find((f) => f.filterBy.fieldName === fieldName)
      : undefined;
  }

  private emitErrorNoEndpointURL(subscriber: Subscriber<T>): void {
    this._logger.error(
      `Failed to form URL, there is no discovery configuration document!`,
    );
    subscriber.error(
      `Failed to form URL, there is no discovery configuration document!`,
    );
    subscriber.complete();
  }

  private emitErrorNoEndpointURLTable(
    subscriber: Subscriber<RRITableData<T>>,
  ): void {
    this._logger.error(
      `Failed to form URL, there is no discovery configuration document!`,
    );
    subscriber.error(
      `Failed to form URL, there is no discovery configuration document!`,
    );
    subscriber.complete();
  }

  protected errorOnInitService(error: Error) {
    this._logger.error(`Cannot initate ${this.constructor['name']}`, error);
  }

  protected abstract initService(sdkConfig: ICsApiSdkConfiguration);
}
