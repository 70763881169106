<div class="row mt-5 d-flex flex-row justify-content-end">
  <button
    id="create-request-cancel-btn"
    (click)="cancelClicked()"
    type="button"
    class="btn btn-primary mr-3"
  >
    Cancel
  </button>
  <div class="my-group">
    <button
      id="create-request-submit-btn"
      type="button"
      class="btn btn-primary btn-action-text"
      (click)="createRequestClicked()"
      [disabled]="
        (this.createRequestModalStore.requestCommonDataValid$ | async) === false
          ? 'disabled'
          : null
      "
    >
      <span>Submit request </span>
    </button>
    <button
      type="button"
      class="btn btn-primary btn-action-arrow"
      (mouseover)="mouseOver = true"
      (mouseout)="mouseOver = false"
      (click)="onDropDownClicked()"
      [disabled]="
        (this.createRequestModalStore.requestCommonDataValid$ | async) === false
          ? 'disabled'
          : null
      "
    >
      <div
        class="btn-arrow-ctn d-flex justify-content-center align-items-center"
      >
        <span
          [ngClass]="{
            'rr-arrow-down-white': !mouseOver,
            'rr-arrow-down-gray': mouseOver
          }"
        ></span>
      </div>
    </button>
    <div
      class="btn-action-backdrop"
      *ngIf="showDropDown"
      (click)="clickOutside($event)"
    ></div>
    <div class="btn-dropdown d-flex flex-column" *ngIf="showDropDown" #dropdown>
      <div class="entry" (click)="createRequestClicked()">
        <span>Submit request</span>
      </div>
      <div class="entry" (click)="saveRequestAndAnotherOneClicked()">
        <span>Submit and create another request</span>
      </div>
      <div
        class="entry"
        *ngIf="createRequestModalStore.supportDraft$ | async"
        (click)="saveDraftRequestClicked()"
      >
        <span>Save draft</span>
      </div>
    </div>
  </div>
  <div
    class="btn-group"
    role="group"
    aria-label="Button group with nested dropdown"
  ></div>
</div>
