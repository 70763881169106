import { ILogger } from 'projects/content-service-cms/src/app/logging';
import * as Sentry from '@sentry/angular';

import { version } from '../../../info';
import { NgDiscoveryService } from '../services/ng-discovery.service';
import { UserDataPermissionService } from '../services/userdata-permission.service';

export function startupFactory(
  logger: ILogger,
  discoveryConfig: NgDiscoveryService,
  userData: UserDataPermissionService,
): () => Promise<void> {
  return async () => {
    const canCollectData = await new Promise<boolean>((resolve) => {
      userData.granted$.subscribe((granted) => {
        logger.info('startupFactory', 'granted$:', granted);
        resolve(granted.includes('UserExperience'));
      });
    });

    logger.info('startupFactory', 'canCollectData:', canCollectData);
    const cfg = discoveryConfig.config;

    if (cfg.appConfiguration.sentry && canCollectData) {
      const sentryCfg = cfg.appConfiguration.sentry;
      Sentry.init({
        dsn: sentryCfg.dsn,
        environment: sentryCfg.env,
        release: version,
        tracesSampleRate: sentryCfg.sample_rate ?? 1.0,
      });
    } else {
      Sentry.init({
        enabled: false,
      });
    }
  };
}
