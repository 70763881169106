import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';
import { AbstractFilterService, FilterConfig, Filters } from '../../..';

export interface FilterBarInputElement<FilterType> {
  getValue(): FilterType;
  setValue(value: FilterType);
  init(element: any): void | Promise<void>;
  valueChanged: EventEmitter<FilterType>;
  filterValueSub?: BehaviorSubject<FilterType>;
  options?: unknown;
}

export interface FilterBarElement<
  F extends Filters,
  K extends keyof F = keyof F,
> {
  badge: boolean | number;
  config: FilterConfig<F, K>;
}

@Component({
  selector: 'rr-filter-bar-element',
  templateUrl: './filter-bar-element.component.html',
  styleUrls: ['./filter-bar-element.component.scss'],
})
export class FilterBarElementComponent<
  F extends Filters,
  K extends keyof F = keyof F,
  R extends F[K] = F[K],
> implements AfterViewInit, FilterBarElement<F, K>
{
  @Input() filterService: AbstractFilterService<F>;
  @Input() config: FilterConfig<F, K>;
  @Input() badge: boolean | number = false;

  public icon: string;
  public label: string;

  @ViewChild('inputCard', { read: ViewContainerRef, static: false })
  filterInputCard: ViewContainerRef;

  @Input() state: 'expand' | 'collapse' = 'collapse';
  self: HTMLElement;

  // rename this mess :(
  @Output() valueChanged: EventEmitter<R> = new EventEmitter();
  @Input() changeValue: Observable<R> = new EventEmitter();

  get<T>(type: T): string {
    return typeof type;
  }

  ngAfterViewInit(): void {
    if ('label' in this.config) {
      this.icon = this.config.icon;
      this.label = this.config.label;
      if (!this.config.inputComponent) return;

      this.filterInputCard.clear();

      const ref = this.filterInputCard.createComponent<
        FilterBarInputElement<R>
      >(
        this.config.inputComponent && this.config.inputComponent instanceof Type
          ? this.config.inputComponent
          : (this.config.inputComponent as any).type,
      );
      ref.instance.options = this.config.inputOptions;
      ref.instance.init(this);
      ref.instance.filterValueSub = this.filterService.filterValues.pipe(
        map((e) => (e as Partial<F>)?.[this.config.id] ?? null),
        filter((e) => e !== undefined),
      ) as BehaviorSubject<R>;

      // handle events when the instance changes it's value
      ref.instance.valueChanged.subscribe((value) => {
        /*console.log(
          'FilterBarElementComponent',
          'New value:',
          value,
          this.config.label,
        );*/

        const filters = this.filterService.filterValues.getValue();
        this.filterService.filterValues.next({
          ...filters,
          [this.config.id]: value,
        });
      });

      this.changeValue.subscribe((value) => {
        if (ref.instance.getValue() == value) return;
        ref.instance.setValue(value);
      });
    }
  }

  toggleInputState() {
    this.state = this.state === 'expand' ? 'collapse' : 'expand';
  }

  @HostListener('click', ['$event'])
  onSelfClick(event: MouseEvent) {
    this.self = event.currentTarget as HTMLElement;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.self) return;
    if (
      this.state === 'expand' &&
      this.self &&
      !this.isChildOf(this.self, event.target as HTMLElement)
    ) {
      this.state = 'collapse';
    }
  }

  private isChildOf(parent: HTMLElement, child: HTMLElement) {
    if (!child.parentElement) return false;
    if (child.parentElement === parent) return true;
    return this.isChildOf(parent, child.parentElement);
  }
}
