import { RRIBaseModel } from '../../common/interfaces/base-model.interface';
import { RRISort } from '../../common/interfaces/base-sort.interface';

export class RRClientSideDataModel<T extends RRIBaseModel> {
  results: T[];
  sortBy: RRISort<T>;
  currentPage: number;
  pageCount: number;
  pageSize: number;
  recordCount: number;
}
