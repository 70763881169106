<div *ngIf="!reference.synced" class="not-synced">not synced</div>
<div
  (click)="onSelect()"
  class="d-flex flex-row assetCard"
  ngClass="{{ !reference.synced ? 'disabled' : '' }}"
>
  <div class="entry">
    <img src="{{ reference.thumbnail }}" class="thumbnail" />
  </div>
  <div class="d-flex flex-column ml-3">
    <b>{{ reference.name }}</b>
    <div class="dataLine">
      <span class="data-label mt-2"><b>Reference: </b></span>
      <span class="data-value">{{ reference.reference }}</span>
      <app-copy-to-clipboard
        data="{{ reference.reference }}"
      ></app-copy-to-clipboard>
    </div>
    <div class="dataLine">
      <span class="data-label mt-2"><b>CS-Id: </b></span>
      <span class="data-value">{{ reference.id }}</span>
      <app-copy-to-clipboard data="{{ reference.id }}"></app-copy-to-clipboard>
    </div>
  </div>
</div>
