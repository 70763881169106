import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { REDUCER_TOKEN } from './store/customer.reducer';
import { CustomerEffects } from './store/customer.effects';
import { ICustomerService } from '.';
import { CustomerService } from './service/customer.service';
import { CustomerReducerService } from './service/customer-reducer.service';
import { CustomerStorageService } from './service/customer-storage.service';
import { CustomerSelectorComponent } from './customer-selector/customer-selector.component';
import { RRLayoutModule } from 'projects/web-ui-component-library/src';

@NgModule({
  declarations: [CustomerSelectorComponent],
  imports: [
    CommonModule,
    RRLayoutModule,
    StoreModule.forFeature('customer', REDUCER_TOKEN),
    EffectsModule.forFeature([CustomerEffects]),
  ],
  providers: [
    {
      provide: ICustomerService,
      useClass: CustomerService,
    },
    CustomerReducerService,
    CustomerStorageService,
  ],
  exports: [CustomerSelectorComponent],
})
export class CustomerModule {
  constructor() {}
}
