<div *ngIf="user$ | async as user">
  <app-input-text
    id="email"
    class="mb-5"
    placeholder="Email"
    [disabled]="true"
    [value]="user.email"
  >
  </app-input-text>

  <app-input-text
    id="firstName"
    class="mb-5"
    placeholder="First Name"
    [value]="user.givenName"
    (valueChange)="updateUser('givenName', $event)"
  >
  </app-input-text>

  <app-input-text
    id="lastName"
    class="mb-5"
    placeholder="Last Name"
    [value]="user.familyName"
    (valueChange)="updateUser('familyName', $event)"
  >
  </app-input-text>

  <rr-button
    class="btn btn-primary"
    [action]="updateUserInfo(user.id)"
    (done)="handleDone($event)"
  >
    Update
  </rr-button>
</div>
