import { AfterViewInit, Component } from '@angular/core';
import { ApiKeyDto } from '@reactivereality/cs-api-sdk';
import {
  DataSource,
  TableConfiguration,
} from 'projects/web-ui-component-library/src';
import { ApiKeyRowComponent } from './components/api-key-row.component';
import { Observable, combineLatest, interval, map } from 'rxjs';
import { ApiKeyStore } from './state/api-list.store';
const moment = require('moment');

@Component({
  selector: 'app-profile-api-keys',
  templateUrl: './profile-api-keys.component.html',
  styleUrls: ['./profile-api-keys.component.scss'],
  providers: [ApiKeyStore],
})
export class ProfileApiKeysComponent implements AfterViewInit {
  // Table configuration

  requestTableConfiguration: TableConfiguration<ApiKeyDto> = {
    rowComponent: ApiKeyRowComponent,
    columns: [
      {
        title: 'Name',
      },
      {
        title: 'Created At',
      },
      {
        title: '',
        hideSort: true,
      },
    ],
  };

  requestDatasource: DataSource<ApiKeyDto> = {
    fetch: (opts) => {
      return this.store.keys$.pipe(
        map((keys) => ({
          data: keys,
        })),
      );
    },
  };

  keyName: string = null;

  public updated_text: Observable<string>;
  public updated_date: Observable<number>;

  constructor(public store: ApiKeyStore) {
    //this.store = new ApiKeyStore(userService);

    this.updated_text = combineLatest([
      this.store.lastUpdate$,
      interval(5000),
    ]).pipe(
      map(([u, _]) => {
        if (u) {
          const mom = moment(u);
          const info = mom.fromNow();
          return `Last updated ${info}`;
        }
        return '';
      }),
    );

    this.updated_date = this.store.lastUpdate$.pipe(
      map((u) => {
        if (u) {
          return u.getTime() / 1000;
        }
        return null;
      }),
    );
  }

  updateKeyName(name: string) {
    if (name != null && name.length === 0) {
      this.keyName = null;
      return;
    }
    this.keyName = name;
  }

  ngAfterViewInit(): void {
    this.store.refreshKeys();
  }

  addKey() {
    return () => this.store.addKey(this.keyName);
  }
}
