import {
  MerchantSdk,
  PublicSdk,
  getMerchantSdk,
  getPublicSdk,
} from 'projects/content-service-cms/src/app/data/central-asset-platform-sdk/src';
import { ICentralAssetPlatformService } from '../api';
import {
  ExtendedCollection,
  ICollection,
} from '../../collection-overview/collection.interface';
import { v4 as uuid } from 'uuid';
import { IAuthService } from '../../auth';
import { Injectable } from '@angular/core';
import {
  CreateSettingMutation,
  UpdateCollectionMutation,
} from '../central-asset-platform-sdk/src/merchant-sdk';
import { NgDiscoveryService } from '../../core/services/ng-discovery.service';

@Injectable()
export class CentralAssetPlatformService
  implements ICentralAssetPlatformService
{
  private _merchant_sdk: MerchantSdk;
  private _public_sdk: PublicSdk;

  private _publicPostfix = 'graphql/public/v1';
  private _merchantPostfix = 'graphql/merchant/v1';
  constructor(
    private _authService: IAuthService,
    private discoveryService: NgDiscoveryService,
  ) {}

  private getMerchantSDK = () => {
    if (!this._merchant_sdk) {
      this._merchant_sdk = getMerchantSdk(
        this._authService?.getToken(),
        this.merchantPlatformUrl(),
      );
    }
    return this._merchant_sdk;
  };

  private getPublicSDK = () => {
    if (!this._public_sdk) {
      this._public_sdk = getPublicSdk(
        this._authService?.getToken(),
        this.publicPlatformUrl(),
      );
    }
    return this._public_sdk;
  };

  private publicPlatformUrl = () => {
    return (
      this.discoveryService.config.apiConfiguration.platformApiBaseUrl +
      this._publicPostfix
    );
  };
  private merchantPlatformUrl = () => {
    return (
      this.discoveryService.config.apiConfiguration.platformApiBaseUrl +
      this._merchantPostfix
    );
  };
  updateCollection(
    id: uuid,
    collection: ICollection,
  ): Promise<UpdateCollectionMutation> {
    const r = this.getMerchantSDK().updateCollection({
      settingId: id,
      data: collection,
    });
    return r;
  }

  async createCollection(org: uuid, collection: ICollection): Promise<uuid> {
    const result = (await this.getMerchantSDK().createSetting({
      org: org,
      data: collection,
      type: 'VIRTUAL_STYLING_V1',
    })) as CreateSettingMutation;
    return result.createSetting?.id;
  }

  async getAllCollections(org: uuid): Promise<ExtendedCollection[]> {
    if (!this._merchant_sdk) {
      this._merchant_sdk = getMerchantSdk(
        this._authService?.getToken(),
        this.merchantPlatformUrl(),
      );
    }
    const res = await this._merchant_sdk.getAllSettings({ org: org });

    //TODO: filtering based on version?
    const collections: ExtendedCollection[] = res.getAllSettings
      .filter((c) => c.type.type === 'VIRTUAL_STYLING_V1')
      .map((c) => {
        return {
          collection: c.data,
          externalReferences: c.externalReferences.map((e) => e.reference),
          id: c.id,
          type: c.type.type,
          schema: c.type.schema,
        };
      });

    return collections;
  }

  public referenceExists = async (org: uuid, reference: string) => {
    const setting = await this.getPublicSDK().findSetting({
      org: org,
      reference: reference,
    });

    return setting.findSetting !== null;
  };

  public addExternalReferenceToSetting = async (
    setting: string,
    reference: string,
  ) => {
    const result = await this.getMerchantSDK().addExternalReferenceToSetting({
      setting: setting,
      reference: reference,
    });
    return result;
  };

  // public getCollection = async (
  //   org: uuid,
  //   ref: string,
  // ): Promise<{ id: string; collection: ICollection }> => {
  //   if (!this._public_sdk) {
  //     this._public_sdk = getPublicSdk(
  //       this._authService?.getToken(),
  //       this.publicPlatformUrl(),
  //     );
  //   }
  //   const setting = await this._public_sdk.findSetting({
  //     org: org,
  //     reference: ref,
  //   });
  //   if (setting.findSetting === null) {
  //     return;
  //   }
  //   return {
  //     id: setting.findSetting.id,
  //     collection: setting.findSetting.setting,
  //   };
  // };

  async getThumbnail(org: uuid, ref: string): Promise<{ thumbnail: string }> {
    const result = await this.getMerchantSDK().getThumbnail({
      org: org,
      reference: ref,
    });
    if (result.getRequests === null) {
      return;
    }
    return {
      thumbnail: result.getRequests[0]?.thumbnail,
    };
  }

  async getId(org: uuid, ref: string): Promise<{ id: uuid }> {
    const result = await this.getMerchantSDK().getId({
      org: org,
      reference: ref,
    });
    if (result.getRequests === null) {
      return;
    }
    return {
      id: result.getRequests[0]?.id,
    };
  }
}
