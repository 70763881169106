import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICustomerState } from './customer.state';

const feature = createFeatureSelector('customer');

export const selectCurrentCustomer = createSelector(
  feature,
  (state: ICustomerState) => state.currentCustomer,
);

export const selectMyCustomers = createSelector(
  feature,
  (state: ICustomerState) => state.myCustomerList,
);
