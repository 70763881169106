import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  imports: [CommonModule, FormsModule],
  standalone: true,
})
export class InputTextComponent implements OnInit {
  @Input() placeholder: string;
  @Input() value: string;
  @Input() required: boolean;
  @Input() validationFunction: (value: string) => boolean;
  @Input() disabled: boolean;

  @Output() valueChange = new EventEmitter<{ value: string; valid: boolean }>();
  @Output() public isValid: boolean;

  constructor() {}

  ngOnInit(): void {
    //don't show the error frame when opening a modal
    this.isValid = true;
  }

  valueChanged(value: string) {
    if (this.validationFunction) {
      this.isValid = this.validationFunction(value);
    }
    this.value = value;
    this.valueChange.emit({ value: value, valid: this.isValid });
  }
}
