import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

// https://stackoverflow.com/questions/49102724/angular-5-copy-to-clipboard/52949299#52949299
/*
<a role="button" [copy-clipboard]="'some stuff'" (copied)="notify($event)">
  <i class="fa fa-clipboard"></i>
  Copy
</a>

public notify(payload: string) {
   // Might want to notify the user that something has been pushed to the clipboard
   console.info(`'${payload}' has been copied to clipboard`);
}
*/

@Directive({
  selector: '[rrCopyToClipboard]',
})
export class CopyToClipboardDirective {
  constructor() {}

  @Input('rrCopyToClipboard')
  public payload: string;

  @Output()
  public copied: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    const classname = (<Element>event.target).parentElement.className;
    const parent = (<Element>event.target).parentElement;
    parent.className = classname + ' active';
    setTimeout(function () {
      parent.className = classname;
    }, 250);
    event.preventDefault();
    if (!this.payload) return;

    const listener = (e: ClipboardEvent) => {
      const clipboard = e.clipboardData || window['clipboardData'];
      clipboard.setData('text', this.payload.toString());
      e.preventDefault();

      this.copied.emit(this.payload);
    };

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);
  }
}
