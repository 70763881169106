import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RRIBatchOption,
  RRIDownloadBatchOptionSettings,
  RRIFileService,
  RRIQueryParams,
  RRITableData,
} from 'projects/web-ui-component-library/src';
import { Observable, Subject } from 'rxjs';
import { NgStorageManagerService } from '../../core/services/ng-storage-manager.service';
import { AlertService } from 'projects/content-service-cms/src/app/core/services/alert.service';
import { ProgressBarService } from 'projects/content-service-cms/src/app/core/services/progress-bar.service';
import {
  IFileDownloadService,
  IFileService,
  IRequestResultFile,
} from 'projects/content-service-cms/src/app/data/api';
import { GlobalVariable } from 'projects/content-service-cms/src/app/core/globals';
import { File } from 'projects/content-service-cms/src/app/core/models/files/file.model';
import {
  IApiSdkService,
  ICsApiSdkConfiguration,
} from 'projects/content-service-cms/src/app/data';
import { ILogger } from 'projects/content-service-cms/src/app/logging';

import { FileRevision } from '../../core/models/files/file-revision.model';
import {
  FilesApi,
  ImageDTO,
  ImageDTOPage,
  MIMETypeDTO,
  ProductTypeDTO,
} from '@reactivereality/cs-api-sdk';

import { AxiosResponse } from 'axios';
import { of } from 'rxjs';
import { NgDiscoveryService } from '../../core/services/ng-discovery.service';

@Injectable({
  providedIn: 'root',
})
export class FilesService<T>
  extends IFileService<T>
  implements RRIFileService<T>
{
  static endpointName = 'files';
  private configFilesurl: string;
  private fileConfiguration: File;

  private _filesApi: FilesApi;
  private _apiVersion: string;

  public isLoading: Subject<any>;
  public BASE_API_URL: string;

  constructor(
    protected _http: HttpClient,
    protected _discoveryService: NgDiscoveryService,
    protected _logger: ILogger,
    protected _apiSdkService: IApiSdkService,
    protected _alertService: AlertService,
    protected _progressBarOverlayService: ProgressBarService,
    private _storageService: NgStorageManagerService,
    private _fileDownloadService: IFileDownloadService<T>,
  ) {
    super(
      FilesService.endpointName,
      _http,
      _logger,
      _apiSdkService,
      _discoveryService,
    );
    this.standardUrl = `${this.BASE_API_URL}/${FilesService.endpointName}`;
    this.isLoading = this._progressBarOverlayService.isLoading;
    this._apiSdkService.sdkConfig$.subscribe({
      next: this.initService,
      error: this.errorOnInitService,
    });
  }

  public async deleteFile(file: ImageDTO): Promise<ImageDTO> {
    const response = await this._filesApi.vversionFilesIdDelete(file.id, '1.5');
    return response.data;
  }

  public async fileDownload(
    entities: any[],
    visibleBatchOptions: RRIBatchOption<RRIDownloadBatchOptionSettings<T, any>>,
    singleDownload?: boolean,
  ): Promise<void> {
    return this._fileDownloadService.fileDownload(
      entities,
      visibleBatchOptions,
      singleDownload,
    );
  }

  public async downloadFile(file: File): Promise<void> {
    return this._fileDownloadService.downloadFile(file).toPromise();
  }

  public setFileConfiguration(file: File): void {
    this.fileConfiguration = file;
  }

  public getData(
    params?: RRIQueryParams<File, any>,
  ): Observable<RRITableData<File>> {
    const filter: string = this.formatFilters(params.filters);

    const imagePage = this.getRequestFiles(
      this.fileConfiguration.configurationId,
      filter,
    );
    return this.mapRequestFiles(imagePage);
  }

  public async getFilesFromRequest(): Promise<ImageDTOPage> {
    return this.getRequestFiles(this.fileConfiguration.configurationId);
  }

  public async getRequestFiles(
    requestId: string,
    filters?: string,
  ): Promise<ImageDTOPage> {
    if (this._filesApi) {
      return this._filesApi
        .vversionConfigurationsConfigurationIdFilesGet(
          requestId,
          this._apiVersion,
          1,
          -1,
          undefined,
          filters,
        )
        .then((response: AxiosResponse<ImageDTOPage>) => {
          if (response && response.data) {
            return response.data;
          }
          return undefined;
        })
        .catch((reason: any) => {
          this._logger.error(
            `Error while fetching request files for config ${requestId}`,
            reason,
          );
          return null;
        });
    }
    return Promise.reject('There is no Files API.');
  }

  public async getFile(fileId: string): Promise<File> {
    if (this._filesApi) {
      return this._filesApi
        .fileLink(fileId, this._apiVersion)
        .then((response: AxiosResponse<ImageDTO>) => {
          if (response && response.data) {
            return response.data;
          }
          return undefined;
        })
        .catch((reason: any) => {
          this._logger.error(`Error while fetching file ${fileId}`, reason);
          return null;
        });
    }
    return Promise.reject('There is no Files API.');
  }

  public async getTypes(): Promise<ProductTypeDTO[]> {
    if (!this._storageService.getItem(GlobalVariable.FILE_TYPES, 'session')) {
      if (this._filesApi) {
        return this._filesApi
          .vversionFilesTypesGet(this._apiVersion)
          .then((response: AxiosResponse<ProductTypeDTO[]>) => {
            if (response && Array.isArray(response.data)) {
              this._storageService.setItem(
                GlobalVariable.FILE_TYPES,
                response.data,
                'session',
              );
              return Promise.resolve(response.data);
            }

            return undefined;
          })
          .catch((reason: any) => {
            this._logger.error('Error while fetching product types.', reason);
            return Promise.reject();
          });
      }
    } else {
      const data = this._storageService.getItem(
        GlobalVariable.FILE_TYPES,
        'session',
      );
      return Promise.resolve(data);
    }
    return undefined;
  }

  public async getMimeTypes(): Promise<MIMETypeDTO[]> {
    if (
      !this._storageService.getItem(GlobalVariable.FILE_MIME_TYPES, 'session')
    ) {
      if (this._filesApi) {
        return this._filesApi
          .vversionFilesMIMEtypesGet(this._apiVersion)
          .then((response: AxiosResponse<MIMETypeDTO[]>) => {
            if (response && Array.isArray(response.data)) {
              this._storageService.setItem(
                GlobalVariable.FILE_MIME_TYPES,
                response.data,
                'session',
              );
              return response.data;
            }

            return undefined;
          })
          .catch((reason: any) => {
            this._logger.error('Error while fetching product types.', reason);
            return Promise.reject();
          });
      }
    } else {
      const data = this._storageService.getItem(
        GlobalVariable.FILE_MIME_TYPES,
        'session',
      );
      return Promise.resolve(data);
    }
  }

  public loadFile(lbActiveFile: File): Observable<any> {
    return new Observable((subscriber) => {
      if (typeof lbActiveFile.mimeType !== 'undefined') {
        const request = new XMLHttpRequest();
        request.open('GET', lbActiveFile.url, true);
        request.responseType = 'blob';
        request.onload = () => {
          const reader = new FileReader();
          reader.readAsText(request.response);
          reader.onloadend = (e) => {
            lbActiveFile.text = e.target['result'].toString();
            if (
              lbActiveFile.mimeType &&
              lbActiveFile.mimeType.includes('text')
            ) {
              lbActiveFile.text = lbActiveFile.text.replace(
                /(?:\r\n|\r|\n)/g,
                '<br>',
              );
            }
            subscriber.next({ file: lbActiveFile, hideLoader: true });
            subscriber.complete();
          };
          reader.onerror = () => {
            this._logger.error('Error: File loading');
            subscriber.next({ file: lbActiveFile, hideLoader: true });
            reader.abort();
            subscriber.complete();
          };
        };
        request.onerror = () => {
          this._logger.error('Error: File loading');
          subscriber.next({ file: lbActiveFile, hideLoader: true });
          subscriber.complete();
        };
        request.send();
      } else {
        const extension = this.getFileExtension(lbActiveFile);
        let hideLoader = true;
        if (extension == '.garment' || extension == '.avatar') {
          hideLoader = false;
        }
        subscriber.next({ file: lbActiveFile, hideLoader: hideLoader });
        subscriber.complete();
      }
    });
  }

  private mapRequestFiles(
    files: Promise<ImageDTOPage>,
  ): Observable<RRITableData<File>> {
    const data: RRITableData<File> = { pagination: null, results: [] };

    files.then((res) => {
      res.results.map((r) => {
        const f: File = new File();
        f.configurationId = r.configurationId;
        f.createdAt = r.createdAt;
        f.currentRevisionId = r.currentRevisionId;
        f.id = r.id;
        f.mimeType = r.mimeType;
        f.mimeTypeId = r.mimeTypeId;
        f.modifiedAt = r.modifiedAt;
        f.name = r.name;
        f.typeId = r.typeId;
        f.url = r.url;
        f.previewUrl = r.url;

        if (f.typeId != 36) {
          //Do not add the thumbnail to the returned files
          data.results.push(f);
        }
      });
    });
    return of(data);
  }

  protected initService = (sdkConfig: ICsApiSdkConfiguration) => {
    if (sdkConfig !== undefined && sdkConfig.filesApiSdk) {
      this._apiVersion = sdkConfig.apiVersion;
      this._filesApi = sdkConfig.filesApiSdk;
    } else {
      this.errorOnInitService(
        new Error('There is no Products API Sdk Configuration or API version.'),
      );
    }
  };

  private getFileExtension(
    file: File | IRequestResultFile | FileRevision,
  ): string {
    let extension = file.url;
    if (extension.indexOf('?') > -1) {
      extension = extension.substring(0, extension.indexOf('?'));
    }
    extension = extension.substr(extension.lastIndexOf('.'), extension.length);
    return extension;
  }
}
