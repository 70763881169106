<ng-template #addGarments>
  <app-add-garment-modal
    [productType]="createRequestModalStore.productType$ | async"
    [singleSelect]="true"
    [currentSelectedGarments]="
      currentSlot === undefined
        ? []
        : accessorySelectionService.currentSelectedGarments()[currentSlot] ===
          undefined
        ? []
        : [accessorySelectionService.currentSelectedGarments()[currentSlot]]
    "
    (setSelectedGarment)="setGarmentForCurrentSlot($event)"
  ></app-add-garment-modal>
</ng-template>

<div class="mt-5" id="addGarmentContainer">
  <h2>Accessories</h2>
  <div class="addGarmentContainer">
    <!--TOP-->
    <app-add-item
      id="accessory-select-add-item-top"
      *ngIf="
        (accessorySelectionService.selectedGarments$ | async)['TOP'] ===
        undefined
      "
      info="Select Top Accessory"
      (addItemClicked)="addAccessoriesClicked('TOP')"
    ></app-add-item>

    <ng-container
      *ngIf="
        (accessorySelectionService.selectedGarments$ | async)['TOP'] !==
        undefined
      "
    >
      <div class="accessory-entry">
        <app-accessory-selection-entry
          [accessory]="
            (accessorySelectionService.selectedGarments$ | async)['TOP']
          "
          (deleteClicked)="deleteAccessoryEntry('TOP')"
        >
        </app-accessory-selection-entry>
      </div>
    </ng-container>
    <!--MIDDLE-->
    <app-add-item
      id="accessory-select-add-item-middle"
      *ngIf="
        (accessorySelectionService.selectedGarments$ | async)['MIDDLE'] ===
        undefined
      "
      info="Select Middle Accessory"
      (addItemClicked)="addAccessoriesClicked('MIDDLE')"
    ></app-add-item>

    <ng-container
      *ngIf="
        (accessorySelectionService.selectedGarments$ | async)['MIDDLE'] !==
        undefined
      "
    >
      <div class="accessory-entry">
        <app-accessory-selection-entry
          [accessory]="
            (accessorySelectionService.selectedGarments$ | async)['MIDDLE']
          "
          (deleteClicked)="deleteAccessoryEntry('MIDDLE')"
        >
        </app-accessory-selection-entry>
      </div>
    </ng-container>
    <!--BOTTOM-->
    <app-add-item
      id="accessory-select-add-item-bottom"
      *ngIf="
        (accessorySelectionService.selectedGarments$ | async)['BOTTOM'] ===
        undefined
      "
      info="Select Bottom Accessory"
      (addItemClicked)="addAccessoriesClicked('BOTTOM')"
    ></app-add-item>

    <ng-container
      *ngIf="
        (accessorySelectionService.selectedGarments$ | async)['BOTTOM'] !==
        undefined
      "
    >
      <div class="accessory-entry">
        <app-accessory-selection-entry
          [accessory]="
            (accessorySelectionService.selectedGarments$ | async)['BOTTOM']
          "
          (deleteClicked)="deleteAccessoryEntry('BOTTOM')"
        >
        </app-accessory-selection-entry>
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="
      (accessorySelectionService.selectedGarments$ | async)['TOP'] !==
        undefined ||
      (accessorySelectionService.selectedGarments$ | async)['MIDDLE'] !==
        undefined ||
      (accessorySelectionService.selectedGarments$ | async)['BOTTOM'] !==
        undefined
    "
    class="row addremove"
  >
    <div
      class="d-flex flex-row ml-auto align-items-center"
      role="button"
      (click)="deleteAllAccessories()"
    >
      <div class="p2 mt-3 mb-3 rr-delete"></div>
      <div class="p2 ml-3 mt-3 mb-3">Remove all</div>
    </div>
  </div>
</div>
