import { Component } from '@angular/core';
import { InputTextComponent } from '../../../components/inputs/input-text/input-text.component';
import { CommonModule } from '@angular/common';
import { BaseListRowComponent } from '../base-list-row/base-list-row.component';
import { AssetReference } from '../../collection-screen.state';
import { RowButtonsComponent } from '../row-buttons/row-buttons.component';
import { RRCopyToClipboardModule } from 'projects/web-ui-component-library/src';
import { CopyToClipboardComponent } from '../copy-to-clipboard/copy-to-clipboard.component';

@Component({
  selector: 'app-asset-list-row',
  standalone: true,
  imports: [
    InputTextComponent,
    CommonModule,
    RowButtonsComponent,
    RRCopyToClipboardModule,
    CopyToClipboardComponent,
  ],
  templateUrl: './asset-list-row.component.html',
  styleUrls: ['../base-list-row/base-list-row.scss'],
})
export class AssetListRowComponent extends BaseListRowComponent<AssetReference> {
  constructor() {
    super();
  }

  onNameChanged($event: { value: string; valid: boolean }) {
    if ($event.valid) {
      this.updateReference.emit({ ...this.reference, name: $event.value });
    }
  }
}
