<div class="vdr-container">
  <ng-content select="app-avatar-management"></ng-content>

  <app-overlay-spinner
    class="overall-loader"
    [spinnerStatus]="spinner"
  ></app-overlay-spinner>
  <ng-content select="app-expand-button"></ng-content>
  <pictofit-web
    #PictofitWeb
    [style.height]="settings?.height"
    [style.width]="settings?.width"
  ></pictofit-web>
  <ng-content></ng-content>
  <div
    class="bottom-viewer-warning d-flex justify-content-center align-items-center"
    *ngIf="warningMessage"
  >
    <div class="message">
      {{ warningMessage }}
    </div>
  </div>
</div>
