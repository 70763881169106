import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CollectionScreenStore,
  ScreenMode,
} from '../../../collection-screen/collection-screen.state';
import {
  loadCollections,
  sortChange,
} from '../../store/collections-overview.actions';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../app.state';
import {
  selectCollectionsFilter,
  selectCollectionsOverview,
} from '../../store/collections-overview.selector';
import { BehaviorSubject, Subject, map, takeUntil } from 'rxjs';
import {
  DataSource,
  PageContextService,
  RRISort,
  SelectedFilters,
  TableConfiguration,
} from 'projects/web-ui-component-library/src';
import { ExtendedCollection } from '../../collection.interface';
import * as _ from 'lodash';
import { CollectionListRowComponent } from './collection-list-row/collection-list-row.component';
import { CollectionListActionBarComponent } from '../collection-list-action-bar/collection-list-action-bar.component';
import { ICustomerService } from '../../../customer';
import { CollectionFilters } from '../../../collection-screen/components/reference-search/components/collection-search-filter-bar/collection-search-filter-bar.component';

export interface CollectionRecord {
  id: string;
  name: string;
  imageUrl: string;
  references: string[];
  type: string;
}
@Component({
  selector: 'app-collection-list.page',
  standalone: false,
  templateUrl: './collection-list.page.component.html',
  styleUrl: './collection-list.page.component.scss',
  providers: [],
})
export class CollectionListPageComponent implements OnInit, OnDestroy {
  public spinnerStatus = {
    show: false,
    text: 'Loading...',
  };

  public displayedCollections: BehaviorSubject<ExtendedCollection[]> =
    new BehaviorSubject([]);
  constructor(
    public store: Store<IAppState>,

    private _createCollectionModalStore: CollectionScreenStore,
    private _pageContextService: PageContextService,
    private _customerService: ICustomerService,
  ) {}

  ngOnInit() {
    this._pageContextService
      .setPageParent()
      .setPageActionBar(CollectionListActionBarComponent, (_) => {})
      .setPageTitle('Collections');

    this._onDestroy$ = new Subject<void>();

    const collections$ = this.store.select(selectCollectionsOverview);

    collections$.pipe(takeUntil(this._onDestroy$)).subscribe({
      next: (x) => {
        this.onCollectionsChanged(x);
      },
      error: (a) => {
        console.warn('collections$ error: ', a);
      },
    });
    //reload collections when customer changes
    this._customerService.currentCustomer$.subscribe((customer) => {
      if (customer) this.onReadCollections();
    });

    this.store.select(selectCollectionsFilter).subscribe((filters) => {
      this.onFiltersChanged(filters);
    });

    this.displayedCollections.subscribe((collections) => {
      console.warn('filtered collections: ', collections);
    });
  }

  public collectionTableConfiguration: TableConfiguration<CollectionRecord> = {
    rowComponent: CollectionListRowComponent,
    columns: [
      {
        title: '',
        hideSort: true,
      },
      {
        title: 'Name',
        hideSort: true,
      },
      {
        title: 'Id',
        hideSort: true,
      },
      {
        title: 'Reference',
        hideSort: true,
      },
    ],
  };

  public collectionDatasource: DataSource<CollectionRecord> = {
    fetch: (_) => {
      const mapCollectionToRecord = (
        c: ExtendedCollection,
      ): CollectionRecord => ({
        id: c.id,
        name: c.collection?.info?.name,
        imageUrl: c.collection?.info?.imageUrl,
        references: c.externalReferences,
        type: c.type,
      });

      return this.displayedCollections.pipe(
        map((c) => ({ data: c.map(mapCollectionToRecord) })),
      );
    },
  };

  private _onDestroy$: Subject<void>;

  private onFiltersChanged(filters: SelectedFilters<CollectionFilters>) {
    if (filters.name) {
      this.displayedCollections.next(
        this.displayedCollections.value.filter((c) =>
          c.collection?.info?.name.includes(filters.name),
        ),
      );
    }
  }

  private onCollectionsChanged = ({
    collections: collections,
    recordCount,
  }: {
    collections: ExtendedCollection[];
    recordCount: number;
  }) => {
    if (_.isEqual(this.displayedCollections.value, collections)) return;
    this.displayedCollections.next(collections);

    //this.manageShowSpinner(false);
  };

  ngOnDestroy(): void {
    if (this._onDestroy$) {
      this._onDestroy$.next();
      this._onDestroy$.complete();
      this._onDestroy$.unsubscribe();
      this._onDestroy$ = undefined;
    }
  }

  async onCreateNewClicked() {
    this._createCollectionModalStore.reset();
    this._createCollectionModalStore.setMode(ScreenMode.NEW);
  }

  async onReadCollections() {
    this.store.dispatch(
      loadCollections({
        pagination: {
          pageSize: 10,
          currentPage: 1,
          pageCount: 1,
          recordCount: 0,
          recordCountHidden: 1,
        },
        sort: { fieldName: 'test' },
      }),
    );
  }

  onCloseClicked() {}

  public onSortByChange(uiSort: RRISort<CollectionRecord>): void {
    const sort = Object.assign({}, uiSort);
    this.store.dispatch(sortChange({ sort }));
  }

  onSortColumn(col: {
    title: string;
    hideSort?: boolean;
    sort?: 'ASC' | 'DESC' | '';
    sortable?: (a: CollectionRecord, b: CollectionRecord) => number;
  }) {
    this.onSortByChange(this.getSortObject(col));
  }

  getSortObject(col: {
    title: string;
    hideSort?: boolean;
    sort?: 'ASC' | 'DESC' | '';
    sortable?: (a: CollectionRecord, b: CollectionRecord) => number;
  }): RRISort<CollectionRecord> {
    switch (col.title) {
      case 'Name':
        return {
          fieldName: 'name',
          desc: col.sort === 'DESC' ? true : false,
        };
      case 'Id':
        return {
          fieldName: 'id',
          desc: col.sort === 'DESC' ? true : false,
        };
    }
  }
}
