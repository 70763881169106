import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import { IPaginationDataProviderInterface } from '../../../services/pagination-data-provider.interface';

declare global {
  interface Number {
    toCollection(): Array<number>;
  }

  interface Array<T> {
    take(count: number): Array<T>;
    window(offset: number, count: number): Array<T>;
    chunk(offset: number, group: number): Array<T>;
  }
}

Number.prototype.toCollection = function () {
  if (this <= 0) return [];

  const generate = function* (value: number) {
    for (let i = 1; i <= value; i++) {
      yield i as number;
    }
  };

  return Array.from(generate(this));
};

Array.prototype.chunk = function (offset, group) {
  const startAt = ((offset / group) | 0) * group;
  return this.window(startAt, group);
};

Array.prototype.take = function (count: number) {
  return this.slice(0, Math.min(count, this.length));
};

Array.prototype.window = function (offset: number, count: number) {
  offset = Math.min(offset, Math.max(this.length - 1, 0));
  return this.slice(offset).take(count);
};

@Component({
  selector: 'rr-request-list-pagination',
  templateUrl: './request-list-pagination.component.html',
  styleUrls: ['./request-list-pagination.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class RequestListPaginationComponent {
  public currentPage: number = 1;
  public pageCount: number = 1;
  public readonly WindowSize: number = 3;

  @Input()
  public AdvancedControls: boolean = false;

  public readonly currentItems$: Observable<[number, number]>;

  private readonly _pagination$: Observable<[number, number]>;

  constructor(public paginationDataProvider: IPaginationDataProviderInterface) {
    this._pagination$ = combineLatest([
      this.paginationDataProvider.currentPage$,
      this.paginationDataProvider.pageCount$,
    ]);

    this._pagination$.subscribe(([currentPage, pageCount]) => {
      this.currentPage = currentPage;
      this.pageCount = pageCount;
    });

    this.currentItems$ = combineLatest([
      this.paginationDataProvider.currentPage$,
      this.paginationDataProvider.pageSize$,
      this.paginationDataProvider.totalRecords$,
    ]).pipe(
      map(([currentPage, pageSize, totalRecords]) => {
        return [
          (currentPage - 1) * pageSize + 1,
          Math.min((currentPage - 1) * pageSize + pageSize, totalRecords),
        ];
      }),
    );
  }

  showNextPage() {
    return this.jumpPage(1);
  }

  showPrevPage() {
    return this.jumpPage(-1);
  }

  getStartOfBlock(page: number, blockSize: number) {
    const pc = Math.floor((page - 1) / blockSize);
    return blockSize * pc + 1;
  }

  nextPageBlockAt(thisPage: number, skip: number): number {
    return this.getStartOfBlock(thisPage + skip, skip);
  }

  prevPageBlockAt(thisPage: number, skip: number): number {
    return this.getStartOfBlock(Math.max(1, thisPage - skip), skip) + skip - 1;
  }

  jumpPage(page: number) {
    const newPage = Math.min(
      Math.max(this.currentPage + page, 1),
      this.pageCount,
    );
    this.showPage(newPage);
  }

  showPage(newPage: number) {
    this.paginationDataProvider.setCurrentPage(newPage);
  }
}
