import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ConfigurationDTO, CustomerDTO } from '@reactivereality/cs-api-sdk';
import { Subject, takeUntil } from 'rxjs';
import { PRODUCT_TYPE } from '../../data';
import { AvatarDTO, IRequestService } from '../../data/api';
import { ModalService } from '../../modal/modal.service';
import { CreateRequestModalStore } from '../../request-overview/components/create-request-modal/create-request-modal.state';
import {
  AvatarThumbnailPipe,
  GarmentThumbnailPipe,
  ProductTypePipe,
} from '../../utils/CommonPipes';
import { AddItemComponent } from '../add-item/add-item.component';
import { AddAvatarModalComponent } from './add-avatar-modal/add-avatar-modal.component';
import { AvatarSelectionService } from './avatar-selection.service';
import { NgStorageManagerService } from '../../core/services/ng-storage-manager.service';
import { ICustomerService } from '../../customer';

const LOCAL_STORAGE_KEY = 'avatarSelection';
const LAST_USED_AVATAR = 'lastUsedAvatar';

@Component({
  selector: 'app-avatar-selection-entry',
  templateUrl: './avatar-selection-entry.component.html',
  styleUrls: ['./avatar-selection-entry.component.scss'],
  imports: [
    CommonModule,
    AddItemComponent,
    GarmentThumbnailPipe,
    AvatarThumbnailPipe,
    ProductTypePipe,
    AddAvatarModalComponent,
  ],
  standalone: true,
})
export class AvatarSelectionEntryComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  public avatarType: PRODUCT_TYPE.AVATAR_2D | PRODUCT_TYPE.AVATAR_3D;
  @Input() public selectedAvatar: AvatarDTO;
  @Output() public changeClicked = new EventEmitter<ConfigurationDTO>();
  @ViewChild('selectAvatar') selectAvatar: TemplateRef<any>;

  private _currentCustomer: CustomerDTO;

  constructor(
    @Optional() public createRequestModalStore: CreateRequestModalStore,
    public modalService: ModalService,
    public avatarSelectionService: AvatarSelectionService,
    private _localStorageService: NgStorageManagerService,
    private _customerService: ICustomerService,
    private _requestService: IRequestService,
  ) {
    this.avatarSelectionService.onSelect
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.modalService.show({
          title: 'Select Avatar',
          template: this.selectAvatar,
        });
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async ngOnInit(): Promise<void> {
    this._customerService.currentCustomer$.subscribe((customer) => {
      this._currentCustomer = customer;
    });
    this.createRequestModalStore?.productType$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((productType) => {
        this.avatarType =
          productType === PRODUCT_TYPE.OUTFIT_2D ||
          productType === PRODUCT_TYPE.GARMENT_2D ||
          productType === PRODUCT_TYPE.OUTFIT_2D_PARALLAX
            ? PRODUCT_TYPE.AVATAR_2D
            : PRODUCT_TYPE.AVATAR_3D;
      });
    if (!this.selectedAvatar) {
      const lastUsedAvatarId = this._localStorageService.getItem(
        [
          LOCAL_STORAGE_KEY,
          LAST_USED_AVATAR,
          PRODUCT_TYPE.AVATAR_2D,
          this._currentCustomer.id,
        ].join('.'),
        'local',
      );
      if (lastUsedAvatarId === undefined) {
        return;
      }
      const avatar = await this._requestService.getRequestByID(
        lastUsedAvatarId,
      );
      this.selectedAvatar = avatar as AvatarDTO;
      this.createRequestModalStore.setAvatar(this.selectedAvatar);
    }
  }

  public onAvatarSelected($event: AvatarDTO) {
    this._localStorageService.setItem(
      [
        LOCAL_STORAGE_KEY,
        LAST_USED_AVATAR,
        PRODUCT_TYPE.AVATAR_2D,
        this._currentCustomer.id,
      ].join('.'),
      $event?.id,
      'local',
    );
    this.createRequestModalStore.setAvatar($event);
  }
}
