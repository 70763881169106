import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CollectionOverviewRoutingModule } from './collection-overview-routing.module';
import { CollectionListPageComponent } from './components/collection-list.page/collection-list.page.component';
import {
  RRFilterModule,
  RRFilteredTableModule,
  RRLayoutModule,
  RRSpinnerModule,
  RRTableModule,
} from 'projects/web-ui-component-library/src';
import { EffectsModule } from '@ngrx/effects';
import { CollectionsOverviewEffects } from './store/collections-overview.effects';
import { StoreModule } from '@ngrx/store';
import { collectionOverviewReducer } from './store/collections.overview.reducer';
import { CollectionListRowComponent } from './components/collection-list.page/collection-list-row/collection-list-row.component';
import { CollectionListActionBarComponent } from './components/collection-list-action-bar/collection-list-action-bar.component';
import { PreviewService } from './services/collection-preview.service';

@NgModule({
  declarations: [CollectionListPageComponent, CollectionListActionBarComponent],
  imports: [
    CommonModule,
    RRTableModule,
    CollectionOverviewRoutingModule,
    RRFilteredTableModule,
    RRFilterModule,
    RRLayoutModule,
    RRSpinnerModule,
    EffectsModule.forFeature([CollectionsOverviewEffects]),
    StoreModule.forFeature('collectionOverview', collectionOverviewReducer),
    CollectionListRowComponent,
  ],
  exports: [CollectionListPageComponent, CollectionListActionBarComponent],
  providers: [PreviewService],
})
export class CollectionOverviewModule {}
