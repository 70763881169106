import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  GarmentThumbnailPipe,
  ProductTypePipe,
} from '../../../utils/CommonPipes';
import { StylingOptionsComponent } from '../../garment-selection/garment-selection-entry/styling-options/styling-options.component';
import { ConfigurationDisplayComponent } from '../../garment-selection/configuration-display/configuration-display.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccessoryDTO } from '../../../data/api';
import { ACCESSORY_SLOT } from '../accessory-selection.component';

@Component({
  selector: 'app-accessory-selection-entry',
  standalone: true,
  imports: [
    CommonModule,
    ProductTypePipe,
    GarmentThumbnailPipe,
    StylingOptionsComponent,
    ConfigurationDisplayComponent,
    TooltipModule,
  ],
  templateUrl: './accessory-selection-entry.component.html',
  styleUrl: './accessory-selection-entry.component.scss',
})
export class AccessorySelectionEntryComponent {
  @Input() public accessory: AccessoryDTO;
  @Input() public slot: ACCESSORY_SLOT;
  @Output() public deleteClicked = new EventEmitter<AccessoryDTO>();
  @Output() public useClicked = new EventEmitter<AccessoryDTO>();

  public deleteEntry(_: MouseEvent) {
    this.deleteClicked.emit(this.accessory);
  }
}
