import { NgModule } from '@angular/core';
import { CtrlClickDirective } from './directives/ctrl-click.directive';
import { RRSharedModule } from '../shared-module/shared.module';
import { MiddleClickDirective } from './directives/middle-click.directive';

@NgModule({
  declarations: [CtrlClickDirective, MiddleClickDirective],
  imports: [RRSharedModule],
  exports: [CtrlClickDirective, MiddleClickDirective],
})
export class RRClickEventsModule {}
