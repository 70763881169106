import { Subject } from 'rxjs';
import { IFileInformation } from '../../data';

export enum FILE_UPLOAD_STATE {
  SELECTED,
  UPLOADING,
  DONE,
}
export interface UploadData {
  fileinformation: IFileInformation;
  file: Blob;
  uploadState: Subject<FILE_UPLOAD_STATE>;
}
