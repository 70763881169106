import { NgModule } from '@angular/core';
import { StackedBarChartComponent } from './components/stacked-bar-chart/stacked-bar-chart.component';
import { RRSharedModule } from '../shared-module/shared.module';
import { RRBootstrapModule } from '../bootstrap/bootstrap.module';

@NgModule({
  declarations: [StackedBarChartComponent],
  imports: [RRSharedModule, RRBootstrapModule],
  exports: [StackedBarChartComponent],
})
export class RRChartsModule {}
