import { Injectable } from '@angular/core';
import { AlertService } from 'projects/content-service-cms/src/app/core/services/alert.service';
import {
  IRequestService,
  PRODUCT_TYPE,
} from 'projects/content-service-cms/src/app/data';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import {
  CloneRequestInputData,
  CreateRequestInputData,
  UpdateRequestInputData,
  isCloneRequestInputData,
  isCreateRequestInputData,
  isUpdateRequestInputData,
} from '../create-request-input.interface';
import { CreateRequestModalStore } from '../create-request-modal.state';
import { CreateGarment2DService } from './create-garment-2d.service';
import { CreateGarment3DCADService } from './create-garment-3d-cad.service';
import { CreateOutfit3DService } from './create-outfit-3d.service';
import { ICreateRequestService } from './create-request-interface';
import { CreateOutfit2DParallaxService } from './create-outfit-2d-parallax.service';

@Injectable({
  providedIn: 'root',
})
export class CreateRequestService {
  private dispatch: { [type: string]: ICreateRequestService } = {};

  constructor(
    private _logger: ILogger,
    private _requestService: IRequestService,
    createGarment2D: CreateGarment2DService,
    createOutfit2DParallax: CreateOutfit2DParallaxService,
    createGarment3DCAD: CreateGarment3DCADService,
    createOutfit3D: CreateOutfit3DService,
    private alertService: AlertService,
  ) {
    this.dispatch[PRODUCT_TYPE.GARMENT_2D] = createGarment2D;
    this.dispatch[PRODUCT_TYPE.OUTFIT_2D_PARALLAX] = createOutfit2DParallax;
    this.dispatch[PRODUCT_TYPE.GARMENT_3D_CAD] = createGarment3DCAD;
    this.dispatch[PRODUCT_TYPE.OUTFIT_3D] = createOutfit3D;
  }

  supportsDraft = (productType: PRODUCT_TYPE): boolean => {
    const service = this.dispatch[productType];
    return service?.prepareDraftEdit !== undefined;
  };

  prepare = async (
    inputData:
      | CreateRequestInputData
      | UpdateRequestInputData
      | CloneRequestInputData,
    store: CreateRequestModalStore,
  ): Promise<void> => {
    store.showLoading();
    try {
      this._logger.debug(`Preparing data`, inputData);
      if (isCreateRequestInputData(inputData)) {
        const service = this.dispatch[inputData.productType];
        if (service) {
          this._logger.debug(`prepareCreateNew ...`, inputData);
          return await service.prepareCreateNew(inputData, store);
        }
        this.alertService.showErrorAlert(
          'Creating a request is not supported by this product type',
        );
      } else if (isUpdateRequestInputData(inputData)) {
        const request = await this._requestService.getRequestByID(inputData.id);
        if (request) {
          this._logger.debug(`get request ...`, request);
          const service = this.dispatch[request.productTypeId];
          if (service && service.prepareDraftEdit) {
            this._logger.debug(`prepareDraftEdit ...`, inputData);
            return await service.prepareDraftEdit(request, store);
          }
        }
        this.alertService.showErrorAlert(
          'Opening as draft is not supported by this product type',
        );
      } else if (isCloneRequestInputData(inputData)) {
        const request = await this._requestService.getRequestByID(
          inputData.cloneRequestId,
        );
        if (request) {
          this._logger.debug(`get request ...`, request);
          const service = this.dispatch[request.productTypeId];
          if (service && service.prepareClone) {
            this._logger.debug(`prepareClone ...`, inputData);
            return await service.prepareClone(request, store);
          }
        }
        this.alertService.showErrorAlert(
          'Cloning is not supported by this request',
        );
      }
      store.cancelAction();
    } finally {
      store.hideLoading();
    }
  };
}
