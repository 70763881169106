<div class="row" [hidden]="title === undefined">
  <label for="fileupload" class="form-label">{{ title }}</label>
</div>
<app-file-upload
  *ngIf="selectedFiles.length === 0"
  [multipleFiles]="multipleFiles"
  (filesSelected)="onFilesSelected($event)"
></app-file-upload>

<input
  #fileinput
  id="fileinput"
  class="file-input"
  type="file"
  [attr.multiple]="multipleFiles === true ? '' : null"
  (change)="onFilesSelected($event)"
/>

<div class="filelist">
  <div *ngFor="let f of selectedFiles">
    <app-file-upload-entry
      [file]="f"
      [uploadState]="f.uploadState"
      (deleteClicked)="singleFileDeleteClicked($event)"
    ></app-file-upload-entry>
  </div>
</div>

<div
  *ngIf="selectedFiles.length !== 0 && multipleFiles === true"
  class="row addremove"
>
  <div
    class="d-flex flex-row align-items-center"
    role="button"
    (click)="fileinput.click()"
  >
    <div class="p2 mt-3 mb-3 rr-plus"></div>
    <div class="p2 ml-3 mt-3 mb-3">Add Images</div>
  </div>
  <div
    class="d-flex flex-row ml-auto align-items-center"
    role="button"
    (click)="removeAllClicked()"
  >
    <div class="p2 mt-3 mb-3 rr-delete"></div>
    <div class="p2 ml-3 mt-3 mb-3">Remove all</div>
  </div>
</div>
