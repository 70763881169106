/* eslint-disable @angular-eslint/component-selector */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { TableRowComponent } from 'projects/web-ui-component-library/src';
import { Subject } from 'rxjs';
import { ApiKeyDto } from '@reactivereality/cs-api-sdk';
import { ApiKeyStore } from '../state/api-list.store';

@Component({
  selector: 'tbody.app-api-key-row',
  templateUrl: './api-key-row.component.html',
  styleUrls: ['./api-key-row.component.scss'],
})
export class ApiKeyRowComponent
  implements AfterViewInit, TableRowComponent<ApiKeyDto>, OnChanges
{
  @Input() record: ApiKeyDto;
  @Input() loaded: Subject<void>;
  expanded: boolean = false;
  constructor(
    private element: ElementRef<HTMLDivElement>,
    private router: Router,
    public store: ApiKeyStore,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {}

  deleteKey(key: ApiKeyDto): void {
    this.store.deleteKey(key.id);
  }

  ngAfterViewInit() {}
}
