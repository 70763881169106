export class Mutex {
  private _queue: Array<() => void> = [];
  private _isLocked = false;

  constructor() {}

  isLocked(): boolean {
    return this._isLocked;
  }

  private aquire = (): Promise<void> => {
    if (this._isLocked === false) {
      this._isLocked = true;
      return Promise.resolve();
    }
    return new Promise((r) => this._queue.push(r));
  };

  private release = () => {
    if (this._queue.length > 0) {
      const next = this._queue.shift();
      if (next !== undefined) {
        next();
      }
    } else {
      this._isLocked = false;
    }
  };

  exec<T>(fn: () => T | PromiseLike<T>): Promise<T> {
    return this.aquire().then(() => {
      return Promise.resolve()
        .then(fn)
        .then(
          (result) => {
            this.release();
            return result;
          },
          (err) => {
            this.release();
            throw err;
          },
        );
    });
  }
}
