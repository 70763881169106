import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadCollections,
  loadCollectionsError,
  loadCollectionsSuccess,
} from './collections-overview.actions';
import { catchError, from, map, of, switchMap } from 'rxjs';
import { ICentralAssetPlatformService } from '../../data/api';
import { ICustomerService } from '../../customer';

@Injectable()
export class CollectionsOverviewEffects {
  constructor(
    private actions$: Actions,
    private _cAPService: ICentralAssetPlatformService,
    private customerService: ICustomerService,
  ) {}

  loadCollections$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadCollections),
      switchMap(() => {
        return from(this.customerService.currentCustomer()).pipe(
          switchMap((customer) => {
            return from(this._cAPService.getAllCollections(customer?.id))
              .pipe(
                map((collections) => {
                  //const sort = await this.sortService.sort()
                  const sortedCollections = collections.sort((a, b) => {
                    if (
                      a.collection.info?.name.toLowerCase() <
                      b.collection.info?.name.toLowerCase()
                    ) {
                      return -1;
                    }
                    if (
                      a.collection.info?.name.toLowerCase() >
                      b.collection.info?.name
                    ) {
                      return 1;
                    }
                    return 0;
                  });
                  return sortedCollections;
                }),
              )
              .pipe(
                map((collections) =>
                  loadCollectionsSuccess({
                    collections: collections,
                    recordCount: collections.length,
                  }),
                ),
                catchError((error) =>
                  of(loadCollectionsError({ error: error })),
                ),
              );
          }),
        );
      }),
    );
  });
}
