import { Injectable } from '@angular/core';
import {
  ConfigurationHasTagKeyDTO,
  TagKeyBindingModel,
  TagKeyDTO,
  TagsApi,
} from '@reactivereality/cs-api-sdk';
import { v4 as uuid } from 'uuid';
import { ILogger } from '../../logging';
import { IApiSdkService, ICsApiSdkConfiguration, ITagService } from '../api';

@Injectable({
  providedIn: 'root',
})
export class TagService implements ITagService {
  private _tagsApi: TagsApi;
  private _apiVersion: string;

  constructor(
    private _apiSdkService: IApiSdkService,
    private _logger: ILogger,
  ) {
    this._apiSdkService.sdkConfig$.subscribe({
      next: this.initService,
      error: this.initProcessServiceHasError,
    });
  }
  async getTagOnlyForCustomer(id: uuid, customerId: uuid): Promise<TagKeyDTO> {
    const response = await this._tagsApi.vversionTagsGet(
      this._apiVersion,
      1,
      -1,
      'name',
      `{"id": "${id}", "customerId" : "${customerId}"}`,
    );
    if (
      response &&
      response.data &&
      response.data.results &&
      response.data.results.length === 1
    ) {
      return response.data.results[0];
    }
    return undefined;
  }
  async deleteTag(id: uuid): Promise<TagKeyDTO> {
    const response = await this._tagsApi.vversionTagsTagKeyIdDelete(
      id,
      this._apiVersion,
    );
    if (response && response.data) {
      return response.data;
    }
    return undefined;
  }
  async deleteTagFromConfiguration(
    id: uuid,
    configurationId: uuid,
  ): Promise<ConfigurationHasTagKeyDTO> {
    const response =
      await this._tagsApi.vversionTagsTagKeyIdConfigurationsConfigurationIdDelete(
        id,
        configurationId,
        this._apiVersion,
      );
    if (response && response.data) {
      return response.data;
    }
    return undefined;
  }
  async addTagToConfiguration(
    id: uuid,
    configurationId: uuid,
  ): Promise<ConfigurationHasTagKeyDTO> {
    const response =
      await this._tagsApi.vversionTagsTagKeyIdConfigurationsConfigurationIdPut(
        id,
        configurationId,
        this._apiVersion,
      );
    if (response && response.data) {
      return response.data;
    }
    return undefined;
  }
  async createOrUpdateTag(id: string, tag: TagKeyBindingModel): Promise<void> {
    await this._tagsApi.vversionTagsTagKeyIdPut(id, this._apiVersion, tag);
  }
  async getAllTags(): Promise<TagKeyDTO[]> {
    const response = await this._tagsApi.vversionTagsGet(
      this._apiVersion,
      1,
      -1,
      'name',
    );
    if (response && response.data) {
      return response.data.results;
    }
    return undefined;
  }
  async getAllTagsByCustomerId(customerId: string): Promise<TagKeyDTO[]> {
    const response = await this._tagsApi.vversionTagsGet(
      this._apiVersion,
      1,
      -1,
      'name',
      `{"customerId": "${customerId}"}`,
    );
    if (response && response.data) {
      return response.data.results;
    }
    return undefined;
  }
  async getTag(id: uuid): Promise<TagKeyDTO> {
    const response = await this._tagsApi.vversionTagsGet(
      this._apiVersion,
      1,
      -1,
      'name',
      `{"id": "${id}"}`,
    );
    if (
      response &&
      response.data &&
      response.data.results &&
      response.data.results.length === 1
    ) {
      return response.data.results[0];
    }
    return undefined;
  }
  protected initService = (sdkConfig: ICsApiSdkConfiguration) => {
    if (
      sdkConfig !== undefined &&
      sdkConfig.tagsApiSdk &&
      sdkConfig.apiVersion
    ) {
      this._apiVersion = sdkConfig.apiVersion;
      this._tagsApi = sdkConfig.tagsApiSdk;
    }
  };
  private initProcessServiceHasError = (error: Error) => {
    this._logger.error('Cannot initate process API.', error);
  };
}
