/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import {
  ComponentStore,
  OnStateInit,
  OnStoreInit,
} from '@ngrx/component-store';
import {
  IReferenceSelectionData,
  getOutfitParallaxInfoUrl,
} from './components/reference-search/reference-selection.component';
import { IRequestService, PRODUCT_TYPE } from '../data';
import {
  CollectionAccessoryReference,
  CollectionAvatarReference,
  CollectionBaseReference,
  CollectionBaseReferencesWithName,
  CollectionGarmentReference,
  CollectionInfo,
  CollectionSceneReference,
  ICollection,
} from '../collection-overview/collection.interface';

import { v4 as uuid } from 'uuid';
import { Observable, switchMap } from 'rxjs';
import { SelectedFilters } from 'projects/web-ui-component-library/src';
import { CollectionFilters } from './components/reference-search/components/collection-search-filter-bar/collection-search-filter-bar.component';
import { ICentralAssetPlatformService } from '../data/api';

export interface AssetReference extends CollectionBaseReferencesWithName {
  id: string;
  thumbnail?: string;
}
export interface GarmentReference extends CollectionGarmentReference {
  id: string;
  thumbnail?: string;
}

export enum ScreenMode {
  NEW = 'new',
  EDIT = 'edit',
}

export interface OutfitReferenceJson extends CollectionBaseReferencesWithName {
  id: string;
  resultUrl: string;
  thumbnail?: string;
}

export interface OutfitReference {
  id: string;
  scene: CollectionBaseReference;
  avatar: CollectionBaseReference;
  preview: CollectionBaseReference;
  garments: CollectionBaseReference[];
  accessories: CollectionAccessoryReference[];
}

export interface CollectionScreen {
  filteredRequests: IReferenceSelectionData[];
  //data
  id: string;
  info: CollectionInfo;
  avatars: AssetReference[];
  scenes: AssetReference[];
  garments: GarmentReference[];
  outfits: OutfitReferenceJson[];

  searchProductType: PRODUCT_TYPE;
  searchPattern: string;
  filters: SelectedFilters<CollectionFilters>;
  mode: ScreenMode;
  loading: boolean;
  garmentsLoading: boolean;
  avatarsLoading: boolean;
  scenesLoading: boolean;
  outfitsLoading: boolean;
}

const initState: CollectionScreen = {
  info: {
    name: '',
    imageUrl: '',
  },
  id: '',
  filteredRequests: [],
  avatars: [],
  scenes: [],
  garments: [],
  outfits: [],
  searchProductType: PRODUCT_TYPE.UNDEFINED,
  searchPattern: '',
  filters: {},
  mode: ScreenMode.EDIT,
  loading: false,
  garmentsLoading: false,
  avatarsLoading: false,
  scenesLoading: false,
  outfitsLoading: false,
};

@Injectable()
export class CollectionScreenStore
  extends ComponentStore<CollectionScreen>
  implements OnStoreInit, OnStateInit
{
  constructor(
    private _requestService: IRequestService,
    private _capService: ICentralAssetPlatformService,
  ) {
    super(initState);
  }
  ngrxOnStateInit() {}
  ngrxOnStoreInit() {}

  readonly reset = this.updater((_) => {
    return initState;
  });

  readonly setLoading = this.updater((state, loading: boolean) => {
    return {
      ...state,
      loading,
    };
  });

  readonly setGarmentsLoading = this.updater((state, loading: boolean) => {
    return {
      ...state,
      garmentsLoading: loading,
    };
  });

  readonly setAvatarsLoading = this.updater((state, loading: boolean) => {
    return {
      ...state,
      avatarsLoading: loading,
    };
  });

  readonly setScenesLoading = this.updater((state, loading: boolean) => {
    return {
      ...state,
      scenesLoading: loading,
    };
  });

  readonly setOutfitsLoading = this.updater((state, loading: boolean) => {
    return {
      ...state,
      outfitsLoading: loading,
    };
  });

  readonly setMode = this.updater((state, mode: ScreenMode) => {
    return {
      ...state,
      mode,
    };
  });

  readonly setId = this.updater((state, id: string) => {
    return {
      ...state,
      id,
    };
  });

  readonly moveAvatar = this.updater(
    (state, data: { id: string; direction: 'up' | 'down' }) => {
      const avatars =
        data.direction === 'up'
          ? this.moveUp(state.avatars, data.id)
          : this.moveDown(state.avatars, data.id);
      return {
        ...state,
        avatars,
      };
    },
  );
  readonly moveScene = this.updater(
    (state, data: { id: string; direction: 'up' | 'down' }) => {
      const scenes =
        data.direction === 'up'
          ? this.moveUp(state.scenes, data.id)
          : this.moveDown(state.scenes, data.id);
      return {
        ...state,
        scenes,
      };
    },
  );
  readonly moveGarment = this.updater(
    (state, data: { id: string; direction: 'up' | 'down' }) => {
      const garments =
        data.direction === 'up'
          ? this.moveUp(state.garments, data.id)
          : this.moveDown(state.garments, data.id);
      return {
        ...state,
        garments,
      };
    },
  );
  readonly moveOutfit = this.updater(
    (state, data: { id: string; direction: 'up' | 'down' }) => {
      const outfits =
        data.direction === 'up'
          ? this.moveUp(state.outfits, data.id)
          : this.moveDown(state.outfits, data.id);
      return {
        ...state,
        outfits: outfits,
      };
    },
  );

  moveUp = (list: any[], id: string) => {
    const index = list.findIndex((a) => a.id === id);
    if (index > 0) {
      const temp = list[index - 1];
      list[index - 1] = list[index];
      list[index] = temp;
    }

    return list;
  };

  moveDown = (list: any[], id: string) => {
    const index = list.findIndex((a) => a.id === id);
    if (index < list.length - 1) {
      const temp = list[index + 1];
      list[index + 1] = list[index];
      list[index] = temp;
    }
    return list;
  };

  readonly setAvatars = this.effect(
    (avatars: Observable<CollectionAvatarReference[]>) => {
      return avatars.pipe(
        switchMap(async (avatars) => {
          this.setAvatarsLoading(true);
          const requests$ = avatars.map(async (avatar) => {
            return this._capService
              .getThumbnail(avatar.organizationId, avatar.reference)
              .then((res) => {
                return {
                  ...avatar,
                  thumbnail: res.thumbnail,
                };
              });
          });
          const t = await Promise.all(requests$);
          const idRequests = t.map((result) =>
            this._capService
              .getId(result.organizationId, result.reference)
              .then((res) => ({
                ...result,
                id: res.id,
              })),
          );

          const resultsWithIds = await Promise.all(idRequests);

          for (const result of resultsWithIds) {
            this.addOrUpdateAvatar(result);
          }
          this.setAvatarsLoading(false);
        }),
      );
    },
  );

  readonly setScenes = this.effect(
    (scenes: Observable<CollectionSceneReference[]>) => {
      return scenes.pipe(
        switchMap(async (scenes) => {
          this.setScenesLoading(true);
          const requests$ = scenes.map((scene) => {
            return this._capService
              .getThumbnail(scene.organizationId, scene.reference)
              .then((res) => {
                return {
                  ...scene,
                  thumbnail: res.thumbnail,
                };
              });
          });
          const t = await Promise.all(requests$);
          const idRequests = t.map((result) =>
            this._capService
              .getId(result.organizationId, result.reference)
              .then((res) => ({
                ...result,
                id: res.id,
              })),
          );

          const resultsWithIds = await Promise.all(idRequests);

          for (const result of resultsWithIds) {
            this.addOrUpdateScene(result);
          }
          this.setScenesLoading(false);
        }),
      );
    },
  );

  readonly setGarments = this.effect(
    (garments: Observable<CollectionGarmentReference[]>): Observable<void> => {
      return garments.pipe(
        switchMap(async (garments) => {
          this.setGarmentsLoading(true);
          const requests$ = garments.map((garment) => {
            return this._capService
              .getThumbnail(garment.organizationId, garment.reference)
              .then((res) => {
                if (res.thumbnail == undefined) {
                  console.warn('Thumbnail not found for ' + garment.reference);
                }
                return {
                  ...garment,
                  thumbnail: res.thumbnail,
                };
              });
          });
          const t = await Promise.all(requests$);
          const idRequests = t.map((result) =>
            this._capService
              .getId(result.organizationId, result.reference)
              .then((res) => ({
                ...result,
                id: res.id,
              })),
          );

          const resultsWithIds = await Promise.all(idRequests);

          for (const result of resultsWithIds) {
            this.addOrUpdateGarment(result);
          }
          this.setGarmentsLoading(false);
        }),
      );
    },
  );

  readonly setOutfits = this.effect(
    (outfits: Observable<OutfitReference[]>) => {
      return outfits.pipe(
        switchMap(async (outfits) => {
          this.setOutfitsLoading(true);
          for (const outfit of outfits) {
            await this._requestService
              .getRequestByID(outfit.id)
              .then((request) => {
                const result: OutfitReferenceJson = {
                  id: request.id,
                  name: request.name,
                  organizationId: request.customerId,
                  reference: `gid://pictofit/sku/${request.sku}`,
                  thumbnail: request.previewUrl,
                  resultUrl:
                    request.productTypeId === PRODUCT_TYPE.OUTFIT_2D_PARALLAX
                      ? getOutfitParallaxInfoUrl(request)
                      : undefined,
                };
                this.addOrUpdateOutfit(result);
              });
          }
          this.setOutfitsLoading(false);
        }),
      );
    },
  );

  readonly setFilters = this.updater(
    (state, filters: SelectedFilters<CollectionFilters>) => ({
      ...state,
      filters: filters,
    }),
  );

  readonly setCollection = async (id: string, collection: ICollection) => {
    this.reset();
    await Promise.all([
      this.setId(id),
      this.setInfoName(collection.info.name),
      this.setInfoImageUrl(collection.info.imageUrl),
      this.setGarments(collection.garments),
      this.setAvatars(collection.avatars),
      this.setScenes(collection.scenes),
      this.setOutfits(collection.outfits),
    ]);
  };
  //updaters
  readonly setFilteredCollections = this.updater(
    (state, data: IReferenceSelectionData[]) => ({
      ...state,
      filteredRequests: data,
    }),
  );
  readonly setInfoName = this.updater((state, data: string) => ({
    ...state,
    info: { ...state.info, name: data },
  }));
  readonly setInfoImageUrl = this.updater((state, data: string) => ({
    ...state,
    info: { ...state.info, imageUrl: data },
  }));

  readonly addOrUpdateAvatar = this.updater((state, data: AssetReference) => {
    const index = state.avatars.findIndex((x) => x.id === data.id);
    //add new, if not found
    if (index === -1) {
      return {
        ...state,
        avatars: [...state.avatars, data],
      };
    }
    //update existing
    state.avatars[index] = data;
    return {
      ...state,
      avatars: [...state.avatars],
    };
  });

  readonly updateThumbnail = this.updater(
    (state, data: { id: uuid; thumbnail: string }) => {
      return {
        ...state,
        avatars: state.avatars.map((x) => {
          if (x.id === data.id) {
            return {
              ...x,
              thumbnail: data.thumbnail,
            };
          }
        }),
      };
    },
  );

  readonly removeAvatar = this.updater((state, data: AssetReference) => {
    const avatars = state.avatars.filter((x) => x.id !== data.id);
    return {
      ...state,
      avatars: avatars,
    };
  });

  readonly addOrUpdateScene = this.updater((state, data: AssetReference) => {
    const index = state.scenes.findIndex((x) => x.id === data.id);
    //add new, if not found
    if (index === -1) {
      return {
        ...state,
        scenes: [...state.scenes, data],
      };
    }
    //update existing
    state.scenes[index] = data;
    return {
      ...state,
      scenes: [...state.scenes],
    };
  });
  readonly removeScene = this.updater((state, data: AssetReference) => {
    const scenes = state.scenes.filter((x) => x.id !== data.id);
    return {
      ...state,
      scenes: scenes,
    };
  });

  readonly addOrUpdateGarment = this.updater((state, data: AssetReference) => {
    const index = state.garments.findIndex((x) => x.id === data.id);
    //add new, if not found
    if (index === -1) {
      return {
        ...state,
        garments: [...state.garments, data],
      };
    }
    //update existing
    state.garments[index] = data;
    return {
      ...state,
      garments: [...state.garments],
    };
  });
  readonly removeGarment = this.updater((state, data: AssetReference) => {
    const garments = state.garments.filter((x) => x.id !== data.id);
    return {
      ...state,
      garments: garments,
    };
  });

  readonly addOrUpdateOutfit = this.updater(
    (state, data: OutfitReferenceJson) => {
      const index = state.outfits.findIndex((x) => x.id === data.id);
      //add new, if not found
      if (index === -1) {
        return {
          ...state,
          outfits: [...state.outfits, data],
        };
      }
      //update existing
      state.outfits[index] = data;
      return {
        ...state,
        outfits: [...state.outfits],
      };
    },
  );

  readonly removeOutfit = this.updater((state, data: OutfitReferenceJson) => {
    const outfits = state.outfits.filter((x) => x.id !== data.id);
    return {
      ...state,
      outfits: outfits,
    };
  });

  readonly setSearchProductType = this.updater((state, data: PRODUCT_TYPE) => ({
    ...state,
    searchProductType: data,
    searchPattern: '',
  }));

  //selects
  readonly info$ = this.select((state) => state.info);
  readonly infoImage$ = this.select((state) => state.info.imageUrl);
  readonly infoName$ = this.select((state) => state.info.name);
  readonly filteredRequests$ = this.select((state) => state.filteredRequests);
  readonly avatars$ = this.select((state) => state.avatars);
  readonly avatarById$ = this.select(
    (state) => (id: string) => state.avatars.find((x) => x.id === id),
  );
  readonly scenes$ = this.select((state) => state.scenes);
  readonly garments$ = this.select((state) => state.garments);
  readonly outfits$ = this.select((state) => state.outfits);
  readonly searchProductType$ = this.select((state) => state.searchProductType);

  readonly avatarsVisible$ = this.select(
    (state) => state.searchProductType === PRODUCT_TYPE.AVATAR_2D,
  );
  readonly scenesVisible$ = this.select(
    (state) => state.searchProductType === PRODUCT_TYPE.SCENE,
  );
  readonly garmentsVisible$ = this.select(
    (state) => state.searchProductType === PRODUCT_TYPE.GARMENT_2D,
  );
  readonly outfitsVisible$ = this.select(
    (state) => state.searchProductType === PRODUCT_TYPE.OUTFIT_2D_PARALLAX,
  );
  readonly searchPattern$ = this.select((state) => state.searchPattern);
  readonly filters$ = this.select((state) => state.filters);
  readonly screenMode$ = this.select((state) => state.mode);
  readonly id$ = this.select((state) => state.id);
  readonly loading$ = this.select((state) => {
    return (
      state.garmentsLoading ||
      state.scenesLoading ||
      state.avatarsLoading ||
      state.outfitsLoading
    );
  });
  readonly garmentsLoading$ = this.select((state) => state.garmentsLoading);
  readonly scenesLoading$ = this.select((state) => state.scenesLoading);
  readonly avatarsLoading$ = this.select((state) => state.avatarsLoading);
  readonly outfitsLoading$ = this.select((state) => state.outfitsLoading);
}
